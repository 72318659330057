import { useState, useEffect } from 'react';

export const useStoredState = <T>(
  storageKey: string,
  defaultValue: T,
  parseFunction: (rawValue: string) => T = input => JSON.parse(`${input}`),
  toStringFunction: (rawValue: T) => string = output => JSON.stringify(output)
): [T, (value: T) => void, (value: T) => void] => {
  const storedValue = localStorage.getItem(storageKey);
  const startupValue = storedValue ? parseFunction(storedValue) : defaultValue;
  const [value, setValue] = useState<T>(startupValue);
  useEffect(() => {
    localStorage.setItem(storageKey, toStringFunction(value));
  }, [value, storageKey, toStringFunction]);

  const storeManually = (manualValue: T) => {
    localStorage.setItem(storageKey, toStringFunction(manualValue));
  };

  return [value, setValue, storeManually];
};

export const useStoredStringState = (
  storageKey: string,
  defaultValue: string
) =>
  useStoredState(
    storageKey,
    defaultValue,
    input => input,
    output => `${output}`
  );
