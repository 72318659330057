import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Error } from 'spot-store';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Notification } from '../components/notification';

export function useErrorNotification(
  severity: AlertColor = 'error',
  title: string | undefined = undefined,
  duration = 5000
) {
  const [notification, setNotification] = useState<ReactNode | null>(null);
  const { t } = useTranslation();

  const [notificationQueue, setNotificationQueue] = useState<ReactNode[]>([]);

  const handleSnackClose = useCallback(() => {
    setNotification(null);
  }, [setNotification]);

  const displayErrors = useCallback(
    (errorsArray: Error[] | undefined) => {
      if (errorsArray) {
        const notifications = errorsArray.map(error => {
          let message = error.body;
          try {
            const newMessage = JSON.parse(error.body ?? '')?.message;
            message = newMessage ?? message;
          } catch (e) {
            // nothing here.
          }

          return (
            <Notification
              key={error.status}
              title={title}
              onClose={handleSnackClose}
              severity={severity}
              message={`${message}`}
              duration={duration}
            />
          );
        });
        setNotificationQueue(oldQueue => [...oldQueue, ...notifications]);
      }
    },
    [handleSnackClose, duration, title, setNotificationQueue, severity]
  );

  const displaySuccess = useCallback(() => {
    setNotificationQueue(oldQueue => [
      ...oldQueue,
      <Notification
        key="success"
        onClose={handleSnackClose}
        severity="success"
        message={t('success')}
        duration={duration}
      />
    ]);
  }, [handleSnackClose, duration, t, setNotificationQueue]);

  useEffect(() => {
    if (notificationQueue.length > 0 && !notification) {
      setNotification(notificationQueue.shift());
    }
  }, [notificationQueue, setNotification, notification]);

  return { notification, displayErrors, displaySuccess };
}
