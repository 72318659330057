import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Typography, Alert } from '@mui/material';
import { ExitToAppRounded as PopoutIcon } from '@mui/icons-material';

import { CustomDialog, Gap, Table } from '../../components';
import { openInNewTab } from '../../framework';

export interface FeedValidationError {
  valid: boolean;
  errors: string[];
  warnings: string[];
  itemGroupId: string | null;
  id: string | null;
  title: string | null;
  size: string | null;
  link: string | null;
  gender: string | null;
  availability: string | null;
}

export interface FeedValidationResults {
  itemCount: number;
  errorCount: number;
  errors: FeedValidationError[];
}

export function FeedValidationDialog({
  feedValidationResults,
  onClose
}: {
  feedValidationResults: FeedValidationResults | null;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!feedValidationResults?.errorCount);
  }, [feedValidationResults, setOpen]);

  const tableHeaders = [
    t('id'),
    t('groupId'),
    t('title'),
    t('size'),
    t('gender'),
    t('availability'),
    t('errors'),
    t('warnings'),
    ''
  ];

  const tableRows =
    feedValidationResults?.errors
      .filter(e => !e.valid)
      .slice(0, 100)
      .map(e => ({
        id: e.id,
        cells: [
          e.id,
          e.itemGroupId,
          e.title,
          e.size,
          e.gender,
          e.availability,
          <Alert severity="error" key="errors">
            {e.errors.map(r => (
              <div key={r}>{r}</div>
            ))}
          </Alert>,
          <Alert severity="warning" key="warnings">
            {e.warnings.map(r => (
              <div key={r}>{r}</div>
            ))}
          </Alert>,
          <IconButton
            disabled={!e.link}
            onClick={() => openInNewTab(e.link ?? '')}
            size="large"
            key="button"
          >
            <PopoutIcon />
          </IconButton>
        ]
      })) ?? [];

  const renderControl = () => {
    return (
      <>
        <Typography variant="subtitle1">
          {t('feedValidationsErrors', {
            items: feedValidationResults?.itemCount ?? 0,
            errors: feedValidationResults?.errorCount ?? 0
          })}
        </Typography>
        <Gap />
        <Table rows={tableRows} headers={tableHeaders} maxHeight="60vh" />
      </>
    );
  };

  const close = () => {
    setOpen(false);
    onClose();
  };

  return CustomDialog({
    title: t('feedValidationInfo'),
    initialValue: true,
    dialogOpen: open,
    renderControl,
    onClose: close,
    onSubmit: undefined,
    maxWidth: 'xl',
    rejectLabel: t('close')
  });
}
