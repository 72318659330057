import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Tabs as MaterialTabs,
  Tab as MaterialTab,
  Box,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isNotNullOrUndefined } from 'framework';

import { HorizontalLoadingBar } from './horizontal-loading-bar';
import { Gap } from './spacer';

const StyledTab = styled(Box)<{ newDecorator: boolean }>`
  padding-right: ${p => (p.newDecorator ? '25px' : 'unset')};
`;

const NewDecorator = styled(Typography)`
  font-size: 9px;
  position: absolute;
  top: 50%;
  right: 5px;
  background-color: ${p => p.theme.palette.primary.main};
  color: ${p => p.theme.palette.primary.contrastText};
  padding: 2px 4px;
  border-radius: 5px;
  transform: translate(0%, -50%);
`;

const StyledTabs = styled(MaterialTabs)`
  margin-top: ${p => p.theme.spacing(1)};
  margin-bottom: 10px;
  .MuiTabs-flexContainer {
    border-bottom: 1px solid ${p => p.theme.palette.grey['500']};
  }
  .MuiTabs-fixed {
    margin-bottom: ${p => p.theme.spacing(3)};
  }
  ${p => p.theme.breakpoints.down('lg')} {
    max-width: calc(100vw - 80px);
  }
`;

const LoadingContainer = styled(Box)<{ tabshidden: string }>`
  position: relative;
  top: ${p =>
    p.tabshidden === 'true' ? 0 : `calc(52px + ${p.theme.spacing(1)})`};
`;

export interface TabParams {
  tab?: string;
}

interface Props {
  selected?: string;
  tabs: { [k: string]: JSX.Element | null };
  newTabs?: string[];
  onTabChange?: (tab: string) => unknown;
  loading?: boolean;
  hideIfSingle?: boolean;
}

function Tabs({
  selected,
  tabs,
  newTabs,
  onTabChange,
  loading,
  hideIfSingle
}: Props) {
  if (Object.keys(tabs).length === 0) {
    throw new Error('Must specify more than 0 tabs');
  }
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<string>(
    selected ||
      Object.keys(tabs).filter(key => isNotNullOrUndefined(tabs[key]))?.[0]
  );

  const handleTabChange = (event: unknown, selectedTab: string) => {
    setActiveTab(selectedTab);
    onTabChange && onTabChange(selectedTab);
  };

  const hideTabs =
    hideIfSingle && Object.values(tabs).filter(v => !!v).length === 1;

  return (
    <>
      <LoadingContainer tabshidden={`${!!hideTabs}`}>
        <HorizontalLoadingBar loading={loading ?? false} />
      </LoadingContainer>
      {hideTabs ? (
        <Gap />
      ) : (
        <StyledTabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
          textColor="primary"
        >
          {Object.keys(tabs)
            .filter(tabName => isNotNullOrUndefined(tabs[tabName]))
            .map(tabName => (
              <MaterialTab
                key={tabName}
                label={
                  <StyledTab newDecorator={newTabs?.includes(tabName) ?? false}>
                    {t(tabName) ?? tabName.split(/(?=[A-Z])/).join(' ')}
                    {!!newTabs?.includes(tabName) && (
                      <NewDecorator>{t('new')}</NewDecorator>
                    )}
                  </StyledTab>
                }
                value={tabName}
              />
            ))}
        </StyledTabs>
      )}
      {tabs[activeTab]}
    </>
  );
}

export { Tabs };
