import React, { useCallback, useState } from 'react';
import {
  Box,
  MenuItem,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  Typography,
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { ProductGender, useSpot } from '../../framework';
import { Capitalized, Line } from '../styled';
import {
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection
} from '../form-input';
import { Gap, Spacer } from 'components/spacer';

export interface ConnectProductDialogProps {
  dialogOpen: boolean;
  onSubmit: (tagName: string) => Promise<void>;
  onClose: () => unknown;
  productGender?: ProductGender;
  productType?: 'top' | 'bottom' | 'fullbody' | 'jeans';
}

const StyledDialog = styled(MaterialDialog)`
  .MuiDialog-paperScrollPaper {
    ${p => p.theme.breakpoints.down('md')} {
      margin: 0;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    position: relative;
  }
`;

const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export const ConnectProductDialog = ({
  onSubmit,
  onClose,
  dialogOpen,
  productGender,
  productType
}: ConnectProductDialogProps) => {
  const { t } = useTranslation();
  const { query, data, loading } = useSpot();
  const [step, setStep] = useState(0);
  const [gender, setGender] = useState<ProductGender>(
    productGender ?? 'unisex'
  );
  const [product, setProduct] = useState<
    'top' | 'bottom' | 'fullbody' | 'jeans'
  >(productType ?? 'fullbody');

  const onNext = useCallback(async () => {
    setStep(step => step + 1);
  }, [step, setStep]);

  const onBack = useCallback(async () => {
    setStep(step => step - 1);
  }, [step, setStep]);

  const genderSelected = useCallback(
    event => {
      setGender(event.target.value);
    },
    [setGender]
  );

  const productSelected = useCallback(
    event => {
      setProduct(event.target.value);
    },
    [setProduct]
  );

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      onSubmit(`Faslet_${product}_${gender}`);
      onClose();
    },
    [onSubmit, product, gender]
  );

  return (
    <>
      <StyledDialog
        open={dialogOpen}
        onClose={onClose}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogForm noValidate onSubmit={handleSubmit}>
          <DialogTitle>{t('connectProductTitle')}</DialogTitle>
          <Line />
          <DialogContent>
            <Gap />
            <Box>
              <Stepper activeStep={step}>
                <Step>
                  <StepLabel>{t('selectProductGender')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('selectProductType')}</StepLabel>
                </Step>
              </Stepper>
              <Box>
                <Gap />
                {step === 0 && (
                  <>
                    <FormSection>
                      <FormRow>
                        <FormLabel size="narrow">
                          {t('productGender')}
                        </FormLabel>
                        <FormFieldWrapper>
                          <Select
                            value={gender}
                            onChange={genderSelected}
                            fullWidth
                          >
                            <MenuItem value="unisex">{t('unisex')}</MenuItem>
                            <MenuItem value="female">{t('female')}</MenuItem>
                            <MenuItem value="male">{t('male')}</MenuItem>
                          </Select>
                        </FormFieldWrapper>
                      </FormRow>
                      <Typography variant="subtitle2">
                        {t('selectProductGenderInfo')}
                      </Typography>
                    </FormSection>
                  </>
                )}
                {step === 1 && (
                  <>
                    <FormSection>
                      <FormRow>
                        <FormLabel size="narrow">{t('productType')}</FormLabel>
                        <FormFieldWrapper>
                          <Select
                            value={product}
                            onChange={productSelected}
                            fullWidth
                          >
                            <MenuItem value="fullbody">
                              {t('fullbody')}
                            </MenuItem>
                            <MenuItem value="top">{t('top')}</MenuItem>
                            <MenuItem value="bottom">{t('bottom')}</MenuItem>
                            <MenuItem value="jeans">{t('jeans')}</MenuItem>
                          </Select>
                        </FormFieldWrapper>
                      </FormRow>
                      <Typography variant="subtitle2">
                        {t('selectProductTypeInfo')}
                      </Typography>
                    </FormSection>
                  </>
                )}
              </Box>
            </Box>
            <Gap />
          </DialogContent>
          <Spacer />
          <DialogActions>
            {step === 1 && (
              <Button onClick={onBack} color="secondary" disabled={loading}>
                <Capitalized>{t('back')}</Capitalized>
              </Button>
            )}
            {step === 0 && (
              <Button onClick={onClose} color="secondary" disabled={loading}>
                <Capitalized>{t('cancel')}</Capitalized>
              </Button>
            )}
            {step === 1 && (
              <Button color="primary" type="submit">
                <Capitalized>
                  {loading ? <CircularProgress size={24} /> : t('submit')}
                </Capitalized>
              </Button>
            )}
            {step === 0 && (
              <Button onClick={onNext} color="primary">
                <Capitalized>
                  {loading ? <CircularProgress size={24} /> : t('next')}
                </Capitalized>
              </Button>
            )}
          </DialogActions>
        </DialogForm>
      </StyledDialog>
    </>
  );
};
