import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { SaveRounded as SaveIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';

import { Brand, useSpot } from 'framework';
import {
  Row,
  Container,
  Gap,
  Spacer,
  BrandSelector,
  CenteredRow
} from 'components';
import { MultiplierSlider } from './multiplier-slider';

const SliderWrapper = styled(Row)`
  padding: ${p => p.theme.spacing(2)};
`;

interface TransformedMultipliers {
  [k: string]: {
    id: number;
    value: number;
    profile: number;
    measurement: string;
    brand: {
      slug: string;
      name: string;
      logo?: string;
      id: number;
    };
  }[];
}

interface MultipliersProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function Multipliers({ onLoadingChanged }: MultipliersProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { brandOrProduct: brand } = useParams<{ brandOrProduct?: string }>();
  const { spot, query, command, loading } = useSpot();
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);

  const [multipliers, setMultipliers] = useState<TransformedMultipliers>({});

  useEffect(() => {
    if (spot.data.loading) {
      return;
    }
    navigate(`/size-and-fit/multipliers/${selectedBrand?.slug ?? ''}`);
  }, [selectedBrand, navigate, spot]);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const updateMultipliers = useCallback(() => {
    if (!spot.data.multipliers) {
      return;
    }

    let newMultipliers = spot.data.multipliers
      ?.filter(multiplier => !multiplier.brand)
      ?.reduce(
        (accum, current) => ({
          ...accum,
          [current.measurement]: [
            ...(accum[current.measurement] ?? []),
            {
              id: current.id,
              value: Number(current.value),
              profile: current.profile,
              measurement: current.measurement,
              brand: current.brand
            }
          ]
        }),
        {} as TransformedMultipliers
      );

    if (selectedBrand) {
      newMultipliers = spot.data.multipliers
        ?.filter(multiplier => multiplier.brand?.id === selectedBrand?.id)
        ?.reduce((accum, current) => {
          const multiplierToOverride = accum[current.measurement].find(
            mul => mul.profile === current.profile
          );
          if (multiplierToOverride) {
            multiplierToOverride.brand = current.brand;
            multiplierToOverride.value = Number(current.value);
            multiplierToOverride.id = current.id;
          } else {
            accum[current.measurement].push({
              ...current
            });
          }

          return accum;
        }, newMultipliers);
    }

    setMultipliers(newMultipliers);
  }, [spot, setMultipliers, selectedBrand]);

  const refresh = useCallback(async () => {
    const promises: Promise<unknown>[] = [];

    promises.push(query('multiplier/', {}, ['multipliers']));
    if (!spot.data.brands) {
      promises.push(query('brand/', {}, ['brands']));
    }

    await Promise.all(promises);

    updateMultipliers();
  }, [query, updateMultipliers, spot]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    updateMultipliers();
  }, [updateMultipliers]);

  const handleSave = useCallback(async () => {
    const promises = Object.values(multipliers)
      .reduce((accum, curr) => [...accum, ...curr], [])
      .map(multiplier => {
        const old = spot.data.multipliers?.find(m => multiplier.id === m.id);

        if (Number(old?.value ?? '') !== multiplier.value) {
          if (old?.brand?.id !== selectedBrand?.id) {
            return command(
              'multiplier/',
              {
                profile: multiplier.profile,
                brand: selectedBrand?.slug,
                value: multiplier.value,
                measurement: multiplier.measurement
              },
              { method: 'POST' }
            );
          }

          return command(
            `multiplier/${multiplier.id}`,
            { ...multiplier },
            { method: 'PATCH' }
          );
        }

        return Promise.resolve();
      });

    await Promise.all(promises);

    await refresh();
  }, [command, multipliers, spot.data, refresh, selectedBrand]);

  const handleChange = useCallback(
    (measurement: string, newValue: number[]) => {
      const newMultipliers = { ...multipliers };

      (newValue as number[]).forEach((value, index) => {
        const mult = newMultipliers[measurement].find(
          m => m.profile === index + 1
        );
        if (mult) {
          mult.value = value;
        }
      });

      setMultipliers(newMultipliers);
    },
    [setMultipliers, multipliers]
  );

  return (
    <Container padding={0}>
      <CenteredRow>
        <BrandSelector
          includeAllBrands
          onBrandChanged={setSelectedBrand}
          selectedBrand={selectedBrand}
          initialBrandSlug={brand}
        />
        <Spacer />
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          {t('save')}
        </Button>
      </CenteredRow>
      <Gap />
      <Container padding={2}>
        <Row>
          <Typography variant="h5">{t('fit')}</Typography>
        </Row>
        <SliderWrapper>
          <MultiplierSlider
            id="fit"
            values={
              multipliers.fit
                ?.sort((v1, v2) => v1.profile - v2.profile)
                .map(fm => fm.value) ?? []
            }
            onValuesChanged={handleChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('chest')}</Typography>
        </Row>
        <SliderWrapper>
          <MultiplierSlider
            id="chest"
            values={
              multipliers.chest
                ?.sort((v1, v2) => v1.profile - v2.profile)
                .map(fm => fm.value) ?? []
            }
            onValuesChanged={handleChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('waist')}</Typography>
        </Row>
        <SliderWrapper>
          <MultiplierSlider
            id="waist"
            values={
              multipliers.waist
                ?.sort((v1, v2) => v1.profile - v2.profile)
                .map(fm => fm.value) ?? []
            }
            onValuesChanged={handleChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('hips')}</Typography>
        </Row>
        <SliderWrapper>
          <MultiplierSlider
            id="hips"
            values={
              multipliers.hips
                ?.sort((v1, v2) => v1.profile - v2.profile)
                .map(fm => fm.value) ?? []
            }
            onValuesChanged={handleChange}
          />
        </SliderWrapper>
      </Container>
    </Container>
  );
}
