import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import React, { useCallback } from 'react';
import Papa from 'papaparse';
import { Error } from 'spot-store';
import styled from '@emotion/styled';

import { FileInputButton } from './file-input-button';
import { Row, Container } from './layout';
import { Table } from './table';
import {
  ExtendedRetailer,
  getCdnPath,
  useErrorNotification,
  useSpot,
  WidgetConfiguration
} from '../framework';

const languages = [
  'en',
  'nl',
  'de',
  'fr',
  'es',
  'nb',
  'sv',
  'da',
  'it',
  'pl',
  'hr',
  'pt'
];

const LinkField = styled(Row)`
  ${p => p.theme.breakpoints.up('sm')} {
    min-width: 400px;
  }
`;

const LocalLink = styled(Link)`
  padding-left: 10px;
`;

export interface WidgetLocalizationProps {
  selectedRetailer: ExtendedRetailer;
  updateWidgetConfiguration: (
    widgetConfiguration: WidgetConfiguration | null
  ) => unknown;
  widgetConfiguration: WidgetConfiguration | null | undefined;
}

export function WidgetLocalization({
  selectedRetailer,
  updateWidgetConfiguration,
  widgetConfiguration
}: WidgetLocalizationProps) {
  const { t } = useTranslation();
  const { raw } = useSpot();
  const { displayErrors, notification } = useErrorNotification();

  const handleLocalizationUpload = useCallback(
    async (retailerObj: ExtendedRetailer, language: string, file: File) => {
      if (retailerObj) {
        let uploadBody;
        let fileName = file.name;

        const extension = file.name.substring(file.name.lastIndexOf('.') + 1);
        if (extension === 'csv') {
          const parsed = (await new Promise((resolve, reject) => {
            Papa.parse(file, {
              header: false,
              complete: resolve,
              error: reject,
              dynamicTyping: true
            });
          })) as Papa.ParseResult<string[]>;

          const result = parsed.data.reduce((accum, curr) => {
            return { ...accum, [curr[0]]: curr[1] };
          }, {});

          uploadBody = new Blob([JSON.stringify(result)], {
            type: 'application/json'
          });

          fileName = `${file.name.substring(
            0,
            file.name.lastIndexOf('.')
          )}.json`;
        } else if (extension === 'json') {
          uploadBody = file;
        } else {
          displayErrors([{ message: 'Invalid file type' }]);
          return;
        }

        const urlResult = await raw<{ url: string }>(
          `retailer/${retailerObj.slug}/file-upload-url/${fileName}`
        );
        const url = urlResult?.url;

        if (url) {
          try {
            const uploadResponse: Response = await fetch(url, {
              method: 'PUT',
              body: uploadBody
            });

            if (uploadResponse.ok) {
              const localizationUrl = new URL(url);
              const cdnPath = `${getCdnPath()}${localizationUrl.pathname}`;

              updateWidgetConfiguration({
                ...widgetConfiguration,
                localizationOverrides: {
                  ...(widgetConfiguration?.localizationOverrides ?? {}),
                  [language]: cdnPath
                }
              });
            }
          } catch (e) {
            displayErrors(e as Error[]);
          }
        }
      }
    },
    [raw, displayErrors, widgetConfiguration, updateWidgetConfiguration]
  );
  const rows = languages.map(language => ({
    id: language,
    cells: [
      t(language),
      <LinkField key={language}>
        {widgetConfiguration?.localizationOverrides?.[language] ? (
          <LocalLink
            href={widgetConfiguration?.localizationOverrides?.[language] ?? ''}
            target="_blank"
          >
            {`${language.toUpperCase()} - URL Link` ?? ''}
          </LocalLink>
        ) : null}
      </LinkField>,
      <FileInputButton
        key="button"
        buttonText={t('upload')}
        onFileSelected={f =>
          handleLocalizationUpload(selectedRetailer, language, f)
        }
      />
    ]
  }));
  const headers = [t('language'), t('link'), ''];

  return (
    <Container>
      <Table rows={rows} headers={headers} />
      {notification}
    </Container>
  );
}
