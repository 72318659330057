export const languages: string[] = [
  'en',
  'nl',
  'da',
  'de',
  'es',
  'fr',
  'hr',
  'it',
  'nb',
  'pl',
  'sv',
  'pt'
];

export const userAllowedKeys = [
  'CLOSE',
  'GO_BACK',
  'EDIT_YOUR_PREFERENCES',
  'NEXT',
  'SAVE',
  'SHOP_RECOMMENDS',
  'HOW_YOU_WEAR',
  'SHOP_NOW',
  'PROFILE',
  'TIGHTER',
  'LOOSER',
  'BELLY',
  'BUST',
  'HIPS',
  'MALE',
  'FEMALE',
  'GENDER',
  'SETTINGS',
  'HEIGHT',
  'WEIGHT',
  'TIGHT_OR_LOOSE',
  'YOUR_SIZE_IS',
  'PRIVACY_POLICY',
  'REMOVE_DATA',
  'ARE_YOU_SURE',
  'OPEN_TEXT',
  'TRY_LATER',
  'NOT_FOUND',
  'RESTART',
  'KEEP_SHOPPING',
  'SELECT_PRODUCT_OPTION',
  'LOADING',
  'AGE',
  'AGE_PLACEHOLDER',
  'AGE_SETTING',
  'WEIGHT_KG',
  'WEIGHT_LB',
  'HEIGHT_CM',
  'HEIGHT_FT',
  'SIR_TEXT_CART',
  'SIR_TEXT_RESULT',
  'SIZING_HELP_TITLE',
  'SIZING_HELP',
  'getYourSizeBanner',
  'rightSizeTitle',
  'rightSizeText',
  'weightTitle',
  'heightTitle',
  'genderTitle',
  'ageTitle',
  'backToTheShop',
  'addToCart',
  'thankYou',
  'thankYouName',
  'thankYouText',
  'weAdvise',
  'resultTitle',
  'resultNotFoundTitle',
  'resultNotFoundText',
  'version',
  'nameTitle',
  'enterName',
  'iWear',
  'referenceBrandTitle',
  'referenceModelTitle',
  'referenceSizeTitle',
  'multipleResultsReason',
  'resultSubtitleOutOfStock',
  'resultSubtitleNoVariant',
  'productRecommendations',
  'confirmDeleteProfile',
  'areYouSure',
  'confirmRemove',
  'buttonText',
  'buttonTextResult',
  'buttonTextResultName',
  'braSizeTitle',
  'feedbackTitle',
  'feedbackTextTitle',
  'feedbackTextPlaceholder',
  'feedbackSuccess',
  'tryLater'
];

export function flattenLocJson(
  resultingLocJson: Record<string, string>,
  locJson: Record<string, any>,
  parentKey?: string
): Record<string, string> {
  const flattened: Record<string, string> = {};
  Object.entries(locJson).forEach(([key, value]) => {
    if (typeof value === 'string') {
      resultingLocJson[`${parentKey ? `${parentKey}.` : ''}${key}`] = value;
    } else if (Array.isArray(value)) {
      resultingLocJson[`${parentKey ? `${parentKey}.` : ''}${key}[]`] =
        value.join(',');
    } else if (typeof value === 'object') {
      flattenLocJson(
        resultingLocJson,
        value,
        `${parentKey ? `${parentKey}.` : ''}${key}`
      );
    }
  });

  return flattened;
}

export function inflateLocJson(
  localization: Record<string, any>,
  inflated: Record<string, any>
) {
  Object.entries(localization).forEach(([key, value]) => {
    const [first, ...rest] = key.split('.');
    if (rest.length === 0 && key.includes('[')) {
      const values = value?.split(',');
      const resolvedKey = key.substring(0, key.indexOf('['));
      inflated[resolvedKey] = values;
    } else if (rest.length === 0) {
      inflated[key] = value;
    } else {
      const childKey = rest.join('.');
      const innerInflated = inflated[first] ?? {};
      inflateLocJson({ [childKey]: value }, innerInflated);
      inflated[first] = innerInflated;
    }
  });
}
