import { Box, Typography } from '@mui/material';
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import {
  DateBox,
  FallbackStatsComponent,
  Gap,
  Row,
  Spacer
} from '../../components';
import {
  openInNewTab,
  RetailerContext,
  RoleContext,
  useSpot
} from '../../framework';

import missedSalesBlocked from '../../images/missedSalesBlocked.png';

const MissedSalesComponent = lazy(() => import('./missed-sales-table'));

const StyledLink = styled.span`
  text-decoration: underline;
`;

const SectionDisabledContainer = styled(Box)`
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
`;

const SectionDisabledImage = styled.img`
  width: 100%;
  position: relative;
`;

const SectionDisabledTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function MissedSales() {
  const { t } = useTranslation();
  const { spot, loading } = useSpot();
  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  const { retailer } = useContext(RetailerContext);
  const { isAdmin } = useContext(RoleContext);

  const [showMissedSalesInsights, setShowMissedSalesInsights] = useState(false);

  useEffect(() => {
    const selectedRetailers = !retailer ? [...spot.data.retailers] : [retailer];
    const shouldShowMissedSalesInsights = selectedRetailers.reduce(
      (anyEnabled, curr) => anyEnabled || !!curr.features?.missedSalesInsights,
      false
    );

    setShowMissedSalesInsights(shouldShowMissedSalesInsights || isAdmin());
  }, [retailer, spot.data.retailers, isAdmin]);

  const handleMissedSalesInsightsUpsell = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Enable Missed Sales Insights&body=I'd like to enable Missed Sales Insights in my store`
    );
  }, []);

  return (
    <>
      <Row>
        <Box>
          <Typography variant="h4">{t('missedSalesTitle')}</Typography>
        </Box>
        <Spacer />
        <DateBox
          disabled={loading}
          onEndChange={setEndDate}
          onStartChange={setStartDate}
          allowedRanges={[
            'lastSevenDays',
            'lastThirtyDays',
            'lastMonth',
            'thisMonth',
            'custom'
          ]}
        />
      </Row>
      <Suspense fallback={<FallbackStatsComponent />}>
        {showMissedSalesInsights && (
          <MissedSalesComponent
            startDate={startDate}
            endDate={endDate}
            retailer={retailer}
          />
        )}
        {!showMissedSalesInsights && (
          <SectionDisabledContainer onClick={handleMissedSalesInsightsUpsell}>
            <SectionDisabledImage src={missedSalesBlocked} />
            <SectionDisabledTextContainer>
              <Typography variant="h4" color="primary">
                {t('missedSalesDisabled')}
              </Typography>
              <Gap size={2} />
              <Typography variant="subtitle2" color="primary">
                <Trans
                  i18nKey="missedSalesDisabledInfo"
                  components={[<StyledLink key="link" />]}
                />
              </Typography>
            </SectionDisabledTextContainer>
          </SectionDisabledContainer>
        )}
      </Suspense>
    </>
  );
}
