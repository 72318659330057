import React, { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface ImportedValueProps {
  oldValue: ReactNode;
  newValue: ReactNode;
  required?: boolean;
  noDeletes?: boolean;
}

export function ImportedValue({
  oldValue,
  newValue,
  required = false,
  noDeletes = false
}: ImportedValueProps) {
  const { t } = useTranslation();

  if (oldValue) {
    if (newValue) {
      if (oldValue !== newValue) {
        return (
          <span>
            {oldValue}
            &nbsp;&#8594;&nbsp;
            <b>{newValue}</b>
          </span>
        );
      }
      return <span>{oldValue}</span>;
    }
    if (required) {
      return (
        <span>
          {oldValue}
          &nbsp;&#8594;&nbsp;
          <Tooltip title={t('required') ?? 'Required'}>
            <span className="import-value-required">?</span>
          </Tooltip>
        </span>
      );
    }
    if (noDeletes) {
      return <span>{oldValue}</span>;
    }
    return (
      <span>
        <del>{oldValue}</del>
      </span>
    );
  }

  if (newValue) {
    return <span>{newValue}</span>;
  }

  if (required) {
    return (
      <Tooltip title={t('required') ?? 'Required'}>
        <span className="import-value-required">?</span>
      </Tooltip>
    );
  }

  return <span>&nbsp;</span>;
}
