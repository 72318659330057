import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Box, Typography, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { ResponsivePie } from '@nivo/pie';
import { useOrdinalColorScale } from '@nivo/colors';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

import {
  SizeChartLink,
  BrandLink,
  Gap,
  FormSection,
  FormRow,
  FormFieldWrapper,
  FormTextValue,
  useBrandLink,
  FormLabel
} from 'components';
import {
  Brand,
  ProductReturn,
  Retailer,
  RoleContext,
  formatStat,
  useSpot
} from 'framework';
import moment from 'moment';
import { reasonStrings } from './common';

const ChartContainer = styled(Box)`
  height: 400px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const TallChartContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 600px;
  position: relative;
  overflow: hidden;
`;

const GridParent = styled(Grid)`
  height: 600px;
`;

const ProductImageWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
`;

export function ProductReturns({
  startDate,
  endDate,
  retailer,
  brand
}: {
  startDate: moment.Moment;
  endDate: moment.Moment;
  retailer: Retailer | null;
  brand: Brand | null;
}) {
  const { t } = useTranslation();
  const { spot, query, loading } = useSpot();
  const { isAdmin } = useContext(RoleContext);

  const [selectedItem, setSelectedItem] = useState<ProductReturn>();
  const [returnReasons, setReturnReasons] = useState<
    { id: string; label: string; value: number }[]
  >([]);
  useBrandLink();

  const returnsColorScale = useOrdinalColorScale(
    { scheme: 'category10' },
    'id'
  );

  const [returnReasonColors] = useState<Record<string, string>>(
    reasonStrings.reduce(
      (accum, r) => ({
        ...accum,
        [r]: returnsColorScale({ id: r })
      }),
      {}
    )
  );

  const fetchProductReturns = useCallback(async () => {
    if (!retailer) {
      return;
    }

    const params: {
      startDate: string;
      endDate: string;
      retailers?: string[];
      brands?: string[];
    } = {
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString()
    };

    if (retailer) {
      params.retailers = [encodeURIComponent(retailer.name), retailer.slug];
    }

    if (brand) {
      params.brands = [encodeURIComponent(brand.name)];
    }

    await query('analytics/product-returns', params, [
      'returns',
      'productReturns'
    ]);
  }, [startDate, endDate, retailer, brand, query]);

  useEffect(() => {
    setSelectedItem(undefined);
    startDate && endDate && fetchProductReturns();
  }, [startDate, endDate, fetchProductReturns]);

  const onProductClicked = useCallback(
    ({ row }) => {
      setSelectedItem(row);
    },
    [setSelectedItem]
  );

  useEffect(() => {
    const calculatedReasonBreakdown =
      selectedItem?.returnReasons?.reduce(
        (accum, current) => {
          const found = accum.find(a => a.id === reasonStrings[current - 1]);
          if (found) {
            found.value++;
          } else {
            accum.push({
              id: reasonStrings[current - 1],
              label: reasonStrings[current - 1],
              value: 1
            });
          }
          return [...accum];
        },
        [] as { id: string; value: number; label: string }[]
      ) ?? [];

    setReturnReasons(calculatedReasonBreakdown);
  }, [selectedItem, setReturnReasons]);

  const columns: GridColumns = [
    {
      field: 'imageLink',
      headerName: t('productImage'),
      width: 80,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <ProductImageWrapper>
            <LazyLoadImage
              src={params.value as string}
              alt={params.row.name as string}
              height="100%"
              effect="opacity"
              decoding="async"
            />
          </ProductImageWrapper>
        ) : null
    },
    {
      field: 'correlationId',
      headerName: t('correlationId'),
      align: 'left'
    },
    { field: 'title', headerName: t('productTitle'), align: 'left', flex: 1 },
    {
      field: 'tagName',
      headerName: t('fasletStatus'),
      flex: 0.6,
      resizable: false,
      hide: !!selectedItem,
      renderCell: (params: GridCellParams) => (
        <SizeChartLink
          isAdmin={isAdmin}
          brand={params.row.brand?.name}
          brandSlug={params.row.brand?.slug}
          tagName={params.row.tagName}
          tagGender={params.row.tagGender}
          tagProductName={params.row.tagProductName}
        />
      )
    },
    {
      field: 'brand',
      headerName: t('brand'),
      flex: 0.8,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <BrandLink brand={params.row.brand?.name} retailer={retailer} />
      )
    },
    {
      field: 'returns',
      headerName: t('returns'),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value }) => formatStat(value),
      width: 140
    }
  ];

  const productRows = spot.data?.returns?.productReturns?.productReturns ?? [];

  return !!retailer && !!productRows.length ? (
    <Box>
      <Typography variant="h5">{t('topReturnedProducts')}</Typography>
      {!selectedItem && (
        <Typography variant="caption" color="primary">
          {t('returnsSelectProduct')}
        </Typography>
      )}
      <Gap size={2} />
      <GridParent container>
        <Grid item xs={selectedItem ? 7 : 12}>
          <DataGrid
            rows={productRows}
            columns={columns}
            getRowId={row => row.correlationId}
            onRowClick={onProductClicked}
            loading={loading}
            disableColumnMenu
            disableColumnSelector
            autoPageSize
            columnBuffer={2}
            headerHeight={40}
            rowHeight={52}
            initialState={{
              sorting: {
                sortModel: [{ field: 'returns', sort: 'desc' }]
              }
            }}
          />
        </Grid>
        {selectedItem && (
          <Grid item xs={5}>
            <TallChartContainer>
              <Gap />
              <Typography variant="subtitle2" color="primary" align="center">
                {t('returnsBreakdown')}
              </Typography>
              <ChartContainer>
                <ResponsivePie
                  data={returnReasons}
                  innerRadius={0.4}
                  padAngle={3}
                  cornerRadius={8}
                  activeOuterRadiusOffset={8}
                  valueFormat={val => formatStat(val, false, 0)}
                  colors={r => returnReasonColors[r.id]}
                  arcLabelsTextColor="#fff"
                  arcLabelsSkipAngle={10}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={3}
                  arcLinkLabelsDiagonalLength={8}
                  arcLinkLabelsStraightLength={32}
                  arcLinkLabelsColor={{ from: 'color' }}
                  margin={{
                    top: 40,
                    right: 80,
                    bottom: 40,
                    left: 80
                  }}
                  borderWidth={5}
                  borderColor="rgba(255,255,255,0)"
                />
              </ChartContainer>
              <FormSection>
                <FormRow>
                  <FormLabel>{t('totalReturns')}</FormLabel>
                  <FormFieldWrapper size="narrow">
                    <FormTextValue>
                      {formatStat(selectedItem.returns)}
                    </FormTextValue>
                  </FormFieldWrapper>
                </FormRow>
                <FormRow>
                  <FormLabel>{t('fasletReturns')}</FormLabel>
                  <FormFieldWrapper size="narrow">
                    <FormTextValue>
                      {formatStat(selectedItem.fasletReturns)}
                    </FormTextValue>
                  </FormFieldWrapper>
                </FormRow>
                <FormRow>
                  <FormLabel>{t('returnRate')}</FormLabel>
                  <FormFieldWrapper size="narrow">
                    <FormTextValue>
                      {formatStat(
                        (selectedItem.returns / selectedItem.orders) * 100,
                        true,
                        2
                      )}
                    </FormTextValue>
                  </FormFieldWrapper>
                </FormRow>
                <FormRow>
                  <FormLabel>{t('sizeRelatedReturnRate')}</FormLabel>
                  <FormFieldWrapper size="narrow">
                    <FormTextValue>
                      {formatStat(
                        (selectedItem.sizeRelatedReturns /
                          selectedItem.orders) *
                          100,
                        true,
                        2
                      )}
                    </FormTextValue>
                  </FormFieldWrapper>
                </FormRow>
              </FormSection>
            </TallChartContainer>
          </Grid>
        )}
      </GridParent>
    </Box>
  ) : null;
}
