import {
  BrandSelector,
  CenteredRow,
  Container,
  Gap,
  Row,
  Spacer,
  Tabs
} from 'components';
import {
  Brand,
  RetailerContext,
  RoleContext,
  useTabFromQuerystring
} from 'framework';
import React, { useContext, useState } from 'react';
import { StoreProductList } from './store-product-list';
import { UntaggedProducts } from './untagged-products';
import { Dictionary, IgnoreList } from './dictionaries';
import { useTranslation } from 'react-i18next';
import { Checkbox, Typography } from '@mui/material';

export function ProductsAndDictionaries() {
  const { tab, setTab } = useTabFromQuerystring('products');
  const [loading, setLoading] = useState(false);
  const { isAdmin } = useContext(RoleContext);
  const { t } = useTranslation();
  const { retailer } = useContext(RetailerContext);

  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [retailerBrandsOnly, setRetailerBrandsOnly] = useState<boolean>(false);

  const brandDisabled =
    ((tab === 'productDictionary' || tab === 'genderDictionary') &&
      !!retailer) ||
    tab === 'ignoreList';

  const resolvedBrand = brandDisabled ? null : selectedBrand;

  return (
    <Container>
      <CenteredRow>
        <Typography variant="h4">{t(tab ?? 'products')}</Typography>
        <Spacer />
        {isAdmin() && (
          <>
            <Typography>{t('retailerBrandsOnly')}</Typography>
            <Checkbox
              color="primary"
              checked={!!retailerBrandsOnly}
              onChange={e => setRetailerBrandsOnly(e.target.checked)}
              inputProps={{ 'aria-label': t('retailerBrandsOnly') }}
            />
            <Gap />
          </>
        )}
        <BrandSelector
          selectedBrand={resolvedBrand}
          includeAllBrands
          onBrandChanged={setSelectedBrand}
          disabled={brandDisabled || loading}
          retailer={retailerBrandsOnly ? retailer : null}
        />
      </CenteredRow>

      <Tabs
        selected={tab}
        onTabChange={setTab}
        hideIfSingle
        loading={loading}
        tabs={{
          products: (
            <StoreProductList
              onLoadingChanged={setLoading}
              selectedBrand={resolvedBrand}
              retailerBrandsOnly={retailerBrandsOnly}
            />
          ),
          productDictionary: isAdmin() ? (
            <Dictionary
              onLoadingChanged={setLoading}
              type="product"
              selectedBrand={resolvedBrand}
            />
          ) : null,
          genderDictionary: isAdmin() ? (
            <Dictionary
              onLoadingChanged={setLoading}
              type="gender"
              selectedBrand={resolvedBrand}
            />
          ) : null,
          ignoreList: isAdmin() ? (
            <IgnoreList onLoadingChanged={setLoading} />
          ) : null,
          legacyUntagged: isAdmin() ? (
            <UntaggedProducts
              onLoadingChanged={setLoading}
              selectedBrand={resolvedBrand}
            />
          ) : null
        }}
      />
    </Container>
  );
}
