import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Container, Gap, Row, Spacer } from 'components';
import { Typography, Link } from '@mui/material';
import imageNotFound from '../images/error-404.jpg';

const StyledContainer = styled(Container)`
  max-width: 600px;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export function UserNotFound() {
  const { t } = useTranslation();
  return (
    <Row>
      <Spacer />
      <StyledContainer>
        <Gap />
        <Typography variant="h4">{t('userNotFound')}</Typography>
        <Gap />
        <img src={imageNotFound} alt={t('userNotFound')} width={600} />
        <Gap />
        <Typography>
          <Trans
            i18nKey="userNotFoundInfo"
            components={[
              <StyledLink key="mailto" href="mailto:support@faslet.me" />
            ]}
          />
        </Typography>
        <Gap />
      </StyledContainer>
      <Spacer />
    </Row>
  );
}
