import React, { ReactNode, useRef } from 'react';
import { Button, IconButton, CircularProgress } from '@mui/material';

interface FileInputButtonProps {
  buttonText?: string;
  onFileSelected: (file: File) => unknown;
  disabled?: boolean;
  loading?: boolean;
  accept?: string;
  icon?: ReactNode;
  color?:
    | 'primary'
    | 'secondary'
    | 'inherit'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  variant?: 'contained' | 'outlined' | 'text';
}

export function FileInputButton({
  buttonText,
  onFileSelected,
  disabled,
  loading,
  accept,
  icon,
  color,
  variant
}: FileInputButtonProps) {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files?.length && onFileSelected(e.target.files[0]);
    if (fileInput.current) fileInput.current.value = '';
  };

  return (
    <>
      {buttonText ? (
        <Button
          variant={variant ?? 'contained'}
          color={color ?? 'primary'}
          onClick={() => fileInput.current?.click()}
          disabled={disabled || loading}
          startIcon={
            loading ? <CircularProgress size={20} color="inherit" /> : icon
          }
        >
          {buttonText}
        </Button>
      ) : (
        <IconButton
          onClick={() => fileInput.current?.click()}
          disabled={disabled || loading}
          color={color ?? 'primary'}
          size="large"
        >
          {icon}
        </IconButton>
      )}
      <input
        ref={fileInput}
        type="file"
        onChange={handleFileSelected}
        style={{ display: 'none' }}
        accept={accept}
      />
    </>
  );
}
