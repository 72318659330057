import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import React from 'react';
import { Row } from './layout';

const FallbackContainer = styled(Row)`
  flex: 1 1 800px;
  justify-content: center;
  align-items: center;
`;

export function FallbackStatsComponent() {
  return (
    <FallbackContainer>
      <CircularProgress />
    </FallbackContainer>
  );
}
