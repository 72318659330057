import React, { useEffect } from 'react';
import { Link } from '@mui/material';

import { useSpot } from '../framework';

export function useRetailerLink() {
  const { spot, query } = useSpot();
  function findRetailer(retailer: string) {
    const lowerValue = retailer.toLocaleLowerCase();
    const retailerObj = spot.data?.retailers?.find(
      v =>
        v.name.toLocaleLowerCase() === lowerValue ||
        v.slug.toLocaleLowerCase() === lowerValue
    );
    return retailerObj ?? null;
  }

  useEffect(() => {
    (async () => {
      if (!spot.data?.retailers) {
        await query('retailer/', {}, ['retailer']);
      }
    })();
  }, [spot, query]);

  return { findRetailer };
}

export function RetailerLink({ retailer }: { retailer: string }) {
  const { findRetailer } = useRetailerLink();

  const retailerObj = findRetailer(retailer);

  return retailerObj ? (
    <Link
      href={`/retailer/${retailerObj?.slug || retailer.toLocaleLowerCase()}`}
    >
      {retailerObj?.name}
    </Link>
  ) : (
    <span>{retailer}</span>
  );
}
