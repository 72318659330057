import React, { HtmlHTMLAttributes, useCallback, useEffect } from 'react';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import { AccountCircleRounded as GoToUserIcon } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { User, useSpot } from '../framework';
import { CenteredRow } from './layout';
import { Gap } from './spacer';
import unknownLogo from '../images/spiral-logo.png';

const Wrapper = styled.div`
  flex: 0 0 360px;

  ${p => p.theme.breakpoints.down('md')} {
    flex: 0 0 300px;
  }

  display: flex;
  align-items: center;
`;

const UserNameRow = styled(CenteredRow)`
  flex-wrap: nowrap;
  flex: 1 1 auto;
`;

const Logo = styled.div<{ image?: string }>`
  height: 32px;
  background: url('${p => p.image}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 50px;
  margin-right: 12px;
  margin-left: 4px;
`;

const UserName = styled(Typography)`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const StyledInput = styled(TextField)`
  color: black;
  background-color: white;
  border-radius: 5px;
`;

const GoToUserButton = styled(IconButton)`
  color: ${p => p.theme.palette.primary.main};
`;

export interface UserSelectorProps {
  includeAllUsers?: boolean;
  onUserChanged: (user: User | null) => unknown;
  selectedUser: User | null;
  initialUserEmail?: string;
}

export const allUsersUserId = -1;

const portalLogo = unknownLogo;

export const allUsersUser: User = {
  firstName: 'All',
  lastName: 'Users',
  id: allUsersUserId,
  email: '',
  avatar: portalLogo,
  role: 'user'
};

const defaultFilterOptions = createFilterOptions<User>({
  limit: 30,
  ignoreCase: true,
  ignoreAccents: true
});

export function UserSelector({
  includeAllUsers,
  onUserChanged,
  selectedUser,
  initialUserEmail
}: UserSelectorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { spot, query, data, loading } = useSpot();

  const disabled = loading;

  useEffect(() => {
    (async () => {
      if (!spot.data?.users) {
        await query('user/', {}, ['users']);
      }
      if (
        spot.data.users?.length === 1 &&
        selectedUser?.id !== spot.data.users?.[0]?.id
      ) {
        onUserChanged(spot.data.users?.[0] ?? null);
      } else if (initialUserEmail) {
        const foundUser = spot.data.users?.find(
          u => u.email === initialUserEmail
        );
        onUserChanged(foundUser ?? null);
      } else if (!includeAllUsers) {
        onUserChanged(spot.data.users?.[0] ?? null);
      }
    })();
  }, []);

  const baseUsers = includeAllUsers ? [allUsersUser] : ([] as User[]);

  const defaultUser = includeAllUsers ? allUsersUser : null;

  const extendedUsers = baseUsers.concat(data?.users ?? []);

  const userChanged = useCallback(
    (event: unknown, newUser: User | null) => {
      onUserChanged &&
        onUserChanged(newUser?.id === allUsersUserId ? null : newUser);
    },
    [onUserChanged]
  );

  const onGoToUser = useCallback(() => {
    navigate(`/user/${selectedUser?.email}`);
  }, [navigate, selectedUser]);

  const getOptionLabel = useCallback(
    (u: User) =>
      u.id === allUsersUserId
        ? t(`allUsers`)
        : `${u.firstName} ${u.lastName}` ?? '-',
    [t]
  );

  const renderInput = useCallback(
    (params: any) => (
      <StyledInput
        {...params}
        hiddenLabel
        fullWidth
        size="small"
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: <Logo image={selectedUser?.avatar ?? portalLogo} />
        }}
      />
    ),
    [selectedUser]
  );

  const renderOption = useCallback(
    (props: HtmlHTMLAttributes<HTMLLIElement>, u: User) => (
      <li {...props} key={u.email}>
        <UserNameRow>
          <Logo image={u.avatar ?? portalLogo} />
          <UserName>
            {u.id === allUsersUserId
              ? t(`allUsers`)
              : `${u.firstName} ${u.lastName}` ?? '-'}
          </UserName>
        </UserNameRow>
      </li>
    ),
    [t]
  );

  const getOptionSelected = useCallback(
    (r1: User, r2: User) => r1.email === r2.email,
    []
  );

  return (
    <Wrapper>
      <Autocomplete
        disabled={disabled}
        fullWidth
        onChange={userChanged}
        value={selectedUser ?? defaultUser}
        disableClearable={!defaultUser}
        selectOnFocus
        options={extendedUsers}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        renderInput={renderInput}
        filterOptions={defaultFilterOptions}
        renderOption={renderOption}
      />
      <Gap size={1} />
      <GoToUserButton
        onClick={onGoToUser}
        disabled={!selectedUser}
        title={t('goToUser')}
      >
        <GoToUserIcon color="inherit" />
      </GoToUserButton>
    </Wrapper>
  );
}
