import { Box } from '@mui/material';
import styled from '@emotion/styled';

export const Spacer = styled(Box)`
  flex: 1 1 auto;
`;

export const Gap = styled(Box)<{ size?: number }>`
  margin-top: ${p => p.theme.spacing(p.size ?? 2)};
  margin-left: ${p => p.theme.spacing(p.size ?? 2)};
`;
