import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Switch } from '@mui/material';
import styled from '@emotion/styled';

import { DeleteForeverRounded as DeleteIcon } from '@mui/icons-material';

import {
  ExperimentVariant,
  useSpot,
  Retailer,
  isNotNullOrUndefined,
  RoleContext
} from 'framework';
import {
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormTextInput,
  FormSection,
  FormSubsection,
  FormSectionTitle,
  Spacer,
  FeatureEditor,
  WidgetConfigurationBasic,
  CollapsableFormSection,
  WidgetLocalization,
  WidgetConfigurationSizes,
  StepConfiguration
} from 'components';
import { objectPatch } from 'helpers/object-difference';

const StepConfigWrapper = styled(Box)`
  position: relative;
  height: 600px;
`;

interface VariantEditorProps {
  loading: boolean;
  variant: ExperimentVariant;
  deleteVariant: (id: number) => unknown;
  updateVariant: (variant: ExperimentVariant) => unknown;
  retailer: Retailer | null;
}

export function VariantEditor({
  loading,
  variant,
  deleteVariant,
  updateVariant,
  retailer
}: VariantEditorProps) {
  const { t } = useTranslation();
  const { spot } = useSpot();

  const { isAdmin } = useContext(RoleContext);

  const resolvedWidgetConfiguration = objectPatch(
    spot.data?.extendedRetailer?.widgetConfiguration ?? {},
    variant.widgetConfiguration ?? {}
  );

  const resolvedFeatures = objectPatch(
    spot.data?.extendedRetailer?.features ?? {},
    variant.features ?? {}
  );

  return (
    <FormSubsection key={variant.id}>
      <FormRow>
        <FormSectionTitle>{variant.name}</FormSectionTitle>
        <Spacer />
        <IconButton onClick={() => deleteVariant(variant.id)} size="large">
          <DeleteIcon />
        </IconButton>
      </FormRow>
      <FormSection>
        <FormRow>
          <FormLabel>{t('variantName')}</FormLabel>
          <FormFieldWrapper>
            <FormTextInput
              disabled={loading}
              value={variant.name}
              onChange={value =>
                updateVariant({
                  ...variant,
                  name: value
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
      </FormSection>
      <CollapsableFormSection
        title={t('widgetFeatureConfig')}
        initiallyOpen={false}
      >
        <FeatureEditor
          featureSet="widget"
          features={resolvedFeatures}
          onChange={features => updateVariant({ ...variant, features })}
          externallyChanged={variant.features}
          hideTitle
        />
      </CollapsableFormSection>
      {retailer && (
        <CollapsableFormSection
          title={t('widgetConfiguration')}
          initiallyOpen={false}
        >
          <WidgetConfigurationBasic
            retailerSlug={retailer.slug}
            updateWidgetConfiguration={widgetConfiguration =>
              updateVariant({
                ...variant,
                widgetConfiguration: widgetConfiguration ?? undefined
              })
            }
            widgetConfiguration={resolvedWidgetConfiguration}
            isAdmin={isAdmin}
            hideTitle
            externallyChanged={variant.widgetConfiguration}
            features={retailer.features}
          />
        </CollapsableFormSection>
      )}
      <CollapsableFormSection
        title={t('stepConfiguration')}
        initiallyOpen={false}
      >
        <StepConfigWrapper>
          <StepConfiguration
            experimentVariant={variant}
            retailer={retailer ?? undefined}
          />
        </StepConfigWrapper>
      </CollapsableFormSection>
      {retailer && (
        <CollapsableFormSection title={t('localization')} initiallyOpen={false}>
          <WidgetLocalization
            selectedRetailer={retailer}
            updateWidgetConfiguration={widgetConfiguration =>
              updateVariant({
                ...variant,
                widgetConfiguration: widgetConfiguration ?? undefined
              })
            }
            widgetConfiguration={resolvedWidgetConfiguration}
          />
        </CollapsableFormSection>
      )}
      {retailer && (
        <CollapsableFormSection title={t('effects')} initiallyOpen={false}>
          <FormRow
            highlighted={isNotNullOrUndefined(
              variant.widgetConfiguration?.effects?.buttonShake
            )}
          >
            <FormLabel>{t('buttonShakeTitle')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateVariant({
                    ...variant,
                    widgetConfiguration: {
                      ...variant.widgetConfiguration,
                      effects: {
                        ...(variant.widgetConfiguration?.effects ?? {}),
                        buttonShake: event.target.checked
                      }
                    }
                  })
                }
                checked={
                  variant.widgetConfiguration?.effects?.buttonShake ?? false
                }
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              variant.widgetConfiguration?.effects?.flyToCart
            )}
          >
            <FormLabel>{t('flyToCartTitle')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateVariant({
                    ...variant,
                    widgetConfiguration: {
                      ...variant.widgetConfiguration,
                      effects: {
                        ...(variant.widgetConfiguration?.effects ?? {}),
                        flyToCart: event.target.checked
                      }
                    }
                  })
                }
                checked={
                  variant.widgetConfiguration?.effects?.flyToCart ?? false
                }
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              variant.widgetConfiguration?.effects?.resultConfetti
            )}
          >
            <FormLabel>{t('resultConfettiTitle')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateVariant({
                    ...variant,
                    widgetConfiguration: {
                      ...variant.widgetConfiguration,
                      effects: {
                        ...(variant.widgetConfiguration?.effects ?? {}),
                        resultConfetti: event.target.checked
                      }
                    }
                  })
                }
                checked={
                  variant.widgetConfiguration?.effects?.resultConfetti ?? false
                }
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              variant.widgetConfiguration?.effects?.snow
            )}
          >
            <FormLabel>{t('snowEffectTitle')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateVariant({
                    ...variant,
                    widgetConfiguration: {
                      ...variant.widgetConfiguration,
                      effects: {
                        ...(variant.widgetConfiguration?.effects ?? {}),
                        snow: event.target.checked
                      }
                    }
                  })
                }
                checked={variant.widgetConfiguration?.effects?.snow ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              variant.widgetConfiguration?.effects?.pride
            )}
          >
            <FormLabel>{t('prideEffectTitle')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateVariant({
                    ...variant,
                    widgetConfiguration: {
                      ...variant.widgetConfiguration,
                      effects: {
                        ...(variant.widgetConfiguration?.effects ?? {}),
                        pride: event.target.checked
                      }
                    }
                  })
                }
                checked={variant.widgetConfiguration?.effects?.pride ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
          <FormRow
            highlighted={isNotNullOrUndefined(
              variant.widgetConfiguration?.effects?.pride
            )}
          >
            <FormLabel>{t('soccerNlEffectTitle')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateVariant({
                    ...variant,
                    widgetConfiguration: {
                      ...variant.widgetConfiguration,
                      effects: {
                        ...(variant.widgetConfiguration?.effects ?? {}),
                        soccerNl: event.target.checked
                      }
                    }
                  })
                }
                checked={
                  variant.widgetConfiguration?.effects?.soccerNl ?? false
                }
              />
            </FormFieldWrapper>
          </FormRow>
        </CollapsableFormSection>
      )}
      {retailer && (
        <CollapsableFormSection title={t('sizeRanges')} initiallyOpen={false}>
          <WidgetConfigurationSizes
            updateWidgetConfiguration={widgetConfiguration =>
              updateVariant({
                ...variant,
                widgetConfiguration: widgetConfiguration ?? undefined
              })
            }
            widgetConfiguration={resolvedWidgetConfiguration}
            externallyChanged={variant.widgetConfiguration}
          />
        </CollapsableFormSection>
      )}
    </FormSubsection>
  );
}
