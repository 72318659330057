import React, { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button, IconButton, Typography, Link } from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowModel
} from '@mui/x-data-grid';
import {
  AddRounded as AddIcon,
  DeleteForeverRounded as DeleteIcon,
  AssessmentRounded as SizeChartSetupIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { Brand, RoleContext, useSpot } from 'framework';

import {
  Gap,
  HorizontalLoadingBar,
  Line,
  Row,
  Spacer,
  ConfirmDeleteAlert,
  QuickSearchToolbar,
  RetailersPopdownList,
  DataGridImage,
  DataGridFrame,
  CenteredRow
} from 'components';

import { Error } from 'spot-store';

export interface BrandListProps {
  displayErrors: (errors: Error[]) => unknown;
  addBrand: () => unknown;
  brandRows: GridRowModel[];
  refresh: (value?: string) => Promise<unknown>;
  onSearchTermChanged: (value: string) => unknown;
}

export function BrandList({
  displayErrors,
  addBrand,
  brandRows,
  refresh,
  onSearchTermChanged
}: BrandListProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { command, loading } = useSpot();

  const [confirmBrandDeleteOpen, setConfirmBrandDeleteOpen] =
    useState<boolean>(false);
  const [deletedBrandId, setDeletedBrandId] = useState<string | undefined>();

  const { isAdmin } = useContext(RoleContext);

  const brandHeaders: GridColumns = [
    {
      field: 'logo',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 70,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <DataGridImage alt={t('logo')} src={params.value?.toString()} />
        ) : (
          <span>&nbsp;</span>
        )
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select'
    },
    {
      field: 'baseUrl',
      headerName: t('brandBaseUrl'),
      flex: 1,
      resizable: false,
      sortable: false,
      renderCell: (params: GridCellParams) => {
        return params.value ? (
          <Link href={params.value as string} rel="noreferrer" target="_blank">
            {params.value}
          </Link>
        ) : null;
      }
    },
    {
      field: 'retailers',
      headerName: t('brandRetailersCount'),
      width: 150,
      resizable: false,
      sortable: false,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return (
          <RetailersPopdownList id={params.id} retailers={params.value as []} />
        );
      }
    },
    {
      field: 'itemsCount',
      headerName: t('brandSizeChartsCount'),
      width: 130,
      resizable: false,
      sortable: false,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        const brandName = params.row.slug;
        return isAdmin() ? (
          <CenteredRow>
            <Box>
              <IconButton
                className="grid-icon-button"
                color="primary"
                onClick={() =>
                  navigate(`/size-and-fit/size-charts/${brandName}`)
                }
                size="large"
              >
                <SizeChartSetupIcon />
              </IconButton>
            </Box>
            <Box>{(params.value as number) > 0 ? params.value : ''}</Box>
          </CenteredRow>
        ) : null;
      }
    },
    {
      field: 'id',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 40,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        isAdmin() ? (
          <IconButton
            className="grid-icon-button"
            onClick={() => confirmDeleteBrand(params.value)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
    }
  ];

  const deleteBrand = useCallback(
    async (brandId: string | undefined) => {
      setConfirmBrandDeleteOpen(false);

      try {
        if (brandId) {
          await command(`brand/${brandId}`, undefined, {
            method: 'DELETE'
          });

          await refresh();
        }
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [command, refresh, displayErrors]
  );

  const confirmDeleteBrand = useCallback(
    async brandId => {
      if (brandId) {
        setDeletedBrandId(brandId);
        setConfirmBrandDeleteOpen(true);
      }
    },
    [setDeletedBrandId, setConfirmBrandDeleteOpen]
  );

  const onGridCellClick = useCallback(
    (params: GridCellParams) => {
      const clickedField = params.colDef.field;
      if (!['name', 'slug', 'logo'].includes(clickedField)) {
        return;
      }
      if (params.row) {
        const selected = params.row as unknown as Brand;
        navigate(`/brand/${selected.slug}`);
      }
    },
    [navigate]
  );

  const searchTextChanged = useCallback(
    (e: React.ChangeEvent<{ value: string }>) =>
      onSearchTermChanged(e.target.value),
    [onSearchTermChanged]
  );

  const clearSearch = useCallback(
    () => onSearchTermChanged(''),
    [onSearchTermChanged]
  );

  return (
    <>
      <ConfirmDeleteAlert
        id="confirmBrandDelete"
        title={t('deleteBrandConfirmation')}
        text={t('deleteBrandConfirmationText')}
        open={confirmBrandDeleteOpen}
        onConfirm={deleteBrand}
        value={deletedBrandId}
        keepMounted
      />
      <Row>
        <Typography variant="h4">{t('brands')}</Typography>
        <Spacer />
        {isAdmin() && (
          <Button
            variant="contained"
            color="primary"
            onClick={addBrand}
            startIcon={<AddIcon />}
          >
            {t('addBrand')}
          </Button>
        )}
      </Row>
      <Line />
      <HorizontalLoadingBar loading={loading} />
      <Gap />
      <DataGridFrame>
        <DataGrid
          onCellClick={onGridCellClick}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          loading={loading}
          rows={brandRows}
          columns={brandHeaders}
          autoPageSize
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              onChange: searchTextChanged,
              clearSearch
            }
          }}
        />
      </DataGridFrame>
    </>
  );
}
