import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import i18next from 'i18next';
import initLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ReactGA from 'react-ga4';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { initializeSpot } from 'spot-store';
import { ThemeProvider as StyledThemeProvider } from '@emotion/react';

import { pages, Template } from 'pages';
import { theme } from 'theme';
import {
  ApplicationData,
  SpotContext,
  getApi,
  Retailer,
  RetailerContext,
  useSpot
} from 'framework';

import { RoleContext } from 'framework/role-context';
import enUS from './translations/en-US.json';

ReactGA.initialize(`G-DTP0SXLW6F`);

const spot = initializeSpot<ApplicationData>(
  getApi(),
  process.env.NODE_ENV === 'development'
);

function App() {
  const [state, setState] = useState<
    'uninitialized' | 'loading' | 'initialized'
  >('uninitialized');

  const initialize = async () => {
    setState('loading');
    await i18next
      .use(initLanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en-US',
        resources: {
          'en-US': { translation: enUS }
        },
        debug: process.env.NODE_ENV === 'development',
        keySeparator: false,
        interpolation: {
          escapeValue: false
        }
      });

    setState('initialized');
  };

  const spotInstance = useMemo(
    () => ({
      spot
    }),
    []
  );

  if (state === 'uninitialized') {
    initialize();
  }

  if (state !== 'initialized') {
    return <>...</>;
  }

  return (
    <div className="App">
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StyledThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <SpotContext.Provider value={spotInstance}>
              <CoreApplication />
            </SpotContext.Provider>
          </LocalizationProvider>
        </StyledThemeProvider>
      </MuiThemeProvider>
    </div>
  );
}

const maintenance = false;

// Split this out, because changing retailer was causing Spot to get re-initialized and nuke all my data.
function CoreApplication() {
  const { spot: localSpot } = useSpot();

  const [retailer, setRetailer] = useState<Retailer | null>(null);
  const retailerContext = useMemo(
    () => ({ retailer, onRetailerChanged: setRetailer }),
    [retailer, setRetailer]
  );

  const [role, setRole] = useState<string | null>(null);
  const isAdmin = useCallback(() => role === 'admin', [role]);
  const hasRole = useCallback(
    (allowedRoles: string[]) => {
      return !!role && allowedRoles.includes(role);
    },
    [role]
  );
  const roleContext = useMemo(
    () => ({ role, onRoleChanged: setRole, isAdmin, hasRole }),
    [role, setRole, isAdmin]
  );
  useEffect(() => {
    setRole(localSpot.data?.profile?.role ?? null);
  }, [setRole, localSpot.data?.profile?.role]);

  return (
    <RoleContext.Provider value={roleContext}>
      <RetailerContext.Provider value={retailerContext}>
        <Router>
          {!maintenance && (
            <Routes>
              <Route path="/" element={<Template page={pages.landing} />} />
              {Object.values(pages).map(page => (
                <Route
                  key={page.path}
                  path={`${page.path}${
                    page.pathParams ? `/${page.pathParams.join('/')}` : ''
                  }`}
                  element={<Template page={page} />}
                />
              ))}
              <Route path="*" element={<Template page={pages.notFound} />} />
            </Routes>
          )}
          {maintenance && (
            <Routes>
              <Route path="/" element={<Template page={pages.maintenance} />} />
              <Route path="*" element={<Template page={pages.maintenance} />} />
            </Routes>
          )}
        </Router>
      </RetailerContext.Provider>
    </RoleContext.Provider>
  );
}

export default App;
