import React, { useCallback, useContext, useState, Fragment } from 'react';

import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  Box,
  Typography,
  Chip,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Hidden,
  Collapse
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowModel
} from '@mui/x-data-grid';
import {
  AddRounded as AddIcon,
  DeleteForeverRounded as DeleteIcon,
  KeyboardArrowDown as ExpandIcon,
  KeyboardArrowUp as CollapseIcon
} from '@mui/icons-material';

import { Error } from 'spot-store';
import {
  boolFeatureKeys,
  Brand,
  Platform,
  PlatformTypesEnum,
  Retailer,
  RoleContext,
  useSpot
} from 'framework';

import {
  CenteredRow,
  ConfirmDeleteAlert,
  DataGridImage,
  Filler,
  FormFieldWrapper,
  FormLabel,
  FormRow,
  Gap,
  HorizontalLoadingBar,
  Line,
  QuickSearchToolbar,
  Row,
  Spacer
} from 'components';
import styled from '@emotion/styled';
import { PricePlanDefinition, pricePlans } from './price-plan';

const StyledChip = styled(Chip)`
  margin-top: ${p => p.theme.spacing(1)};
  margin-right: ${p => p.theme.spacing(1)};
`;

const ChipsFieldWrapper = styled(FormFieldWrapper)`
  padding: ${p => p.theme.spacing(1, 1)};
  flex-wrap: wrap;
`;

const DataContainer = styled(Row)`
  position: relative;
  height: calc(100vh - 280px);
  overflow-y: auto;
`;

const FilterBox = styled(Box)`
  flex: 0 0 390px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: ${p => p.theme.spacing(1)};
  border-radius: 4px;
  margin-right: ${p => p.theme.spacing(1)};
  overflow-y: auto;
  height: 100%;
`;

const FilterRow = styled(FormRow)`
  flex-wrap: wrap;
  margin: 0;
  width: 100%;
`;

const FeatureRow = styled(CenteredRow)`
  margin: ${p => p.theme.spacing(1, 0)};
  width: 100%;
`;

const FeatureLabel = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
  padding: ${p => p.theme.spacing(1)};
  text-transform: capitalize;
  position: relative;
`;

const ToggleRow = styled(CenteredRow)`
  position: relative;
  cursor: pointer;
  width: 100%;
`;

const FeatureCollapse = styled(Collapse)`
  position: relative;
  width: 100%;
`;

const FeatureSelectWrapper = styled(Box)`
  flex: 0 0 100px;
  margin-left: ${p => p.theme.spacing(1)};
`;

const FilterTextField = styled(TextField)`
  margin: 0;
`;

export interface SearchFilters {
  searchTerm?: string;
  platform?: string;
  categories?: string[];
  brands?: string[];
  enabledFeatures?: string[];
  disabledFeatures?: string[];
  pricePlan?: string;
  hasFeed?: boolean;
}

export interface RetailerListProps {
  displayErrors: (errors: Error[]) => unknown;
  addRetailer: () => unknown;
  retailerRows: GridRowModel[];
  refresh: () => Promise<unknown>;
  filters: SearchFilters;
  onFiltersChanged: (filters: SearchFilters) => unknown;
}

export function RetailerList({
  displayErrors,
  addRetailer,
  retailerRows,
  refresh,
  filters,
  onFiltersChanged
}: RetailerListProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { command, loading } = useSpot();

  const [confirmRetailerDeleteOpen, setConfirmRetailerDeleteOpen] =
    useState<boolean>(false);

  const [deletedRetailerId, setDeletedRetailerId] = useState<
    string | undefined
  >();

  const { isAdmin } = useContext(RoleContext);

  const retailerHeaders: GridColumns = [
    {
      field: 'logoS3',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 70,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <DataGridImage
            alt={t('retailerLogo')}
            src={params.value?.toString()}
          />
        ) : (
          <span>&nbsp;</span>
        )
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select'
    },
    {
      field: 'brandsCount',
      headerName: t('brands'),
      width: 100,
      resizable: false,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) =>
        params.value ? params.value : <>&nbsp;</>
    },
    {
      field: 'id',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 40,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        isAdmin() ? (
          <IconButton
            className="grid-icon-button"
            onClick={() => confirmDeleteRetailer(params.value)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
    }
  ];

  const deleteRetailer = useCallback(
    async (retailerId: string | undefined) => {
      setConfirmRetailerDeleteOpen(false);

      try {
        if (retailerId) {
          await command(`retailer/${retailerId}`, undefined, {
            method: 'DELETE'
          });

          await refresh();
        }
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [command, refresh, displayErrors]
  );

  const confirmDeleteRetailer = useCallback(
    async retailerId => {
      if (retailerId) {
        setDeletedRetailerId(retailerId);
        setConfirmRetailerDeleteOpen(true);
      }
    },
    [setDeletedRetailerId, setConfirmRetailerDeleteOpen]
  );

  return (
    <>
      <ConfirmDeleteAlert
        id="confirmRetailerDelete"
        title={t('deleteRetailerConfirmation')}
        text={t('deleteRetailerConfirmationText')}
        open={confirmRetailerDeleteOpen}
        onConfirm={(retailerId: string | undefined) =>
          deleteRetailer(retailerId)
        }
        value={deletedRetailerId}
        keepMounted
      />
      <Row>
        <Typography variant="h4">{t('retailers')}</Typography>
        <Spacer />
        {isAdmin() && (
          <Button
            variant="contained"
            color="primary"
            onClick={addRetailer}
            startIcon={<AddIcon />}
          >
            {t('addRetailer')}
          </Button>
        )}
      </Row>
      <Line />
      <HorizontalLoadingBar loading={loading} />
      <Gap />
      <DataContainer>
        {isAdmin() && (
          <Hidden lgDown>
            <RetailerFilterBox
              onFiltersChanged={onFiltersChanged}
              filters={filters}
            />
          </Hidden>
        )}
        <DataGrid
          onCellClick={(params: GridCellParams) => {
            const clickedField = params.colDef.field;
            if (!['name', 'slug', 'logoS3'].includes(clickedField)) {
              return;
            }
            if (params.row) {
              const selected = params.row as unknown as Retailer;
              navigate(`/retailer/${selected.slug}`);
            }
          }}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          loading={loading}
          rows={retailerRows}
          columns={retailerHeaders}
          autoPageSize
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              onChange: (e: React.ChangeEvent<{ value: string }>) =>
                onFiltersChanged({ ...filters, searchTerm: e.target.value }),
              clearSearch: () =>
                onFiltersChanged({ ...filters, searchTerm: undefined })
            }
          }}
        />
      </DataContainer>
    </>
  );
}
// This should be a list of the keys in Features
const booleanFeatureList = boolFeatureKeys;

interface RetailerFilterBoxProps {
  filters: SearchFilters;
  onFiltersChanged: (filters: SearchFilters) => unknown;
}

function RetailerFilterBox({
  filters,
  onFiltersChanged
}: RetailerFilterBoxProps) {
  const { spot } = useSpot();
  const { t } = useTranslation();
  const [featuresOpen, setFeaturesOpen] = useState(false);

  return (
    <FilterBox>
      <Typography variant="h5">{t('filters')}</Typography>
      <Line />
      <FilterRow>
        <FormLabel>{t('brand')}</FormLabel>
        <ChipsFieldWrapper>
          <Autocomplete
            fullWidth
            onChange={(_, value: Brand | null) =>
              value &&
              onFiltersChanged({
                ...filters,
                brands: [...(filters.brands ?? []), value.slug]
              })
            }
            value={null}
            options={spot.data?.brands ?? []}
            getOptionLabel={(r: Brand) => r.name}
            isOptionEqualToValue={(r1: Brand, r2: Brand) => r1.id === r2.id}
            renderInput={params => (
              <FilterTextField {...params} margin="normal" />
            )}
            clearOnBlur
            clearOnEscape
          />
          <Filler />
          {filters.brands?.map(brand => (
            <Fragment key={brand}>
              <StyledChip
                label={
                  spot.data.brands.find(b => b.slug === brand)?.name ?? brand
                }
                onDelete={() =>
                  onFiltersChanged({
                    ...filters,
                    brands: filters.brands?.filter(b => b !== brand)
                  })
                }
              />
            </Fragment>
          ))}
        </ChipsFieldWrapper>
      </FilterRow>
      <Line />
      <FilterRow>
        <FormLabel>{t('categories')}</FormLabel>
        <ChipsFieldWrapper>
          <Autocomplete
            fullWidth
            freeSolo
            onChange={(_, value: string | null) =>
              value &&
              onFiltersChanged({
                ...filters,
                categories: [...(filters.categories ?? []), value]
              })
            }
            value={null}
            options={spot.data?.categories?.map(c => c.name) ?? []}
            renderInput={params => (
              <FilterTextField {...params} margin="normal" />
            )}
            clearOnBlur
            clearOnEscape
          />
          <Filler />
          {filters.categories?.map(category => (
            <Fragment key={category}>
              <StyledChip
                label={category}
                onDelete={() =>
                  onFiltersChanged({
                    ...filters,
                    categories:
                      filters.categories?.filter(c => c !== category) ??
                      undefined
                  })
                }
              />
            </Fragment>
          ))}
        </ChipsFieldWrapper>
      </FilterRow>
      <Line />
      <FilterRow>
        <FormLabel>{t('storePlatform')}</FormLabel>
        <FormFieldWrapper>
          <Autocomplete
            fullWidth
            onChange={(_, value: Platform | null) => {
              if (value === null) {
                const newFilters = { ...filters };
                delete newFilters.platform;
                onFiltersChanged({
                  ...newFilters
                });
              } else {
                onFiltersChanged({
                  ...filters,
                  platform: value ?? undefined
                });
              }
            }}
            value={(filters.platform as Platform) ?? null}
            options={Object.values(PlatformTypesEnum) as Platform[]}
            renderInput={params => (
              <FilterTextField {...params} margin="normal" />
            )}
          />
        </FormFieldWrapper>
      </FilterRow>
      <Line />
      <FilterRow>
        <FormLabel>{t('pricePlan')}</FormLabel>
        <ChipsFieldWrapper>
          <Autocomplete
            fullWidth
            onChange={(_, value: PricePlanDefinition | null) => {
              if (value === null) {
                const newFilters = { ...filters };
                delete newFilters.pricePlan;
                onFiltersChanged({
                  ...newFilters
                });
              } else {
                onFiltersChanged({
                  ...filters,
                  pricePlan: value?.id
                });
              }
            }}
            value={pricePlans.find(p => p.id === filters.pricePlan) ?? null}
            options={pricePlans ?? []}
            getOptionLabel={(r: PricePlanDefinition) => r.name}
            isOptionEqualToValue={(
              r1: PricePlanDefinition,
              r2: PricePlanDefinition
            ) => r1.id === r2.id}
            renderInput={params => (
              <FilterTextField {...params} margin="normal" />
            )}
          />
        </ChipsFieldWrapper>
      </FilterRow>
      <Line />
      <FilterRow>
        <FormLabel>{t('productFeed')}</FormLabel>
        <FormFieldWrapper>
          <Select
            fullWidth
            onChange={(
              event: SelectChangeEvent<'on' | 'off' | 'unset' | null>
            ) => {
              const newFilters = {
                ...filters
              };
              if (event.target.value === 'on') {
                newFilters.hasFeed = true;
              } else if (event.target.value === 'off') {
                newFilters.hasFeed = false;
              } else {
                delete newFilters.hasFeed;
              }
              onFiltersChanged(newFilters);
            }}
            value={
              // eslint-disable-next-line no-nested-ternary
              filters.hasFeed !== undefined
                ? filters.hasFeed
                  ? 'on'
                  : 'off'
                : 'unset'
            }
          >
            <MenuItem value="unset">--</MenuItem>
            <MenuItem value="on">{t('on')}</MenuItem>
            <MenuItem value="off">{t('off')}</MenuItem>
          </Select>
        </FormFieldWrapper>
      </FilterRow>
      <Line />
      <FilterRow>
        <ToggleRow onClick={() => setFeaturesOpen(old => !old)}>
          <FormLabel>{t('features')}</FormLabel>
          <Spacer />
          {featuresOpen ? <CollapseIcon /> : <ExpandIcon />}
          <Gap />
        </ToggleRow>
        <FeatureCollapse in={featuresOpen}>
          {booleanFeatureList.map(feature => (
            <FeatureRow key={feature}>
              <FeatureLabel variant="subtitle2" color="primary">
                {t(feature)}
              </FeatureLabel>
              <Spacer />
              <FeatureSelectWrapper>
                <Select
                  fullWidth
                  onChange={(
                    event: SelectChangeEvent<'on' | 'off' | 'unset' | null>
                  ) => {
                    const newEnabledFeatures = [
                      ...(filters.enabledFeatures ?? [])
                    ].filter(f => f !== feature);
                    const newDisabledFeatures = [
                      ...(filters.disabledFeatures ?? [])
                    ].filter(f => f !== feature);

                    if (event.target.value === 'on') {
                      newEnabledFeatures.push(feature);
                    } else if (event.target.value === 'off') {
                      newDisabledFeatures.push(feature);
                    }
                    onFiltersChanged({
                      ...filters,
                      enabledFeatures: newEnabledFeatures,
                      disabledFeatures: newDisabledFeatures
                    });
                  }}
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    (filters.enabledFeatures ?? []).indexOf(feature) !== -1
                      ? 'on'
                      : (filters.disabledFeatures ?? []).indexOf(feature) !== -1
                        ? 'off'
                        : 'unset'
                  }
                >
                  <MenuItem value="unset">--</MenuItem>
                  <MenuItem value="on">{t('on')}</MenuItem>
                  <MenuItem value="off">{t('off')}</MenuItem>
                </Select>
              </FeatureSelectWrapper>
            </FeatureRow>
          ))}
        </FeatureCollapse>
      </FilterRow>
      <Line />
    </FilterBox>
  );
}
