/* eslint-disable no-prototype-builtins */
export function objectDifference(baseObject: any, differentObject: any) {
  const deltaObject = Object.entries(differentObject).reduce(
    (differenceObject, [key, value]) => {
      // eslint-disable-next-line eqeqeq
      if (!baseObject.hasOwnProperty(key) || baseObject[key] != value) {
        let valueDifference = value;
        if (
          baseObject.hasOwnProperty(key) &&
          Array.isArray(value) &&
          Array.isArray(baseObject[key])
        ) {
          const valArray = Array.from(value);
          const baseObjectArray = Array.from(baseObject[key]);
          if (
            valArray.length === baseObjectArray.length &&
            valArray.join('') === baseObjectArray.join('')
          ) {
            return { ...differenceObject };
          }
        } else if (
          baseObject.hasOwnProperty(key) &&
          typeof value === 'object'
        ) {
          const internalDiff = objectDifference(baseObject[key] ?? {}, value);

          if (Object.keys(internalDiff).length === 0) {
            return { ...differenceObject };
          }

          valueDifference = internalDiff;
        }
        return { ...differenceObject, [key]: valueDifference };
      }

      return { ...differenceObject };
    },
    {}
  );

  return deltaObject;
}

export function objectPatch(baseObject: any, differentObject: any) {
  return Object.entries(differentObject).reduce((ro, [key, value]) => {
    if (
      baseObject.hasOwnProperty(key) &&
      typeof value === 'object' &&
      !Array.isArray(baseObject[key])
    ) {
      return {
        ...ro,
        [key]: objectPatch(baseObject[key] ?? {}, value)
      };
    }
    return { ...ro, [key]: value };
  }, baseObject);
}
