import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Row, Tabs } from '../../components';
import { ReferenceBrands } from './reference-brands';
import { ReferenceModels } from './reference-models';
import { AuditTrail } from './audit-trail';
import { StepConfiguration } from './step-configuration';
import { Notifications } from './notifications';

export function GlobalConfiguration() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();
  const [loading, setLoading] = useState(false);

  const onChangeTab = useCallback(
    newTab => {
      navigate(`/global-config/${newTab}`);
    },
    [navigate]
  );

  return (
    <>
      <Row>
        <Typography variant="h4">{t('globalConfiguration')}</Typography>
      </Row>
      <Tabs
        selected={tab ?? 'referenceBrands'}
        onTabChange={onChangeTab}
        hideIfSingle
        loading={loading}
        tabs={{
          referenceBrands: <ReferenceBrands onLoadingChanged={setLoading} />,
          referenceModels: <ReferenceModels onLoadingChanged={setLoading} />,
          auditTrail: <AuditTrail onLoadingChanged={setLoading} />,
          stepConfiguration: (
            <StepConfiguration onLoadingChanged={setLoading} />
          ),
          notifications: <Notifications onLoadingChanged={setLoading} />
        }}
      />
    </>
  );
}
