import React, { useState, useCallback } from 'react';
import { HexColorPicker, HexAlphaColorPicker } from 'react-colorful';
import styled from '@emotion/styled';
import { Popover, TextField } from '@mui/material';
import { Row } from './layout';
import { Gap } from './spacer';

const ColorPickerRow = styled(Row)`
  align-items: center;
  wrap: nowrap;
  flex: 1 1 auto;
  position: relative;
`;

const ColorSwatch = styled.button<{
  color: string | undefined;
  size?: 's' | 'm' | 'l';
}>`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;

  padding: ${p => p.theme.spacing(1)};
  border-radius: ${p => p.theme.shape.borderRadius}px;
  border: 1px solid #ccc;
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(0, 0, 0, 0.1);
  background-color: ${p => p.color ?? 'transparent'};
  cursor: pointer;
`;

const StyledTextField = styled(TextField)`
  flex: 1 1 calc(100% - 48px);
`;

export interface ColorPickerProps {
  color: string | undefined;
  onChange: (newCol: string | undefined) => unknown;
  showTextField?: boolean;
  alpha?: boolean;
}

export function ColorPicker({
  color,
  onChange,
  showTextField = true,
  alpha = false
}: ColorPickerProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [internalColor, setInternalColor] = useState(color);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleSwatchClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleTextChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: string;
      }>
    ) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleClose = useCallback(() => {
    onChange(internalColor);
    setAnchorEl(null);
  }, [onChange, setAnchorEl, internalColor]);

  return (
    <ColorPickerRow>
      {showTextField && (
        <>
          <StyledTextField
            hiddenLabel
            onChange={handleTextChange}
            value={color}
          />
          <Gap />
        </>
      )}
      <ColorSwatch
        className="swatch"
        color={color}
        onClick={handleSwatchClick}
        aria-describedby={id}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {alpha ? (
          <HexAlphaColorPicker
            color={internalColor}
            onChange={setInternalColor}
          />
        ) : (
          <HexColorPicker color={internalColor} onChange={setInternalColor} />
        )}
      </Popover>
    </ColorPickerRow>
  );
}
