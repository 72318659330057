import React, { useCallback } from 'react';
import { Slider } from '@mui/material';

export interface MultiplerSliderProps {
  values: number[];
  onValuesChanged: (id: string, values: number[]) => unknown;
  id: string;
}

export function MultiplierSlider({
  id,
  values,
  onValuesChanged
}: MultiplerSliderProps) {
  const handleChange = useCallback(
    (event, value) => {
      onValuesChanged(id, value as number[]);
    },
    [id, onValuesChanged]
  );

  const marks = [
    {
      value: 0.8,
      label: '0.8'
    },
    {
      value: 1,
      label: '1'
    },
    {
      value: 1.2,
      label: '1.2'
    }
  ];

  return (
    <Slider
      id={id}
      value={values}
      onChange={handleChange}
      marks={marks}
      valueLabelDisplay="on"
      step={0.01}
      min={0.8}
      max={1.2}
    />
  );
}
