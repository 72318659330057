import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react';
import moment from 'moment';
import { Box, Grid, Slider, Typography, IconButton } from '@mui/material';
import {
  LoopRounded as RefreshIcon,
  CloseRounded as CloseIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  Brand,
  formatStat,
  GroupedAccuracyStat,
  RetailerContext,
  useSpot
} from 'framework';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import {
  BrandLink,
  FormFieldWrapper,
  FormLabel,
  FormRow,
  Gap,
  RetailerLink,
  Row,
  SearchField,
  Spacer,
  useBrandLink
} from 'components';
import { ProductAccuracyGroupInfo } from './product-accuracy-group-info';
import { DataContainer } from './common';

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${p => p.theme.spacing(3)};
  right: ${p => p.theme.spacing(2)};
`;

export interface ProductAccuracyListProps {
  onLoadingChanged: (loading: boolean) => void;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  selectedBrand: Brand | null;
}

export function ProductAccuracyList({
  onLoadingChanged,
  startDate,
  endDate,
  selectedBrand
}: ProductAccuracyListProps) {
  const { t } = useTranslation();
  const { spot, query, loading } = useSpot();

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  useBrandLink();

  const [selectedItem, setSelectedItem] = useState<GroupedAccuracyStat>();
  const [threshold, setThreshold] = useState<number>(10);
  const [thresholdInternal, setThresholdInternal] = useState<number>(threshold);
  const [filter, setFilter] = useState('');
  const timeoutHandle = useRef(-1);

  const { retailer } = useContext(RetailerContext);

  const fetchProductAccuracy = useCallback(async () => {
    if (!retailer && !selectedBrand) {
      return;
    }

    const params: {
      startDate: string;
      endDate: string;
      retailers?: string[];
      threshold: number;
      brands?: string[];
    } = {
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      retailers: retailer
        ? [encodeURIComponent(retailer.name), retailer.slug]
        : [],
      brands: selectedBrand ? [encodeURIComponent(selectedBrand.name)] : [],
      threshold
    };

    await query('analytics/product-accuracy/grouped', params, [
      'productAccuracy',
      'summary'
    ]);
  }, [startDate, endDate, retailer, query, threshold, selectedBrand]);

  useEffect(() => {
    setSelectedItem(undefined);
    startDate && endDate && fetchProductAccuracy();
  }, [startDate, endDate, fetchProductAccuracy]);

  const onRowClick = useCallback(
    ({ row }) => {
      setSelectedItem(row);
    },
    [setSelectedItem]
  );

  const handleThresholdChange = (event, newValue) => {
    setThresholdInternal(newValue);
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(() => {
      setThreshold(newValue);
    }, 500);
  };

  const columns: GridColumns = [
    { field: 'id', headerName: t('correlationId'), hide: true },
    {
      field: 'retailer',
      headerName: t('retailer'),
      align: 'left',
      renderCell: ({ value }) => <RetailerLink retailer={value} />,
      flex: 1,
      minWidth: 160,
      hide: !!retailer
    },
    {
      field: 'brand',
      headerName: t('brand'),
      align: 'left',
      renderCell: ({ value }) => (
        <BrandLink brand={value} retailer={retailer} />
      ),
      flex: 1,
      minWidth: 160,
      hide: !!selectedBrand
    },
    {
      field: 'productType',
      headerName: t('productType'),
      align: 'left',
      flex: 1
    },
    {
      field: 'gender',
      headerName: t('gender'),
      align: 'left',
      flex: 1,
      renderCell: ({ value }) => t(value)
    },

    {
      field: 'items',
      headerName: t('products'),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value }) => value.length,
      maxWidth: 120,
      hide: !!selectedItem
    },
    {
      field: 'users',
      headerName: t('users'),
      headerAlign: 'right',
      align: 'right',
      maxWidth: 120,
      hide: !!selectedItem
    },
    {
      field: 'orderCount',
      headerName: t('itemsOrdered'),
      headerAlign: 'right',
      align: 'right',
      maxWidth: 120
    },
    {
      field: 'adviceCount',
      headerName: t('followedAdvice'),
      headerAlign: 'right',
      align: 'right',
      maxWidth: 120,
      hide: !!selectedItem
    },
    {
      field: 'adviceAndSizeRelated',
      headerName: t('sizeRelatedReturnsWithAdvice'),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) =>
        formatStat((value / row.orderCount) * 100, true, 2),
      maxWidth: 120
    },
    {
      field: 'accuracy',
      headerName: t('accuracy'),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value }) => formatStat(value, true, 2),
      width: 120
    }
  ];

  const rows = (spot.data?.productAccuracy?.summary ?? []).filter(
    r =>
      r.brand?.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase()) ||
      r.productType
        ?.toLocaleLowerCase()
        .startsWith(filter.toLocaleLowerCase()) ||
      r.gender?.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase())
  );

  return (
    <>
      <FormRow>
        <FormLabel>{t('minUsersThreshold')}</FormLabel>
        <FormFieldWrapper>
          <Slider
            value={thresholdInternal}
            onChange={handleThresholdChange}
            marks
            valueLabelDisplay="on"
            step={1}
            min={0}
            max={50}
          />
        </FormFieldWrapper>
        <Spacer />
        <IconButton
          color="primary"
          disabled={loading}
          onClick={fetchProductAccuracy}
          title={t('refresh')}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
      </FormRow>
      <Gap />
      <SearchField onChange={setFilter} />
      <Gap />
      <Row>
        <Grid container>
          <Grid xs={selectedItem ? 7 : 12}>
            <DataContainer>
              <DataGrid
                columns={columns}
                rows={rows}
                onRowClick={onRowClick}
                disableColumnMenu
                disableColumnSelector
                autoPageSize
                loading={loading}
                columnBuffer={2}
                headerHeight={40}
                rowHeight={52}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'accuracy', sort: 'asc' }]
                  }
                }}
              />
            </DataContainer>
          </Grid>
          {selectedItem && (
            <Grid xs={5}>
              <DataContainer>
                <ProductAccuracyGroupInfo
                  productAccuracyGroupInfo={selectedItem}
                  startDate={startDate}
                  endDate={endDate}
                />
                <CloseButton
                  onClick={() => {
                    setSelectedItem(undefined);
                  }}
                >
                  <CloseIcon />
                </CloseButton>
              </DataContainer>
            </Grid>
          )}
        </Grid>
      </Row>
    </>
  );
}
