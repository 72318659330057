import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Box, Button, CircularProgress } from '@mui/material';

import styled from '@emotion/styled';

import {
  CloseRounded as CloseIcon,
  FileCopyRounded as CopyIcon,
  LoopRounded as GenerateIcon
} from '@mui/icons-material';

import copyToClipboard from 'copy-to-clipboard';
import { useSpot, IntegrationDetails, useErrorNotification } from 'framework';

import {
  FormSection,
  FormSectionTitle,
  FormLabel,
  Gap,
  Spacer,
  Notification,
  ConfirmDialog
} from 'components';
import { IntegrationEditProps } from './common';

const FormRowDirections = styled(Box)`
  margin: ${p => p.theme.spacing(1, 0)};
  display: flex;

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  ${p => p.theme.breakpoints.up('sm')} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const TitleSection = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const TokenWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0 0 600px;
`;

const StyledCode = styled.code`
  line-break: anywhere;
  background-color: #f0f0f0;
  border-radius: ${p => p.theme.spacing(1)};
  padding: ${p => p.theme.spacing(2)};
`;

const LoaderBox = styled(Box)`
  height: 4.5rem;
  padding: ${p => p.theme.spacing(2)};
`;

const EditLoading = styled(CircularProgress)`
  margin-left: 45%;
`;

export function Buckles({
  active,
  slug,
  close,
  integration,
  loading
}: IntegrationEditProps) {
  const { t } = useTranslation();
  const { command, query, spot } = useSpot();
  const [integrationData, setIntegrationData] = useState<
    IntegrationDetails | undefined
  >(integration);

  const [copied, setCopied] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const { displayErrors, notification } = useErrorNotification();

  const handleCopy = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const copyContent = (
        event.currentTarget as Node
      )?.parentNode?.querySelector('code')?.textContent;
      if (copyContent) {
        copyToClipboard(copyContent);
        setCopied(true);
      }
    },
    []
  );

  const handleRegenerate = useCallback(() => {
    setConfirm(true);
  }, [setConfirm]);

  const generateToken = useCallback(async () => {
    try {
      await command(
        `retailer/${slug}/integrations/generate-buckles-token`,
        {},
        {
          method: 'POST'
        }
      );
      await query(`retailer/${slug}/integrations`, {}, ['integrationDetails']);
      setIntegrationData(spot.data?.integrationDetails);
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [query, command, setIntegrationData, displayErrors, slug, spot]);

  return (
    <FormSection>
      <TitleSection>
        <FormSectionTitle>
          {`${active === true ? t(`add`) : t(`edit`)} ${t(
            `bucklesIntegration`
          )}`}
        </FormSectionTitle>
        <Spacer />
        <Button
          variant="contained"
          color="primary"
          onClick={close}
          startIcon={<CloseIcon />}
        >
          {t(`close`)}
        </Button>
      </TitleSection>
      {loading && integration ? (
        <LoaderBox>
          <EditLoading color="primary" />
        </LoaderBox>
      ) : (
        <>
          <FormRowDirections>
            <FormLabel>{t('apiToken')}</FormLabel>
            <TokenWrapper>
              {integrationData?.buckles?.fasletApiToken ? (
                <>
                  <StyledCode>
                    {integrationData.buckles.fasletApiToken}
                  </StyledCode>
                  <IconButton onClick={handleCopy} size="large">
                    <CopyIcon />
                  </IconButton>
                  <IconButton onClick={handleRegenerate} size="large">
                    <GenerateIcon />
                  </IconButton>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={generateToken}
                  startIcon={<GenerateIcon />}
                >
                  {t(`generateToken`)}
                </Button>
              )}
            </TokenWrapper>
          </FormRowDirections>
          <Gap size={2} />
          {t('bucklesIntegrationInfo')}
          <Gap size={4} />
        </>
      )}
      {notification}
      {copied && (
        <Notification
          onClose={() => setCopied(false)}
          title={t('copied')}
          severity="info"
          duration={3000}
        />
      )}
      {confirm && (
        <ConfirmDialog
          title={t('confirmRegenerateTokenTitle')}
          text={t('confirmRegenerateTokenText')}
          dialogOpen={confirm}
          onClose={() => setConfirm(false)}
          onSubmit={generateToken}
        />
      )}
    </FormSection>
  );
}
