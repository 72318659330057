import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Paper,
  Popover,
  Chip,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Link,
  Autocomplete,
  SelectChangeEvent,
  InputLabel
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowModel,
  GridRowId
} from '@mui/x-data-grid';
import {
  AddRounded as AddIcon,
  SaveRounded as SaveIcon,
  CancelRounded as CancelIcon,
  DeleteForeverRounded as DeleteIcon,
  StoreRounded as RetailerIcon,
  ArrowDropDownRounded,
  ArrowBackRounded as BackIcon,
  GetAppRounded as ExportUsersIcon,
  SendRounded as SendIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  downloadObjectsAsCsv,
  ExtendedUser,
  formatDateTime,
  Retailer,
  RoleContext,
  useErrorNotification,
  User,
  useSpot
} from 'framework';

import {
  Container,
  FileInputButton,
  Gap,
  HorizontalLoadingBar,
  Line,
  Row,
  Spacer,
  ConfirmDeleteAlert,
  QuickSearchToolbar,
  escapeRegExp,
  Table,
  DataGridImage,
  DataGridFrame,
  UserAuditTrail
} from 'components';

interface UserRetailersListProps {
  id: GridRowId;
  retailers: {
    id: number;
    name: string;
    slug: string;
  }[];
}

export function UserRetailersList({ id, retailers }: UserRetailersListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const headers = [t('retailers')];

  const rows =
    retailers?.map(retailer => {
      return {
        id: `retailer_${id}_${retailer.id}`,
        cells: [
          <>
            <IconButton
              className="grid-icon-button"
              color="primary"
              onClick={() => {
                navigate(`/retailer/${retailer.slug}`);
              }}
              size="large"
            >
              <RetailerIcon />
            </IconButton>
            <span
              className="grid-select"
              key={`retailer_${id}_${retailer.id}`}
              role="link"
              onClick={() => navigate(`/retailer/${retailer.slug}`)}
            >
              {retailer.name}
            </span>
          </>
        ]
      };
    }) ?? [];

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (retailers && retailers.length) {
    if (retailers.length === 1) {
      return (
        <>
          <IconButton
            className="grid-icon-button"
            color="primary"
            onClick={() => navigate(`/retailer/${retailers[0].slug}`)}
            size="large"
          >
            <RetailerIcon />
          </IconButton>
          <span
            className="grid-select"
            role="link"
            key={`retailer_${id}_${retailers[0].id}`}
            onClick={() => navigate(`/retailer/${retailers[0].slug}`)}
          >
            {retailers[0].name}
          </span>
        </>
      );
    }
    return (
      <div>
        <div>
          <IconButton
            className="grid-icon-button"
            onClick={handlePopoverOpen}
            color="primary"
            size="large"
          >
            <RetailerIcon />
            <ArrowDropDownRounded />
          </IconButton>
          {retailers.length}
        </div>
        <Popover
          id={`retailers-popover-${id}`}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Table headers={headers} rows={rows} />
        </Popover>
      </div>
    );
  }
  return <div>&nbsp;</div>;
}

export function UserEditor() {
  const navigate = useNavigate();
  const { email } = useParams<{ email?: string }>();
  const { t } = useTranslation();
  const { spot, query, command, raw, data, loading } = useSpot();
  const [extendedUser, setExtendedUser] = useState<ExtendedUser | null>();

  const [confirmUserDeleteOpen, setConfirmUserDeleteOpen] =
    useState<boolean>(false);

  const [deletedUserId, setDeletedUserId] = useState<string | undefined>();

  const [searchText, setSearchText] = useState<string>('');
  const [userRows, setUserRows] = useState<GridRowModel[]>([]);

  const { displayErrors, notification } = useErrorNotification();

  const { isAdmin } = useContext(RoleContext);

  useEffect(() => {
    (async () => {
      if (!spot.data.retailers) {
        await query('retailer/', {}, ['retailers']);
      }

      await query('user/', {}, ['users']);
      setUserRows(spot.data.users ?? []);
    })();
  }, [query, setUserRows, spot]);

  useEffect(() => {
    (async () => {
      if (!spot.data.retailers) {
        await query('retailer/', {}, ['retailers']);
      }
      if (email) {
        await query(`user/email/${email}`, {}, ['extendedUser']);
        setExtendedUser(spot.data.extendedUser);
      } else {
        setExtendedUser(null);
      }
    })();
  }, [query, email, spot, setExtendedUser]);

  const errorText = useCallback(
    (fieldName: string) => {
      const regexFields = {
        email:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      };
      const requiredFields = ['email', 'firstName', 'lastName', 'role'];
      const existingFields = ['email'];
      const validateForNewUserOnly: string[] = [];

      if (
        validateForNewUserOnly.includes(fieldName) &&
        extendedUser &&
        extendedUser.id > 0
      ) {
        return undefined;
      }

      const fieldValue = (extendedUser as any)[fieldName];

      if (requiredFields.includes(fieldName) && !fieldValue) {
        return t('required');
      }

      const regexPattern = (regexFields as any)[fieldName];
      if (regexPattern) {
        const regex = new RegExp(regexPattern);
        if (!regex.test(fieldValue)) {
          return `${t('invalid')} ${t(fieldName)}`;
        }
      }

      if (
        existingFields.includes(fieldName) &&
        spot.data.users.find(
          u => (u as any)[fieldName] === fieldValue && u.id !== extendedUser?.id
        )
      ) {
        return t('userExists');
      }
      return undefined;
    },
    [t, extendedUser, spot]
  );

  const hasError = useCallback(
    (fieldName: string) => {
      return !!errorText(fieldName);
    },
    [errorText]
  );

  const validate = useCallback(
    (userObj: ExtendedUser) => {
      return !Object.keys(userObj)
        .map(fieldName => hasError(fieldName))
        .includes(true);
    },
    [hasError]
  );

  const userHeaders: GridColumns = [
    {
      field: 'avatarS3',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 70,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <DataGridImage alt={t('avatar')} src={params.value?.toString()} />
        ) : (
          <span>&nbsp;</span>
        )
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) => {
        return (
          <Link
            href={`mailto:${params.row.email}`}
            rel="noreferrer"
            target="_blank"
            title={params.row.email}
          >
            {`${params.row.firstName} ${params.row.lastName}`}
          </Link>
        );
      }
    },
    {
      field: 'role',
      headerName: t('role'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) => {
        return t(params.value as string);
      }
    },
    {
      field: 'retailers',
      headerName: t('userRetailersCount'),
      width: 150,
      resizable: false,
      sortable: false,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return (
          <UserRetailersList id={params.id} retailers={params.value as []} />
        );
      }
    },
    {
      field: 'lastLogin',
      headerName: t('lastLogin'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) => {
        return params.value
          ? formatDateTime(params.value as string, true)
          : '-';
      }
    },
    {
      field: 'id',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 40,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        isAdmin() ? (
          <IconButton
            className="grid-icon-button"
            onClick={() => confirmDeleteUser(params.value)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
    }
  ];

  const addUser = useCallback(async () => {
    setExtendedUser({
      id: -1,
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      retailers: [],
      password: ''
    });
  }, []);

  const requestSearch = useCallback(
    (searchValue: string) => {
      setSearchText(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

      if (spot.data.users) {
        const filteredRows = spot.data.users.filter((row: GridRowModel) => {
          return ['email', 'firstName', 'lastName'].some((field: string) => {
            return searchRegex.test(row[field] ?? '');
          });
        });
        setUserRows(filteredRows);
      }
    },
    [setUserRows, spot, setSearchText]
  );

  const saveUser = useCallback(
    async (userObj: ExtendedUser) => {
      if (userObj && validate(userObj)) {
        const userData = {
          ...userObj
        };

        try {
          await command(`user/${userObj.id > 0 ? userObj.id : ''}`, userData, {
            method: userObj.id > 0 ? 'PATCH' : 'POST'
          });

          setExtendedUser(null);
          navigate(`/user`);
          await query('user/', {}, ['users']);
          requestSearch(searchText ?? '');
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    },
    [
      query,
      command,
      setExtendedUser,
      navigate,
      searchText,
      requestSearch,
      validate,
      displayErrors
    ]
  );

  const goBack = useCallback(async () => {
    await query('user/', {}, ['users']);
    setExtendedUser(null);
    navigate(`/user`);
    requestSearch(searchText ?? '');
  }, [query, setExtendedUser, navigate, searchText, requestSearch]);

  const cancelUserEdit = useCallback(
    async (userObj: ExtendedUser) => {
      if (userObj) {
        await query(`user/email/${email}`, {}, ['extendedUser']);
        setExtendedUser(spot.data.extendedUser);
      }
    },
    [setExtendedUser, spot, query, email]
  );

  const deleteUser = useCallback(
    async (userId: string | undefined) => {
      setConfirmUserDeleteOpen(false);

      try {
        if (userId) {
          await command(`user/${userId}`, undefined, {
            method: 'DELETE'
          });

          await query('user/', {}, ['users']);
          requestSearch(searchText ?? '');
        }
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [query, command, requestSearch, searchText, displayErrors]
  );

  const confirmDeleteUser = useCallback(
    async (userId: string) => {
      if (userId) {
        setDeletedUserId(userId);
        setConfirmUserDeleteOpen(true);
      }
    },
    [setDeletedUserId, setConfirmUserDeleteOpen]
  );

  const handleAvatarUpload = useCallback(
    async (userObj: ExtendedUser, file: File) => {
      if (userObj) {
        const urlResult = await raw<{ url: string }>(
          `user/${userObj.email}/avatar-upload-url/${file.name}`
        );

        const url = urlResult?.url;

        if (url) {
          try {
            const uploadResponse: Response = await fetch(url, {
              method: 'PUT',
              body: file
            });

            if (uploadResponse.ok) {
              // TODO: use CDN url
              const avatarUrl = new URL(url);
              const avatarUrlClean = `${avatarUrl.origin}${avatarUrl.pathname}`;

              setExtendedUser({
                ...userObj,
                avatarS3: null
              });

              setExtendedUser({
                ...userObj,
                avatarS3: `${avatarUrlClean}`
              });
            }
          } catch (e) {
            displayErrors(e as Error[]);
          }
        }
      }
    },
    [raw, displayErrors]
  );

  const removeRetailer = useCallback(
    async (
      userObj: ExtendedUser,
      retailer: {
        id: number;
        name: string;
      }
    ) => {
      if (userObj) {
        setExtendedUser({
          ...userObj,
          retailers: userObj?.retailers?.filter(r => r.id !== retailer.id) ?? []
        });
      }
    },
    [setExtendedUser]
  );

  const exportUsers = useCallback(() => {
    const nonAdmins = spot.data.users
      .filter(u => u.role !== 'admin')
      .map(u => ({
        email: u.email,
        firstName: u.firstName,
        lastName: u.lastName,
        optOutOfTransactionalEmails: u.optOutOfEmails,
        stores: (u as ExtendedUser).retailers?.map(r => r.name)?.join(',')
      }));
    downloadObjectsAsCsv(nonAdmins, 'faslet-non-admin-users.csv');
  }, [spot]);

  const sendWelcomeEmail = useCallback(async () => {
    await command('user/send-welcome', {
      email: extendedUser?.email,
      firstName: extendedUser?.firstName,
      lastName: extendedUser?.lastName
    });
  }, [command, extendedUser]);

  const sendOrderTrackingWarningEmail = useCallback(async () => {
    await command('user/send-order-tracking-warning', {
      email: extendedUser?.email,
      firstName: extendedUser?.firstName,
      lastName: extendedUser?.lastName,
      shopId: extendedUser?.retailers?.[0]?.slug
    });
  }, [command, extendedUser]);

  const sendTrafficDropEmail = useCallback(async () => {
    await command('user/send-traffic-drop', {
      email: extendedUser?.email,
      firstName: extendedUser?.firstName,
      lastName: extendedUser?.lastName,
      shopId: extendedUser?.retailers?.[0]?.slug
    });
  }, [command, extendedUser]);

  return !extendedUser ? (
    <>
      <ConfirmDeleteAlert
        id="confirmUserDelete"
        title={t('deleteUserConfirmation')}
        text={t('deleteUserConfirmationText')}
        open={confirmUserDeleteOpen}
        onConfirm={(userId: string | undefined) => deleteUser(userId)}
        value={deletedUserId}
        keepMounted
      />
      <Row>
        <Typography variant="h4">{t('users')}</Typography>
        <Spacer />
        {isAdmin() && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={addUser}
              startIcon={<AddIcon />}
            >
              {t('addUser')}
            </Button>
            <Gap />
            <Button
              variant="contained"
              color="primary"
              onClick={exportUsers}
              startIcon={<ExportUsersIcon />}
            >
              {t('exportUsers')}
            </Button>
          </>
        )}
      </Row>
      <Line />
      <HorizontalLoadingBar loading={loading} />
      <Gap />
      <DataGridFrame>
        <DataGrid
          onCellClick={(params: GridCellParams) => {
            const clickedField = params.colDef.field;
            if (
              ![
                'avatarS3',
                'email',
                'firstName',
                'lastName',
                'email',
                'role'
              ].includes(clickedField)
            ) {
              return;
            }
            if (params.row) {
              const selected = params.row as unknown as User;
              navigate(`/user/${selected.email}`);
            }
          }}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          loading={loading}
          rows={userRows}
          columns={userHeaders}
          autoPageSize
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (e: React.ChangeEvent<{ value: string }>) =>
                requestSearch(e.target.value),
              clearSearch: () => requestSearch('')
            }
          }}
        />
      </DataGridFrame>
    </>
  ) : (
    <>
      <Row>
        <Typography variant="h4">
          {spot.data?.users.length > 1 && (
            <IconButton onClick={goBack} size="large">
              <BackIcon />
            </IconButton>
          )}
          {extendedUser?.email ? extendedUser.email : t('newUser')}
        </Typography>
        <Spacer />
        <Button
          disabled={!validate(extendedUser)}
          variant="contained"
          color="primary"
          onClick={() => saveUser(extendedUser)}
          startIcon={<SaveIcon />}
        >
          {t('save')}
        </Button>
        <Gap />
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => cancelUserEdit(extendedUser)}
          startIcon={<CancelIcon />}
        >
          {t('reset')}
        </Button>
      </Row>
      <Line />
      <HorizontalLoadingBar loading={loading} />
      <Gap />
      <Row>
        <Container>
          {extendedUser.id <= 0 && (
            <TextField
              fullWidth
              label={t('email')}
              required
              error={hasError('email')}
              helperText={errorText('email')}
              onChange={(event: React.ChangeEvent<{ value: string }>) =>
                setExtendedUser({
                  ...extendedUser,
                  email: event.target.value
                })
              }
              value={extendedUser.email}
            />
          )}
          {extendedUser.email && (
            <>
              <Gap />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={2}>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Paper variant="outlined" style={{ fontSize: 0 }}>
                        {extendedUser.avatarS3 || extendedUser.avatar ? (
                          <img
                            width={200}
                            height={200}
                            src={
                              extendedUser.avatarS3 ?? extendedUser.avatar ?? ''
                            }
                            alt={t('avatar')}
                            style={{ objectFit: 'scale-down' }}
                          />
                        ) : (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            style={{ width: 200, height: 200 }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="overline"
                                align="center"
                                display="block"
                              >
                                {t('noLogo')}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Box display="flex">
                        <Box p={1}>
                          <FileInputButton
                            buttonText={t('upload')}
                            onFileSelected={file =>
                              handleAvatarUpload(extendedUser, file)
                            }
                          />
                        </Box>
                        <Box
                          p={1}
                          display={
                            extendedUser.avatarS3 || extendedUser.avatar
                              ? 'block'
                              : 'none'
                          }
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              setExtendedUser({
                                ...extendedUser,
                                avatarS3: null,
                                avatar: null
                              })
                            }
                          >
                            {t('delete')}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={1}>
                  &nbsp;
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        fullWidth
                        label={t('firstName')}
                        required
                        error={hasError('firstName')}
                        helperText={errorText('firstName')}
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) =>
                          setExtendedUser({
                            ...extendedUser,
                            firstName: event.target.value
                          })
                        }
                        value={extendedUser.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        fullWidth
                        label={t('lastName')}
                        required
                        error={hasError('lastName')}
                        helperText={errorText('lastName')}
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) =>
                          setExtendedUser({
                            ...extendedUser,
                            lastName: event.target.value
                          })
                        }
                        value={extendedUser.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <FormControl error={hasError('role')} required fullWidth>
                        <InputLabel id="user-role-label">
                          {t('role')}
                        </InputLabel>
                        <Select
                          labelId="user-role-label"
                          label={t('role')}
                          id="user-role"
                          value={extendedUser.role}
                          onChange={(event: SelectChangeEvent) =>
                            setExtendedUser({
                              ...extendedUser,
                              role: event.target.value
                            })
                          }
                        >
                          <MenuItem value="user">{t('user')}</MenuItem>
                          <MenuItem value="admin">{t('admin')}</MenuItem>
                          <MenuItem value="analytics-admin">
                            {t('analyticsAdmin')}
                          </MenuItem>
                        </Select>
                        <FormHelperText>{errorText('role')}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Gap />
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        onChange={(
                          event: unknown,
                          newValue: Retailer | null
                        ) => {
                          if (newValue) {
                            const addedRetailer = extendedUser?.retailers.find(
                              r => r.id === newValue.id
                            );
                            if (!addedRetailer) {
                              setExtendedUser({
                                ...extendedUser,
                                retailers: [
                                  ...(extendedUser?.retailers ?? []),
                                  newValue
                                ]
                              });
                            }
                          }
                        }}
                        value={null}
                        options={data?.retailers ?? []}
                        getOptionLabel={(r: Retailer) => r.name}
                        isOptionEqualToValue={(r1: Retailer, r2: Retailer) =>
                          r1.id === r2.id
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            margin="normal"
                            label={t('addRetailerToUser')}
                          />
                        )}
                      />
                      <Row>
                        {extendedUser.retailers?.map(retailer => (
                          <Fragment key={retailer.id}>
                            <Chip
                              label={retailer.name}
                              onClick={() =>
                                navigate(`/retailer/${retailer.slug}`)
                              }
                              onDelete={
                                isAdmin()
                                  ? () => removeRetailer(extendedUser, retailer)
                                  : undefined
                              }
                            />
                            <Gap />
                          </Fragment>
                        ))}
                      </Row>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Gap />
              {isAdmin() && (
                <>
                  <Line />
                  <Gap />
                  <Row>
                    <Spacer />
                    <Button
                      variant="contained"
                      onClick={sendWelcomeEmail}
                      color="primary"
                      endIcon={<SendIcon />}
                    >
                      {t('sendWelcome')}
                    </Button>
                    <Gap />
                    <Button
                      variant="contained"
                      onClick={sendOrderTrackingWarningEmail}
                      color="primary"
                      endIcon={<SendIcon />}
                      disabled={extendedUser?.retailers?.length !== 1}
                    >
                      {t('sendOrderTrackingWarning')}
                    </Button>
                    <Gap />
                    <Button
                      variant="contained"
                      onClick={sendTrafficDropEmail}
                      color="primary"
                      endIcon={<SendIcon />}
                    >
                      {t('sendTrafficDrop')}
                    </Button>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      </Row>
      {isAdmin() && extendedUser && (
        <>
          <Gap size={4} />
          <Line />
          <Gap />
          <UserAuditTrail id={extendedUser.id} />
        </>
      )}
      {notification}
    </>
  );
}
