import React, { useCallback, useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import styled from '@emotion/styled';
import {
  GetAppRounded as DownloadIcon,
  LoopRounded as RefreshIcon
} from '@mui/icons-material';
import { Buffer } from 'buffer';

import {
  AuditTrailEvent,
  downloadBlob,
  formatDateTime,
  useSpot
} from 'framework';

import { Link } from 'react-router-dom';
import { SearchField } from '../search-field';
import { DateBox } from '../date-box';
import { Gap, Spacer } from '../spacer';
import { CenteredRow, Row, Container } from '../layout';
import { actions, describeAction } from './common';

const DataContainer = styled(Row)`
  position: relative;
  height: calc(100vh - 280px - 120px);
  overflow-y: auto;
`;

const MainContainer = styled(Container)`
  padding: ${p => p.theme.spacing(1)};
`;

export function AuditTrail() {
  const [startDate, setStartDate] = useState<moment.Moment>();
  const [endDate, setEndDate] = useState<moment.Moment>();
  const [search, setSearch] = useState<string>();
  const { t } = useTranslation();
  const { isAdmin, query, spot, loading } = useSpot();

  const refresh = useCallback(async () => {
    if (isAdmin()) {
      const restParams = {} as any;
      if (search) {
        restParams.search = search;
      }
      await query(
        'audit-trail/search',
        {
          ...restParams,
          startDate: moment(startDate).startOf('day').toISOString(),
          endDate: moment(endDate).endOf('day').toISOString()
        },
        ['auditTrail', 'search']
      );
    }
  }, [query, search, startDate, endDate, isAdmin]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const columns: GridColumns<AuditTrailEvent> = [
    {
      field: 'id',
      hide: true
    },
    {
      field: 'baseUrl',
      headerName: t('entity'),
      renderCell: ({ value }) => t(`${actions[value]}`),
      flex: 1
    },
    {
      field: 'path',
      headerName: t('action'),
      renderCell: ({ value, row }) =>
        t(`${describeAction(row.baseUrl, value, row.method)}`),
      flex: 1
    },
    {
      field: 'user',
      headerName: t('user'),
      renderCell: ({ value }: GridCellParams) => {
        return (
          <Link
            to={`/user/${value.email}`}
            rel="noreferrer"
            title={value.email}
          >
            {`${value.firstName} ${value.lastName}`}
          </Link>
        );
      },
      flex: 1
    },
    {
      field: 'createdAt',
      headerName: t('date'),
      renderCell: ({ value }) => formatDateTime(value),
      flex: 1
    },
    {
      field: 'actions',
      headerName: '',
      width: 64,
      renderCell: ({ row }) => (
        <CenteredRow>
          <IconButton
            disabled={loading || !row.body}
            onClick={() => {
              if (
                row.body &&
                typeof row.body === 'object' &&
                (row.body as any).type === 'Buffer'
              ) {
                const buffer = Buffer.from(row.body as any);
                downloadBlob(
                  new Blob([buffer]),
                  `${
                    describeAction(row.baseUrl, row.path, row.method) ??
                    'unknown-action'
                  }.csv`
                );
              } else {
                downloadBlob(
                  new Blob([JSON.stringify(row.body, null, 2)]),
                  `${
                    describeAction(row.baseUrl, row.path, row.method) ??
                    'unknown-action'
                  }.json`
                );
              }
            }}
            size="large"
          >
            <DownloadIcon />
          </IconButton>
        </CenteredRow>
      )
    }
  ];

  return (
    <MainContainer>
      <Row>
        <IconButton
          color="primary"
          disabled={loading}
          onClick={refresh}
          title={t('refresh')}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
        <Spacer />
        <DateBox
          includeToday
          disabled={loading}
          defaultRange="thisMonth"
          onStartChange={setStartDate}
          onEndChange={setEndDate}
        />
      </Row>
      <Gap />
      <SearchField onChange={setSearch} />
      <Gap />
      <DataContainer>
        <DataGrid
          columns={columns}
          rows={spot.data?.auditTrail?.search || []}
          disableColumnMenu
          disableColumnSelector
          autoPageSize
          loading={loading}
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
        />
      </DataContainer>
    </MainContainer>
  );
}
