import React, { useCallback, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link, Popover, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { QuestionMarkRounded as HelpIcon } from '@mui/icons-material';

import { formatStat, RetailerItem, RoleContext } from 'framework';

import {
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormTextValue
} from './form-input';
import { StatusIcon } from './status-icon';

const ShopIdEmphasis = styled.pre`
  font-weight: bold;
  font-style: italic;
  background-color: #50aa8d11;
  border-radius: 5px;
  padding: 4px;
  margin: 4px;
  display: inline;
`;

const StyledLink = styled(Link)`
  color: ${p => p.theme.palette.primary.main};
  text-decoration: underline;
`;

const IconBox = styled.span`
  position: relative;
  top: -4px;
  pointer-events: auto;
  width: 10px;
  height: 10px;
  font-size: 12px;
`;

const InfoPopup = styled(Popover)`
  pointer-events: none;
`;

const InfoTextBox = styled(Box)`
  padding: ${p => p.theme.spacing(2)};
  border: 1px solid ${p => p.theme.palette.primary.main};
  border-radius: 5px;
  max-width: 400px;
`;

export interface IntegrationStatus {
  hasButtonShow: boolean;
  hasAddToCart: boolean;
  hasCorrelationId: boolean;
  hasVariants: boolean;
  hasOrderTracking: boolean;
  hasOrderSku: boolean;
  hasOrderCorrelationId: boolean;
  hasOrdersWhereAdviceFollowed: boolean;
  hasOrdersWhereWidgetUsed: boolean;
  buttonShowPercent: number;
}

export interface WidgetIntegrationStatusProps {
  retailer: RetailerItem;
  integrationStatus?: IntegrationStatus;
  loading: boolean;
}

function StatusRow({
  title,
  info,
  loading,
  value
}: {
  title: string;
  info: string;
  loading: boolean;
  value?: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <FormRow>
      <FormLabel>
        {title}
        <IconBox
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <HelpIcon fontSize="inherit" />
        </IconBox>
        <InfoPopup
          id="mouse-over-popover"
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <InfoTextBox>{info}</InfoTextBox>
        </InfoPopup>
      </FormLabel>
      <FormFieldWrapper>
        <StatusIcon
          loading={loading}
          status={
            value === undefined ? 'unknown' : value ? 'active' : 'not-active'
          }
        />
      </FormFieldWrapper>
    </FormRow>
  );
}

export function WidgetIntegrationStatus({
  retailer,
  integrationStatus,
  loading
}: WidgetIntegrationStatusProps) {
  const { t } = useTranslation();

  const { isAdmin } = useContext(RoleContext);

  return (
    <FormSection>
      <FormSectionTitle>{t('integrationStatus')}</FormSectionTitle>
      <StatusRow
        title={t('integrationStatusProduct')}
        info={t('integrationStatusProductInfo')}
        loading={loading}
        value={integrationStatus?.hasButtonShow}
      />
      <StatusRow
        title={t('integrationStatusVariants')}
        info={t('integrationStatusVariantsInfo')}
        loading={loading}
        value={integrationStatus?.hasVariants}
      />
      <StatusRow
        title={t('integrationStatusAddToCart')}
        info={t('integrationStatusAddToCartInfo')}
        loading={loading}
        value={integrationStatus?.hasAddToCart}
      />
      <StatusRow
        title={t('integrationStatusOrderTracking')}
        info={t('integrationStatusOrderTrackingInfo')}
        loading={loading}
        value={integrationStatus?.hasOrderTracking}
      />
      <StatusRow
        title={t('integrationStatusOrderSku')}
        info={t('integrationStatusOrderSkuInfo')}
        loading={loading}
        value={integrationStatus?.hasOrderSku}
      />

      <StatusRow
        title={t('integrationStatusOrderCorrelationId')}
        info={t('integrationStatusOrderCorrelationIdInfo')}
        loading={loading}
        value={integrationStatus?.hasOrderCorrelationId}
      />
      {isAdmin() && (
        <StatusRow
          title={t('integrationStatusFollowedAdvice')}
          info={t('integrationStatusFollowedAdviceInfo')}
          loading={loading}
          value={integrationStatus?.hasOrdersWhereAdviceFollowed}
        />
      )}
      {isAdmin() && (
        <FormRow>
          <FormLabel>{t('integrationButtonShowPercentage')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>
              {formatStat(integrationStatus?.buttonShowPercent, true, 0)}
            </FormTextValue>
          </FormFieldWrapper>
        </FormRow>
      )}
      <FormRow>
        <Typography>
          <Trans
            i18nKey="integrationDocs"
            values={{ slug: retailer.slug }}
            components={[
              <StyledLink
                key="docs_link"
                target="_blank"
                href="https://docs.faslet.net/docs/widget/"
              />,
              <ShopIdEmphasis key="docs_bold" />
            ]}
          />
        </Typography>
      </FormRow>
    </FormSection>
  );
}
