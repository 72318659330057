import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpot, IntegrationDetails, useErrorNotification } from 'framework';
import {
  TextField,
  Typography,
  Link,
  Box,
  Button,
  CircularProgress
} from '@mui/material';
import styled from '@emotion/styled';
import {
  SaveRounded as SaveIcon,
  CloseRounded as CloseIcon
} from '@mui/icons-material';
import {
  FormSection,
  FormSectionTitle,
  FormRow,
  FormFieldWrapper,
  FormLabel,
  Gap,
  Spacer,
  Notification
} from '../../../components';
import returnlessDirections1 from '../returnless-step_1.png';
import returnlessDirections2 from '../returnless-step_2.png';
import returnlessDirections3 from '../returnless-step_3.png';
import returnlessDirections4 from '../returnless-step_4.png';
import { IntegrationEditProps } from './common';

const FormRowDirections = styled(Box)`
  margin: ${p => p.theme.spacing(1, 0)};
  display: flex;

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  ${p => p.theme.breakpoints.up('sm')} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const TitleSection = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const DirectionsIntro = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DirectionsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${p => p.theme.spacing(1)};
`;

const LoaderBox = styled(Box)`
  height: 4.5rem;
  padding: ${p => p.theme.spacing(2)};
`;

const EditLoading = styled(CircularProgress)`
  margin-left: 45%;
`;

const DirectionsImage = styled.img`
  height: 100%;
  object-fit: scale-down;
  border: 0;
  max-height: 300px;
`;

export function Returnless({
  active,
  slug,
  close,
  integration,
  loading
}: IntegrationEditProps) {
  const { t } = useTranslation();
  const { command, query, spot } = useSpot();
  const [integrationData, setIntegrationData] = useState<
    IntegrationDetails | undefined
  >(integration);
  const [copied, setCopied] = useState(false);

  const { displayErrors, notification } = useErrorNotification();

  const handleSave = useCallback(async () => {
    const integrationInfo = {
      ...integrationData
    };

    try {
      await command(`retailer/${slug}/integrations`, integrationInfo, {
        method: 'POST'
      });
      await query(`retailer/${slug}/integrations`, {}, ['integrationDetails']);
      setIntegrationData(spot.data?.integrationDetails);
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [
    query,
    command,
    setIntegrationData,
    displayErrors,
    integrationData,
    slug,
    spot
  ]);

  return (
    <>
      <FormSection>
        <TitleSection>
          <FormSectionTitle>
            {`${active === true ? t(`add`) : t(`edit`)} ${t(
              `returnlessIntegration`
            )}`}
          </FormSectionTitle>
          <Spacer />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            startIcon={<SaveIcon />}
          >
            {t(`save`)}
          </Button>
          <Gap />
          <Button
            variant="contained"
            color="primary"
            onClick={close}
            startIcon={<CloseIcon />}
          >
            {t(`close`)}
          </Button>
        </TitleSection>
        {loading && integration ? (
          <LoaderBox>
            <EditLoading color="primary" />
          </LoaderBox>
        ) : (
          <>
            <FormRowDirections>
              <FormLabel>{t('directions')}</FormLabel>
              <DirectionsWrapper>
                <DirectionsIntro>
                  <Typography>{t('goto')}</Typography>
                  <Gap size={1} />
                  <Link
                    href="https://account.returnless.com/retourportal/dashboard/apikey/grid/"
                    target="_blank"
                  >
                    <b>Returnless</b>
                  </Link>
                </DirectionsIntro>
                <Gap />
                <Typography>{t('returnlessDirections1')}</Typography>
                <Gap />
                <DirectionsImage
                  alt="returnless-integration"
                  src={returnlessDirections1}
                />
                <Gap />
                <Typography>{t('returnlessDirections2')}</Typography>
                <Gap />
                <DirectionsImage
                  alt="returnless-integration"
                  src={returnlessDirections2}
                />
                <Gap />
                <Typography>{t('returnlessDirections3')}</Typography>
                <Gap />
                <DirectionsImage
                  alt="returnless-integration"
                  src={returnlessDirections3}
                />
                <Gap />
                <DirectionsImage
                  alt="returnless-integration"
                  src={returnlessDirections4}
                />
                <Gap />
                <Typography>{t('returnlessDirections4')}</Typography>
                <Gap />
              </DirectionsWrapper>
            </FormRowDirections>
            <Gap size={4} />
            <FormRow>
              <FormLabel>{t('apiKey')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  type="text"
                  fullWidth
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    setIntegrationData({
                      ...integrationData,
                      returnless: {
                        ...integrationData?.returnless,
                        apiPassword: event.target.value
                      }
                    })
                  }
                  value={integrationData?.returnless?.apiPassword ?? ''}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={2} />
          </>
        )}
      </FormSection>
      {copied && (
        <Notification
          onClose={() => setCopied(false)}
          title={t('copied')}
          severity="info"
          duration={3000}
        />
      )}
      {notification}
    </>
  );
}
