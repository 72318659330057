import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Brand,
  formatStat,
  Item,
  openInNewTab,
  ProductInfoListResponse,
  RetailerContext,
  RoleContext,
  useErrorNotification,
  useSpot
} from 'framework';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Slider,
  Button
} from '@mui/material';

import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';

import {
  ExitToAppRounded as PopoutIcon,
  RssFeedRounded as FeedIcon,
  WebRounded as WidgetIcon,
  CloseRounded as CloseIcon,
  SaveRounded as SaveIcon,
  UnarchiveRounded as SizeChartUploadIcon
} from '@mui/icons-material';

import {
  CenteredRow,
  FormRow,
  FormSection,
  FormSectionTitle,
  Gap,
  RetailerLink,
  Row,
  SizeChartLink,
  Spacer
} from 'components';

import { DataContainer, RecommendationBar, RecommendationTab } from './common';
import styled from '@emotion/styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SourceIconContainer = styled.div`
  margin-right: ${p => p.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GridSectionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  height: calc(100vh - 290px);
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

const ProductImageWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
`;

const CloseButton = styled(IconButton)``;

const ProductIdContainer = styled(CenteredRow)`
  flex-wrap: nowrap;
`;

interface ProductInfosResponse {
  products: ProductInfoListResponse[];
  count: number;
}

export interface SrrSinceLastAdjustmentProps {
  onLoadingChanged: (loading: boolean) => void;
  selectedBrand: Brand | null;
}

interface ReturnSinceLastAdjustment {
  id: number;
  gender: 'male' | 'female' | 'unisex';
  sizeRelatedReturnsSinceLastAdjustment: number;
  followedAdviceSizeRelatedReturnsSinceLastAdjustment: number;
  deltaSinceLastAdjustment: number;
  followedAdviceDeltaSinceLastAdjustment: number;
  productId: number;
  brandId: number;
  brandName: string;
  productName: string;
  followedDeviation: number;
  deviation: number;
}

export function SrrSinceLastAdjustment({
  onLoadingChanged,
  selectedBrand
}: SrrSinceLastAdjustmentProps) {
  const { t } = useTranslation();
  const { rawQuery, rawCommand } = useSpot();
  const { retailer } = useContext(RetailerContext);
  const { isAdmin } = useContext(RoleContext);
  const [chartTtsSkew, setChartTtsSkew] = useState<number | undefined>();
  const [items, setItems] = useState<Item[]>([]);

  const [loading, setLoading] = useState(false);

  const [selectedSizeChart, setSelectedSizeChart] =
    useState<ReturnSinceLastAdjustment>();
  const [productInfos, setProductInfos] = useState<ProductInfoListResponse[]>(
    []
  );

  const [returnsSinceLastAdjustment, setReturnsSinceLastAdjustment] = useState<
    ReturnSinceLastAdjustment[]
  >([]);

  const { displayErrors, notification, displaySuccess } =
    useErrorNotification();

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const getReturnsSinceLastAdjustment = useCallback(async () => {
    setLoading(true);

    const params = {} as Record<string, any>;

    if (selectedBrand) {
      params.brandId = selectedBrand.id;
    }

    try {
      const result = await rawQuery<ReturnSinceLastAdjustment[]>(
        `returns/since-last-adjustment`,
        params
      );
      setReturnsSinceLastAdjustment(result);
    } catch (e) {
      console.error(e);
      displayErrors(e as Error[]);
    }

    setLoading(false);
  }, [rawQuery, retailer, t, setReturnsSinceLastAdjustment, selectedBrand]);

  useEffect(() => {
    getReturnsSinceLastAdjustment();
  }, [getReturnsSinceLastAdjustment]);

  const getProductInfo = useCallback(async () => {
    if (!selectedSizeChart) {
      return;
    }
    setLoading(true);
    try {
      const productInfo = await rawQuery<ProductInfosResponse>(
        `product-info/`,
        {
          brand: encodeURIComponent(selectedSizeChart.brandName),
          productType: encodeURIComponent(selectedSizeChart.productName),
          productGender: encodeURIComponent(selectedSizeChart.gender)
        }
      );
      console.log(productInfo);
      setProductInfos(productInfo.products);
    } catch (e) {
      console.error(e);
      displayErrors(e as Error[]);
    }
    setLoading(false);
  }, [rawQuery, selectedSizeChart]);

  const getChartItems = useCallback(async () => {
    if (!selectedSizeChart) {
      return;
    }
    setLoading(true);
    try {
      const items = await rawQuery<Item[]>(`item/`, {
        brandId: selectedSizeChart.brandId,
        product: selectedSizeChart.productName
      });
      setItems(items);

      items.length && setChartTtsSkew(items[0].ttsSkew);
    } catch (e) {
      console.error(e);
      displayErrors(e as Error[]);
    }

    setLoading(false);
  }, [rawQuery, selectedSizeChart, setChartTtsSkew]);

  useEffect(() => {
    (async () => {
      await getProductInfo();
      await getChartItems();
    })();
  }, [selectedSizeChart, getChartItems, rawQuery]);

  const handleSaveChartSkew = useCallback(async () => {
    if (!selectedSizeChart) {
      return;
    }
    setLoading(true);
    try {
      const promises = items.map(item => {
        return rawCommand(`item/${item.id}/tts-skew`, {
          ttsSkew: chartTtsSkew
        });
      });

      await Promise.all(promises);

      displaySuccess();
    } catch (e) {
      console.error(e);
      displayErrors(e as Error[]);
    }

    setLoading(false);
  }, [rawCommand, selectedSizeChart, chartTtsSkew, items]);

  const onChangeChartTtsSkew = useCallback(
    (_, value) => {
      setChartTtsSkew(Number(value));
    },
    [setChartTtsSkew]
  );

  const headers: GridColumns = [
    {
      field: 'brandName',
      headerName: t('brand'),
      flex: 1
    },
    {
      field: 'gender',
      headerName: t('gender'),
      flex: 1,
      renderCell: (params: GridCellParams) => t(params.value)
    },
    {
      field: 'productName',
      headerName: t('product'),
      flex: 1
    },
    {
      field: 'sizeRelatedReturnsSinceLastAdjustment',
      headerName: t('sizeRelatedReturnsSinceLastAdjustment'),
      align: 'right',
      flex: 1,
      renderCell: (params: GridCellParams) => formatStat(params.value)
    },
    {
      field: 'deviation',
      headerName: t('srReturnReasons'),
      align: 'right',
      flex: 1,
      renderCell: params => (
        <RecommendationBar
          title={`${formatStat(Math.abs(params.value) * 100, true, 2)} (${formatStat(Math.abs(params.row.deltaSinceLastAdjustment), false, 0)}) ${Math.sign(params.value) < 0 ? t('tooSmall') : t('tooBig')}`}
        >
          <RecommendationTab delta={params.value} />
        </RecommendationBar>
      )
    },
    {
      field: 'followedAdviceSizeRelatedReturnsSinceLastAdjustment',
      headerName: t('followedAdviceSizeRelatedReturnsSinceLastAdjustment'),
      align: 'right',
      flex: 1,
      renderCell: (params: GridCellParams) => formatStat(params.value)
    },

    {
      field: 'followedDeviation',
      headerName: t('srFollowedAdviceReturnReasons'),
      align: 'right',
      flex: 1,
      renderCell: params => (
        <RecommendationBar
          title={`${formatStat(Math.abs(params.value) * 100, true, 2)} (${formatStat(Math.abs(params.row.followedAdviceDeltaSinceLastAdjustment), false, 0)}) ${Math.sign(params.value) < 0 ? t('tooSmall') : t('tooBig')}`}
        >
          <RecommendationTab delta={params.value} />
        </RecommendationBar>
      )
    }
  ];

  const productInfoHeaders: GridColumns = [
    {
      field: 'imageLink',
      headerName: t('productImage'),
      width: 80,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <ProductImageWrapper>
            <LazyLoadImage
              src={params.value as string}
              alt={params.row.name as string}
              height="100%"
              effect="opacity"
              decoding="async"
            />
          </ProductImageWrapper>
        ) : null
    },
    {
      field: 'productIdentifier',
      headerName: t('productId'),
      flex: 0.6,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <ProductIdContainer>
          {isAdmin() && params.row.source === 'feed' && (
            <SourceIconContainer title={t('sourceFeed')}>
              <FeedIcon />
            </SourceIconContainer>
          )}
          {isAdmin() && params.row.source === 'widget' && (
            <SourceIconContainer title={t('sourceWidget')}>
              <WidgetIcon />
            </SourceIconContainer>
          )}
          {isAdmin() && params.row.source === 'size-chart-api' && (
            <SourceIconContainer title={t('sourceSizeChartApi')}>
              <SizeChartUploadIcon />
            </SourceIconContainer>
          )}
          <p title={params.value}>{params.value}</p>
        </ProductIdContainer>
      )
    },
    {
      field: 'name',
      headerName: t('title'),
      flex: 1.4,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <p title={params.row.name}>{`${
          params.row.customLabel0 ? `[${params.row.customLabel0}] ` : ''
        }${params.row.name}`}</p>
      )
    },
    {
      field: 'tagName',
      headerName: t('fasletStatus'),
      flex: 0.6,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <SizeChartLink
            isAdmin={isAdmin}
            brand={params.row.brand}
            brandSlug={params.row.brandSlug}
            tagName={params.row.tagName}
            tagGender={params.row.tagGender}
            tagProductName={params.row.tagProductName}
            hasSizeChart={params.row.hasSizeChart}
            hasChartLabels={params.row.hasChartLabels}
          />
        </>
      )
    },
    {
      field: 'retailer',
      headerName: t('retailer'),
      flex: 0.6,
      hide: retailer !== null,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <RetailerLink retailer={params.row.retailer} />
      )
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 64,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <Row>
          <IconButton
            disabled={!params.row.productUrl}
            onClick={() => openInNewTab(`${params.row.productUrl}`)}
            title={
              params.row.productUrl ? `${params.row.productUrl}` : undefined
            }
            size="large"
          >
            <PopoutIcon />
          </IconButton>
        </Row>
      )
    }
  ];

  return (
    <>
      <Gap />
      <Grid container>
        <Grid xs={selectedSizeChart ? 7 : 12}>
          <GridSectionContainer>
            <DataGrid
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              loading={loading}
              rows={returnsSinceLastAdjustment}
              onRowClick={gridRow => setSelectedSizeChart(gridRow.row)}
              columns={headers}
              autoPageSize
              getRowId={row => row.id}
              columnBuffer={2}
              headerHeight={40}
              rowHeight={52}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'errorPercentage', sort: 'desc' }]
                }
              }}
            />
          </GridSectionContainer>
        </Grid>
        {!!selectedSizeChart && (
          <Grid xs={5}>
            <GridSectionContainer>
              <FormSection>
                <CenteredRow>
                  <FormSectionTitle>{t('ttsSkew')}</FormSectionTitle>
                  <Spacer />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveChartSkew}
                    startIcon={<SaveIcon />}
                    disabled={loading || !selectedSizeChart.productName}
                  >
                    {t('save')}
                  </Button>
                  <Gap />
                  <CloseButton
                    onClick={() => {
                      setSelectedSizeChart(undefined);
                    }}
                  >
                    <CloseIcon />
                  </CloseButton>
                </CenteredRow>
                <Gap size={4} />
                <FormRow>
                  <Slider
                    id="ttsSkew"
                    title={t('ttsSkewInfo')}
                    value={chartTtsSkew ?? 0}
                    onChange={onChangeChartTtsSkew}
                    marks
                    track={false}
                    valueLabelDisplay={
                      !!selectedSizeChart.productName ? 'on' : 'off'
                    }
                    step={0.1}
                    min={-2}
                    max={2}
                    disabled={chartTtsSkew === undefined}
                  />
                </FormRow>
              </FormSection>
              <DataContainer>
                <DataGrid
                  rows={productInfos}
                  columns={productInfoHeaders}
                  getRowId={row => row.productIdentifier}
                  // onRowClick={onRowClick} TODO: Open product info.
                  disableColumnMenu
                  disableColumnSelector
                  autoPageSize
                  columnBuffer={2}
                  headerHeight={40}
                  rowHeight={52}
                />
              </DataContainer>
            </GridSectionContainer>
          </Grid>
        )}
      </Grid>
      {notification}
    </>
  );
}

export default SrrSinceLastAdjustment;
