import React, {
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useContext
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Box,
  TextField,
  Link,
  IconButton,
  SvgIcon,
  Autocomplete
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowParams
} from '@mui/x-data-grid';

import {
  UntaggedProduct,
  useSpot,
  downloadObjectsAsCsv,
  getApi,
  downloadCsv,
  RetailerContext,
  openInNewTab,
  Brand
} from 'framework';
import {
  SaveRounded as SaveIcon,
  GetAppRounded as ExportIcon,
  PublishRounded as ImportIcon,
  ExitToAppRounded as PopoutIcon,
  FileCopyRounded as CopyIcon,
  LoopRounded as RefreshIcon
} from '@mui/icons-material';

import styled from '@emotion/styled';

import {
  Row,
  Line,
  Spacer,
  Container,
  Gap,
  Notification,
  FileInputButton,
  CenteredRow,
  RowCenter
} from 'components';
import { Error } from 'spot-store';
import { ReactComponent as PredictIcon } from 'images/wand.svg';

import copyToClipboard from 'copy-to-clipboard';
import { batchItems } from 'helpers';

const UntaggedGridContainer = styled(Box)`
  display: flex;
  height: 58vh;
  width: 100%;
`;

const UntaggedSummaryGridContainer = styled(UntaggedGridContainer)`
  .MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
  }
`;

export interface UntaggedProductsProps {
  onLoadingChanged: (loading: boolean) => unknown;
  selectedBrand: Brand | null;
}

export function UntaggedProducts({
  onLoadingChanged,
  selectedBrand
}: UntaggedProductsProps) {
  const { t } = useTranslation();
  const { spot, data, query, loading, raw } = useSpot();
  const [notification, setNotification] = useState<ReactNode>();
  const [copied, setCopied] = useState(false);
  const [untaggedProducts, setUntaggedProducts] = useState<UntaggedProduct[]>(
    []
  );

  const { retailer, onRetailerChanged } = useContext(RetailerContext);
  const retailerSlug = retailer?.slug;

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const handleCopy = useCallback((text: string) => {
    copyToClipboard(text);
    setCopied(true);
  }, []);

  const handleSnackClose = useCallback(
    () => setNotification(undefined),
    [setNotification]
  );

  useEffect(() => {
    if (retailerSlug) {
      query(`retailer/slug/${retailerSlug}`, {}, ['extendedRetailer']);
    }
  }, [query, retailerSlug]);

  const refresh = useCallback(() => {
    if (retailerSlug) {
      return query(
        `analytics/untagged-products?retailer=${
          retailerSlug ?? ''
        }&brand=${selectedBrand?.slug ?? ''}`,
        {},
        ['untaggedProducts']
      );
    }
    return query(`analytics/untagged-products-summary`, {}, [
      'untaggedProductSummary'
    ]);
  }, [query, retailerSlug, selectedBrand]);

  const selectTag = (currentProductId: string, newTag: string | null) => {
    const toChange = untaggedProducts.find(
      ut => ut.prodId === currentProductId
    );
    if (!toChange || !newTag) {
      return;
    }
    toChange.tag = newTag;
    const newUntaggedProducts = untaggedProducts.filter(
      ut => ut.prodId !== currentProductId
    );
    setUntaggedProducts([...newUntaggedProducts, toChange]);
  };

  const handleExportItems = useCallback(
    async (items: UntaggedProduct[]) => {
      if (items && retailer) {
        const rows = items?.map((item: UntaggedProduct) => ({
          ProductId: item.prodId,
          Retailer: item.shopId,
          Brand: item.brand_id,
          Tag: item.tag,
          product: item.product,
          gender: item.gender,
          variants: item.variants,
          pageUrl: item.pageUrl
        }));

        const filename = `${retailer.slug}_untagged_products_uploads.csv`;
        downloadObjectsAsCsv(rows, filename);
      }
    },
    [retailer]
  );

  useEffect(() => {
    query('tag/', {}, ['tags']);
  }, [query]);

  useEffect(() => {
    (async () => {
      await query('retailer/', {}, ['retailers']);
    })();
  }, [query]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setUntaggedProducts(data?.untaggedProducts ?? []);
  }, [data]);

  const handleImportProductIds = useCallback(
    async (file: File | string) => {
      if (file) {
        try {
          await raw(`tag-metadata/csv`, {
            body: file,
            method: 'POST',
            headers: {
              'content-type': 'text/csv'
            }
          });
          setNotification(
            <Notification
              onClose={handleSnackClose}
              severity="success"
              message={t('importProductIdsSuccess')}
            />
          );

          await refresh();
        } catch (e) {
          (e as Error[]).forEach((error: Error) => {
            setNotification(
              <Notification
                onClose={handleSnackClose}
                severity="error"
                message={`${error.body}`}
              />
            );
          });
        }
      }
    },
    [handleSnackClose, refresh, raw, t]
  );

  const handleSaveUntaggedProd = useCallback(
    async (items: UntaggedProduct[]) => {
      const itemCsv = [['ProductId', 'Tag', 'Retailer', 'Brand'].join(',')]
        .concat(
          items
            .filter(item => !!item.tag)
            .map(item =>
              [item.prodId, item.tag, item.shopId, item.brand_id].join(',')
            )
        )
        .join('\n');
      await handleImportProductIds(itemCsv);
    },
    [handleImportProductIds]
  );

  const handleExportProductIds = useCallback(async () => {
    const response = await fetch(
      `${getApi()}/tag-metadata/export?retailer=${retailerSlug}`,
      {
        credentials: 'include'
      }
    );
    const csv = await response.text();
    downloadCsv(`${retailerSlug}-tags.csv`, csv);
  }, [retailerSlug]);

  const retailerSelected = useCallback(
    (params: GridRowParams) => {
      const found = data.retailers?.find(
        r => r.slug === params.row.shopId || r.name === params.row.shopId
      );
      if (found) {
        onRetailerChanged(found);
      }
    },
    [onRetailerChanged, data]
  );

  const predictTag = useCallback(
    async (
      title: string,
      gender: string | undefined,
      labels: string | undefined,
      id: string | number,
      brand: string
    ) => {
      const prediction = await raw<{
        product?: string;
        gender?: 'male' | 'female' | 'unisex';
        labels?: string;
        tag?: string;
      }>('product-predictor/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          retailer: retailerSlug,
          title,
          gender,
          labels,
          brand
        })
      });

      const newUntaggedProducts = [...untaggedProducts];
      const prod = newUntaggedProducts.find(p => p.prodId === id);
      if (prod) {
        prod.tag =
          prediction?.tag ??
          (prediction?.product === 'excluded' ? 'Faslet_Excluded' : prod.tag);
      }

      setUntaggedProducts([...newUntaggedProducts]);
    },
    [untaggedProducts, raw, retailerSlug]
  );

  const predictTags = useCallback(async () => {
    const byBrand = untaggedProducts.reduce(
      (accum: Record<string, any[]>, ut: any) => {
        const newAccum = { ...accum };
        if (!newAccum[ut.brand_id ?? 'FASLET_noBrand']) {
          newAccum[ut.brand_id ?? 'FASLET_noBrand'] = [];
        }

        newAccum[ut.brand_id ?? 'FASLET_noBrand'].push(ut);

        return newAccum;
      },
      {}
    ) as Record<string, any[]>;

    let results: any[] = [];

    const itemBatches = batchItems(Object.entries(byBrand), 10);

    while (itemBatches.length) {
      const nextBatch = itemBatches.shift();

      const promises =
        nextBatch?.map(async ([brand, untaggedForBrand]) => {
          interface Predictions {
            results: {
              product?: string;
              gender?: 'male' | 'female' | 'unisex';
              labels?: string;
              tag?: string;
            }[];
          }
          let predictions: Predictions | undefined;

          try {
            predictions = await raw<Predictions>(
              'product-predictor/batch-predict',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  retailer: retailerSlug,
                  titles: untaggedForBrand.map(ut => ut.product),
                  genders: untaggedForBrand.map(ut => ut.gender),
                  labels: untaggedForBrand.map(ut => ut.variants),
                  brand: brand === 'FASLET_noBrand' ? undefined : brand
                })
              }
            );
          } catch (e) {
            console.error(e);
          }

          const predictedForBrand = untaggedForBrand.map((ut, index) => ({
            ...ut,
            tag:
              predictions?.results?.[index]?.tag ??
              (predictions?.results?.[index]?.product === 'excluded'
                ? 'Faslet_Excluded'
                : ut.tag)
          }));

          return predictedForBrand;
        }) ?? [];

      // eslint-disable-next-line no-await-in-loop
      const newResults = await Promise.all(promises);

      results = results.concat(newResults);
    }

    const flattened = results.reduce(
      (accum, curr) => (curr ? [...accum, ...curr] : accum),
      []
    );

    setUntaggedProducts([...flattened]);
  }, [untaggedProducts, raw, retailerSlug]);

  const getBrandSlugFromText = (value: string) => {
    const lowerValue = value.toLocaleLowerCase();
    return (
      spot.data?.extendedRetailer?.brands?.find(
        v =>
          v.name.toLocaleLowerCase() === lowerValue ||
          v.slug.toLocaleLowerCase() === lowerValue
      )?.slug ?? lowerValue
    );
  };

  const summaryHeaders: GridColumns = [
    {
      field: 'id',
      headerName: t(`untaggedId`),
      hide: true
    },
    {
      field: 'shopId',
      headerName: t(`retailer`),
      flex: 1
    },
    {
      field: 'products',
      headerName: t(`products`),
      minWidth: 150,
      align: 'right'
    }
  ];

  const itemHeaders: GridColumns = [
    {
      field: 'id',
      headerName: t(`untaggedId`),
      hide: true
    },
    {
      field: 'prodId',
      headerName: t(`untaggedProductId`),
      minWidth: 150
    },
    {
      field: 'product',
      headerName: t(`untaggedProduct`),
      flex: 1,
      minWidth: 120,
      renderCell: (params: GridCellParams) => (
        <span title={`${params.value}`}>{params.value}</span>
      )
    },
    {
      field: 'brand_id',
      headerName: t(`untaggedProductBrand`),
      minWidth: 120,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <Link href={`/brand/${getBrandSlugFromText(params.value as string)}`}>
            {params.value}
          </Link>
        ) : null
    },
    {
      field: 'variants',
      headerName: t(`untaggedProductVariants`),
      flex: 1,
      minWidth: 150
    },
    {
      field: 'gender',
      headerName: t(`gender`),
      minWidth: 120,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true
    },
    {
      field: 'tag',
      headerName: t(`untaggedProductTags`),
      minWidth: 240,
      flex: 1,
      editable: true,
      renderCell: (params: GridCellParams) => (
        <RowCenter>{params.value || t('noTag')}</RowCenter>
      ),
      renderEditCell: (params: GridCellParams) => (
        <Autocomplete
          fullWidth
          onChange={(event: unknown, newTagValue: string | null) =>
            selectTag(`${params.id}`, newTagValue)
          }
          value={params.value ? `${params.value}` : null}
          options={spot.data.tags?.map(tag => tag.name) ?? []}
          renderInput={p => <TextField {...p} placeholder={t('selectTag')} />}
        />
      )
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 196,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <Row>
          <IconButton
            onClick={() => handleCopy(params.row.product)}
            title={params.row.product ? `${params.row.product}` : undefined}
            size="large"
          >
            <CopyIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              predictTag(
                params.row.product,
                params.row.gender ?? undefined,
                params.row.variants ?? undefined,
                params.id,
                params.row.brand_id
              )
            }
            title={params.row.product ? `${params.row.product}` : undefined}
            size="large"
          >
            <SvgIcon component={PredictIcon} />
          </IconButton>
          <IconButton
            disabled={!params.row.pageUrl}
            onClick={() => openInNewTab(`${params.row.pageUrl}`)}
            title={params.row.pageUrl ? `${params.row.pageUrl}` : undefined}
            size="large"
          >
            <PopoutIcon />
          </IconButton>
        </Row>
      )
    }
  ];

  return (
    <>
      <CenteredRow>
        <IconButton
          color="primary"
          disabled={loading}
          onClick={refresh}
          title={t('refresh')}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
        <IconButton
          color="primary"
          disabled={!untaggedProducts?.length || loading}
          onClick={() => handleSaveUntaggedProd(untaggedProducts)}
          title={t('save')}
          size="large"
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          disabled={retailer === null || loading}
          color="primary"
          onClick={predictTags}
          title={t('predictTags')}
          size="large"
        >
          <SvgIcon component={PredictIcon} />
        </IconButton>
        <Spacer />
        <FileInputButton
          buttonText={t('importProductIds')}
          onFileSelected={handleImportProductIds}
          loading={loading}
          icon={<ImportIcon />}
        />
        <Gap />
        <Button
          disabled={retailer === null || loading}
          variant="contained"
          color="primary"
          onClick={() => handleExportProductIds()}
          startIcon={<ExportIcon />}
        >
          {t('exportRetailerProducts')}
        </Button>
        <Gap />
        <Button
          disabled={!untaggedProducts?.length || loading}
          variant="contained"
          color="primary"
          onClick={() => handleExportItems(untaggedProducts)}
          startIcon={<ExportIcon />}
        >
          {t('exportUntagged')}
        </Button>
      </CenteredRow>
      <Gap />
      <Line />
      <Row>
        <Container>
          {retailerSlug ? (
            <UntaggedGridContainer>
              <DataGrid
                rows={
                  untaggedProducts
                    ?.filter(prod => !!prod.prodId && !!prod.brand_id)
                    ?.map(prod => ({
                      ...prod,
                      id: prod.prodId
                    }))
                    .sort((a, b) => a.id.localeCompare(b.id)) ?? []
                }
                columns={itemHeaders}
                loading={loading}
                autoPageSize
                columnBuffer={2}
                headerHeight={40}
                rowHeight={52}
              />
            </UntaggedGridContainer>
          ) : (
            <UntaggedSummaryGridContainer>
              <DataGrid
                rows={
                  data.untaggedProductSummary?.map(row => ({
                    ...row,
                    id: row.shopId
                  })) ?? []
                }
                columns={summaryHeaders}
                loading={loading}
                autoPageSize
                columnBuffer={2}
                headerHeight={40}
                rowHeight={52}
                onRowClick={retailerSelected}
              />
            </UntaggedSummaryGridContainer>
          )}
        </Container>
      </Row>
      {notification}
      {copied && (
        <Notification
          onClose={() => setCopied(false)}
          title={t('copiedProductName')}
          severity="info"
          duration={3000}
        />
      )}
    </>
  );
}
