import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Button, Hidden, List } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useLocation } from 'react-router';
import ReactGA from 'react-ga4';

import {
  PageDescriptor,
  useSpot,
  useStoredState,
  RoleContext,
  Error,
  openInSameTab,
  logout
} from 'framework';
import { Gap, LinkButton, Spacer } from 'components';

import { Page } from './page';
import { pages } from './pages';
import { Header } from './header';

const CalloutButton = styled(Button)`
  border-radius: 20px;
  padding: ${p => p.theme.spacing(1, 2)}
  background-color: #ffffff;
  margin-right: ${p => p.theme.spacing(2)};
  white-space: nowrap;
  min-width: 140px;
`;

const CookieBanner = styled(Box)`
  display: flex;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: ${p => p.theme.palette.primary.main};
  align-items: center;
  color: ${p => p.theme.palette.primary.contrastText};
`;

const RootContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const PageContainer = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SidebarContainer = styled(Box)<{ expanded: boolean }>`
  position: relative;
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 52px);
  height: 100vh;

  flex: 0 0 240px;
  padding: 0 ${p => p.theme.spacing(2)};

  ${p => p.theme.breakpoints.down('xl')} {
    flex: 0 0 ${p => (p.expanded ? 240 : 52)}px;
    width: ${p => (p.expanded ? 240 : 52)}px;
    padding: ${p => p.theme.spacing(0, p.expanded ? 2 : 0)};
    transition: all 0.2s;
  }
`;

const SidebarList = styled(List)`
  position: fixed;
  padding: 0;
  text-wrap: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  max-height: calc(100vh - 52px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #ccc;
  }
  scrollbar-width: thin;
`;

const SidebarLink = styled(Box)`
  position: relative;
  width: 100%;
`;

const NewDecorator = styled(Typography)`
  font-size: 9px;
  position: absolute;
  top: 50%;
  right: 10px;
  background-color: ${p => p.theme.palette.primary.main};
  color: ${p => p.theme.palette.primary.contrastText};
  padding: 2px 4px;
  border-radius: 5px;
  transform: translate(0%, -50%);
`;

interface TemplateProps {
  page: PageDescriptor;
}

export function Template({ page }: TemplateProps) {
  const { spot, query, data, command } = useSpot();
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [expandEnabled, setExpandEnabled] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);

  const sidebar = useRef<HTMLElement | null>(null);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1536) {
        setExpandEnabled(false);
        setSidebarExpanded(true);
      } else {
        setExpandEnabled(true);
        setSidebarExpanded(false);
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleMouseEnter = () => {
      if (!expandEnabled) {
        return;
      }
      setSidebarExpanded(true);
    };

    const handleMouseLeave = () => {
      if (!expandEnabled) {
        return;
      }
      setSidebarExpanded(false);
    };

    sidebar.current?.addEventListener('mouseenter', handleMouseEnter);
    sidebar.current?.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      sidebar.current?.removeEventListener('mouseenter', handleMouseEnter);
      sidebar.current?.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [sidebar.current, expandEnabled, setSidebarExpanded]);

  const [visiblePages, setVisiblePages] = useState<PageDescriptor[]>([]);

  const [acceptedCookedBanner, setAcceptedCookieBanner] =
    useStoredState<boolean>('faslet-cookiebanner', true);

  const [seenStore, setSeenStore] = useStoredState<boolean>(
    'faslet-seenstore',
    false
  );

  const { role } = useContext(RoleContext);

  const acceptCookies = useCallback(() => {
    setAcceptedCookieBanner(true);
  }, [setAcceptedCookieBanner]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: t(page.title)
    });
  }, [location.pathname, page.title, t]);

  const eventTrack = useCallback(
    (category: string, action: string, label: string) => {
      ReactGA.event({
        category,
        action,
        label
      });
    },
    []
  );

  document.title = page.title
    ? `Faslet Portal - ${t(page.title)}`
    : 'Faslet Portal';

  useEffect(() => {
    if (page.authorized && !spot.data.profile && !spot.data.loading) {
      (async () => {
        try {
          await query('profile/me', {}, ['profile']);
        } catch (e) {
          if ((e as Error[])[0]?.status === 404) {
            await logout('user-not-found');
          }
        }

        await command('profile/logged-in');
        if (spot.data.profile) {
          await query('retailer/', {}, ['retailers']);
        }
      })();
    }
  }, [query, command, page.authorized, spot, navigate]);

  useEffect(() => {
    if (!role) {
      return;
    }

    const filteredPages = Object.values(pages)
      .filter(innerPage => {
        return !innerPage.hidden;
      })
      .filter(innerPage => {
        if (!innerPage.authorized) {
          return true;
        }

        return data.profile;
      })
      .filter(innerPage => {
        if (innerPage.authorized && innerPage.groups?.length && role) {
          return innerPage.groups.includes(role);
        }
        return true;
      })
      .filter(innerPage => {
        if (innerPage.features?.length) {
          return (
            role === 'admin' ||
            innerPage.features.every(f =>
              data.retailers?.some(r => r.features?.[f])
            )
          );
        }
        return true;
      });
    setVisiblePages(filteredPages);
  }, [setVisiblePages, data.profile, data.retailers, role]);

  if (page.authorized && !data.profile) {
    return <>{t('loadingProfile')}</>;
  }

  if (
    page.authorized &&
    page.groups?.length &&
    !page.groups.includes(data.profile?.role)
  ) {
    eventTrack(
      'not-allowed-triggered',
      'page_authorized_rejection',
      'not-allowed-triggered'
    );
    return <>{t('notAllowed')}</>;
  }

  if (page.authorized && !data.retailers) {
    return <>{t('loadingRetailers')}</>;
  }

  if (
    role !== 'admin' &&
    page.features?.length &&
    !page.features.every(f => data.retailers?.some(r => r.features?.[f]))
  ) {
    eventTrack(
      'not-allowed-triggered',
      'page_authorized_rejection',
      'not-allowed-triggered'
    );
    return <>{t('notAllowed')}</>;
  }

  if (page.path === '/welcome' && !seenStore) {
    setSeenStore(true);
    navigate('/retailer/');
  }

  return (
    <RootContainer id="scroll-root">
      {!page.ignoreContainer && <Header />}
      <PageContainer>
        {page.authorized && (
          <SidebarContainer ref={sidebar} expanded={sidebarExpanded}>
            <SidebarList>
              <Gap size={2} />
              {visiblePages.map(innerPage => (
                <SidebarLink key={innerPage.path}>
                  <LinkButton
                    title={innerPage.title && t(innerPage.title)}
                    path={`${innerPage.path.replaceAll(':tab?', '')}`}
                    icon={innerPage.icon}
                    showTitle={sidebarExpanded}
                  />
                  {innerPage.newDecoration && sidebarExpanded && (
                    <NewDecorator>{t('new')}</NewDecorator>
                  )}
                </SidebarLink>
              ))}
            </SidebarList>
          </SidebarContainer>
        )}
        <Page key={page.path} ignoreContainer={page.ignoreContainer}>
          {page.screen && <page.screen />}
        </Page>
      </PageContainer>
      {!acceptedCookedBanner && (
        <CookieBanner>
          <Box padding={2}>
            <Hidden lgDown>
              <Typography variant="h4">{t('cookieTitle')}</Typography>
            </Hidden>
            <Hidden mdDown>
              <Typography variant="body1">{t('cookieDesc')}</Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="caption">{t('cookieDesc')}</Typography>
            </Hidden>
          </Box>
          <Spacer />
          <CalloutButton variant="contained" onClick={acceptCookies}>
            {t('cookieButton')}
          </CalloutButton>
        </CookieBanner>
      )}
    </RootContainer>
  );
}
