import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useOrdinalColorScale } from '@nivo/colors';
import moment, { Moment } from 'moment';
import { DataGrid, GridColumns } from '@mui/x-data-grid';

import {
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection,
  FormTextValue,
  Gap,
  SizeChartLink,
  useBrandLink,
  useRetailerLink
} from 'components';
import {
  AccuracyStat,
  GroupedAccuracyStat,
  RetailerContext,
  RoleContext,
  formatStat,
  useSpot
} from 'framework';
import { ResponsivePie } from '@nivo/pie';
import { Panel, TallChartContainer } from './common';
import { barMargins } from './accuracy-helpers';
import { ProductAccuracyInfo } from './product-accuracy-info';

interface ProductAccuracyInfoProps {
  productAccuracyGroupInfo: GroupedAccuracyStat;
  startDate: Moment | undefined;
  endDate: Moment | undefined;
}

export function ProductAccuracyGroupInfo({
  productAccuracyGroupInfo,
  startDate,
  endDate
}: ProductAccuracyInfoProps) {
  const { query, spot } = useSpot();
  const { t } = useTranslation();
  const { isAdmin } = useContext(RoleContext);
  const [selectedItem, setSelectedItem] = useState<AccuracyStat[]>();
  const { findBrand } = useBrandLink();
  const { findRetailer } = useRetailerLink();

  const returnsColorScale = useOrdinalColorScale(
    { scheme: 'category10' },
    'id'
  );

  const [returnColors] = useState<
    Record<'tooSmall' | 'tooBig' | 'wrongSize' | 'otherReturns', string>
  >({
    otherReturns: returnsColorScale({ id: 'otherReturns' }),
    wrongSize: returnsColorScale({ id: 'wrongSize' }),
    tooBig: returnsColorScale({ id: 'tooBig' }),
    tooSmall: returnsColorScale({ id: 'tooSmall' })
  });

  useEffect(() => {
    setSelectedItem(undefined);
  }, [productAccuracyGroupInfo, startDate, endDate]);

  const onRowClick = useCallback(
    async row => {
      const params: {
        startDate: string;
        endDate: string;
        retailers?: string[];
      } = {
        startDate: moment(startDate).startOf('day').toISOString(),
        endDate: moment(endDate).endOf('day').toISOString()
      };

      const item = row.row as AccuracyStat;
      const itemRetailer = findRetailer(item.shopId);
      params.retailers = itemRetailer ? [itemRetailer.slug] : [];

      await query(
        `analytics/product-accuracy/${encodeURIComponent(item.correlationId)}`,
        params,
        ['productAccuracy', 'items', item.correlationId]
      );
      const stat = spot.data.productAccuracy?.items[item.correlationId];

      setSelectedItem(stat);
    },
    [setSelectedItem, startDate, endDate, findRetailer, query, spot]
  );

  const columns: GridColumns = [
    { field: 'correlationId', headerName: t('correlationId'), align: 'left' },
    { field: 'title', headerName: t('productTitle'), align: 'left', flex: 1 },
    {
      field: 'users',
      headerName: t('users'),
      align: 'right'
    },
    {
      field: 'adviceAndSizeRelated',
      headerName: t('sizeRelatedReturnsWithAdvice'),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ value, row }) =>
        formatStat((value / row.orderCount) * 100, true, 2),
      width: 120
    },
    {
      field: 'accuracy',
      headerName: t('accuracy'),
      headerAlign: 'right',
      align: 'right',
      renderCell: ({ row }) =>
        formatStat((row.adviceCount / row.orderCount) * 100, true, 2),
      width: 120
    }
  ];

  const onBack = useCallback(() => {
    setSelectedItem(undefined);
  }, [setSelectedItem]);

  const {
    returns,
    wrongSize,
    tooBig,
    tooSmall,
    adviceAndReturned,
    adviceAndTooBig,
    adviceAndTooSmall,
    adviceAndWrongSize,
    orderCount,
    brand,
    gender,
    productType
  } = productAccuracyGroupInfo;

  const brandObj = findBrand(brand);
  const { name: brandName, slug: brandSlug } = brandObj || {};

  if (selectedItem) {
    return (
      <ProductAccuracyInfo onBack={onBack} productAccuracyInfo={selectedItem} />
    );
  }

  return (
    <Panel>
      <Typography variant="h5" color="primary">
        {t('groupStats')}
      </Typography>
      {returns > 0 && (
        <>
          <TallChartContainer>
            <Typography variant="h5" color="primary">
              {t('returns')}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              {t('returnsAllGraphDescription')}
            </Typography>
            <ResponsivePie
              data={[
                {
                  label: 'otherReturns',
                  id: t('otherReturns'),
                  value: returns - wrongSize - tooBig - tooSmall
                },
                {
                  label: 'tooSmall',
                  id: t('tooSmall'),
                  value: tooSmall
                },
                {
                  label: 'tooBig',
                  id: t('tooBig'),
                  value: tooBig
                },
                {
                  label: 'wrongSize',
                  id: t('wrongSize'),
                  value: wrongSize,
                  color: '#ff00ff'
                }
              ].filter(d => d.value > 0)}
              innerRadius={0.4}
              padAngle={3}
              cornerRadius={8}
              activeOuterRadiusOffset={8}
              valueFormat={val => formatStat(val, false, 0)}
              colors={val => returnColors[val.label]}
              arcLabelsTextColor="#fff"
              arcLabelsSkipAngle={10}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={3}
              arcLinkLabelsDiagonalLength={8}
              arcLinkLabelsStraightLength={32}
              arcLinkLabelsColor={{ from: 'color' }}
              margin={barMargins}
              borderWidth={5}
              borderColor="rgba(255,255,255,0)"
            />
          </TallChartContainer>
          <Gap />
        </>
      )}

      {adviceAndReturned > 0 && (
        <>
          <TallChartContainer>
            <Typography variant="subtitle2" color="primary">
              {t('returnsAdviceFollowedGraphDescription')}
            </Typography>
            <ResponsivePie
              data={[
                {
                  label: 'otherReturns',
                  id: t('otherReturns'),
                  value:
                    adviceAndReturned -
                    adviceAndWrongSize -
                    adviceAndTooBig -
                    adviceAndTooSmall
                },
                {
                  label: 'tooSmall',
                  id: t('tooSmall'),
                  value: adviceAndTooSmall
                },
                {
                  label: 'tooBig',
                  id: t('tooBig'),
                  value: adviceAndTooBig
                },
                {
                  label: 'wrongSize',
                  id: t('wrongSize'),
                  value: adviceAndWrongSize,
                  color: '#ff00ff'
                }
              ].filter(d => d.value > 0)}
              innerRadius={0.4}
              padAngle={3}
              cornerRadius={8}
              activeOuterRadiusOffset={8}
              valueFormat={val => formatStat(val, false, 0)}
              colors={val => returnColors[val.label]}
              arcLabelsTextColor="#fff"
              arcLabelsSkipAngle={10}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={3}
              arcLinkLabelsDiagonalLength={8}
              arcLinkLabelsStraightLength={32}
              arcLinkLabelsColor={{ from: 'color' }}
              margin={barMargins}
              borderWidth={5}
              borderColor="rgba(255,255,255,0)"
            />
          </TallChartContainer>
          <Gap />
        </>
      )}
      <FormSection>
        <FormRow>
          <FormLabel size="narrow">{t('sizeChart')}</FormLabel>
          <FormFieldWrapper>
            <SizeChartLink
              isAdmin={isAdmin}
              brand={brandName}
              brandSlug={brandSlug}
              tagGender={gender}
              tagProductName={productType}
              tagName={`FASLET_${productType}_${gender}`}
            />
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('itemsOrdered')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>{formatStat(orderCount)}</FormTextValue>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('returns')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>{formatStat(returns)}</FormTextValue>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('returnRate')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>
              {formatStat((returns / orderCount) * 100, true, 2)}
            </FormTextValue>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('sizeRelatedReturnRate')}</FormLabel>
          <FormFieldWrapper>
            <FormTextValue>
              {formatStat(((tooBig + tooSmall) / orderCount) * 100, true, 2)}
            </FormTextValue>
          </FormFieldWrapper>
        </FormRow>
      </FormSection>
      <Gap />
      <Typography variant="h5" color="primary">
        {t('groupItems')}
      </Typography>
      <Gap />
      <DataGrid
        rows={productAccuracyGroupInfo.items}
        columns={columns}
        getRowId={row => row.correlationId}
        onRowClick={onRowClick}
        disableColumnMenu
        disableColumnSelector
        pageSize={10}
        autoHeight
        columnBuffer={2}
        initialState={{
          sorting: {
            sortModel: [{ field: 'accuracy', sort: 'asc' }]
          }
        }}
      />
    </Panel>
  );
}
