import {
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Link,
  SvgIcon,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  GetAppRounded as ExportIcon,
  PublishRounded as ImportIcon
} from '@mui/icons-material';
import Papa from 'papaparse';

import {
  CenteredRow,
  FileInputButton,
  FormSection,
  FormTextInput,
  Gap,
  Spacer
} from 'components';

import {
  AgeGroup,
  Brand,
  DictionaryEntryType,
  downloadObjectsAsCsv,
  ProductGender,
  RetailerContext,
  useErrorNotification,
  useSpot
} from 'framework';
import { ReactComponent as PredictIcon } from 'images/wand.svg';

import { GenderDictionary } from './gender-dictionary';
import { ProductDictionary } from './product-dictionary';

const StyledCode = styled.code`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px;
`;

const TestRow = styled(CenteredRow)`
  padding: ${p => p.theme.spacing(0, 2)};
`;

const TestFieldInput = styled(CenteredRow)`
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0 360px;
    width: 360px;
  }
  display: flex;
  align-items: center;
`;

const TestFieldResult = styled(CenteredRow)`
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0 420px;
    width: 420px;
  }
  display: flex;
  align-items: center;
`;

export interface DictionaryProps {
  onLoadingChanged: (loading: boolean) => unknown;
  type: 'product' | 'gender';
  selectedBrand: Brand | null;
}

interface GenderDictionaryEntry {
  word: string;
  gender: ProductGender;
  key: string;
  type?: DictionaryEntryType;
}

interface ProductDictionaryEntry {
  word: string;
  product: string;
  key: string;
  type?: DictionaryEntryType;
}

export function Dictionary({
  onLoadingChanged,
  type,
  selectedBrand
}: DictionaryProps) {
  const { spot, query, command, loading } = useSpot();
  const { t } = useTranslation();

  const [productWords, setProductWords] = useState<ProductDictionaryEntry[]>(
    []
  );

  const [kidsProductWords, setKidsProductWords] = useState<
    ProductDictionaryEntry[]
  >([]);

  const [genderWords, setGenderWords] = useState<GenderDictionaryEntry[]>([]);

  const [ageGroup, setAgeGroup] = useState<AgeGroup>('adult');

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const { displayErrors, notification } = useErrorNotification();

  const { retailer } = useContext(RetailerContext);
  const retailerSlug = retailer?.slug;
  const brandSlug = selectedBrand?.slug;

  const refresh = useCallback(async () => {
    // Do this first to save a ton of loading on the product rows
    await query('product/', {}, ['products']);

    const dictionaryParams: Record<string, string> = {};

    if (brandSlug) {
      dictionaryParams.brand = brandSlug;
    } else if (retailerSlug) {
      dictionaryParams.retailer = retailerSlug;
    }

    await query('product-predictor/dictionary', dictionaryParams, [
      'productPredictor',
      'dictionary'
    ]);

    setProductWords(
      spot.data.productPredictor?.dictionary?.productWords?.map((w, index) => ({
        ...w,
        key: `${index}-${w.word}`
      })) ?? []
    );

    setKidsProductWords(
      spot.data.productPredictor?.dictionary?.kidsProductWords?.map(
        (w, index) => ({
          ...w,
          key: `${index}-${w.word}`
        })
      ) ?? []
    );

    setGenderWords(
      spot.data.productPredictor?.dictionary?.genderWords?.map((w, index) => ({
        ...w,
        key: `${index}-${w.word}`
      })) ?? []
    );
  }, [query, retailerSlug, brandSlug, spot, setProductWords, setGenderWords]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const saveMapping = useCallback(async () => {
    try {
      const dictionaryParams: Record<string, string> = {};
      if (brandSlug) {
        dictionaryParams.brand = brandSlug;
      } else if (retailerSlug) {
        dictionaryParams.retailer = retailerSlug;
      }

      await command('product-predictor/dictionary', {
        ...dictionaryParams,
        productWords: productWords.map(p => ({
          word: p.word,
          product: p.product,
          type: p.type
        })),
        kidsProductWords: kidsProductWords.map(p => ({
          word: p.word,
          product: p.product,
          type: p.type
        })),
        genderWords: genderWords.map(g => ({
          word: g.word,
          gender: g.gender,
          type: g.type
        }))
      });
      await refresh();
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [
    displayErrors,
    command,
    refresh,
    retailerSlug,
    brandSlug,
    productWords,
    genderWords,
    kidsProductWords
  ]);

  const onExport = useCallback(async () => {
    if (type === 'product') {
      downloadObjectsAsCsv(
        (ageGroup === 'kids' ? kidsProductWords : productWords).map(p => ({
          word: p.word,
          product: p.product,
          type: p.type
        })),
        `product-dictionary-${ageGroup}.csv`
      );
    } else {
      downloadObjectsAsCsv(
        genderWords.map(g => ({
          word: g.word,
          gender: g.gender,
          type: g.type
        })),
        'gender-dictionary.csv'
      );
    }
  }, [genderWords, productWords, kidsProductWords, ageGroup, type]);

  const onImport = useCallback(
    async (file: File) => {
      const parsed = (await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: resolve,
          error: reject,
          dynamicTyping: false
        });
      })) as Papa.ParseResult<ProductDictionaryEntry | GenderDictionaryEntry>;

      const words: (GenderDictionaryEntry | ProductDictionaryEntry)[] =
        parsed.data
          .filter(d => !!d.word)
          .map((d, i) => ({
            ...d,
            key: `${i}-${d.word}`
          }));

      if (type === 'product' && ageGroup === 'kids') {
        setKidsProductWords(words as ProductDictionaryEntry[]);
      } else if (type === 'product' && ageGroup === 'adult') {
        setProductWords(words as ProductDictionaryEntry[]);
      } else if (type === 'gender') {
        setGenderWords(words as GenderDictionaryEntry[]);
      }
    },
    [type, ageGroup]
  );

  return (
    <>
      <CenteredRow>
        <TestField
          retailer={retailerSlug}
          brand={brandSlug}
          ageGroup={ageGroup}
        />
        <Spacer />
        <Gap />
        <FileInputButton
          onFileSelected={onImport}
          icon={<ImportIcon />}
          buttonText={t('importCsv')}
          variant="outlined"
        />
        <Gap />
        <Button
          color="primary"
          onClick={onExport}
          variant="outlined"
          startIcon={<ExportIcon />}
        >
          {t('downloadCsv')}
        </Button>
        <Gap />

        {type === 'product' && (
          <ToggleButtonGroup
            exclusive
            value={ageGroup}
            onChange={(_, value) => setAgeGroup(value)}
            size="small"
          >
            <ToggleButton value="adult" aria-label="adult" title={t('adult')}>
              {t('adult')}
            </ToggleButton>
            <ToggleButton value="kids" aria-label="kids" title={t('kids')}>
              {t('kids')}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </CenteredRow>
      {type === 'product' && (
        <ProductDictionary
          loading={loading}
          ageGroup={ageGroup}
          productWords={ageGroup === 'adult' ? productWords : kidsProductWords}
          setProductWords={
            ageGroup === 'adult' ? setProductWords : setKidsProductWords
          }
          saveDictionary={saveMapping}
        />
      )}
      {type === 'gender' && (
        <GenderDictionary
          loading={loading}
          genderWords={genderWords}
          setGenderWords={setGenderWords}
          saveDictionary={saveMapping}
        />
      )}
      <FormSection>
        <Typography variant="caption">
          <Trans
            i18nKey="dictionaryInfo"
            components={[<StyledCode key="code" />]}
          />
        </Typography>
      </FormSection>
      {notification}
    </>
  );
}

function TestField({
  retailer,
  brand,
  ageGroup
}: {
  retailer: string | undefined;
  brand: string | undefined;
  ageGroup: AgeGroup;
}) {
  const { t } = useTranslation();
  const { raw, loading } = useSpot();
  const { displayErrors, notification } = useErrorNotification();

  const [testTitle, setTestTitle] = useState('');
  const [testResult, setTestResult] = useState<{
    product?: string;
    gender?: 'male' | 'female' | 'unisex';
    tag?: string;
  }>({});

  const testProductTitle = useCallback(async () => {
    try {
      const result = await raw<{
        product?: string;
        gender?: 'male' | 'female' | 'unisex';
        tag?: string;
      }>('product-predictor/predict', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          retailer,
          title: testTitle,
          brand,
          ageGroup
        })
      });
      setTestResult(result ?? {});
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [raw, displayErrors, retailer, brand, testTitle, setTestResult, ageGroup]);
  return (
    <>
      <TestRow>
        <TestFieldInput>
          <FormTextInput
            value={testTitle}
            onChange={setTestTitle}
            placeholder={t('testProductTitle')}
          />
          <IconButton
            onClick={testProductTitle}
            title={t('test')}
            size="large"
            color="primary"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <SvgIcon component={PredictIcon} />
            )}
          </IconButton>
        </TestFieldInput>
        <TestFieldResult>
          {testResult?.tag ? (
            <Link href={`/tags-and-products/tags/${testResult.tag}`}>
              {testResult.tag}
            </Link>
          ) : (
            t('noTagFound')
          )}
          {testResult.product && <Gap />}
          {testResult.product && (
            <Chip label={testResult.product} color="primary" />
          )}
          {testResult.gender && <Gap />}
          {testResult.gender && (
            <Chip label={testResult.gender} color="secondary" />
          )}
        </TestFieldResult>
      </TestRow>
      <CenteredRow>{notification}</CenteredRow>
    </>
  );
}
