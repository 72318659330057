import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';

import {
  BrandSelector,
  DateBox,
  Gap,
  Row,
  Spacer,
  Tabs,
  useBrandLink
} from '../../components';
import { ProductAccuracyList } from './product-accuracy-list';
import { WidgetErrors } from './widget-errors';
import { ErrorProfiles } from './error-profiles';
import { Brand } from 'framework';
import SrrSinceLastAdjustment from './srr-since-last-adjustment';

export function ProductAccuracy() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<moment.Moment>();
  const [endDate, setEndDate] = useState<moment.Moment>();
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  useBrandLink();

  const onChangeTab = useCallback(
    newTab => {
      navigate(`/product-accuracy/${newTab}`);
    },
    [navigate]
  );

  return (
    <>
      <Row>
        <Typography variant="h4">{t('accuracyTitle')}</Typography>
        <Spacer />
        <BrandSelector
          includeAllBrands
          selectedBrand={selectedBrand}
          onBrandChanged={setSelectedBrand}
          disabled={loading}
        />
        <Gap />
        <DateBox
          disabled={loading || tab === 'srr-since-last-adjustment'}
          onEndChange={setEndDate}
          onStartChange={setStartDate}
          allowedRanges={[
            'lastMonth',
            'thisMonth',
            'lastThirtyDays',
            'last12Months',
            'thisYear',
            'lastYear',
            'allTime',
            'lastSevenDays',
            'custom'
          ]}
          defaultRange="lastMonth"
        />
      </Row>
      <Tabs
        selected={tab ?? 'orders-and-returns'}
        onTabChange={onChangeTab}
        hideIfSingle
        loading={loading}
        tabs={{
          'orders-and-returns': (
            <ProductAccuracyList
              onLoadingChanged={setLoading}
              startDate={startDate}
              endDate={endDate}
              selectedBrand={selectedBrand}
            />
          ),
          'widget-errors': (
            <WidgetErrors
              onLoadingChanged={setLoading}
              startDate={startDate}
              endDate={endDate}
              selectedBrand={selectedBrand}
            />
          ),
          'error-profiles': (
            <ErrorProfiles
              onLoadingChanged={setLoading}
              startDate={startDate}
              endDate={endDate}
              selectedBrand={selectedBrand}
            />
          ),
          'srr-since-last-adjustment': (
            <SrrSinceLastAdjustment
              onLoadingChanged={setLoading}
              selectedBrand={selectedBrand}
            />
          )
        }}
      />
    </>
  );
}
