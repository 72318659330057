import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { ExtendedBrand, useErrorNotification, useSpot } from 'framework';

import { escapeRegExp } from 'components';

import { GridRowModel } from '@mui/x-data-grid';
import { BrandList } from './brand-list';
import { SingleBrand } from './single-brand';

export function BrandEditor() {
  const { brand } = useParams<{ brand?: string }>();
  const navigate = useNavigate();
  const { spot, query } = useSpot();
  const [extendedBrand, setExtendedBrand] = useState<ExtendedBrand | null>();

  const [searchText, setSearchText] = useState<string>('');
  const [brandRows, setBrandRows] = useState<GridRowModel[]>([]);

  const { notification, displayErrors } = useErrorNotification();

  useEffect(() => {
    (async () => {
      const promises: Promise<unknown>[] = [];
      promises.push(query('brand/', {}, ['brands']));
      promises.push(query('brand/aliases', {}, ['aliases']));
      promises.push(query('tag/', {}, ['tags']));
      await Promise.all(promises);

      setBrandRows(spot.data.brands ?? []);
    })();
  }, [query, setBrandRows, spot]);

  useEffect(() => {
    (async () => {
      if (brand) {
        await query(`brand/${brand}`, {}, ['extendedBrand']);
        setExtendedBrand(spot.data.extendedBrand);
      } else {
        setExtendedBrand(null);
      }
    })();
  }, [query, brand, spot, setExtendedBrand]);

  const filterResults = useCallback(
    (searchValue = '') => {
      setSearchText(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

      if (spot.data.brands) {
        const filteredRows = spot.data.brands.filter((row: GridRowModel) => {
          return searchRegex.test(row.name);
        });
        setBrandRows(filteredRows);
      }
    },
    [setBrandRows, spot, setSearchText]
  );

  const refresh = useCallback(
    async (searchTerm?: string) => {
      const promises: Promise<unknown>[] = [];
      promises.push(query('brand/', {}, ['brands']));
      promises.push(query('brand/aliases', {}, ['aliases']));
      await Promise.all(promises);

      filterResults(searchTerm ?? searchText ?? '');
    },
    [query, searchText, filterResults]
  );

  const addBrand = useCallback(async () => {
    setExtendedBrand({
      id: -1,
      name: '',
      slug: '',
      logo: null,
      logoS3: null,
      baseUrl: '/',
      retailers: [],
      aliases: []
    });
  }, []);

  const backToList = useCallback(async () => {
    setExtendedBrand(null);
    await refresh('');
    navigate(`/brand`);
  }, [setExtendedBrand, refresh, navigate]);

  return (
    <>
      {extendedBrand ? (
        <SingleBrand
          updateBrand={setExtendedBrand}
          displayErrors={displayErrors}
          selectedBrand={extendedBrand}
          onBack={backToList}
        />
      ) : (
        <BrandList
          brandRows={brandRows}
          addBrand={addBrand}
          refresh={refresh}
          onSearchTermChanged={filterResults}
          displayErrors={displayErrors}
        />
      )}
      {notification}
    </>
  );
}
