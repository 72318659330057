import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, CircularProgress, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SendRounded as SendIcon } from '@mui/icons-material';

import { User, useErrorNotification, useSpot } from 'framework';
import {
  CenteredRow,
  ConfirmDialog,
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormTextInput,
  Row,
  Spacer,
  UserSelector
} from 'components';
import { pages } from 'pages';

const ConfigContainer = styled(Box)`
  position: relative;
  height: calc(100vh - 280px);
`;

export interface NotificationsProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function Notifications({ onLoadingChanged }: NotificationsProps) {
  const { loading, command } = useSpot();
  const { t } = useTranslation();
  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [actionInfo, setActionInfo] = useState<string | undefined>();
  const [action, setAction] = useState<'screen' | 'link' | 'none'>('none');
  const [level, setLevel] = useState<'info' | 'warn' | 'error'>('info');
  const [title, setTitle] = useState<string | undefined>();
  const [message, setMessage] = useState<string | undefined>();
  const [confirm, setConfirm] = useState(false);

  const { displayErrors, notification } = useErrorNotification();

  const onUserChanged = useCallback(
    user => {
      setSelectedUser(user);
    },
    [setSelectedUser]
  );

  const onActionSelected = useCallback(
    event => {
      setAction(event.target.value);
      setActionInfo(undefined);
    },
    [setAction]
  );

  const onPageSelected = useCallback(
    event => {
      setActionInfo(event.target.value);
    },
    [setActionInfo]
  );

  const onLevelSelected = useCallback(
    event => {
      setLevel(event.target.value);
    },
    [setLevel]
  );

  const canSend = useMemo(() => !!(title && message), [action, title, message]);

  const sendNotification = useCallback(async () => {
    if (canSend) {
      const notification = {
        level,
        title,
        message,
        action: action === 'none' ? undefined : action,
        actionInfo,
        userId: selectedUser?.id
      };
      try {
        await command('notification', notification);
      } catch (error) {
        displayErrors(error as Error[]);
      }
    }
  }, [
    canSend,
    message,
    title,
    action,
    actionInfo,
    level,
    selectedUser,
    command,
    displayErrors
  ]);

  return (
    <ConfigContainer>
      <CenteredRow>
        <FormSection>
          <FormSectionTitle>{t('notifications')}</FormSectionTitle>
          <FormRow>
            <FormLabel>{t('title')}</FormLabel>
            <FormFieldWrapper>
              <FormTextInput onChange={setTitle} value={title} />
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('message')}</FormLabel>
            <FormFieldWrapper>
              <FormTextInput onChange={setMessage} value={message} />
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('level')}</FormLabel>
            <FormFieldWrapper>
              <Select onChange={onLevelSelected} value={level}>
                <MenuItem value="info">{t('info')}</MenuItem>
                <MenuItem value="warn">{t('warn')}</MenuItem>
                <MenuItem value="error">{t('error')}</MenuItem>
              </Select>
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel>{t('action')}</FormLabel>
            <FormFieldWrapper>
              <Select onChange={onActionSelected} value={action}>
                <MenuItem value="none">{t('noAction')}</MenuItem>
                <MenuItem value="link">{t('externalLink')}</MenuItem>
                <MenuItem value="screen">{t('portalScreen')}</MenuItem>
              </Select>
            </FormFieldWrapper>
          </FormRow>
          {action === 'link' && (
            <FormRow>
              <FormLabel>{t('url')}</FormLabel>
              <FormFieldWrapper>
                <FormTextInput value={actionInfo} onChange={setActionInfo} />
              </FormFieldWrapper>
            </FormRow>
          )}
          {action === 'screen' && (
            <FormRow>
              <FormLabel>{t('screen')}</FormLabel>
              <FormFieldWrapper>
                <Select onChange={onPageSelected} value={actionInfo}>
                  {Object.values(pages)
                    .filter(page => page.groups?.includes('user'))
                    .filter(page => !page.hidden)
                    .map(page => (
                      <MenuItem value={page.path}>{t(page.title)}</MenuItem>
                    ))}
                </Select>
              </FormFieldWrapper>
            </FormRow>
          )}
          <FormRow>
            <FormLabel>{t('user')}</FormLabel>
            <FormFieldWrapper>
              <UserSelector
                onUserChanged={onUserChanged}
                selectedUser={selectedUser}
                includeAllUsers
              />
            </FormFieldWrapper>
          </FormRow>
          <Row>
            <Spacer />
            <Button
              color="primary"
              variant="contained"
              onClick={() => setConfirm(true)}
              disabled={!canSend || loading}
              endIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <SendIcon />
                )
              }
            >
              {t('send')}
            </Button>
          </Row>
        </FormSection>
      </CenteredRow>
      {notification}
      {confirm && (
        <ConfirmDialog
          title={t('confirmSendNotification')}
          text={t('confirmSendNotificationText', {
            name: selectedUser
              ? `${selectedUser.firstName} ${selectedUser.lastName}`
              : t('allUsers')
          })}
          dialogOpen={confirm}
          onClose={() => setConfirm(false)}
          onSubmit={sendNotification}
        />
      )}
    </ConfigContainer>
  );
}
