import React from 'react';
import { useNavigate } from 'react-router';
import { IconButton, Popover } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import {
  StoreRounded as RetailerIcon,
  ArrowDropDownRounded as DropdownArrow
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { Table } from './table';
import { RetailerItem } from '../framework';

export interface RetailersListProps {
  id: GridRowId;
  includeAllRetailers?: boolean;
  retailers: RetailerItem[];
}

export function RetailersPopdownList({
  id,
  retailers,
  includeAllRetailers
}: RetailersListProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const headers = [t('retailers')];

  const rows =
    retailers?.map(retailer => {
      return {
        id: `retailer_${id}_${retailer.id}`,
        cells: [
          <>
            <IconButton
              className="grid-icon-button"
              color="primary"
              onClick={() => {
                navigate(`/retailer/${retailer.slug}`);
              }}
              size="large"
            >
              <RetailerIcon />
            </IconButton>
            <span
              className="grid-select"
              key={`retailer_${id}_${retailer.id}`}
              role="link"
              onClick={() => navigate(`/retailer/${retailer.slug}`)}
            >
              {retailer.name}
            </span>
          </>
        ]
      };
    }) ?? [];

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (retailers && retailers.length) {
    if (retailers.length === 1) {
      return (
        <>
          <IconButton
            className="grid-icon-button"
            color="primary"
            onClick={() => {
              navigate(`/retailer/${retailers[0].slug}`);
            }}
            size="large"
          >
            <RetailerIcon />
          </IconButton>
          <span
            className="grid-select"
            key={`retailer_${id}_${retailers[0].id}`}
            role="link"
            onClick={() => navigate(`/retailer/${retailers[0].slug}`)}
          >
            {retailers[0].name}
          </span>
        </>
      );
    }
    return (
      <div>
        <div>
          <IconButton
            className="grid-icon-button"
            onClick={handlePopoverOpen}
            color="primary"
            size="large"
          >
            <RetailerIcon />
            <DropdownArrow />
          </IconButton>
          {retailers.length}
        </div>
        <Popover
          id={`retailers-popover-${id}`}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Table headers={headers} rows={rows} />
        </Popover>
      </div>
    );
  }
  if (includeAllRetailers) {
    return (
      <>
        <IconButton className="grid-icon-button" size="large">
          <RetailerIcon />
        </IconButton>
        {t('allRetailers')}
      </>
    );
  }
  return <div>&nbsp;</div>;
}
