import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Typography, TextField, Autocomplete, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import {
  CustomDialog,
  FormFieldWrapper,
  FormLabel,
  FormNumberInput,
  FormRow,
  FormTextInput,
  Line
} from 'components';

import { User, useSpot } from 'framework';

export interface CouponDescriptor {
  baseCode?: string;
  expiryDate?: string;
  maxRedemptions?: number;
  issuerId?: number;
}

interface Props {
  dialogOpen: boolean;
  onSubmit?: (couponDescriptor: CouponDescriptor) => Promise<void>;
  onClose?: () => unknown;
}

const allUsersUser = {
  id: -1,
  firstName: 'All',
  lastName: 'Users'
} as User;

const defaultFilterOptions = createFilterOptions<User>({
  limit: 30,
  ignoreCase: true,
  ignoreAccents: true,
  stringify: (u: User) => `${u.firstName} ${u.lastName} (${u.email})`
});

export const CreateCouponDialog = ({
  dialogOpen,
  onClose,
  onSubmit
}: Props) => {
  const { t } = useTranslation();
  const { query, spot } = useSpot();

  useEffect(() => {
    query('user/', {}, ['users']);
  }, [query]);

  const renderControl = (
    setValue: (val?: CouponDescriptor) => void,
    value?: CouponDescriptor
  ) => {
    const selectedUser =
      value?.issuerId === -1
        ? allUsersUser
        : spot.data?.users?.find(u => u.id === value?.issuerId) ??
          spot.data?.users?.find(u => u.id === spot.data?.profile?.id) ??
          null;

    return (
      <Box>
        <Typography variant="subtitle1" color="primary">
          {t('createCouponInfo')}
        </Typography>
        <Line />
        <FormRow>
          <FormLabel size="narrow">{t('baseCode')} *</FormLabel>
          <FormFieldWrapper>
            <FormTextInput
              value={value?.baseCode}
              onChange={newValue => setValue({ ...value, baseCode: newValue })}
            />
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('expiryDate')}</FormLabel>
          <FormFieldWrapper>
            <DatePicker
              label={t('expiryDate')}
              value={value?.expiryDate ? moment(value?.expiryDate) : null}
              onChange={newValue =>
                setValue({ ...value, expiryDate: newValue?.toISOString() })
              }
              renderInput={params => <TextField {...params} />}
              minDate={moment()}
              inputFormat="D MMM YYYY"
              views={['month', 'day']}
              disableMaskedInput
            />
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('maxRedemptions')}</FormLabel>
          <FormFieldWrapper>
            <FormNumberInput
              value={value?.maxRedemptions}
              onChange={newValue =>
                setValue({
                  ...value,
                  maxRedemptions: newValue
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('issuer')}</FormLabel>
          <FormFieldWrapper>
            <Autocomplete
              fullWidth
              onChange={(e, newValue) =>
                setValue({ ...value, issuerId: newValue?.id })
              }
              value={selectedUser}
              getOptionLabel={(u: User) => `${u.firstName} ${u.lastName}`}
              options={[allUsersUser, ...(spot.data?.users ?? [])]}
              isOptionEqualToValue={(u1: User, u2: User) => u1.id === u2.id}
              filterOptions={defaultFilterOptions}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="normal"
                  label={t('selectUser')}
                />
              )}
              clearOnBlur
              clearOnEscape
            />
          </FormFieldWrapper>
        </FormRow>
      </Box>
    );
  };

  return CustomDialog({
    title: t('createCoupon'),
    dialogOpen,
    renderControl,
    onSubmit,
    onClose,
    canSubmit: desc => !!desc?.baseCode,
    acceptLabel: t('create'),
    rejectLabel: t('cancel')
  });
};
