import React, { useState, useCallback, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';

import styled from '@emotion/styled';

import {
  SaveRounded as SaveIcon,
  CloseRounded as CloseIcon,
  AddCircleRounded as AddIcon,
  DeleteForeverRounded as DeleteIcon
} from '@mui/icons-material';

import { useSpot, IntegrationDetails, useErrorNotification } from 'framework';

import {
  FormSection,
  FormSectionTitle,
  FormRow,
  FormFieldWrapper,
  FormLabel,
  Gap,
  Spacer,
  CenteredRow,
  FormNumberInput,
  Line
} from 'components';
import { IntegrationEditProps } from './common';

const FormRowDirections = styled(Box)`
  margin: ${p => p.theme.spacing(1, 0)};
  display: flex;

  ${p => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
  ${p => p.theme.breakpoints.up('sm')} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const TitleSection = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const DirectionsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${p => p.theme.spacing(1)};
`;

const LoaderBox = styled(Box)`
  height: 4.5rem;
  padding: ${p => p.theme.spacing(2)};
`;

const EditLoading = styled(CircularProgress)`
  margin-left: 45%;
`;

export function Returnista({
  active,
  slug,
  close,
  integration,
  loading
}: IntegrationEditProps) {
  const { t } = useTranslation();
  const { command, query, spot } = useSpot();
  const [integrationData, setIntegrationData] = useState<
    IntegrationDetails | undefined
  >(integration);

  const [reasonMappings, setReasonMappings] = useState<
    {
      returnista: number;
      faslet: number;
      key: string;
    }[]
  >(
    integration?.returnista?.reasonMappings?.map((rm, index) => ({
      ...rm,
      key: `${index}-${rm.returnista}`
    })) ?? []
  );

  const { displayErrors, notification } = useErrorNotification();

  const addMapping = useCallback(() => {
    setReasonMappings([
      ...reasonMappings,
      {
        returnista: 0,
        faslet: 8,
        key: `${reasonMappings?.length ?? 0}-${0}`
      }
    ]);
  }, [setReasonMappings, reasonMappings]);

  const updateMapping = useCallback(
    (
      index: number,
      returnistaValue: number | undefined,
      falsetValue: number | undefined
    ) => {
      const newMappings = [...reasonMappings];
      const mappingToUpdate = reasonMappings?.[index];
      if (!mappingToUpdate) {
        return;
      }

      mappingToUpdate.returnista =
        returnistaValue ?? mappingToUpdate.returnista;
      mappingToUpdate.faslet = falsetValue ?? mappingToUpdate.faslet;

      setReasonMappings(newMappings);
    },
    [setReasonMappings, reasonMappings]
  );

  const removeMapping = useCallback(
    (index: number) => {
      const newReasonMappings = [...reasonMappings];

      if (!newReasonMappings || !newReasonMappings[index]) {
        return;
      }

      newReasonMappings.splice(index, 1);

      setReasonMappings([...newReasonMappings]);
    },
    [reasonMappings, setReasonMappings]
  );

  const handleSave = useCallback(async () => {
    const integrationInfo = {
      ...integrationData
    };

    try {
      await command(
        `retailer/${slug}/integrations`,
        {
          ...integrationInfo,
          returnista: {
            ...integrationData?.returnista,
            reasonMappings: [...reasonMappings].map(rm => ({
              returnista: rm.returnista,
              faslet: rm.faslet
            }))
          }
        },
        {
          method: 'POST'
        }
      );
      await query(`retailer/${slug}/integrations`, {}, ['integrationDetails']);
      setIntegrationData(spot.data?.integrationDetails);
      setReasonMappings(
        spot.data?.integrationDetails?.returnista?.reasonMappings?.map(
          (rm, index) => ({
            ...rm,
            key: `${index}-${rm.returnista}`
          })
        ) ?? []
      );
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [
    query,
    command,
    setReasonMappings,
    setIntegrationData,
    displayErrors,
    integrationData,
    reasonMappings,
    slug,
    spot
  ]);

  return (
    <>
      <FormSection>
        <TitleSection>
          <FormSectionTitle>
            {`${active === true ? t(`add`) : t(`edit`)} ${t(
              `returnistaIntegration`
            )}`}
          </FormSectionTitle>
          <Spacer />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            startIcon={<SaveIcon />}
          >
            {t(`save`)}
          </Button>
          <Gap />
          <Button
            variant="contained"
            color="primary"
            onClick={close}
            startIcon={<CloseIcon />}
          >
            {t(`close`)}
          </Button>
        </TitleSection>
        {loading && integration ? (
          <LoaderBox>
            <EditLoading color="primary" />
          </LoaderBox>
        ) : (
          <>
            <FormRowDirections>
              <FormLabel>{t('directions')}</FormLabel>
              <DirectionsWrapper>
                <Typography>{t('returnistaDirections1')}</Typography>
              </DirectionsWrapper>
            </FormRowDirections>
            <Gap size={4} />
            <FormRow>
              <FormLabel>{t('apikey')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  type="text"
                  minRows={3}
                  multiline
                  fullWidth
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    setIntegrationData({
                      ...integrationData,
                      returnista: {
                        ...integrationData?.returnista,
                        apiKey: event.target.value
                      }
                    })
                  }
                  value={integrationData?.returnista?.apiKey ?? ''}
                />
              </FormFieldWrapper>
            </FormRow>
          </>
        )}
      </FormSection>
      <FormSection>
        <CenteredRow>
          <FormSectionTitle>{t('returnReasonIdMapping')}</FormSectionTitle>
          <Spacer />
          <IconButton onClick={addMapping} size="large">
            <AddIcon />
          </IconButton>
        </CenteredRow>
        {(reasonMappings ?? []).map(({ returnista, faslet, key }, index) => (
          <Fragment key={key}>
            <FormRow>
              <FormLabel>{t('returnistaReasonId')}</FormLabel>
              <FormFieldWrapper>
                <FormNumberInput
                  value={returnista}
                  onChange={value => updateMapping(index, value, undefined)}
                />
              </FormFieldWrapper>
            </FormRow>
            <FormRow>
              <FormLabel>{t('fasletReason')}</FormLabel>
              <FormFieldWrapper>
                <Select
                  fullWidth
                  value={faslet}
                  onChange={(event: SelectChangeEvent<number>) =>
                    updateMapping(
                      index,
                      undefined,
                      event.target.value as number
                    )
                  }
                >
                  {[1, 2, 3, 4, 5, 6, 7, 9, 8].map(v => (
                    <MenuItem key={v} value={v}>
                      {t(`returnReason${v}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormFieldWrapper>
              <IconButton onClick={() => removeMapping(index)} size="large">
                <DeleteIcon />
              </IconButton>
            </FormRow>
            <Line />
          </Fragment>
        ))}
      </FormSection>
      {notification}
    </>
  );
}
