import { useNavigate, useLocation } from 'react-router';
import { useCallback, useMemo, useState } from 'react';

export function useTabFromQuerystring(
  fallbackValue: string | undefined = undefined
) {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const [internalTab, setInternalTab] = useState<string | undefined>(
    queryParams.get('tab') ?? fallbackValue
  );

  const setTab = useCallback(
    (v: string) => {
      queryParams.set('tab', v);
      navigate(`${pathname}?${queryParams.toString()}`);
      setInternalTab(v);
    },
    [navigate, setInternalTab, queryParams, pathname]
  );

  return { tab: internalTab, setTab };
}
