import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Button,
  Popover,
  MenuList,
  MenuItem,
  Switch
} from '@mui/material';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import {
  DeleteForeverRounded as DeleteIcon,
  SaveRounded as SaveIcon,
  AddRounded as AddIcon,
  DragIndicatorRounded as DragIcon
} from '@mui/icons-material';

import {
  ExperimentVariant,
  Retailer,
  StepConfig,
  useErrorNotification,
  useSpot
} from 'framework';
import { DraggableGridRow, useDraggableGridRow } from './draggable-grid-row';
import { CenteredRow } from './layout';
import { Gap, Spacer } from './spacer';

const StepConfigList = styled(Box)`
  position: relative;
  height: calc(100% - 50px);
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const StepsList = styled(Box)`
  position: relative;
  height: calc(100% - 50px);
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const GridParent = styled(Grid)`
  position: relative;
  height: 100%;
`;

const StyleDataGrid = styled(DataGrid)`
  .row-selected {
    background-color: ${p => p.theme.palette.primary.light};
  }
`;

interface InternalStep {
  id: string;
  order: number;
}

const shoeSteps = [
  'referenceShoeBrand',
  'referenceShoeModel',
  'referenceShoeSize',
  'footWidth',
  'age'
];

const clothingSteps = [
  'basic',
  'hips',
  'belly',
  'bust',
  'fit',
  'age',
  'referenceShoeBrand',
  'referenceShoeModel',
  'referenceShoeSize'
];

function mapSteps(steps: string[]) {
  return steps.reduce((accum, step) => {
    const newStep = {
      id: step,
      order: accum.length
    };

    if (step === 'height') {
      newStep.id = 'basic';
    } else if (step === 'weight') {
      return accum;
    }

    accum.push(newStep);
    return accum;
  }, [] as InternalStep[]);
}

function unmapSteps(steps: InternalStep[]) {
  return steps.reduce((accum, step) => {
    let newStep = step.id;

    if (step.id === 'basic') {
      newStep = 'weight';
      accum.push('height');
    }

    accum.push(newStep);
    return accum;
  }, [] as string[]);
}

export interface StepConfigurationProps {
  retailer?: Retailer;
  experimentVariant?: ExperimentVariant;
}

export function StepConfiguration({
  retailer,
  experimentVariant
}: StepConfigurationProps) {
  const { t } = useTranslation();
  const { query, command, spot, loading } = useSpot();
  const [stepConfigs, setStepConfigs] = useState<StepConfig[]>([]);
  const [selectedStepConfig, setSelectedStepConfig] =
    useState<StepConfig | null>(null);
  const [steps, setSteps] = useState<InternalStep[]>([]);
  const [firstTime, setFirstTime] = useState(false);
  const { displayErrors, notification } = useErrorNotification();

  const [stepMenuEl, setStepMenuEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const openStepMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStepMenuEl(event.currentTarget);
  };

  const closeStepMenu = () => {
    setStepMenuEl(null);
  };

  const reorderSteps = useCallback(
    async (dragIndex, dragTargetIndex) => {
      setSteps(oldSteps => {
        let newSteps = [...oldSteps];
        const step = newSteps[dragIndex];
        const deleteIndex =
          dragIndex < dragTargetIndex ? dragIndex : dragIndex + 1;

        newSteps.splice(dragTargetIndex, 0, step);
        newSteps.splice(deleteIndex, 1);

        newSteps = newSteps.map((internalStep, index) => ({
          ...internalStep,
          order: index
        }));

        return newSteps;
      });
    },
    [setSteps]
  );

  const deleteStep = useCallback(
    (index: number) => {
      setSteps(oldSteps => {
        const newSteps = [...oldSteps];
        newSteps.splice(index, 1);
        return newSteps.map((internalStep, i) => ({
          ...internalStep,
          order: i
        }));
      });
    },
    [setSteps]
  );

  const addStep = useCallback(
    (stepId: string) => {
      setSteps(oldSteps => [
        ...oldSteps,
        {
          id: stepId,
          order: oldSteps.length
        }
      ]);
      closeStepMenu();
    },
    [setSteps]
  );

  const { onDragEnd, onDragOver, onDragStart, isDragging } =
    useDraggableGridRow(reorderSteps);

  const getSteps = useCallback(async () => {
    const queryParams: Record<string, any> = {
      firstTime
    };

    if (retailer?.slug) {
      queryParams.retailer = retailer?.slug;
    }

    if (experimentVariant?.id) {
      queryParams.experimentVariantId = experimentVariant?.id;
    }

    try {
      await query(
        'step-config',
        {
          firstTime
        },
        ['stepConfigs', 'global']
      );

      if (retailer?.slug) {
        await query('step-config', queryParams, [
          'stepConfigs',
          'retailer',
          retailer.slug
        ]);
      }
    } catch (e) {
      displayErrors(e as Error[]);
    }

    let configs: StepConfig[] = [];
    if (retailer) {
      configs = spot.data.stepConfigs.retailer[retailer.slug];
      spot.data.stepConfigs.global.forEach(globalConfig => {
        if (
          !configs.find(
            config =>
              config.type === globalConfig.type &&
              config.ageGroup === globalConfig.ageGroup
          )
        ) {
          configs.push({
            id: -globalConfig.id,
            type: globalConfig.type,
            steps: globalConfig.steps,
            retailer: retailer.slug,
            experimentVariantId: experimentVariant?.id,
            firstTime: globalConfig.firstTime,
            ageGroup: globalConfig.ageGroup
          });
        }
      });
    } else {
      configs = spot.data.stepConfigs.global;
    }
    configs.sort(
      (a, b) =>
        100 * a.ageGroup.localeCompare(b.ageGroup) +
        a.type.localeCompare(b.type)
    );

    setStepConfigs(configs);

    setSelectedStepConfig(oldSelectedStepConfig =>
      oldSelectedStepConfig
        ? configs.find(config => config.id === oldSelectedStepConfig.id) || null
        : null
    );
  }, [
    query,
    setStepConfigs,
    spot,
    retailer,
    experimentVariant,
    displayErrors,
    setSelectedStepConfig,
    firstTime
  ]);

  const configClicked = useCallback(
    params => {
      setSelectedStepConfig(params.row);
      setSteps(mapSteps(params.row.steps));
    },
    [setSelectedStepConfig, setSteps]
  );

  useEffect(() => {
    getSteps();
  }, [getSteps]);

  const saveSteps = useCallback(async () => {
    if (!selectedStepConfig) {
      return;
    }
    const stepsToSave = unmapSteps(steps);
    try {
      await command(
        `step-config/${
          selectedStepConfig.id >= 0 ? selectedStepConfig.id : ''
        }`,
        {
          ...selectedStepConfig,
          steps: stepsToSave
        }
      );
      await getSteps();
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [steps, command, selectedStepConfig, displayErrors, getSteps]);

  const stepConfigHeaders = [
    {
      field: 'id',
      hide: true
    },
    {
      field: 'type',
      headerName: t('type'),
      flex: 1,
      renderCell: (params: GridCellParams) =>
        `${t(params.value)} ${
          params.row.firstTime ? `(${t('firstTime')})` : ''
        }`,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      sortable: false
    },
    {
      field: 'ageGroup',
      headerName: t('ageGroup'),
      flex: 1,
      renderCell: (params: GridCellParams) => t(params.value),
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      sortable: false
    }
  ];

  const stepHeaders = [
    {
      field: 'id',
      headerName: t('name'),
      flex: 1,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridCellParams) => t(params.value)
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 128,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <CenteredRow>
          <IconButton
            disabled={loading}
            onClick={() => deleteStep(params.row.order)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
          <DragIcon color="action" />
        </CenteredRow>
      )
    }
  ];

  const availableSteps = (
    selectedStepConfig?.type === 'shoe' ? shoeSteps : clothingSteps
  ).filter(s => !steps.find(is => is.id === s));

  return (
    <>
      <GridParent container>
        <Grid item xs={selectedStepConfig ? 6 : 12}>
          <StepConfigList>
            <CenteredRow>
              <Typography variant="subtitle1">{t('productTypes')}</Typography>
              <Spacer />
              <CenteredRow>
                <Typography variant="subtitle2" color="primary">
                  {t('firstTime')}
                </Typography>
                <Gap />
                <Switch
                  checked={firstTime}
                  onChange={e => setFirstTime(e.target.checked)}
                  color="primary"
                />
              </CenteredRow>
            </CenteredRow>
            <Gap />
            <StyleDataGrid
              onRowClick={configClicked}
              columns={stepConfigHeaders}
              rows={stepConfigs}
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              disableColumnFilter
              hideFooter
              loading={loading}
              getRowClassName={params =>
                `${params.row === selectedStepConfig ? 'row-selected' : ''}`
              }
            />
          </StepConfigList>
        </Grid>
        {selectedStepConfig && (
          <Grid item xs={6}>
            <StepsList>
              <CenteredRow>
                <Typography variant="subtitle1">{t('popupSteps')}</Typography>
                <Spacer />
                <Button
                  disabled={loading || availableSteps.length === 0}
                  startIcon={<AddIcon />}
                  variant="contained"
                  onClick={openStepMenu}
                >
                  {t('addStep')}
                </Button>
                <Popover
                  id="step-list-popover"
                  open={!!stepMenuEl}
                  anchorEl={stepMenuEl}
                  onClose={closeStepMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                >
                  <MenuList>
                    {availableSteps.map(s => (
                      <MenuItem key={s} onClick={() => addStep(s)}>
                        {t(s)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Popover>
                <Gap />
                <Button
                  disabled={loading}
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={saveSteps}
                >
                  {t('save')}
                </Button>
              </CenteredRow>
              <Gap />
              <DataGrid
                columns={stepHeaders}
                rows={steps}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                disableColumnFilter
                hideFooter
                loading={loading}
                components={{
                  Row: DraggableGridRow
                }}
                componentsProps={{
                  row: {
                    dragStart: onDragStart,
                    dragOver: onDragOver,
                    dragEnd: onDragEnd,
                    clickable: !isDragging
                  }
                }}
              />
            </StepsList>
          </Grid>
        )}
      </GridParent>
      {notification}
    </>
  );
}
