import React from 'react';
import { Popover, Button } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Size } from 'framework';

import { SizeLabelLookup, sizesAreDifferent } from 'helpers';
import { Table } from '../table';
import { ImportedValue } from './imported-value';

interface SizesDiffProps {
  id: GridRowId;
  oldSizes: Size[];
  newSizes: Size[];
}

export function SizesDiff({ id, oldSizes, newSizes }: SizesDiffProps) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const sizeHeaders = [t('label'), t('value'), t('maxValue')];

  const oldSizesMap = oldSizes
    ? oldSizes.reduce(
        (map, size) => ({
          ...map,
          [size.label]: { value: size.value, maxValue: size.maxValue }
        }),
        {}
      )
    : {};

  const newSizesMap = newSizes
    ? newSizes.reduce(
        (map, size) => ({
          ...map,
          [size.label]: { value: size.value, maxValue: size.maxValue }
        }),
        {}
      )
    : {};

  const labels = Array.from(
    new Set<string>([...Object.keys(oldSizesMap), ...Object.keys(newSizesMap)])
  ).sort((a, b) => {
    const aLabelSize = (SizeLabelLookup as any)[a] ?? Number(a);
    const bLabelSize = (SizeLabelLookup as any)[b] ?? Number(b);
    return bLabelSize - aLabelSize;
  });

  const sizeRows =
    labels.map(label => {
      const oldValue = (oldSizesMap as any)[label];
      const newValue = (newSizesMap as any)[label];
      let className = '';

      const newValueRequired = Object.keys(newSizesMap).includes(label);

      if (!oldValue && (newValue || (newValueRequired && !newValue))) {
        className = 'import-status-create';
      }

      if (oldValue && !newValue && !newValueRequired) {
        className = 'import-status-not-imported';
      }

      if (
        oldValue &&
        (newValue || (newValueRequired && !newValue)) &&
        (oldValue.value !== newValue.value ||
          oldValue.maxValue !== newValue.maxValue)
      ) {
        className = 'import-status-update';
      }

      return {
        id: `${id}_${label}`,
        cells: [
          <span key={`size_label_${id}_${label}`}>{label}</span>,
          <span key={`size_value_${id}_${label}`}>
            <ImportedValue
              oldValue={oldValue?.value}
              newValue={newValue?.value}
              required={newValueRequired}
              noDeletes
            />
          </span>,
          <span key={`size_maxvalue_${id}_${label}`}>
            <ImportedValue
              oldValue={oldValue?.maxValue}
              newValue={newValue?.maxValue}
              required={newValueRequired}
              noDeletes
            />
          </span>
        ],
        className
      };
    }) ?? [];

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return sizesAreDifferent(oldSizes, newSizes) ? (
    <div>
      <div>
        <Button onClick={handlePopoverOpen} className="size-diff-btn">
          &#8800;
        </Button>
      </div>
      <Popover
        id={`sizes-popover-${id}`}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Table headers={sizeHeaders} rows={sizeRows} />
      </Popover>
    </div>
  ) : (
    <div>
      <Button disabled className="size-diff-btn">
        &#61;
      </Button>
    </div>
  );
}
