import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from '@emotion/styled';
import { Typography, Button } from '@mui/material';
import { HomeRounded as HomeIcon } from '@mui/icons-material';

import { Container, Gap } from 'components';
import image from '../images/reading-corner.jpg';

const StyledContainer = styled(Container)`
  align-items: center;
  justify-content: center;
`;

export function SignedOut() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <StyledContainer>
      <Gap size={8} />
      <Typography variant="h3">{t('signedOutHeader')}</Typography>
      <Gap size={4} />
      <img src={image} alt={t('signedout')} width={600} />
      <Gap size={4} />
      <Button variant="text" onClick={goHome} startIcon={<HomeIcon />}>
        {t('signBackIn')}
      </Button>
    </StyledContainer>
  );
}
