import { openInSameTab } from './open-window';
import { getApi } from './get-api';

const getRedirectUrl = () => {
  const path =
    window.location.pathname + window.location.search + window.location.hash;
  switch (window.location.hostname) {
    case 'localhost':
      return `https://login-with.link/#/login/910b1596-5fc2-4719-afbb-26114df0ef03?state=${encodeURIComponent(
        path
      )}`;
    case 'portal.dev.faslet.net':
    case 'portal-dev.faslet.net':
      return `https://login-with.link/#/login/89f2d48f-dec2-40a2-a061-e87eb1705f80?state=${encodeURIComponent(
        path
      )}`;
    case 'portal.prod.faslet.net':
    case 'portal.faslet.net':
    default:
      return `https://login-with.link/#/login/60290f04-f8b1-4208-a453-c876a35eb1ea?state=${encodeURIComponent(
        path
      )}`;
  }
};

export const logout = async (redirectTo: string = 'signed-out') => {
  localStorage?.removeItem('faslet_lwltoken');
  await fetch(`${getApi()}/profile/logout`, {
    credentials: 'include',
    redirect: 'manual'
  });

  openInSameTab(`${window.location.origin}/${redirectTo}`);
};

export const redirectToLogin = () => openInSameTab(getRedirectUrl());
