import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import {
  DeleteForeverRounded as DeleteIcon,
  EditRounded as EditIcon,
  AddRounded as AddIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Gap, Spacer } from './spacer';
import { CenteredRow } from './layout';
import {
  EditTextDialog,
  EditTextDialogProps
} from './dialogs/edit-text-dialog';
import { ConfirmDeleteAlert } from './confirm-delete-alert';
import { StyledHeader, Table, TableColumnDescriptor } from './table';

import {
  Item,
  Size,
  useSpot,
  ConfirmDeleteDialogState,
  ItemRow
} from '../framework';

const StyledSizeCell = styled.div`
  padding: ${p => p.theme.spacing(2, 1)};
`;

const StyledMeasurementHeader = styled(StyledHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

const TableWrapper = styled(Box)`
  flex: 1 1 auto;
  position: relative;
`;

export const converItemsToItemRows = (items: Item[]) =>
  items.reduce((accum, item) => {
    const result = [...accum];

    let found = result.find(
      i => i.id === `${item.brandName}-${item.productName}-${item.gender}`
    );

    if (!found) {
      found = {
        itemIds: [],
        id: `${item.brandName}-${item.productName}-${item.gender}`,
        brandName: item.brandName,
        productName: item.productName,
        gender: item.gender,
        measurements: [],
        labels: {},
        ttsSkew: item.ttsSkew,
        adjustments: item.adjustments
      };
      result.push(found);
    }

    found.measurements.push(item.measurementName);
    found.itemIds.push(item.id);
    found.adjustments = [
      ...(item.adjustments ?? []),
      ...(found.adjustments ?? [])
    ];

    item.sizes.forEach(size => {
      if (!found) {
        return;
      }

      found.labels[size.label] = found.labels[size.label] ?? {};
      found.labels[size.label][item.measurementName] = {
        value: size.value,
        maxValue: size.maxValue,
        aliases: size.aliases,
        displayName: size.displayName,
        id: size.id,
        itemId: item.id
      };
    });

    return result;
  }, [] as ItemRow[]) ?? [];

export function SizeChartItemEditor({
  item,
  refresh,
  onLoadingChanged,
  onDeleteItems,
  disabled
}: {
  item: ItemRow;
  refresh?: (itemId: string | undefined) => unknown;
  onLoadingChanged?: (loading: boolean) => unknown;
  onDeleteItems?: (ids: number[]) => unknown;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const { command, loading } = useSpot();

  useEffect(() => {
    onLoadingChanged && onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const [confirmSizeDelete, setConfirmSizeDelete] =
    useState<ConfirmDeleteDialogState<number[]> | null>(null);

  const [confirmAliasDelete, setConfirmAliasDelete] =
    useState<ConfirmDeleteDialogState<{
      sizes: Size[];
      alias: string;
    }> | null>(null);

  const [showEditText, setShowEditText] = useState<EditTextDialogProps | null>(
    null
  );

  const deleteSizes = useCallback(
    async (ids: number[] | undefined) => {
      setConfirmSizeDelete(null);

      const oldItemId = item?.id;

      if (ids) {
        const promises = ids.map(id =>
          command(`size/${id}`, undefined, {
            method: 'DELETE'
          })
        );

        await Promise.all(promises);
        refresh && (await refresh(oldItemId));
      }
    },
    [command, setConfirmSizeDelete, refresh, item]
  );

  const confirmDeleteSize = useCallback(
    (ids: number[]) => {
      if (ids) {
        setConfirmSizeDelete({
          text: t('deleteSizeConfirmationText'),
          title: t('deleteSizeConfirmation'),
          value: ids,
          onConfirm: (sizeIds: number[] | undefined) => deleteSizes(sizeIds)
        });
      }
    },
    [setConfirmSizeDelete, deleteSizes, t]
  );

  const deleteAlias = useCallback(
    async (sizes?: Size[], alias?: string) => {
      setConfirmAliasDelete(null);

      const oldItemId = item?.id;

      if (sizes && alias) {
        const promises = sizes.map(size =>
          size.aliases
            ? command(
                `size/${size.id}`,
                {
                  ...size,
                  aliases: size.aliases.filter(a => a !== alias)
                },
                {
                  method: 'PATCH'
                }
              )
            : null
        );

        await Promise.all(promises);
        refresh && (await refresh(oldItemId));
      }
    },
    [command, item, refresh]
  );

  const confirmDeleteAlias = useCallback(
    (sizes: Size[], alias: string) => {
      if (alias) {
        setConfirmAliasDelete({
          text: t('deleteAliasConfirmationText'),
          title: t('deleteAliasConfirmation'),
          value: { sizes, alias },
          onConfirm: (payload: { sizes: Size[]; alias: string } | undefined) =>
            deleteAlias(payload?.sizes, payload?.alias)
        });
      }
    },
    [setConfirmAliasDelete, deleteAlias, t]
  );

  const addAlias = useCallback(
    async (sizes: Size[], alias) => {
      setShowEditText(null);

      const oldItemId = item?.id;

      if (sizes && alias) {
        const promises = sizes.map(size =>
          command(
            `size/${size.id}`,
            {
              ...size,
              aliases: [...(size.aliases ?? []), alias]
            },
            {
              method: 'PATCH'
            }
          )
        );

        await Promise.all(promises);
        refresh && (await refresh(oldItemId));
      }
    },
    [command, item, refresh]
  );

  const editDisplayName = useCallback(
    async (sizes: Size[], displayName) => {
      setShowEditText(null);

      const oldItemId = item?.id;

      if (sizes) {
        const promises = sizes.map(size =>
          command(
            `size/${size.id}`,
            {
              ...size,
              displayName: displayName || null
            },
            {
              method: 'PATCH'
            }
          )
        );

        await Promise.all(promises);
        refresh && (await refresh(oldItemId));
      }
    },
    [command, item, refresh]
  );

  const deleteMeasurementItem = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const measurement = event.currentTarget.id;
      const itemToDelete = Object.values(item.labels)[0]?.[measurement]?.itemId;

      onDeleteItems && onDeleteItems([itemToDelete]);
    },
    [onDeleteItems, item]
  );

  const sizeColumns: TableColumnDescriptor[] = [
    { align: 'center' },
    ...(item?.measurements.map(
      () => ({ align: 'right' }) as TableColumnDescriptor
    ) ?? []),
    { align: 'left' },
    { align: 'left' }
  ];

  const sizeHeaders = [
    {
      id: 'label',
      content: <StyledHeader>{t('label')}</StyledHeader>
    },
    ...(item?.measurements.map(m => ({
      id: m,
      content: (
        <StyledMeasurementHeader>
          {t(m)}
          <Gap />
          {!disabled && refresh && onDeleteItems && (
            <IconButton id={m} onClick={deleteMeasurementItem} size="large">
              <DeleteIcon />
            </IconButton>
          )}
        </StyledMeasurementHeader>
      )
    })) ?? []),
    {
      id: 'displayName',
      content: <StyledHeader>{t('displayName')}</StyledHeader>
    },
    {
      id: 'aliases',
      content: <StyledHeader>{t('aliases')}</StyledHeader>
    },
    { id: 'id', content: ' ' }
  ];

  const formatSizeRange = (
    key: string,
    measurement: { value: number; maxValue: number | null } | undefined
  ) => {
    return (
      <StyledSizeCell key={key}>
        {measurement
          ? `${measurement?.value ?? '?'}${
              measurement?.maxValue ? ` - ${measurement?.maxValue}` : ''
            }`
          : '-'}
      </StyledSizeCell>
    );
  };

  const sizeRows =
    Object.entries(item?.labels ?? {}).map(([label, sizes]) => ({
      id: `${label}`,
      cells: [
        <span key={`size_label_${label}`}>{label}</span>,
        ...(item?.measurements.map(m =>
          formatSizeRange(`size_${m}_value_${label}`, sizes[m])
        ) ?? []),
        <CenteredRow key={`size_label_${label}_1`}>
          {(
            item?.measurements.reduce(
              (displayNames: string[], measurement: string) => {
                if (
                  sizes[measurement] &&
                  sizes[measurement].displayName &&
                  !displayNames.includes(
                    sizes[measurement].displayName as string
                  )
                ) {
                  return [
                    ...displayNames,
                    sizes[measurement].displayName as string
                  ];
                }

                return displayNames;
              },
              []
            ) ?? []
          ).join(', ')}
          <Spacer />
          {!disabled && refresh && (
            <IconButton
              className="grid-icon-button"
              style={{ flex: 0 }}
              onClick={() =>
                setShowEditText({
                  text: t('editDisplayNameText'),
                  label: t('editDisplayNameLabel'),
                  title: t('editDisplayNameTitle'),
                  initialValue: Object.values(sizes)[0].displayName,
                  dialogOpen: true,
                  onClose: () => setShowEditText(null),
                  onSubmit: displayName =>
                    editDisplayName(
                      Object.values(sizes).map(size => ({
                        ...size,
                        label
                      })),
                      displayName
                    )
                })
              }
              title={t('assignTag')}
              size="large"
            >
              <EditIcon color="primary" />
            </IconButton>
          )}
        </CenteredRow>,
        <CenteredRow key={`size_label_${label}_2`}>
          {(
            item?.measurements.reduce(
              (aliases, measurement) => [
                ...aliases,
                ...(sizes[measurement]?.aliases ?? [])
              ],
              [] as string[]
            ) ?? []
          )
            .filter((alias, index, arr) => arr.indexOf(alias) === index)
            .map(alias => (
              <Fragment key={alias}>
                <Chip
                  label={alias}
                  disabled={disabled || !onDeleteItems}
                  onDelete={() =>
                    confirmDeleteAlias(
                      Object.values(sizes).map(size => ({
                        ...size,
                        label
                      })),
                      alias
                    )
                  }
                />
                <Gap />
              </Fragment>
            ))}
          <Spacer />
          {!disabled && refresh && (
            <IconButton
              className="grid-icon-button"
              style={{ flex: 0 }}
              onClick={() =>
                setShowEditText({
                  text: t('addAliasText'),
                  label: t('addAliasLabel'),
                  title: t('addAliasTitle'),
                  dialogOpen: true,
                  onClose: () => setShowEditText(null),
                  onSubmit: alias =>
                    addAlias(
                      Object.values(sizes).map(size => ({
                        ...size,
                        label
                      })),
                      alias
                    )
                })
              }
              title={t('addAliasTitle')}
              size="large"
            >
              <AddIcon color="primary" />
            </IconButton>
          )}
        </CenteredRow>,
        !disabled && refresh && onDeleteItems ? (
          <IconButton
            className="grid-icon-button"
            key={`size_delete_${label}`}
            onClick={() =>
              confirmDeleteSize(Object.values(sizes).map(s => s.id))
            }
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
      ]
    })) ?? [];

  return (
    <>
      {showEditText && <EditTextDialog {...showEditText} />}
      {confirmSizeDelete?.onConfirm && (
        <ConfirmDeleteAlert
          id="confirmSizeDelete"
          title={confirmSizeDelete?.title ?? ''}
          text={confirmSizeDelete?.text ?? ''}
          open={!!confirmSizeDelete}
          onConfirm={confirmSizeDelete?.onConfirm}
          value={confirmSizeDelete?.value}
          keepMounted
        />
      )}
      {confirmAliasDelete?.onConfirm && (
        <ConfirmDeleteAlert
          id="confirmAliasDelete"
          title={confirmAliasDelete?.title ?? ''}
          text={confirmAliasDelete?.text ?? ''}
          open={!!confirmAliasDelete}
          onConfirm={confirmAliasDelete?.onConfirm}
          value={confirmAliasDelete?.value}
          keepMounted
        />
      )}
      <TableWrapper>
        <Table
          rows={sizeRows}
          headers={sizeHeaders}
          columns={sizeColumns}
          maxHeight="100%"
        />
      </TableWrapper>
    </>
  );
}
