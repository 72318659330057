import React, {
  useCallback,
  useState,
  MouseEvent,
  KeyboardEvent,
  SyntheticEvent,
  useContext
} from 'react';

import copyToClipboard from 'copy-to-clipboard';

import { useTranslation } from 'react-i18next';
import { Box, IconButton, Link, TextField, Autocomplete } from '@mui/material';

import {
  DataGrid,
  GridCellModes,
  GridCellParams,
  GridColumns,
  GridRowId,
  GridRowModel,
  MuiBaseEvent,
  MuiEvent
} from '@mui/x-data-grid';

import {
  Edit as EditIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  FileCopyRounded as CopyIcon,
  GridOnRounded as SizeChartIcon,
  AttachmentRounded as SizeChartFileLink,
  DeleteRounded as DeleteIcon
} from '@mui/icons-material';

import moment from 'moment';

import styled from '@emotion/styled';
import {
  RoleContext,
  SizeChartsUpload,
  SizeChartsUploadInfo,
  useSpot
} from 'framework';
import {
  CenteredRow,
  ConfirmDeleteAlert,
  DataGridFrame,
  Notification,
  QuickSearchToolbar,
  RowCenter,
  SizeSpecDialog
} from 'components';

const TagEdit = styled(Box)<{ missing: boolean }>`
  font-weight: ${p => (p.missing ? 'bold' : 'unset')};
  color: ${p => (p.missing ? 'red' : 'unset')};
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1 1 120px;
`;

export interface UploadedSizeChartsProps {
  onSearchTermChanged: (value: string) => unknown;
  deleteSizeUpload: (id: number) => unknown;
  sizeUploadRows: GridRowModel[];
  tagNames: string[];
  onTagSave: (sizeChartUploadId: number, tagName: string) => Promise<void>;
  onSizeUploadSelected: (sizeChartUpload: SizeChartsUploadInfo) => unknown;
}

export function UploadedSizeChartList({
  onSearchTermChanged,
  deleteSizeUpload,
  sizeUploadRows,
  tagNames,
  onTagSave,
  onSizeUploadSelected
}: UploadedSizeChartsProps) {
  const { t } = useTranslation();

  const { loading } = useSpot();
  const [copied, setCopied] = useState(false);
  const [sizeSpecDialog, setSizeSpecDialog] =
    useState<SizeChartsUploadInfo | null>(null);
  const [currentEditCell, setCurrentEditCell] = useState<GridRowId | null>(
    null
  );
  const [confirmDelete, setConfirmDelete] = useState<number | null>(null);

  const { isAdmin } = useContext(RoleContext);

  const [selectedTagName, setSelectedTagName] = useState<string | null>(null);

  const handleCopy = useCallback((tag: string) => {
    if (tag) {
      copyToClipboard(tag);
      setCopied(true);
    }
  }, []);

  const handleTagEditClick = (params: GridCellParams) => {
    const { id, value } = params;

    setCurrentEditCell(id);

    if (value) {
      setSelectedTagName(value as string);
    }
  };

  const handleTagEditCancelClick = () => {
    setCurrentEditCell(null);
  };

  const handleTagSaveClick = async (params: GridCellParams) => {
    const { id } = params;

    if (selectedTagName) {
      setCurrentEditCell(null);
      setSelectedTagName(null);

      await onTagSave(id as number, selectedTagName as string);
    }
  };

  const selectTag = (event: unknown, newValue: string | null) => {
    setSelectedTagName(newValue);
  };

  const itemHeaders: GridColumns = [
    {
      field: 'uploadedAt',
      headerName: t('uploadedAt'),
      width: 130,
      renderCell: (params: GridCellParams) => (
        <>{moment(params.value as string).format('D MMM | HH:mm')}</>
      )
    },
    {
      field: 'productName',
      headerName: t('productName'),
      renderCell: (params: GridCellParams) => (
        <Link
          title={params.value as string}
          href={(params.row as SizeChartsUpload).productUrl}
          target="_blank"
        >
          {params.value}
        </Link>
      ),
      flex: 1
    },
    {
      field: 'gender',
      headerName: t('gender'),
      width: 80,
      renderCell: (params: GridCellParams) => t(params.value)
    },
    {
      field: 'brandName',
      headerName: t('brand'),
      width: 130
    },
    {
      field: 'sizeChartFile',
      headerName: t('sizeChartFile'),
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const sizeChartUpload = params.row as SizeChartsUploadInfo;
        if (sizeChartUpload.sizeChartFileUrl) {
          return (
            <Link
              href={`${sizeChartUpload.sizeChartFileUrl}`}
              target="_blank"
              title={t('openSizeChartFile')}
            >
              <IconButton color="primary" size="large">
                <SizeChartFileLink />
              </IconButton>
              {params.value}
            </Link>
          );
        }

        return (
          <CenteredRow>
            <IconButton
              color="primary"
              onClick={() => setSizeSpecDialog(sizeChartUpload)}
              size="large"
            >
              <SizeChartIcon />
            </IconButton>
            <span>{t('view')}</span>
          </CenteredRow>
        );
      }
    }
  ];

  if (isAdmin()) {
    itemHeaders.push({
      field: 'productId',
      headerName: t('productId'),
      flex: 1
    });

    itemHeaders.push({
      field: 'uploadedBy',
      headerName: t('uploadedBy'),
      width: 150,
      renderCell: (params: GridCellParams) =>
        params.value === null ? (
          'API'
        ) : (
          <Link
            href={`mailto:${(params.value as { email: string })?.email}`}
            target="_blank"
            title={`${(params.value as { firstName: string })?.firstName} ${
              (params.value as { lastName: string })?.lastName
            }`}
          >
            {`${(params.value as { firstName: string })?.firstName} ${
              (params.value as { lastName: string })?.lastName
            }`}
          </Link>
        )
    });

    itemHeaders.push({
      field: 'tag',
      headerName: t('fasletTag'),
      flex: 2,
      editable: isAdmin(),
      renderCell: (params: GridCellParams) => (
        <RowCenter>
          <TagEdit missing={!params.value} id={params.value}>
            {params.value || t('noTag')}
          </TagEdit>
          {isAdmin() && (
            <IconButton
              style={{ flex: 0 }}
              onClick={() => handleTagEditClick(params)}
              title={t('assignTag')}
              size="large"
            >
              <EditIcon color="primary" />
            </IconButton>
          )}
          {params.value && (
            <IconButton
              onClick={() => handleCopy(params.value as string)}
              size="large"
            >
              <CopyIcon />
            </IconButton>
          )}
        </RowCenter>
      ),
      renderEditCell: (params: GridCellParams) => (
        <RowCenter>
          <Autocomplete
            style={{ flex: 1 }}
            onChange={selectTag}
            value={selectedTagName}
            options={tagNames ?? []}
            renderInput={p => <TextField {...p} placeholder={t('selectTag')} />}
          />
          <IconButton
            style={{ flex: 0 }}
            onClick={() => handleTagSaveClick(params)}
            title={t('submitAssignedSizeChartTag')}
            size="large"
          >
            <SaveIcon color="primary" />
          </IconButton>
          <IconButton
            style={{ flex: 0 }}
            onClick={handleTagEditCancelClick}
            title={t('cancelAssigningSizeChartTag')}
            size="large"
          >
            <CancelIcon color="error" />
          </IconButton>
        </RowCenter>
      )
    });
  }

  itemHeaders.push({
    field: 'actions',
    headerName: ' ',
    width: 100,
    renderCell: (params: GridCellParams) => (
      <RowCenter>
        <IconButton
          onClick={() => setConfirmDelete(params.row.id as number)}
          size="large"
          title={t('delete')}
        >
          <DeleteIcon />
        </IconButton>
      </RowCenter>
    )
  });

  const handleDoubleCellClick = useCallback(
    (params: GridCellParams, event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    },
    []
  );

  const handleCellKeyDown = useCallback(
    (params: GridCellParams, event: KeyboardEvent) => {
      if (['Escape', 'Enter'].includes((event as React.KeyboardEvent).key)) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    []
  );

  const handleCellFocusOut = useCallback(
    (params: GridCellParams, event: MuiEvent<MuiBaseEvent>) => {
      if (params.cellMode === 'edit' && event) {
        (event as SyntheticEvent).preventDefault();
        (event as SyntheticEvent).stopPropagation();
      }
    },
    []
  );

  const handleDeleteConfirm = useCallback(
    (id: number | undefined) => {
      if (id) {
        deleteSizeUpload(id);
      }
      setConfirmDelete(null);
    },
    [deleteSizeUpload]
  );

  const cellModes = sizeUploadRows.reduce((modes, current) => {
    return {
      ...modes,
      [current.id]: {
        tag: {
          mode:
            currentEditCell === current.id
              ? GridCellModes.Edit
              : GridCellModes.View
        }
      }
    };
  }, {});

  return (
    <DataGridFrame>
      <DataGrid
        disableColumnMenu
        disableColumnSelector
        experimentalFeatures={{
          newEditingApi: true
        }}
        loading={loading}
        rows={sizeUploadRows}
        columns={itemHeaders}
        components={{ Toolbar: QuickSearchToolbar }}
        cellModesModel={cellModes}
        componentsProps={{
          toolbar: {
            onChange: (e: React.ChangeEvent<{ value: string }>) =>
              onSearchTermChanged(e.target.value),
            clearSearch: () => onSearchTermChanged('')
          }
        }}
        onCellDoubleClick={handleDoubleCellClick}
        onCellFocusOut={handleCellFocusOut}
        onCellKeyDown={handleCellKeyDown}
        onRowClick={params =>
          onSizeUploadSelected(params.row as SizeChartsUploadInfo)
        }
      />
      {copied && (
        <Notification
          onClose={() => setCopied(false)}
          title={t('copied')}
          severity="info"
          duration={3000}
        />
      )}
      {!!sizeSpecDialog && (
        <SizeSpecDialog
          title={t('sizeSpec')}
          dialogOpen={!!sizeSpecDialog}
          sizeSpec={sizeSpecDialog}
          onClose={() => setSizeSpecDialog(null)}
        />
      )}
      {!!confirmDelete && (
        <ConfirmDeleteAlert
          open={!!confirmDelete}
          onConfirm={handleDeleteConfirm}
          id="confirmSizeDelete"
          title={t('confirmSizeUploadDeleteTitle')}
          text={t('confirmSizeUploadDelete')}
          value={confirmDelete}
          keepMounted
        />
      )}
    </DataGridFrame>
  );
}
