import { Box } from '@mui/material';
import styled from '@emotion/styled';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Row = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CenteredRow = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const CenteredContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RowCenter = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: baseline;
  align-items: center;
  vertical-align: baseline;
  width: 100%;
`;

export const RowBottom = styled(Box)`
  display: flex;
  flex-direction: row;
  align-content: baseline;
  align-items: end;
  vertical-align: baseline;
  width: 100%;
`;

export const RightButtons = styled(Box)`
  height: 4em;
  display: flex;
  align-items: flex-end;
  justify-content: end;
  align-content: end;
`;

export const DataGridFrame = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 65vh;
  width: 100%;
`;

export const DialogContent = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 40vh;
  width: 100%;
`;

export const Filler = styled(Box)`
  display: flex;
  flex: 1 0 100%;
`;
