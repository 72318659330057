import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography, SvgIcon, Popover, Box } from '@mui/material';
import {
  AddRounded as AddIcon,
  SendRounded as ShareIcon,
  FileCopyRounded as CopyIcon,
  HelpOutlineRounded as HelpIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';
import copyToClipboard from 'copy-to-clipboard';
import { useLocation } from 'react-router-dom';

import { RoleContext, formatDate, openInNewTab, useSpot } from 'framework';
import {
  CenteredRow,
  Container,
  EditTextDialog,
  Gap,
  HorizontalLoadingBar,
  Line,
  Notification,
  Spacer
} from 'components';

import { ReactComponent as CouponsIcon } from '../../images/coupons.svg';
import { CouponDescriptor, CreateCouponDialog } from './create-coupon-dialog';

const CouponRow = styled(CenteredRow)`
  margin: ${p => p.theme.spacing(0, 2)};
  padding: ${p => p.theme.spacing(2, 0)};
  border-bottom: 1px solid ${p => p.theme.palette.divider};
  max-width: 1200px;
`;

const CouponCode = styled(Typography)`
  font-weight: bold;
  flex: 0 0 300px;
  position: relative;
`;

const CouponField = styled(Typography)<{ width: number }>`
  flex: 0 0 ${p => p.width}px;
`;

const IconBox = styled.div`
  position: relative;
  color: ${p => p.theme.palette.primary.main};
  top: ${p => p.theme.spacing(0.5)};
  left: ${p => p.theme.spacing(0.5)};
  display: inline;
  pointer-events: auto;
`;

const InfoPopup = styled(Popover)`
  pointer-events: none;
`;

const InfoTextBox = styled(Box)`
  padding: ${p => p.theme.spacing(2)};
  border: 1px solid ${p => p.theme.palette.primary.main};
  border-radius: 5px;
  max-width: 400px;
`;

export function Coupons() {
  const { t } = useTranslation();
  const { query, spot, command, loading } = useSpot();
  const { isAdmin } = useContext(RoleContext);
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const redeemCode = queryParams.get('redeemCode');

  const [redemptionPopup, setRedemptionPopup] = useState(!!redeemCode);
  const [copied, setCopied] = useState(false);
  const [createCouponOpen, setCreateCouponOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openRedemptionPopup = useCallback(async () => {
    setRedemptionPopup(true);
  }, []);

  const closeRedemptionPopup = useCallback(async () => {
    setRedemptionPopup(false);
  }, []);

  const refresh = useCallback(async () => {
    await query('coupon/', {}, ['coupons', 'issued']);
    await query('coupon/redeemed', {}, ['coupons', 'redeemed']);
  }, [query]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const createCoupon = useCallback(
    async (couponDescriptor: CouponDescriptor) => {
      await command('coupon/create', {
        code: couponDescriptor.baseCode?.trim(),
        expiryDate: couponDescriptor.expiryDate,
        maxRedemptions: couponDescriptor.maxRedemptions,
        issuerId:
          couponDescriptor.issuerId !== -1
            ? couponDescriptor.issuerId
            : undefined
      });
      refresh();
    },
    [command, refresh]
  );

  const redeemCoupon = useCallback(
    async (code: string) => {
      await command('coupon/redeem', { code: code.trim() });
      refresh();
    },
    [command, refresh]
  );

  const handleCopy = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const copyContent = (
        event.currentTarget as Node
      )?.parentNode?.querySelector('#coupon-code')?.textContent;
      if (copyContent) {
        copyToClipboard(copyContent);
        setCopied(true);
      }
    },
    []
  );

  const handleSend = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const code = (event.currentTarget as Node)?.parentNode?.querySelector(
        '#coupon-code'
      )?.textContent;
      openInNewTab(
        `mailto:?subject=Here's a free gift from Faslet&body=Hey!%0D%0A%0D%0AUnlock your exclusive Faslet benefit! As a satisfied partner utilizing Faslet’s innovative sizing technology, I’ve experienced reduced returns, increased conversions, and enhanced customer experience. Now, you can too! Claim this coupon code to get your free gift from Faslet:%0D%0A%0D%0A${code}%0D%0A%0D%0AIf you've signed up already, you can redeem this code in the Faslet Partner Portal: https://portal.faslet.net/coupons%0D%0A%0D%0AEnjoy!`
      );
    },
    []
  );

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const closeCreateCouponPopup = useCallback(() => {
    setCreateCouponOpen(false);
  }, [setCreateCouponOpen]);

  const openCreateCouponPopup = useCallback(() => {
    setCreateCouponOpen(true);
  }, [setCreateCouponOpen]);

  return (
    <>
      <CenteredRow>
        <Spacer />
        {isAdmin() && (
          <Button
            variant="contained"
            onClick={openCreateCouponPopup}
            color="primary"
            startIcon={<AddIcon />}
          >
            {t('createCoupon')}
          </Button>
        )}
        <Gap />
        <Button
          variant="contained"
          onClick={openRedemptionPopup}
          color="primary"
          startIcon={<SvgIcon component={CouponsIcon} />}
        >
          {t('redeemCoupon')}
        </Button>
      </CenteredRow>
      <Line />
      <HorizontalLoadingBar loading={loading} />
      <Container>
        <Typography variant="h4">{t('myCoupons')}</Typography>
        <Gap />
        <Typography variant="subtitle2">{t('myCouponsInfo')}</Typography>
        <Gap />
        <CouponRow>
          <CouponField width={300}>{t('couponCode')}</CouponField>
          <CouponField width={300}>{t('expiryDate')}</CouponField>
          <CouponField width={300}>{t('redemptions')}</CouponField>
        </CouponRow>
        {spot.data?.coupons?.issued?.map(c => (
          <CouponRow key={c.code}>
            <CouponCode id="coupon-code">
              {c.code}
              <IconBox
                id={c.code}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <HelpIcon fontSize="small" />
              </IconBox>
            </CouponCode>
            <CouponField width={300}>
              {c.expiryDate ? formatDate(c.expiryDate) : t('noExpiryDate')}
            </CouponField>
            <CouponField width={100}>{`${c.redemptions}/${
              c.maxRedemptions ?? '∞'
            }`}</CouponField>
            <Spacer />
            <Button
              variant="contained"
              color="primary"
              startIcon={<CopyIcon />}
              onClick={handleCopy}
            >
              {t('copy')}
            </Button>
            <Gap />
            <Button
              variant="contained"
              color="primary"
              startIcon={<ShareIcon />}
              onClick={handleSend}
            >
              {t('send')}
            </Button>
          </CouponRow>
        )) ?? <CouponRow>{t('noCoupons')}</CouponRow>}
      </Container>
      <Gap size={8} />
      <Container>
        <Typography variant="h4">{t('redeemedCoupons')}</Typography>
        <Gap />
        <Typography variant="subtitle2">{t('redeemedCouponsInfo')}</Typography>
        <Gap />
        <CouponRow>
          <CouponField width={300}>{t('couponCode')}</CouponField>
          <CouponField width={300}>{t('redemptionDate')}</CouponField>
          <CouponField width={300}>{t('issuer')}</CouponField>
        </CouponRow>
        {spot.data?.coupons?.redeemed?.map(c => (
          <CouponRow key={c.code}>
            <CouponCode id="coupon-code">
              {c.code}
              <IconBox
                id={c.code}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <HelpIcon fontSize="small" />
              </IconBox>
            </CouponCode>
            <CouponField width={300}>
              {formatDate(c.redemptionDate)}
            </CouponField>
            <CouponField width={300}>{c.issuer}</CouponField>
          </CouponRow>
        )) ?? <CouponRow>{t('noCoupons')}</CouponRow>}
      </Container>
      <InfoPopup
        id="mouse-over-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <InfoTextBox>
          {t(
            `${anchorEl?.id
              ?.substring(0, anchorEl.id.length - 6)
              .toLocaleLowerCase()}Info`
          )}
        </InfoTextBox>
      </InfoPopup>
      {redemptionPopup && (
        <EditTextDialog
          title={t('redeemCoupon')}
          dialogOpen={redemptionPopup}
          label={t('enterCouponCode')}
          onClose={closeRedemptionPopup}
          onSubmit={redeemCoupon}
          text={t('enterCouponCodeInfo')}
          initialValue={redeemCode ?? ''}
        />
      )}
      {copied && (
        <Notification
          onClose={() => setCopied(false)}
          title={t('copied')}
          severity="info"
          duration={3000}
        />
      )}
      {createCouponOpen && (
        <CreateCouponDialog
          dialogOpen={createCouponOpen}
          onClose={closeCreateCouponPopup}
          onSubmit={createCoupon}
        />
      )}
    </>
  );
}
