import React, {
  HtmlHTMLAttributes,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import { LabelRounded as GoToTagIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { Brand, RoleContext, Tag, useSpot } from '../framework';
import { CenteredRow } from './layout';
import { Gap } from './spacer';

const Wrapper = styled.div`
  flex: 0 0 360px;

  ${p => p.theme.breakpoints.down('md')} {
    flex: 0 0 300px;
  }

  display: flex;
  align-items: center;
`;

const TagNameRow = styled(CenteredRow)`
  flex-wrap: nowrap;
  flex: 1 1 auto;
`;

const TagName = styled(Typography)`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const StyledInput = styled(TextField)`
  color: black;
  background-color: white;
  border-radius: 5px;
`;

const GoToTagButton = styled(IconButton)`
  color: ${p => p.theme.palette.primary.main};
`;

export interface TagSelectorProps {
  brand?: string;
  includeAllTags?: boolean;
  onTagChanged: (tag: Tag | null) => unknown;
  selectedTag: Tag | null;
  initialTagName?: string; // to allow passing url values in
  disabled?: boolean;
}

export const allTagsTagId = -1;

export const allTagsTag: Tag = {
  name: 'allTags',
  id: allTagsTagId,
  gender: ''
};

const defaultFilterOptions = createFilterOptions<Tag>({
  limit: 30,
  ignoreCase: true,
  ignoreAccents: true
});

export function TagSelector({
  includeAllTags,
  onTagChanged,
  selectedTag,
  initialTagName,
  disabled: propDisabled,
  brand
}: TagSelectorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { spot, query, loading } = useSpot();
  const [tags, setTags] = useState<Tag[]>([]);

  function findBrand(brand: string) {
    const lowerValue = brand.toLocaleLowerCase();
    let brandObj = spot.data?.brands?.find(
      v =>
        v.name.toLocaleLowerCase() === lowerValue ||
        v.slug.toLocaleLowerCase() === lowerValue
    );

    if (!brandObj) {
      const alias = spot.data?.aliases?.find(
        a => a.name.toLocaleLowerCase() === lowerValue
      );
      if (alias) {
        brandObj = spot.data?.brands?.find(b => b.id === alias.brandId);
      }
    }
    return brandObj ?? null;
  }

  const { isAdmin } = useContext(RoleContext);

  const disabled = propDisabled || loading;

  useEffect(() => {
    (async () => {
      let localTags: Tag[];
      if (!spot.data?.tags) {
        await query('tag/', {}, ['tags']);
      }
      localTags = spot.data?.tags ?? [];

      if (brand) {
        if (!spot.data?.brands) {
          await query('brand/', {}, ['brands']);
        }
        const brandObj = findBrand(brand);
        if (brandObj) {
          await query(`item/?brandId=${brandObj.id}`, {}, ['sourceItems']);

          localTags =
            localTags.filter(t =>
              spot.data?.sourceItems.find(
                i => i.gender === t.gender && i.productName === t.product?.name
              )
            ) ?? [];
        }
      }

      setTags(localTags ?? []);

      if (localTags.length === 1 && selectedTag?.id !== localTags[0]?.id) {
        onTagChanged(localTags[0] ?? null);
      } else if (initialTagName) {
        const foundTag = localTags.find(
          b =>
            b.name?.toLocaleLowerCase() === initialTagName.toLocaleLowerCase()
        );
        onTagChanged(foundTag ?? null);
      } else if (!includeAllTags) {
        onTagChanged(localTags[0] ?? null);
      }
    })();
  }, []);

  const baseTags: Tag[] = [];

  if (includeAllTags) {
    baseTags.push(allTagsTag);
  }

  let defaultTag: Tag | null = null;
  if (includeAllTags && !defaultTag) {
    defaultTag = allTagsTag;
  }

  const extendedTags = baseTags.concat(tags ?? []);

  const tagChanged = useCallback(
    (event: unknown, newTag: Tag | null) => {
      onTagChanged && onTagChanged(newTag?.id === allTagsTagId ? null : newTag);
    },
    [onTagChanged]
  );

  const onGoToTag = useCallback(() => {
    navigate(`/tags-and-products/tags/${selectedTag?.name}`);
  }, [navigate, selectedTag]);

  const getOptionLabel = useCallback(
    (r: Tag) => (r.id === allTagsTagId ? t(r.name) : r.name ?? '-'),
    [t]
  );

  const renderInput = useCallback(
    (params: any) => (
      <StyledInput
        {...params}
        hiddenLabel
        fullWidth
        size="small"
        InputProps={{
          ...params.InputProps,
          disableUnderline: true
        }}
      />
    ),
    []
  );

  const renderOption = useCallback(
    (props: HtmlHTMLAttributes<HTMLLIElement>, p: Tag) => (
      <li {...props}>
        <TagNameRow>
          <TagName>{p.id === allTagsTagId ? t(p.name) : p.name ?? '-'}</TagName>
        </TagNameRow>
      </li>
    ),
    [t]
  );

  const getOptionSelected = useCallback(
    (r1: Tag, r2: Tag) => r1.name === r2.name,
    []
  );

  return (
    <Wrapper>
      <Autocomplete
        disabled={disabled}
        fullWidth
        onChange={tagChanged}
        value={selectedTag ?? defaultTag}
        disableClearable={!defaultTag}
        selectOnFocus
        options={extendedTags}
        filterOptions={defaultFilterOptions}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        renderInput={renderInput}
        renderOption={renderOption}
      />
      <Gap size={1} />
      {isAdmin() && (
        <GoToTagButton
          onClick={onGoToTag}
          disabled={!selectedTag}
          title={t('gotoTag')}
        >
          <GoToTagIcon color="inherit" />
        </GoToTagButton>
      )}
    </Wrapper>
  );
}
