import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import 'react-lazy-load-image-component/src/effects/opacity.css';

import {
  DateBox,
  Gap,
  Row,
  Spacer,
  EventGraph,
  BrandSelector,
  useBrandLink,
  Line,
  Tabs
} from 'components';
import {
  Brand,
  openInNewTab,
  RetailerContext,
  RoleContext,
  useSpot,
  useTabFromQuerystring
} from 'framework';

import returnInsightsBlocked from '../../images/returnsUpsell.png';
import returnRepeatReturnersBlocked from '../../images/returnsUpsell2.png';
import { ProductReturns } from './product-returns';
import { RepeatReturners } from './repeat-returners';

const StyledLink = styled.span`
  text-decoration: underline;
`;

const SectionDisabledContainer = styled(Box)`
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
`;

const SectionDisabledImage = styled.img`
  width: 100%;
  position: relative;
`;

const SectionDisabledTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function ReturnInsights() {
  const { t } = useTranslation();
  const { spot, loading } = useSpot();
  useBrandLink();

  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  const { retailer } = useContext(RetailerContext);
  const { isAdmin } = useContext(RoleContext);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const { tab, setTab } = useTabFromQuerystring();

  const [showReturns, setShowReturns] = useState(false);
  const [showRepeatReturners, setShowRepeatReturners] = useState(false);

  useEffect(() => {
    const selectedRetailers = !retailer ? [...spot.data.retailers] : [retailer];
    const shouldShowReturns = selectedRetailers.reduce(
      (anyEnabled, curr) => anyEnabled || !!curr.features?.returns,
      false
    );
    const shouldShowRepeatReturners = selectedRetailers.reduce(
      (anyEnabled, curr) => anyEnabled || !!curr.features?.repeatReturners,
      false
    );

    setShowReturns(shouldShowReturns || isAdmin());
    setShowRepeatReturners(shouldShowRepeatReturners || isAdmin());
  }, [retailer, spot.data.retailers, isAdmin]);

  const handleReturnssUpsell = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Enable Return Insights&body=I'd like to enable Return Insights in my store`
    );
  }, []);

  return (
    <>
      <Row>
        <Box>
          <Typography variant="h4">{t('returnInsights')}</Typography>
        </Box>
        <Spacer />
        {isAdmin() && (
          <BrandSelector
            selectedBrand={selectedBrand}
            includeAllBrands
            onBrandChanged={setSelectedBrand}
            retailer={retailer}
            disabled={loading}
          />
        )}
        <Gap />
        <DateBox
          disabled={loading || !showReturns}
          onEndChange={setEndDate}
          onStartChange={setStartDate}
          defaultRange="twoMonthsAgo"
          allowedRanges={[
            'lastSevenDays',
            'lastThirtyDays',
            'lastMonth',
            'twoMonthsAgo',
            'thisMonth',
            'lastYear',
            'thisYear',
            'custom'
          ]}
        />
      </Row>
      <Gap size={2} />
      <Tabs
        selected={tab}
        onTabChange={setTab}
        newTabs={['repeatReturners']}
        tabs={{
          overview: (
            <>
              {showReturns && (
                <>
                  <Gap size={2} />
                  <EventGraph
                    graphType="returnRate"
                    startDate={startDate}
                    endDate={endDate}
                    retailer={retailer}
                    brand={selectedBrand}
                  />
                  <Gap size={2} />
                  {moment().diff(endDate, 'days') < 30 && (
                    <>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 'bold' }}
                        color="error"
                        align="center"
                      >
                        {t('dataMayStillComeIn')}
                      </Typography>
                      <Gap size={1} />
                    </>
                  )}

                  <Typography
                    variant="subtitle2"
                    color="primary"
                    align="center"
                  >
                    {t('returnInsightsInfo')}
                  </Typography>

                  <Gap size={2} />
                  <Line />
                  <Gap size={2} />
                  {!retailer && (
                    <Typography
                      variant="h5"
                      color="primary"
                      align="center"
                      margin={8}
                    >
                      {t('returnsSelectRetailer')}
                    </Typography>
                  )}
                  <ProductReturns
                    startDate={startDate}
                    endDate={endDate}
                    retailer={retailer}
                    brand={selectedBrand}
                  />
                </>
              )}
              {!showReturns && (
                <SectionDisabledContainer onClick={handleReturnssUpsell}>
                  <SectionDisabledImage src={returnInsightsBlocked} />
                  <SectionDisabledTextContainer>
                    <Typography variant="h4" color="primary">
                      {t('returnInsightsDisabled')}
                    </Typography>
                    <Gap size={2} />
                    <Typography variant="subtitle2" color="primary">
                      <Trans
                        i18nKey="returnInsightsDisabledInfo"
                        components={[<StyledLink key="link" />]}
                      />
                    </Typography>
                  </SectionDisabledTextContainer>
                </SectionDisabledContainer>
              )}
            </>
          ),
          repeatReturners: (
            <>
              {showRepeatReturners && (
                <>
                  <Gap size={2} />
                  <RepeatReturners
                    startDate={startDate}
                    endDate={endDate}
                    retailer={retailer}
                    brand={selectedBrand}
                  />
                </>
              )}
              {!showRepeatReturners && (
                <SectionDisabledContainer onClick={handleReturnssUpsell}>
                  <SectionDisabledImage src={returnRepeatReturnersBlocked} />
                  <SectionDisabledTextContainer>
                    <Typography variant="h4" color="primary">
                      {t('returnInsightsDisabled')}
                    </Typography>
                    <Gap size={2} />
                    <Typography variant="subtitle2" color="primary">
                      <Trans
                        i18nKey="repeatReturnersDisabledInfo"
                        components={[<StyledLink key="link" />]}
                      />
                    </Typography>
                  </SectionDisabledTextContainer>
                </SectionDisabledContainer>
              )}
            </>
          )
        }}
      />
    </>
  );
}
