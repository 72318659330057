import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, IconButton, Typography } from '@mui/material';

import { AssessmentRounded as SizeChartIcon } from '@mui/icons-material';

import { CustomDialog } from './custom-dialog';
import { FormFieldWrapper, FormLabel, FormRow } from '../form-input';
import { Table, TableColumnDescriptor } from '../table';
import {
  Features,
  WidgetConfiguration,
  ItemRow,
  openInNewTab,
  formatStat
} from '../../framework';
import { SizeChartItemEditor } from '../size-chart-item-editor';
import { Gap, Spacer } from '../spacer';
import { Row } from '../layout';

export interface WidgetResponse {
  label?: string;
  measurement?: string;
  min?: number;
  max?: number;
  info?: {
    label: string;
    delta: number;
    measurement: string;
    min: number;
    max: number;
    target: number;
    displayName: string | null;
    aliases: string[] | null;
  }[];
  errorMessage?: string;
}

export interface ProfileMeasurements {
  beforeMultiplier?: {
    chest: number;
    waist: number;
    hips: number;
    innerLeg: number;
  };
  afterMultiplier?: {
    chest: number;
    waist: number;
    hips: number;
    innerLeg: number;
  };
}

export interface WidgetResponseInfoProps {
  title: string;
  brandSlug: string | null;
  widgetResponse: WidgetResponse;
  widgetInfo: { widgetConfig: WidgetConfiguration; features: Features } | null;
  item: ItemRow | null;
  profileMeasurements: ProfileMeasurements | null;
  dialogOpen: boolean;
  onClose?: () => unknown;
}

export const WidgetResponseInfo = (props: WidgetResponseInfoProps) => {
  const {
    widgetResponse,
    title,
    widgetInfo,
    item,
    brandSlug,
    profileMeasurements
  } = props;
  const { t } = useTranslation();

  const onGoToSizeChart = useCallback(() => {
    openInNewTab(
      `${window.location.origin}/size-and-fit/size-charts/${brandSlug}/${item?.id}`
    );
  }, [brandSlug, item]);

  const renderControl = (
    setValue: (val?: WidgetResponse) => void,
    value?: WidgetResponse
  ) => {
    const rows =
      value?.info?.map(v => ({
        id: v.label,
        cells: [v.label, v.measurement, v.delta, v.target, v.min, v.max]
      })) ?? [];

    const headers = [
      t('label'),
      t('measurement'),
      t('delta'),
      t('target'),
      t('min'),
      t('max')
    ];

    const columns = [
      { align: 'center' },
      { align: 'center' },
      { align: 'right' },
      { align: 'right' },
      { align: 'right' },
      { align: 'right' }
    ] as TableColumnDescriptor[];

    const measurementHeaders = [
      t('measurement'),
      t('beforeMultipliers'),
      t('afterMultipliers')
    ];

    const measurementColumns = [
      { align: 'left' },
      { align: 'right' },
      { align: 'right' }
    ] as TableColumnDescriptor[];

    return (
      <>
        <Typography variant="h6">{t('widgetResponseSubtitle')}</Typography>
        <Gap size={2} />
        {value?.errorMessage && (
          <>
            <Alert severity="error">{value?.errorMessage}</Alert>
            <Gap size={2} />
          </>
        )}
        {widgetInfo ? (
          <>
            <FormRow>
              <FormLabel>{t('sizingAlgorithm')}</FormLabel>
              <FormFieldWrapper>
                {widgetInfo?.widgetConfig?.sizingAlgorithm ?? '-'}
              </FormFieldWrapper>
            </FormRow>
            <FormRow>
              <FormLabel>{t('buttonHidden')}</FormLabel>
              <FormFieldWrapper>
                {widgetInfo?.features?.buttonHidden ?? '-'}
              </FormFieldWrapper>
            </FormRow>
            <FormRow>
              <FormLabel>{t('skipLabelDetection')}</FormLabel>
              <FormFieldWrapper>
                {widgetInfo?.features?.skipLabelDetection ?? '-'}
              </FormFieldWrapper>
            </FormRow>
            <FormRow>
              <FormLabel>{t('result')}</FormLabel>
              <FormFieldWrapper>{widgetResponse.label}</FormFieldWrapper>
            </FormRow>
          </>
        ) : (
          <Alert severity="error">{t('widgetInfoError')}</Alert>
        )}
        <Gap size={2} />
        <Table
          rows={rows}
          headers={headers}
          cellPadding={8}
          columns={columns}
        />
        <Gap size={2} />
        <Table
          rows={[
            {
              id: 'chest',
              cells: [
                t('chest'),
                `${formatStat(profileMeasurements?.beforeMultiplier?.chest)} cm`,
                `${formatStat(profileMeasurements?.afterMultiplier?.chest)} cm`
              ]
            },
            {
              id: 'waist',
              cells: [
                t('waist'),
                `${formatStat(profileMeasurements?.beforeMultiplier?.waist)} cm`,
                `${formatStat(profileMeasurements?.afterMultiplier?.waist)} cm`
              ]
            },
            {
              id: 'hips',
              cells: [
                t('hips'),
                `${formatStat(profileMeasurements?.beforeMultiplier?.hips)} cm`,
                `${formatStat(profileMeasurements?.afterMultiplier?.hips)} cm`
              ]
            },
            {
              id: 'innerLeg',
              cells: [
                t('innerLeg'),
                `${formatStat(profileMeasurements?.beforeMultiplier?.innerLeg)} cm`,
                `${formatStat(profileMeasurements?.afterMultiplier?.innerLeg)} cm`
              ]
            }
          ]}
          headers={measurementHeaders}
          cellPadding={8}
          columns={measurementColumns}
        />
        {item && (
          <>
            <Gap size={4} />
            <Row>
              <Typography variant="h6">{t('sizeChart')}</Typography>
              <Spacer />
              {brandSlug && (
                <IconButton
                  onClick={onGoToSizeChart}
                  color="primary"
                  title={t('gotoSizeChart')}
                  size="large"
                >
                  <SizeChartIcon />
                </IconButton>
              )}
            </Row>
            <Gap size={2} />
            <SizeChartItemEditor item={item} disabled />
          </>
        )}
      </>
    );
  };

  return CustomDialog({
    ...props,
    title,
    initialValue: widgetResponse,
    onSubmit: undefined,
    rejectLabel: t('close'),
    maxWidth: 'md',
    renderControl
  });
};
