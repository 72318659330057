import React from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Spacer } from 'components';
import imageNotFound from '../images/error-404.jpg';

export function NotFound() {
  const { t } = useTranslation();
  return (
    <Row>
      <Spacer />
      <img src={imageNotFound} alt={t('notFound')} width={600} />
      <Spacer />
    </Row>
  );
}
