import React from 'react';
import { Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { Brand, Tag } from 'framework';
import { TagSelector } from 'components/tag-selector';
import { Container, Row } from 'components/layout';
import { Gap } from 'components/spacer';
import {
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection
} from 'components/form-input';

import { CustomDialog } from './custom-dialog';

interface Props {
  title: string;
  text: string;
  dialogOpen: boolean;
  initialValue?: string;
  onSubmit?: (tag: Tag | null) => Promise<void>;
  onClose?: () => unknown;
  acceptLabel?: string;
  rejectLabel?: string;
  brand?: string;
}

export function ChangeTagDialog(props: Props) {
  const { text, initialValue, brand } = props;
  const { t } = useTranslation();

  const renderControl = (
    setValue: (val?: Tag | null) => void,
    value?: Tag | null
  ) => {
    return (
      <Container>
        <Typography>{text}</Typography>
        <Gap />
        <Row />
        <Gap />
        <Row>
          <FormSection>
            <FormRow>
              <FormLabel size="narrow">{t('changeTag')}</FormLabel>
              <FormFieldWrapper>
                <TagSelector
                  brand={brand}
                  initialTagName={initialValue}
                  selectedTag={value ?? null}
                  onTagChanged={setValue}
                />
              </FormFieldWrapper>
            </FormRow>
          </FormSection>
        </Row>
      </Container>
    );
  };

  return CustomDialog({
    ...props,
    initialValue: null,
    renderControl
  });
}
