export function analyzeTestResults(
  conversionsA: number,
  totalA: number,
  conversionsB: number,
  totalB: number,
  twoTailed: boolean = false
) {
  const pA = conversionsA / totalA;
  const pB = conversionsB / totalB;
  const percentChange = ((pB - pA) / pA) * 100;

  const pPooled = (conversionsA + conversionsB) / (totalA + totalB);

  const SE = Math.sqrt(pPooled * (1 - pPooled) * (1 / totalA + 1 / totalB));

  const z = (pB - pA) / SE;

  const pValue = twoTailed
    ? 2 * (1 - cumulativeStandardNormalDistribution(Math.abs(z)))
    : 1 - cumulativeStandardNormalDistribution(z);

  const statSignificance =
    (1 - (twoTailed ? pValue : Math.min(pValue, 1 - pValue))) * 100;

  return {
    statSignificance,
    percentChange
  };
}

// Helper function to calculate the cumulative standard normal distribution
function cumulativeStandardNormalDistribution(z: number): number {
  // Constants for the approximation
  const b1 = 0.31938153;
  const b2 = -0.356563782;
  const b3 = 1.781477937;
  const b4 = -1.821255978;
  const b5 = 1.330274429;
  const p = 0.2316419;
  const c = 0.39894228;

  const t = 1.0 / (1.0 + p * Math.abs(z));
  const expPart = Math.exp((-z * z) / 2.0);
  const term = ((((b5 * t + b4) * t + b3) * t + b2) * t + b1) * t;
  const d = c * expPart * term;

  return z >= 0 ? 1 - d : d;
}
