import React, { useCallback, useState } from 'react';
import { Box, Typography, CircularProgress, Popover } from '@mui/material';
import styled from '@emotion/styled';
import {
  HelpOutlineRounded as HelpIcon,
  TrendingUpRounded as TrendingUpIcon,
  TrendingDownRounded as TrendingDownIcon,
  WarningAmberRounded as WarningIcon
} from '@mui/icons-material';

import { Trans, useTranslation } from 'react-i18next';
import { Gap, Spacer } from './spacer';
import upliftUpsell from '../images/upliftUpsell.png';
import { openInNewTab } from '../framework';

const LinkBox = styled.div<{
  active: boolean;
  hasEvent: boolean;
  subtitle: boolean;
}>`
  height: ${p => (p.subtitle ? 224 : 206)}px;
  width: 260px;
  position: relative;
  margin: 16px 16px 8px;
  border-radius: 18px;
  box-shadow: ${p => (p.active ? '4px 4px #dbdbdb' : '2px 2px #f2f2f2')};
  background-color: ${p => (p.active ? p.theme.palette.primary.main : 'unset')};
  pointer-events: ${p => (p.hasEvent ? 'auto' : 'none')};
  cursor: ${p => (p.hasEvent ? 'pointer' : 'auto')};
  border: 1px solid #50aa8d;
  color: ${p => p.theme.palette.primary.main};
`;

const StyledLink = styled.span`
  text-decoration: underline;
`;

const UpsellContainer = styled(Box)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const UpsellTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const UpsellImage = styled.img`
  width: 100%;
  position: relative;
`;

const ButtonLabelContainer = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  color: ${p => (p.active ? 'white' : p.theme.palette.primary.main)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  user-select: none;
  text-align: center;
  padding: ${p => p.theme.spacing(2)};
`;

const IconBox = styled.span<{ active: boolean }>`
  position: absolute;
  top: ${p => p.theme.spacing(2)};
  right: 10px;
  color: ${p => (p.active ? 'white' : p.theme.palette.primary.main)};
  pointer-events: auto;
`;

const LoaderBox = styled(Box)`
  height: 3rem;
  padding: ${p => p.theme.spacing(2)};
`;

const UpliftStatBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatValueRow = styled(Box)`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StatValueTitle = styled(Typography)`
  flex: 0 0 auto;
`;

const StatValueValue = styled(Typography)`
  flex: 0 0 auto;
  font-weight: 600;
`;

const Title = styled(Typography)<{ active: boolean; direction: number }>`
  color: ${p => {
    if (p.active) {
      return 'white';
    }
    return p.direction >= 0 ? p.theme.palette.primary.main : '#D35E44';
  }}   
  font-size: 15px;
  align-self: flex-start;
  text-align: left;
`;

const ButtonStats = styled(Typography)<{ active: boolean; direction: number }>`
  color: ${p => {
    if (p.active) {
      return 'white';
    }
    return p.direction >= 0 ? p.theme.palette.primary.main : '#E8593C';
  }}
  align-self: flex-start;
`;

const ButtonSubtitle = styled(Typography)<{ active: boolean }>`
  color: ${p => (p.active ? 'white' : 'unset')};
  font-size: 0.8em;
  position: absolute;
  bottom: ${p => p.theme.spacing(1)};
  width: 100%;
  text-align: center;
`;
const InfoPopup = styled(Popover)`
  pointer-events: none;
`;

const InfoTextBox = styled(Box)`
  padding: ${p => p.theme.spacing(2)};
  border: 1px solid ${p => p.theme.palette.primary.main};
  border-radius: 5px;
  max-width: 400px;
`;

export function UpliftButton<T>({
  id,
  title,
  upliftValue,
  fasletValue,
  nonFasletValue,
  fasletValueInfo,
  nonFasletValueInfo,
  fasletValueTitle,
  nonFasletValueTitle,
  loading,
  info,
  subtitle,
  direction,
  active,
  onClick,
  upsell,
  dataWarning,
  hideIcon
}: {
  id: T;
  title: string;
  upliftValue: string;
  fasletValue: string;
  nonFasletValue: string;
  fasletValueInfo?: string;
  nonFasletValueInfo?: string;
  fasletValueTitle: string;
  nonFasletValueTitle: string;
  loading?: boolean;
  info?: string;
  subtitle?: string;
  direction?: number;
  active?: boolean;
  onClick?: (id: T) => void;
  upsell?: boolean;
  dataWarning?: boolean;
  hideIcon?: boolean;
}) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleShowGraph = useCallback(() => {
    if (upsell) {
      openInNewTab(
        `mailto:sales@faslet.me?subject=Enable ${fasletValueTitle} Uplift&body=I'd like to enable ${fasletValueTitle} uplift in my store`
      );
      return;
    }

    onClick && onClick(id);
  }, [onClick, fasletValueTitle, upsell, id]);

  return (
    <LinkBox
      active={!!active && !upsell}
      hasEvent={!!onClick || !!upsell}
      onClick={handleShowGraph}
      subtitle={!!subtitle}
    >
      {upsell && (
        <UpsellContainer>
          <UpsellImage src={upliftUpsell} />
          <UpsellTextContainer>
            <Typography variant="h5" color="primary">
              {t('upliftDisabled')}
            </Typography>
            <Gap size={2} />
            <Typography variant="subtitle2" color="primary">
              <Trans
                i18nKey="upliftDisabledInfo"
                components={[<StyledLink key="sales" />]}
              />
            </Typography>
          </UpsellTextContainer>
        </UpsellContainer>
      )}
      {!upsell && (
        <ButtonLabelContainer active={!!active}>
          <Title active={!!active} direction={direction ?? 0}>
            {title}
          </Title>
          <Gap size={2.5} />
          {loading && (
            <LoaderBox>
              <CircularProgress color="inherit" />
            </LoaderBox>
          )}
          {!loading && !dataWarning && (
            <UpliftStatBox>
              {!hideIcon &&
                ((direction ?? 0) >= 0 ? (
                  <TrendingUpIcon fontSize="large" color="success" />
                ) : (
                  <TrendingDownIcon fontSize="large" color="error" />
                ))}
              <Gap size={1} />
              <ButtonStats
                active={!!active}
                direction={direction ?? 0}
                variant="h3"
              >
                {upliftValue}
              </ButtonStats>
            </UpliftStatBox>
          )}
          {!loading && dataWarning && (
            <UpliftStatBox>
              <WarningIcon fontSize="large" color="warning" />
              <Gap size={1} />
              <ButtonStats
                active={!!active}
                direction={direction ?? 0}
                variant="h3"
              >
                {upliftValue}
              </ButtonStats>
            </UpliftStatBox>
          )}
          <Gap size={2.5} />
          <StatValueRow>
            <StatValueTitle>
              {info && (
                <IconBox
                  active={!!active}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <HelpIcon fontSize="small" />
                </IconBox>
              )}
              {fasletValueTitle}
            </StatValueTitle>
            <Gap size={1} />
            <Spacer />
            <StatValueValue>{fasletValue}</StatValueValue>
          </StatValueRow>
          <Gap size={1} />
          <StatValueRow>
            <StatValueTitle>{nonFasletValueTitle}</StatValueTitle>
            <Spacer />
            <StatValueValue>{nonFasletValue}</StatValueValue>
          </StatValueRow>

          {subtitle && (
            <ButtonSubtitle active={!!active}>{subtitle}</ButtonSubtitle>
          )}
        </ButtonLabelContainer>
      )}
      {!upsell && info && (
        <>
          <IconBox
            active={!!active}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <HelpIcon fontSize="small" />
          </IconBox>
          <InfoPopup
            id="mouse-over-popover"
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <InfoTextBox>
              {info}
              <br />
              <br />
              {fasletValueInfo}
              <br />
              <br />
              {nonFasletValueInfo}
            </InfoTextBox>
          </InfoPopup>
        </>
      )}
    </LinkBox>
  );
}
