import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import {
  DeleteForeverRounded as DeleteIcon,
  SaveRounded as SaveIcon,
  AddRounded as AddIcon,
  ArrowBackRounded as BackIcon,
  DragIndicatorRounded as DragIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';

import { Error } from 'spot-store';
import styled from '@emotion/styled';
import {
  BrandSelector,
  CenteredRow,
  ConfirmDeleteAlert,
  Container,
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  Gap,
  RetailerSelector,
  Row,
  Spacer,
  DraggableGridRow,
  useDraggableGridRow
} from 'components';
import {
  Brand,
  ReferenceBrand,
  Retailer,
  RetailerContext,
  useErrorNotification,
  useSpot
} from 'framework';

const ReferenceBrandsContainer = styled(Box)`
  position: relative;
  height: calc(100vh - 280px);
  flex: 1 1 40%;
`;

export interface ReferenceBrandsProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function ReferenceBrands({ onLoadingChanged }: ReferenceBrandsProps) {
  const { t } = useTranslation();
  const { spot, query, command, loading } = useSpot();
  const [referenceBrand, setReferenceBrand] =
    useState<Partial<ReferenceBrand> | null>(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [brandToDeleteId, setBrandToDeleteId] = useState<number | null>(null);
  const { retailer } = useContext(RetailerContext);

  const { displayErrors, notification } = useErrorNotification();

  const [rows, setRows] = useState<ReferenceBrand[]>([]);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const refresh = useCallback(async () => {
    try {
      const params: Record<string, any> = {};
      if (retailer?.slug) {
        params.retailer = retailer.slug;
      }

      await query('brand', {}, ['brands']);
      await query('type', {}, ['types']);
      await query('global-configuration/reference-brands', params, [
        'globalConfig'
      ]);
      setRows(
        spot.data.globalConfig.referenceBrands.map((r, i) => ({
          ...r,
          order: i
        }))
      );
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [query, displayErrors, spot, retailer?.slug]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const saveReferenceBrand = useCallback(
    async (rb: Partial<ReferenceBrand> | null) => {
      if (!rb) {
        return;
      }

      try {
        await command(
          `global-configuration/reference-brands/${rb.id ? rb.id : ''}`,
          {
            retailer: rb.retailer,
            brand: rb.brand,
            type: rb.type
          }
        );
        await refresh();

        if (!rb.id) {
          setShowSidebar(false);
        }
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [command, displayErrors, setShowSidebar, refresh]
  );

  const deleteReferenceBrand = useCallback(
    async (id: number | undefined) => {
      setBrandToDeleteId(null);
      if (!id) {
        return;
      }
      try {
        await command(
          `global-configuration/reference-brands/${id}`,
          {},
          { method: 'DELETE' }
        );
        await refresh();
        setReferenceBrand({
          brand: '',
          type: 'shoe',
          retailer: undefined
        });
        setShowSidebar(false);
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [command, displayErrors, refresh]
  );

  const validate = useCallback((rb: Partial<ReferenceBrand> | null) => {
    if (!rb) {
      return false;
    }

    return !!rb.brand && !!rb.type;
  }, []);

  const createNewReferenceBrand = useCallback(() => {
    setShowSidebar(true);
    setReferenceBrand({
      brand: '',
      type: 'shoe',
      retailer: retailer?.slug
    });
  }, [setShowSidebar, retailer?.slug]);

  const confirmDeleteReferenceBrand = useCallback(
    async id => {
      if (id) {
        setBrandToDeleteId(id);
      }
    },
    [setBrandToDeleteId]
  );

  const rowSelected = useCallback(
    params => {
      setReferenceBrand(params.row as ReferenceBrand);
      setShowSidebar(true);
    },
    [setReferenceBrand, setShowSidebar]
  );

  const onChangeType = useCallback(
    (event: SelectChangeEvent) => {
      setReferenceBrand(existing => ({
        ...existing,
        type: event.target.value
      }));
    },
    [setReferenceBrand]
  );

  const onBack = useCallback(async () => {
    setShowSidebar(false);
  }, [setShowSidebar]);

  const reorderRows = useCallback(
    async (dragIndex, dragTargetIndex) => {
      setRows(oldRows => {
        const newRows = [...oldRows];
        const row = newRows[dragIndex];
        const deleteIndex =
          dragIndex < dragTargetIndex ? dragIndex : dragIndex + 1;
        newRows.splice(dragTargetIndex, 0, row);
        newRows.splice(deleteIndex, 1);
        const modifiedRows = newRows.map((r, i) => ({
          ...r,
          order: i
        }));
        command(`global-configuration/reference-brands/order`, {
          referenceBrands: modifiedRows.map(r => ({ id: r.id, order: r.order }))
        });
        return modifiedRows;
      });
    },
    [setRows, command]
  );

  const { onDragEnd, onDragOver, onDragStart, isDragging } =
    useDraggableGridRow(reorderRows);

  const headers = [
    { field: 'retailer', headerName: t('retailer'), flex: 1 },
    { field: 'brand', headerName: t('brand'), flex: 1 },
    { field: 'type', headerName: t('type') },
    {
      field: 'actions',
      headerName: ' ',
      width: 128,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <CenteredRow>
          <IconButton
            disabled={loading}
            onClick={() => confirmDeleteReferenceBrand(params.row.id)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
          <DragIcon color="action" />
        </CenteredRow>
      )
    }
  ];

  return (
    <>
      <CenteredRow>
        {showSidebar && (
          <IconButton onClick={onBack} size="large">
            <BackIcon />
          </IconButton>
        )}
        <Spacer />
        {showSidebar ? (
          <>
            <Button
              disabled={!referenceBrand?.id}
              variant="contained"
              color="warning"
              onClick={() =>
                referenceBrand?.id &&
                confirmDeleteReferenceBrand(referenceBrand.id)
              }
              startIcon={<DeleteIcon />}
            >
              {t('delete')}
            </Button>
            <Gap />
            <Button
              disabled={showSidebar && !validate(referenceBrand)}
              variant="contained"
              color="primary"
              onClick={() => saveReferenceBrand(referenceBrand)}
              startIcon={<SaveIcon />}
            >
              {referenceBrand?.id ? t('save') : t('create')}
            </Button>
          </>
        ) : (
          <Button
            disabled={showSidebar && !validate(referenceBrand)}
            variant="contained"
            color="primary"
            onClick={createNewReferenceBrand}
            startIcon={<AddIcon />}
          >
            {t('addReferenceBrand')}
          </Button>
        )}
      </CenteredRow>
      <Gap size={1} />
      <Row>
        {showSidebar ? (
          <Container>
            <FormSection>
              <FormSectionTitle>{t('editReferenceBrand')}</FormSectionTitle>
              <FormRow>
                <FormLabel>{t('brand')}</FormLabel>
                <FormFieldWrapper>
                  <BrandSelector
                    includeAllBrands={false}
                    initialBrandSlug={referenceBrand?.brand}
                    selectedBrand={
                      spot.data.brands.find(
                        b => b.slug === referenceBrand?.brand
                      ) ?? null
                    }
                    onBrandChanged={(brand: Brand | null) =>
                      setReferenceBrand(existing => ({
                        ...existing,
                        brand: brand?.slug
                      }))
                    }
                  />
                </FormFieldWrapper>
              </FormRow>
              <FormRow>
                <FormLabel>{t('type')}</FormLabel>
                <FormFieldWrapper>
                  <Select
                    fullWidth
                    value={referenceBrand?.type ?? ''}
                    onChange={onChangeType}
                  >
                    {spot.data.types.map(type => (
                      <MenuItem key={`type_${type.name}`} value={type.name}>
                        {t(type.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormFieldWrapper>
              </FormRow>
              <FormRow>
                <FormLabel>{t('retailer')}</FormLabel>
                <FormFieldWrapper>
                  <RetailerSelector
                    includeAllRetailers
                    initialRetailerSlug={referenceBrand?.retailer}
                    selectedRetailer={
                      spot.data.retailers.find(
                        r => r.slug === referenceBrand?.retailer
                      ) ?? null
                    }
                    onRetailerChanged={(r: Retailer | null) =>
                      setReferenceBrand(existing => ({
                        ...existing,
                        retailer: r?.slug
                      }))
                    }
                  />
                </FormFieldWrapper>
              </FormRow>
              <Gap />
            </FormSection>
          </Container>
        ) : (
          <ReferenceBrandsContainer>
            <DataGrid
              onRowClick={rowSelected}
              columns={headers}
              rows={rows}
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              loading={loading}
              autoPageSize
              components={{
                Row: DraggableGridRow
              }}
              componentsProps={{
                row: {
                  dragStart: onDragStart,
                  dragOver: onDragOver,
                  dragEnd: onDragEnd,
                  clickable: !isDragging
                }
              }}
            />
          </ReferenceBrandsContainer>
        )}
      </Row>
      {brandToDeleteId !== null && (
        <ConfirmDeleteAlert
          id="confirmBrandDelete"
          title={t('deleteBrandConfirmation')}
          text={t('deleteBrandConfirmationText')}
          open={brandToDeleteId !== null}
          onConfirm={deleteReferenceBrand}
          value={brandToDeleteId}
          keepMounted
        />
      )}
      {notification}
    </>
  );
}
