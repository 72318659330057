import React, { useCallback, useContext, useEffect } from 'react';
import { IconButton, Link } from '@mui/material';
import {
  AddRounded as AddIcon,
  LocalOfferRounded as BrandIcon,
  LinkOffRounded as BrokenLinkIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Retailer, RoleContext, useSpot } from 'framework';
import { CenteredRow } from './layout';
import { Gap } from './spacer';

const StyledUnlinkedBrand = styled(CenteredRow)`
  color: ${p => p.theme.palette.primary.main};
`;

export function useBrandLink() {
  const { spot, query } = useSpot();

  function findBrand(brand: string) {
    const lowerValue = brand.toLocaleLowerCase();
    let brandObj = spot.data?.brands?.find(
      v =>
        v.name.toLocaleLowerCase() === lowerValue ||
        v.slug.toLocaleLowerCase() === lowerValue
    );

    if (!brandObj) {
      const alias = spot.data?.aliases?.find(
        a => a.name.toLocaleLowerCase() === lowerValue
      );
      if (alias) {
        brandObj = spot.data?.brands?.find(b => b.id === alias.brandId);
      }
    }
    return brandObj ?? null;
  }

  useEffect(() => {
    (async () => {
      if (!spot.data?.brands) {
        await query('brand/', {}, ['brands']);
      }
      if (!spot.data?.aliases) {
        await query('brand/aliases', {}, ['aliases']);
      }
    })();
  }, [spot, query]);

  return { findBrand };
}

export function BrandLink({
  brand,
  retailer
}: {
  brand: string;
  retailer?: Retailer | null;
}) {
  const { spot, command, query, loading } = useSpot();

  const { isAdmin } = useContext(RoleContext);
  const { t } = useTranslation();

  const createBrand = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      await command(
        'brand/',
        {
          name: brand,
          id: -1,
          slug: '',
          logo: null,
          logoS3: null,
          baseUrl: '/',
          retailers: [],
          aliases: []
        },
        {
          method: 'POST'
        }
      );
      await query('brand/', {}, ['brands']);
    },
    [command, query, brand]
  );

  const lowerValue = brand?.toLocaleLowerCase();
  let brandObj = spot.data?.brands?.find(
    v =>
      v.name.toLocaleLowerCase() === lowerValue ||
      v.slug.toLocaleLowerCase() === lowerValue
  );

  if (!brandObj) {
    const alias = spot.data?.aliases?.find(
      a => a.name.toLocaleLowerCase() === lowerValue
    );
    if (alias) {
      brandObj = spot.data?.brands?.find(b => b.id === alias.brandId);
    }
  }

  if (!isAdmin()) {
    return (
      <StyledUnlinkedBrand>
        <BrandIcon />
        <Gap size={1} />
        {brandObj?.name ?? brand}
      </StyledUnlinkedBrand>
    );
  }

  const hasRetailer = !!(
    retailer && brandObj?.retailers.find(r => r.id === retailer.id)
  );

  return brandObj ? (
    <Link href={`/brand/${brandObj?.slug || lowerValue}`} target="_blank">
      <CenteredRow>
        <BrandIcon />
        <Gap size={1} />
        {brandObj?.name}
        {retailer && !hasRetailer && (
          <IconButton title={t('linkToStore')} disabled>
            <BrokenLinkIcon />
          </IconButton>
        )}
      </CenteredRow>
    </Link>
  ) : (
    <CenteredRow>
      {brand}
      <Gap size={1} />
      <IconButton onClick={createBrand} disabled={loading}>
        <AddIcon />
      </IconButton>
    </CenteredRow>
  );
}
