import { createTheme, Theme as MuiTheme } from '@mui/material/styles';
import { PaletteColorOptions } from '@mui/material/styles/createPalette';

declare module '@emotion/react' {
  interface Theme extends MuiTheme {}
}

declare module '@mui/material/styles' {
  interface Palette {
    header: PaletteColorOptions;
  }

  interface PaletteOptions {
    header: PaletteColorOptions;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    header: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    header: true;
  }
}

const shadow = (elevation: number) =>
  `0px ${elevation}px 5px 0px rgba(0,0,0,0.05)`;

export const theme = createTheme({
  spacing: 8,
  palette: {
    // https://www.canva.com/colors/color-meanings/blue-grotto/
    primary: {
      main: '#50aa8d',
      contrastText: '#ffffff',
      light: '#50aa8d30'
    },
    secondary: {
      light: '#c4c1ac',
      main: '#868474ff',
      contrastText: '#ffffff'
    },
    background: {
      default: '#f7f7f7',
      paper: '#ffffff'
    },
    header: {
      main: '#ffffff',
      contrastText: '#50aa8d'
    }
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(',')
  },
  shadows: [
    'none',
    shadow(1),
    shadow(2),
    shadow(3),
    shadow(4),
    shadow(5),
    shadow(6),
    shadow(7),
    shadow(8),
    shadow(9),
    shadow(10),
    shadow(11),
    shadow(12),
    shadow(13),
    shadow(14),
    shadow(15),
    shadow(16),
    shadow(17),
    shadow(18),
    shadow(19),
    shadow(20),
    shadow(21),
    shadow(22),
    shadow(23),
    shadow(24)
  ],
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiInputLabel: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiSlider: {
      defaultProps: {
        valueLabelDisplay: 'on'
      },
      styleOverrides: {
        valueLabel: {
          lineHeight: 1.2,
          fontSize: 12,
          background: 'unset',
          padding: 0,
          width: 32,
          height: 32,
          borderRadius: '50% 50% 50% 0',
          backgroundColor: '#50aa8d',
          transformOrigin: 'bottom left',
          transform:
            'translate(50%, -24px) rotate(-45deg) scale(0) rotate(45deg)',
          '&:before': { display: 'none' },
          '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -24px) rotate(-45deg) scale(1)'
          },
          '& > *': {
            transform: 'rotate(45deg)'
          }
        }
      }
    }
  }
});
