import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';

export const Capitalized = styled(Typography)`
  text-transform: capitalize;
`;

export const TitleContainer = styled(Box)`
  margin-bottom: ${p => p.theme.spacing(2)};
  margin-top: ${p => p.theme.spacing(4)};
`;

export const Line = styled(Box)`
  margin: ${p => p.theme.spacing(2, 0)};
  border-bottom: 2px solid #50aa8d30;
`;

export const FullWidthContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 240px;
`;

export const LimitedText = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const VerticalContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const DataGridImage = styled.img`
  width: 50px;
  height: 32px;
  object-fit: scale-down;
  border: 0;
`;
