import React, { useEffect } from 'react';

import { useSpot } from 'framework';
import { AuditTrail as SearchableAuditTrail } from 'components';

export interface AuditTrailProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function AuditTrail({ onLoadingChanged }: AuditTrailProps) {
  const { loading } = useSpot();
  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  return <SearchableAuditTrail />;
}
