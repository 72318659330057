import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import {
  DeleteForeverRounded as DeleteIcon,
  DragIndicatorRounded as DragIcon,
  TextFieldsRounded as WordIcon,
  ThirtyFpsRounded as LabelIcon
} from '@mui/icons-material';
import { CenteredRow, FormTextInput, Gap, Spacer } from '../../../components';
import {
  DropZoneBottom,
  DropZoneTop,
  ValueWrapper,
  WordWrapper
} from './common';
import { DictionaryEntryType, ProductGender } from '../../../framework';

const GenderRowContainer = styled(CenteredRow)<{
  dropTop: boolean;
  dropBottom: boolean;
  dragging: boolean;
}>`
  position: relative;
  cursor: pointer;
  :hover {
    background-color: #50aa8d20;
  }
  border-top: ${p => (p.dropTop ? '5px' : '0px')} solid rgba(0, 0, 0, 0.15);
  border-bottom: ${p =>
    p.dropBottom ? '5px solid rgba(0, 0, 0, 0.15)' : '1px solid #50aa8d30'};
`;

export function GenderRow({
  word,
  gender,
  type,
  index,
  updateGenderWord,
  removeGenderWord,
  loading,
  dragStart,
  dragOver,
  dragEnd
}: {
  word: string;
  gender: ProductGender;
  type?: DictionaryEntryType;
  index: number;
  updateGenderWord: (
    i: number,
    s: string,
    gender: ProductGender,
    type?: DictionaryEntryType
  ) => unknown;
  removeGenderWord: (i: number) => unknown;
  loading: boolean;
  dragStart: (currentIndex: number) => unknown;
  dragOver: (targetIndex: number) => unknown;
  dragEnd: () => unknown;
}) {
  const { t } = useTranslation();
  const [internalState, setInternalState] = useState<{
    word: string;
    gender: ProductGender;
    type?: DictionaryEntryType;
  }>({ word, gender, type });
  const timeoutHandle = useRef(-1);
  const [dropTarget, setDropTarget] = useState<'top' | 'bottom' | 'none'>(
    'none'
  );
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(() => {
      updateGenderWord(
        index,
        internalState.word,
        internalState.gender,
        internalState.type
      );
    }, 500);
  }, [internalState, index, timeoutHandle, updateGenderWord]);

  const onUpdateGenderWord = useCallback(
    (value: string) => {
      setInternalState(state => ({ ...state, word: value }));
    },
    [setInternalState]
  );

  const onUpdateGenderGender = useCallback(
    (event: SelectChangeEvent) => {
      setInternalState(state => ({
        ...state,
        gender: event.target.value as ProductGender
      }));
    },
    [setInternalState]
  );

  const onRemoveGenderWord = useCallback(() => {
    removeGenderWord(index);
  }, [index, removeGenderWord]);

  const onDragStart = useCallback(() => {
    dragStart(index);
    setDragging(true);
  }, [dragStart, index]);

  const onDragEnd = useCallback(() => {
    dragEnd();
    setDragging(false);
  }, [dragEnd]);

  const onDragEnterTop = useCallback(() => {
    setDropTarget('top');
    dragOver(index);
  }, [index, setDropTarget, dragOver]);

  const onDragEnterBottom = useCallback(() => {
    setDropTarget('bottom');
    dragOver(index + 1);
  }, [index, setDropTarget, dragOver]);

  const onDragLeave = useCallback(() => {
    setDropTarget('none');
  }, [setDropTarget]);

  const onUpdateType = useCallback((_, newValue: DictionaryEntryType) => {
    setInternalState(state => ({ ...state, type: newValue }));
  }, []);

  return (
    <GenderRowContainer
      draggable="true"
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      dropBottom={dropTarget === 'bottom'}
      dropTop={dropTarget === 'top'}
      dragging={dragging}
    >
      <DropZoneTop
        onDragEnter={onDragEnterTop}
        onDragLeave={onDragLeave}
        hovered={dropTarget === 'top'}
      />
      <DropZoneBottom
        onDragEnter={onDragEnterBottom}
        onDragLeave={onDragLeave}
        hovered={dropTarget === 'bottom'}
      />
      <Box>
        <CenteredRow>
          <ToggleButtonGroup
            exclusive
            value={internalState.type ?? 'title'}
            onChange={onUpdateType}
            size="small"
          >
            <ToggleButton value="title" aria-label="title" title={t('title')}>
              <WordIcon />
            </ToggleButton>
            <ToggleButton value="label" aria-label="label" title={t('label')}>
              <LabelIcon />
            </ToggleButton>
          </ToggleButtonGroup>

          <WordWrapper>
            <FormTextInput
              value={internalState.word}
              onChange={onUpdateGenderWord}
              disabled={loading}
            />
          </WordWrapper>
          <ValueWrapper>
            <Select
              value={internalState.gender}
              onChange={onUpdateGenderGender}
              disabled={loading}
              fullWidth
            >
              <MenuItem value="male">{t('male')}</MenuItem>
              <MenuItem value="female">{t('female')}</MenuItem>
              <MenuItem value="unisex">{t('unisex')}</MenuItem>
            </Select>
          </ValueWrapper>
          <IconButton
            onClick={onRemoveGenderWord}
            disabled={loading}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </CenteredRow>
      </Box>
      <Spacer />
      <DragIcon fontSize="medium" color="action" />
      <Gap />
    </GenderRowContainer>
  );
}
