import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ReorderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  justify-content: center;
`;

export const SearchContainer = styled(Box)`
  flex: 1 1 auto;
`;

export const ScrollableContainer = styled(Box)`
  max-height: calc(
    100vh - 102px /* header & footer */ - 110px /* section header */ - 300px
      /* rest */
  );
  overflow: auto;
`;

export const DropZoneTop = styled(Box)<{ hovered: boolean }>`
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: calc(45% + 5px);
`;

export const DropZoneBottom = styled(Box)<{ hovered: boolean }>`
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: calc(45% + 5px);
`;

export const WordWrapper = styled(Box)`
  padding: ${p => p.theme.spacing(0, 1)};
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0 320px;
    width: 320px;
  }
  display: flex;
  align-items: center;
  position: relative;
`;

export const ValueWrapper = styled(Box)`
  padding: ${p => p.theme.spacing(0, 1)};
  ${p => p.theme.breakpoints.up('sm')} {
    flex: 0 0 320px;
    width: 320px;
  }
  display: flex;
  align-items: center;
  position: relative;
`;
