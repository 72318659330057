import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { useParams } from 'react-router';
import { Box, Grid, Typography, Hidden, Link } from '@mui/material';

import { Container, Gap } from 'components';
import { getApi, Retailer } from 'framework';

const SignupContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${p => p.theme.palette.primary.main};
  padding-bottom: ${p => p.theme.spacing(4)};
`;

const WhiteText = styled(Typography)`
  color: ${p => p.theme.palette.primary.contrastText};
  width: 100%;
`;

const StyledLink = styled(Link)`
  color: ${p => p.theme.palette.primary.contrastText};
  text-decoration: underline;
`;

const StyledCode = styled.pre`
  background-color: ${p => p.theme.palette.background.default};
  color: ${p => p.theme.palette.secondary.main};
  border-radius: 5px;
  padding: ${p => p.theme.spacing(1)};
`;

export function SignupComplete() {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const [retailer, setRetailer] = useState<Retailer | undefined>();

  useEffect(() => {
    window._linkedin_partner_id = '4041905';
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(window._linkedin_partner_id);
    // eslint-disable-next-line func-names
    (function (l) {
      if (!l) {
        // eslint-disable-next-line func-names
        window.lintrk = function (a: unknown, b: unknown) {
          window.lintrk.q.push([a, b]);
        };
        window.lintrk.q = [];
      }
      const s = document.getElementsByTagName('script')[0];
      const b = document.createElement('script');
      b.type = 'text/javascript';
      b.async = true;
      b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
      // eslint-disable-next-line no-unused-expressions
      s?.parentNode?.insertBefore(b, s);
    })(window.lintrk);
  }, []);

  useEffect(() => {
    (async () => {
      const response = await fetch(`${getApi()}/retailer/slug/${slug}`);
      const retailerResponse = (await response.json()) as Retailer;
      setRetailer(retailerResponse);
    })();
  }, [setRetailer, slug]);

  return (
    <SignupContainer>
      <Gap size={4} />
      <Hidden mdDown>
        <WhiteText variant="h3" color="textPrimary" align="center">
          {t('signupCompleteTitle')}
        </WhiteText>
      </Hidden>
      <Hidden mdUp>
        <WhiteText variant="h4" color="textPrimary" align="center">
          {t('signupCompleteTitle')}
        </WhiteText>
      </Hidden>
      <Gap size={4} />
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={6}>
          {retailer && (
            <Container alignItems="center" padding={4}>
              <WhiteText variant="h5">
                {t('signupCompleteSubtitle', { name: retailer.name })}
              </WhiteText>
              <Gap size={4} />
              <Hidden smUp>
                <img alt={retailer.name} src="/woo.gif" width="100%" />
              </Hidden>
              <Hidden smDown>
                <img alt={retailer.name} src="/woo.gif" width="400px" />
              </Hidden>
              <Gap size={4} />
              <WhiteText variant="body1">
                <Trans
                  i18nKey="signupCompleteShopId"
                  values={{ slug: retailer.slug }}
                  components={[<StyledCode key="shop-id" />]}
                />
              </WhiteText>
              <WhiteText variant="body1">
                <Trans
                  i18nKey="signupCompleteInfo"
                  components={[
                    <StyledLink
                      key="docs"
                      href="https://docs.faslet.net/docs/widget/"
                    />
                  ]}
                />
              </WhiteText>
              <Gap size={4} />
            </Container>
          )}
        </Grid>
      </Grid>
    </SignupContainer>
  );
}
