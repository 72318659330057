import React from 'react';
import { TextField, Typography } from '@mui/material';
import { Gap } from 'components/spacer';
import { CustomDialog } from './custom-dialog';

export interface EditTextDialogProps {
  title: string;
  text?: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  initialValue?: string;
  dialogOpen: boolean;
  onSubmit?: (name: string) => Promise<void>;
  onClose?: () => unknown;
}

export const EditTextDialog = (props: EditTextDialogProps) => {
  const renderControl = (setValue: (val?: string) => void, value?: string) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    const { text, label, placeholder, helperText } = props;

    return (
      <>
        {!!text && <Typography>{text}</Typography>}
        {!!text && <Gap />}
        <TextField
          required
          error={!value}
          value={value}
          onChange={handleChange}
          autoFocus
          placeholder={placeholder}
          margin="dense"
          id="edit-text"
          label={label}
          type="text"
          fullWidth
          helperText={!value && helperText}
        />
      </>
    );
  };

  return CustomDialog({ ...props, renderControl });
};
