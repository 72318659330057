import React, { useCallback, useContext } from 'react';
import { Button, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetAppRounded as ExportIcon } from '@mui/icons-material';

import {
  downloadObjectsAsCsv,
  RoleContext,
  SizeChartsUploadInfo,
  useSpot
} from 'framework';
import { Row } from '../layout';
import { Table } from '../table';
import { Spacer } from '../spacer';
import { CustomDialog } from './custom-dialog';

export interface SizeSpecDialogProps {
  title: string;
  sizeSpec: SizeChartsUploadInfo;
  dialogOpen: boolean;
  onClose?: () => unknown;
}

export const SizeSpecDialog = (props: SizeSpecDialogProps) => {
  const { sizeSpec, title } = props;
  const { t } = useTranslation();
  const { spot } = useSpot();

  const { isAdmin } = useContext(RoleContext);

  const handleExportSpec = useCallback(() => {
    const rows = [['Brand', 'Gender', 'Measurement', 'Product', 'Limit']];

    sizeSpec.sizeChartSpecifications.forEach((spec, index) => {
      // Each size spec is a new label, so add label headers
      rows[0] = rows[0].concat([
        `Label${index + 1}`,
        `Value${index + 1}`,
        `MaxValue${index + 1}`
      ]);

      // Each measurement we have is a row
      let currentRow = 1;
      ['waist', 'hips', 'innerLeg', 'chest'].forEach(measurement => {
        const translatedMeasurement =
          measurement === 'innerLeg' ? 'inner-leg' : measurement;
        const value = spec[measurement];

        // Skip a row if the measurement isn't in the doc
        if (!value) {
          return;
        }

        if (!rows[currentRow]) {
          rows[currentRow] = [
            spot.data.brands.find(b => b.slug === sizeSpec.brand)?.name ??
              sizeSpec.brand,
            sizeSpec.gender ?? '',
            translatedMeasurement,
            '',
            ''
          ];
        }

        const maxValue = spec[`${measurement}Stretched`]
          ? spec[`${measurement}Stretched`]
          : '';

        rows[currentRow].push(spec.sizeLabel);
        rows[currentRow].push(value);
        rows[currentRow].push(maxValue);
        currentRow++;
      });
    });

    const filename = `${sizeSpec.brand}-${sizeSpec.productId}-size-export.csv`;
    downloadObjectsAsCsv(rows, filename);
  }, [sizeSpec, spot]);

  const renderControl = (
    setValue: (val?: SizeChartsUploadInfo) => void,
    value?: SizeChartsUploadInfo
  ) => {
    const rows =
      value?.sizeChartSpecifications?.map(v => ({
        id: v.sizeLabel,
        cells: [
          v.sizeLabel,
          `${v?.waist ?? '-'}${
            v?.waistStretched ? ` - ${v?.waistStretched}` : ''
          }`,
          `${v?.chest ?? '-'}${
            v?.chestStretched ? ` - ${v?.chestStretched}` : ''
          }`,
          `${v?.hips ?? '-'}${
            v?.hipsStretched ? ` - ${v?.hipsStretched}` : ''
          }`,
          `${v?.innerLeg ?? '-'}`,
          `${v?.shoulder ?? '-'}`,
          `${v?.sleeve ?? '-'}`,
          `${v?.thigh ?? '-'}`,
          `${v?.length ?? '-'}`,
          <Checkbox key="use-defaults" checked={!!v?.useDefaults} disabled />
        ]
      })) ?? [];

    const headers = [
      t('sizeLabel'),
      t('waist'),
      t('chest'),
      t('hips'),
      t('innerLeg'),
      t('shoulder'),
      t('sleeve'),
      t('thigh'),
      t('length'),
      t('useDefaultCharts')
    ];

    return <Table rows={rows} headers={headers} />;
  };

  return CustomDialog({
    ...props,
    title: (
      <Row>
        {title}
        <Spacer />
        {isAdmin() && (
          <Button
            title={t('exportSizeUploads')}
            variant="contained"
            color="primary"
            onClick={handleExportSpec}
            startIcon={<ExportIcon />}
          >
            {t('export')}
          </Button>
        )}
      </Row>
    ),
    initialValue: sizeSpec,
    onSubmit: undefined,
    rejectLabel: t('close'),
    maxWidth: 'lg',
    renderControl
  });
};
