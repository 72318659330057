import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import {
  ExtendedRetailer,
  useErrorNotification,
  useSpot,
  WidgetConfiguration
} from 'framework';

import { escapeRegExp } from 'components';

import { GridRowModel } from '@mui/x-data-grid';

import { RetailerList, SearchFilters } from './retailer-list';
import { SingleRetailer } from './single-retailer';

export function RetailerEditor() {
  const navigate = useNavigate();
  const { retailer } = useParams<{ retailer?: string }>();
  const { spot, query } = useSpot();
  const [extendedRetailer, setExtendedRetailer] =
    useState<ExtendedRetailer | null>();

  const [widgetConfiguration, setWidgetConfiguration] =
    useState<WidgetConfiguration | null>();

  const [filters, setFilters] = useState<SearchFilters>({});

  const [retailerRows, setRetailerRows] = useState<GridRowModel[]>([]);

  const { displayErrors, notification } = useErrorNotification();

  const searchRetailers = useCallback(async () => {
    const filtersMinusSearch = { ...filters };
    delete filtersMinusSearch.searchTerm;
    await query('retailer/', filtersMinusSearch, ['retailersSearch']);
    let filteredRows = spot.data.retailersSearch ?? [];

    if (filters.searchTerm) {
      const searchRegex = new RegExp(escapeRegExp(filters.searchTerm), 'i');

      filteredRows =
        spot.data?.retailers?.filter((row: GridRowModel) => {
          return searchRegex.test(row.name);
        }) ?? [];
    }

    setRetailerRows(filteredRows);
  }, [query, setRetailerRows, spot, filters]);

  useEffect(() => {
    (async () => {
      await searchRetailers();
    })();
  }, [searchRetailers]);

  // Handle first load stuff
  useEffect(() => {
    (async () => {
      if (!spot.data.brands) {
        await query('brand/', {}, ['brands']);
      }

      if (!spot.data.categories) {
        await query('global-configuration/categories', {}, ['categories']);
      }

      if (spot.data?.retailers?.length === 1) {
        navigate(`/retailer/${spot.data.retailers[0].slug}`);
      }
    })();
  }, [spot, navigate, query]);

  const updateExtendedRetailer = useCallback(
    async (retailerSlug: string) => {
      if (retailerSlug) {
        await query(`retailer/slug/${retailerSlug}`, {}, ['extendedRetailer']);
        setExtendedRetailer(spot.data.extendedRetailer);
        setWidgetConfiguration(spot.data.extendedRetailer.widgetConfiguration);
      }
    },
    [spot, query, setExtendedRetailer, setWidgetConfiguration]
  );

  useEffect(() => {
    (async () => {
      if (!spot.data.brands) {
        await query('brand/', {}, ['brands']);
      }
      if (retailer) {
        await updateExtendedRetailer(retailer);
      } else {
        setExtendedRetailer(null);
        setWidgetConfiguration(null);
      }
    })();
  }, [
    query,
    retailer,
    setExtendedRetailer,
    setWidgetConfiguration,
    spot,
    updateExtendedRetailer
  ]);

  const addRetailer = useCallback(async () => {
    setExtendedRetailer({
      id: -1,
      name: '',
      slug: '',
      default_locale: undefined,
      logo: null,
      logoS3: null,
      brands: [],
      platform: undefined
    });

    setWidgetConfiguration({
      colorPrimary: undefined,
      font: 'Roboto',
      buttonFontSize: 16,
      invertButton: false,
      spacing: '0 0 0 0',
      maleMinWeight: 60,
      maleMaxWeight: 110,
      femaleMinWeight: 45,
      femaleMaxWeight: 100,
      maleMinHeight: 160,
      maleMaxHeight: 200,
      femaleMinHeight: 150,
      femaleMaxHeight: 190,
      extraSteps: {
        bottom: [],
        top: [],
        full: []
      },
      borderRadius: 5,
      fontColor: '#ffffff',
      fasletOutlink: true,
      sizingAlgorithm: 'cheddar'
    });
  }, []);

  const goBack = useCallback(async () => {
    await searchRetailers();
    setExtendedRetailer(null);
    navigate(`/retailer`);
  }, [searchRetailers, setExtendedRetailer, navigate]);

  return (
    <>
      {extendedRetailer ? (
        <SingleRetailer
          displayErrors={displayErrors}
          updateRetailer={setExtendedRetailer}
          selectedRetailer={extendedRetailer}
          widgetConfiguration={widgetConfiguration}
          updateWidgetConfiguration={setWidgetConfiguration}
          onBack={goBack}
        />
      ) : (
        <RetailerList
          retailerRows={retailerRows}
          addRetailer={addRetailer}
          refresh={searchRetailers}
          filters={filters}
          onFiltersChanged={setFilters}
          displayErrors={displayErrors}
        />
      )}
      {notification}
    </>
  );
}
