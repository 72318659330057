import React, { useCallback } from 'react';
import {
  Box,
  IconButton,
  Slider,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { ClearRounded as ResetIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Gap } from 'components';
import { SlackValueMode } from 'framework';

const BaseLineRow = styled(Box)`
  flex-direction: row;
  align-items: baseline;
  display: flex;
`;

const SliderWrapper = styled(Box)`
  flex: 1 1 auto;
`;

const ModeWrapper = styled(Box)`
  align-self: center;
`;

const ModeToggle = styled(ToggleButton)`
  width: 48px;
  text-align: center;
`;

export interface SlackValueSliderProps {
  values: number[];
  mode: SlackValueMode;
  onValuesChanged: (id: string, values: number[]) => unknown;
  onModeChanged: (id: string, mode: SlackValueMode) => unknown;
  onReset: (id: string) => unknown;
  id: string;
  deleteDisabled: boolean;
}

export function SlackValueSlider({
  id,
  values,
  mode,
  onReset,
  onValuesChanged,
  onModeChanged,
  deleteDisabled
}: SlackValueSliderProps) {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event, value) => {
      onValuesChanged(id, value as number[]);
    },
    [id, onValuesChanged]
  );

  const handleReset = useCallback(() => {
    onReset(id);
  }, [id, onReset]);

  const handleModeChange = useCallback(
    (event, value) => {
      onModeChanged(id, value as SlackValueMode);
    },
    [id, onModeChanged]
  );

  return (
    <BaseLineRow>
      <SliderWrapper>
        <Slider
          id={id}
          value={values}
          onChange={handleChange}
          marks={mode === 'absolute'}
          valueLabelDisplay="on"
          step={mode === 'absolute' ? 1 : 0.1}
          min={-9}
          max={+9}
        />
      </SliderWrapper>
      <Gap />
      <ModeWrapper>
        <ToggleButtonGroup value={mode} exclusive onChange={handleModeChange}>
          <ModeToggle value="absolute" title={t('absoluteInfo')}>
            CM
          </ModeToggle>
          <ModeToggle
            value="relative-to-target"
            title={t('relativeToTargetInfo')}
          >
            %
          </ModeToggle>
        </ToggleButtonGroup>
      </ModeWrapper>
      <Gap />
      <IconButton onClick={handleReset} disabled={deleteDisabled} size="large">
        <ResetIcon />
      </IconButton>
    </BaseLineRow>
  );
}
