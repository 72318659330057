import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import styled from '@emotion/styled';

import {
  Brand,
  formatStat,
  RetailerContext,
  RoleContext,
  useSpot,
  WidgetErrorGroup
} from 'framework';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Box,
  Slider
} from '@mui/material';

import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';

import {
  BrandLink,
  useBrandLink,
  RetailerLink,
  Spacer,
  Gap,
  SizeChartLink,
  useSizeChartLink,
  FormRow,
  FormLabel,
  FormFieldWrapper,
  CenteredRow
} from 'components';

import { WidgetErrorsInfo } from './widget-errors-info';
import { DataContainer } from './common';

const SelectWrapper = styled.div`
  flex: 0 0 240px;
`;

const PercentStat = styled.span<{ value?: number }>`
  padding: ${p => p.theme.spacing(2, 1)};
  text-align: right;
  background-color: ${p =>
    p.value
      ? `hsl(${50 - 50 * p.value}deg 100% ${70 + 30 * (1 - p.value)}%)`
      : 'unse1t'};
  width: 100%;
  height: 100%;
`;

export interface WidgetErrorsProps {
  onLoadingChanged: (loading: boolean) => void;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  selectedBrand: Brand | null;
}

export function WidgetErrors({
  onLoadingChanged,
  startDate,
  endDate,
  selectedBrand
}: WidgetErrorsProps) {
  const { t } = useTranslation();
  const { spot, query } = useSpot();
  const { findBrand } = useBrandLink();
  const { findTag } = useSizeChartLink();
  const { isAdmin } = useContext(RoleContext);
  const { retailer } = useContext(RetailerContext);

  const [loading, setLoading] = useState(false);

  const [selectedErrorGroup, setSelectedErrorGroup] =
    useState<WidgetErrorGroup>();

  const [allErrors, setAllErrors] = useState<WidgetErrorGroup[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('all-errors');
  const [threshold, setThreshold] = useState<number>(30);
  const [thresholdInternal, setThresholdInternal] = useState<number>(threshold);
  const timeoutHandle = useRef(-1);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const handleThresholdChange = (event, newValue) => {
    setThresholdInternal(newValue);
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(() => {
      setThreshold(newValue);
    }, 500);
  };

  const getErrors = useCallback(async () => {
    setLoading(true);

    let retailers: string[] = [];
    if (retailer) {
      retailers = [encodeURIComponent(retailer.name), retailer.slug];
    } else if (spot.data?.profile?.role !== 'admin') {
      retailers = spot.data.retailers.reduce(
        (accum, r) => [...accum, encodeURIComponent(r.name), r.slug],
        [] as string[]
      );
    }

    const params = {
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      retailers,
      brands: selectedBrand ? [selectedBrand.name] : [],
      threshold
    } as Record<string, any>;

    if (errorMessage !== 'all-errors') {
      params.errorMessage = errorMessage;
    }

    await query(`analytics/errors`, params, ['widgetErrors']);

    setAllErrors([...spot.data.widgetErrors.errors]);

    setLoading(false);
  }, [
    spot,
    query,
    retailer,
    startDate,
    endDate,
    t,
    errorMessage,
    selectedBrand,
    threshold
  ]);

  useEffect(() => {
    setAllErrors([]);
    startDate && endDate && getErrors();
  }, [getErrors, setAllErrors]);

  const headers: GridColumns = [
    {
      field: 'retailer',
      headerName: t('retailer'),
      renderCell: (params: GridCellParams) => (
        <RetailerLink retailer={params.value as string} />
      ),
      flex: 1
    },
    {
      field: 'brand',
      headerName: t('brand'),
      renderCell: (params: GridCellParams) => (
        <BrandLink brand={params.value as string} retailer={retailer} />
      ),
      flex: 1
    },
    {
      field: 'sizeChart',
      headerName: t('sizeChart'),
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const brand = findBrand(params.row.brand);
        const tag = findTag(params.row.productType, params.row.gender);
        return (
          <SizeChartLink
            isAdmin={isAdmin}
            brand={brand?.name}
            brandSlug={brand?.slug}
            tagGender={tag?.gender}
            tagProductName={tag?.product?.name}
            tagName={tag?.name}
          />
        );
      }
    },
    {
      field: 'badProfileCount',
      headerName: t('badProfileCount'),
      align: 'right',
      width: 150,
      renderCell: (params: GridCellParams) =>
        formatStat(params.row.badProfileCount)
    },
    {
      field: 'goodProfileCount',
      headerName: t('goodProfileCount'),
      align: 'right',
      width: 150,
      renderCell: (params: GridCellParams) =>
        formatStat(params.row.goodProfileCount)
    },
    {
      field: 'errorPercentage',
      headerName: t('errorPercentage'),
      renderCell: (params: GridCellParams) => {
        const amount = params.row.badProfileCount / params.row.goodProfileCount;
        return (
          <PercentStat
            value={amount}
          >{`${formatStat(amount * 100, true, 2)}`}</PercentStat>
        );
      },
      valueGetter: (params: GridCellParams) =>
        params.row.badProfileCount / params.row.goodProfileCount,
      align: 'right',
      width: 150
    }
  ];

  return (
    <>
      <CenteredRow>
        <FormRow>
          <FormLabel>{t('minBadProfiles')}</FormLabel>
          <FormFieldWrapper>
            <Slider
              value={thresholdInternal}
              onChange={handleThresholdChange}
              marks
              valueLabelDisplay="on"
              step={1}
              min={0}
              max={50}
            />
          </FormFieldWrapper>
        </FormRow>
        <Spacer />
        <SelectWrapper>
          <Select
            fullWidth
            onChange={(e: SelectChangeEvent<string>) =>
              setErrorMessage(e.target.value)
            }
            value={errorMessage}
          >
            <MenuItem value="all-errors">{t('all-errors')}</MenuItem>
            <MenuItem value="size-label-mismatch">
              {t('size-label-mismatch')}
            </MenuItem>
            <MenuItem value="no-sizes-for-brand">
              {t('no-sizes-for-brand')}
            </MenuItem>
            <MenuItem value="no-nearest-sizes-found">
              {t('no-nearest-sizes-found')}
            </MenuItem>
            <MenuItem value="retailer-not-found">
              {t('retailer-not-found')}
            </MenuItem>
            <MenuItem value="brand-not-found">{t('brand-not-found')}</MenuItem>
          </Select>
        </SelectWrapper>
      </CenteredRow>
      <Gap />
      <Grid container>
        <Grid xs={selectedErrorGroup ? 7 : 12}>
          <Box
            style={{
              display: 'flex',
              height: '58vh',
              width: '100%',
              position: 'relative'
            }}
          >
            <DataGrid
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              loading={loading}
              rows={allErrors}
              onRowClick={gridRow =>
                setSelectedErrorGroup(gridRow.row as WidgetErrorGroup)
              }
              columns={headers}
              autoPageSize
              getRowId={row =>
                `${row.retailer}-${row.brand}-${row.productType}-${row.gender}`
              }
              columnBuffer={2}
              headerHeight={40}
              rowHeight={52}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'errorPercentage', sort: 'desc' }]
                }
              }}
            />
          </Box>
        </Grid>
        {!!selectedErrorGroup && (
          <Grid xs={5}>
            <DataContainer>
              <WidgetErrorsInfo widgetErrorGroup={selectedErrorGroup} />
            </DataContainer>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default WidgetErrors;
