import React, { ReactNode, useCallback, useState } from 'react';
import { Box, TableRow, TableCell, Collapse } from '@mui/material';
import styled from '@emotion/styled';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@mui/icons-material';

import { StyledTableCell } from './table';

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

const ExpandIcon = styled(Box)`
  position: absolute;
  right: ${p => p.theme.spacing(2)};
  top: calc(50% - 12px);
`;

const ExpandedContentCell = styled(TableCell)<{ open?: boolean }>`
  padding: 0 ${p => p.theme.spacing(2)};

  border-bottom: 1px solid ${p => p.theme.palette.background.default};
  :first-child {
    border-left: 1px solid ${p => p.theme.palette.background.default};
  }
  :last-child {
    border-right: 1px solid ${p => p.theme.palette.background.default};
  }

  border-bottom-width: ${p => (p.open ? 1 : 0)};
`;

type Props = {
  id: string;
  row: ReactNode[];
  collapsedContent: ReactNode;
  initialOpen?: boolean;
  disabled?: boolean;
};

function CollapsableTableRow({
  row,
  collapsedContent,
  id,
  initialOpen,
  disabled
}: Props) {
  const [open, setOpen] = useState(initialOpen);

  const handleRowClick = () => {
    setOpen(!open);
  };

  const renderCell = useCallback(
    (cell: ReactNode, last: boolean) => {
      return (
        <StyledTableCell disabled={disabled}>
          {cell}
          <ExpandIcon>
            {last &&
              (open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
          </ExpandIcon>
        </StyledTableCell>
      );
    },
    [open, disabled]
  );

  return (
    <>
      <StyledTableRow onClick={handleRowClick} hover>
        {row.map((cell, index) => renderCell(cell, index === row.length - 1))}
      </StyledTableRow>
      <TableRow>
        <ExpandedContentCell
          align="justify"
          key={`${id}-cell-collapse`}
          colSpan={row.length}
          open={open}
        >
          <Collapse in={open}>
            <Box my={2}>{collapsedContent}</Box>
          </Collapse>
        </ExpandedContentCell>
      </TableRow>
    </>
  );
}

export { CollapsableTableRow };
