import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  SvgIcon,
  Switch,
  Typography
} from '@mui/material';

import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridOverlay
} from '@mui/x-data-grid';

import { useTranslation } from 'react-i18next';
import {
  GetAppRounded as ExportIcon,
  ExitToAppRounded as PopoutIcon,
  RssFeedRounded as FeedIcon,
  WebRounded as WidgetIcon,
  SaveRounded as SaveIcon,
  PublishRounded as ImportIcon,
  LoopRounded as RefreshIcon,
  NotInterestedRounded as ExcludeIcon,
  EditRounded as EditIcon,
  LinkOffRounded as DeleteIcon,
  CloseRounded as CloseIcon,
  UnarchiveRounded as SizeChartUploadIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import {
  Brand,
  openInNewTab,
  RetailerContext,
  useErrorNotification,
  useSpot,
  ProductInfoListResponse,
  downloadObjectsAsCsv,
  isNotNullOrUndefined,
  Tag,
  RoleContext,
  ProductGender,
  Retailer
} from 'framework';

import {
  Container,
  Row,
  Spacer,
  Gap,
  CenteredRow,
  SearchField,
  SizeChartLink,
  BrandSelector,
  FileInputButton,
  BrandLink,
  useBrandLink,
  ConfirmDeleteAlert,
  ChangeTagDialog,
  RetailerLink
} from 'components';
import { batchItems } from 'helpers';
import { ReactComponent as PredictIcon } from 'images/wand.svg';
import { ReactComponent as AddBrandIcon } from 'images/add_brand.svg';
import { ProductInfo } from './product-info';

const InfoContainer = styled(Container)`
  padding: ${p => p.theme.spacing(2)};
  position: relative;
  height: calc(100vh - 280px);
  overflow-y: auto;
  overflow-x: visible;
  padding: ${p => p.theme.spacing(1)};
`;

const SourceIconContainer = styled.div`
  margin-right: ${p => p.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProductImageWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${p => p.theme.spacing(1)};
  right: ${p => p.theme.spacing(1)};
`;

const SelectWrapper = styled.div`
  flex: 0 0 180px;
`;

const ProductIdContainer = styled(CenteredRow)`
  flex-wrap: nowrap;
`;

const StyleDataGrid = styled(DataGrid)`
  .row-has-changes {
    background-color: ${p => p.theme.palette.primary.light};
  }
`;

const PredictionOverlay = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: #fff;
  padding: ${p => p.theme.spacing(2)};
`;

interface ConfirmDeleteDialogState<T> {
  title: string;
  text: string;
  onConfirm: (value: T | undefined) => unknown;
  value: T;
}

type ProductInfoStatus =
  | 'all'
  | 'untagged'
  | 'tagged'
  | 'untagged-size-upload'
  | 'broken-size-chart'
  | 'unknown-brand';
type ProductSource = 'all' | 'feed' | 'widget' | 'size-chart-api';
type GenderFilter = ProductGender | 'null' | 'allGenders';
type AgeGroupFilter = 'all' | 'adult' | 'kids';

export interface StoreProductListProps {
  onLoadingChanged: (loading: boolean) => unknown;
  selectedBrand: Brand | null;
  retailerBrandsOnly?: boolean;
}

export function StoreProductList({
  onLoadingChanged,
  selectedBrand,
  retailerBrandsOnly
}: StoreProductListProps) {
  const { t } = useTranslation();
  const { query, spot, raw } = useSpot();
  const { displayErrors, displaySuccess, notification } =
    useErrorNotification();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [status, setStatus] = useState<ProductInfoStatus>('all');
  const [gender, setGender] = useState<GenderFilter>('allGenders');
  const [ageGroup, setAgeGroup] = useState<AgeGroupFilter>('all');

  const [products, setProducts] = useState<ProductInfoListResponse[]>([]);
  const [selectedItem, setSelectedItem] = useState<ProductInfoListResponse>();
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] =
    useState<ConfirmDeleteDialogState<string> | null>(null);
  const [editingTag, setEditingTag] = useState<boolean>(false);

  const [source, setSource] = useState<ProductSource>('all');
  const [predictionProgress, setPredictionProgress] = useState<number>(0);
  const [predictionTotal, setPredictionTotal] = useState<number>(0);
  const { retailer } = useContext(RetailerContext);
  const { isAdmin } = useContext(RoleContext);
  useBrandLink();

  const handleStatusChange = useCallback(
    (event: SelectChangeEvent<ProductInfoStatus>) => {
      setStatus(event.target.value as ProductInfoStatus);
    },
    [setStatus]
  );

  const handleGenderChange = useCallback(
    (event: SelectChangeEvent<GenderFilter>) => {
      setGender(event.target.value as GenderFilter);
    },
    [setGender]
  );

  const handleAgeGroupChange = useCallback(
    (event: SelectChangeEvent<AgeGroupFilter>) => {
      setAgeGroup(event.target.value as AgeGroupFilter);
    },
    [setAgeGroup]
  );

  const handleSourceChange = useCallback(
    (event: SelectChangeEvent<ProductSource>) => {
      setSource(event.target.value as ProductSource);
    },
    [setSource]
  );

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const onSelectProduct = useCallback(
    async (product: ProductInfoListResponse) => {
      if (!product.retailerSlug) {
        return;
      }
      setLoading(true);
      setSelectedItem(product);

      try {
        await query(
          `product-info/${encodeURIComponent(product.productIdentifier)}`,
          {
            retailer: product.retailerSlug
          },
          ['productInfo', product.retailerSlug, product.productIdentifier]
        );
      } catch (e) {
        displayErrors(e as Error[]);
      }

      setLoading(false);
    },
    [setSelectedItem, query, displayErrors]
  );

  const refresh = useCallback(
    async (
      selectedBrandLocal: Brand | null,
      retailerLocal: Retailer | null,
      searchTermLocal: string | null,
      statusLocal: ProductInfoStatus,
      genderLocal: GenderFilter,
      ageGroupLocal: AgeGroupFilter,
      sourceLocal?: ProductSource,
      retailerBrandsOnlyLocal?: boolean
    ) => {
      if (selectedBrandLocal || retailerLocal) {
        setLoading(true);
        const params = {
          status: statusLocal,
          retailerBrands: retailerBrandsOnlyLocal
        } as Record<string, unknown>;
        if (selectedBrandLocal) {
          params.brand = selectedBrandLocal.slug;
        }
        if (retailerLocal) {
          params.retailer = retailerLocal.slug;
        }
        if (searchTermLocal?.trim().length) {
          params.search = searchTermLocal.trim();
        }
        if (sourceLocal && sourceLocal !== 'all') {
          params.source = sourceLocal;
        }
        if (genderLocal && genderLocal !== 'allGenders') {
          params.productGender = genderLocal;
        }
        if (ageGroupLocal && ageGroupLocal !== 'all') {
          params.ageGroup = ageGroupLocal;
        }

        try {
          await query(`product-info`, params, ['productInfos']);
        } catch (e) {
          displayErrors(e as Error[]);
        }

        setProducts([...(spot.data.productInfos?.products ?? [])]);

        setLoading(false);
      }
    },
    [query, spot, setProducts, setLoading, displayErrors]
  );

  const refreshCurrent = useCallback(async () => {
    await refresh(
      selectedBrand,
      retailer,
      searchTerm,
      status,
      gender,
      ageGroup,
      source,
      retailerBrandsOnly
    );
  }, [
    refresh,
    selectedBrand,
    retailer,
    searchTerm,
    status,
    gender,
    ageGroup,
    source,
    retailerBrandsOnly
  ]);

  useEffect(() => {
    setSelectedItem(undefined);
    if (retailer?.features?.feedOnlyProductsInProductScreen) {
      setSource('feed');
    }
  }, [retailer]);

  useEffect(() => {
    refreshCurrent();
  }, [refreshCurrent]);

  const handleExportItems = useCallback(async () => {
    if (products && retailer) {
      const rows = products?.map((product: ProductInfoListResponse) => {
        const row = {
          ProductId: product.productIdentifier,
          Retailer: product.retailerSlug,
          Brand: product.brand,
          Tag: product.tagName,
          product: product.tagProductName,
          gender: product.gender ?? product.tagGender,
          variants: product.sizeLabels,
          pageUrl: product.productUrl,
          title: `${product.customLabel0 ? `[${product.customLabel0}] ` : ''}${product.name}`
        } as Record<string, unknown>;

        if (isAdmin()) {
          row.hasSizeChart = product.hasSizeChart;
        }

        return row;
      });

      const filename = `${retailer.slug}_${
        selectedBrand ? `${selectedBrand.slug}_` : ''
      }${searchTerm?.length ? `${searchTerm}_` : ''}${status}.csv`;
      downloadObjectsAsCsv(rows, filename);
    }
  }, [retailer, products, selectedBrand, searchTerm, status, isAdmin]);

  const handleImportProductIds = useCallback(
    async (file: File | string) => {
      if (file) {
        setLoading(true);

        try {
          await raw(`tag-metadata/csv?removeMissingTags=true`, {
            body: file,
            method: 'POST',
            headers: {
              'content-type': 'text/csv'
            }
          });
          await refreshCurrent();
          displaySuccess();
        } catch (e) {
          displayErrors(e as Error[]);
        }

        setLoading(false);
      }
    },
    [refreshCurrent, raw, displayErrors, displaySuccess]
  );

  const hasTagChanged = useCallback(
    (row: ProductInfoListResponse) =>
      row.tagName !==
      spot.data.productInfos.products.find(
        p => p.productIdentifier === row.productIdentifier
      )?.tagName,
    [spot]
  );

  const handleSaveChanges = useCallback(async () => {
    setLoading(true);

    const itemCsv = [['ProductId', 'Tag', 'Retailer', 'Brand'].join(',')]
      .concat(
        products
          .filter(item => hasTagChanged(item))
          .map(item =>
            [
              item.productIdentifier,
              item.tagName,
              item.retailerSlug,
              item.brand
            ].join(',')
          )
      )
      .join('\n');
    await handleImportProductIds(itemCsv);
  }, [products, handleImportProductIds, hasTagChanged]);

  const updateProduct = useCallback(
    product => {
      setProducts(oldProducts => {
        const newProducts = [...oldProducts];
        const index = newProducts.findIndex(
          p => p.productIdentifier === product.productIdentifier
        );
        if (index !== -1) {
          newProducts[index] = product;
        }

        return newProducts;
      });
    },
    [setProducts]
  );

  useEffect(() => {
    setSelectedItem(old => {
      if (!old) {
        return old;
      }

      const product = products.find(
        p => p.productIdentifier === old.productIdentifier
      );
      return product ?? old;
    });
  }, [products]);

  const handleExcludeProducts = useCallback(async () => {
    setProducts(oldProducts => [
      ...oldProducts.map(p => ({
        ...p,
        tagName: 'Faslet_Excluded',
        tagGender: p.tagGender ?? 'unisex',
        tagProductName: 'excluded'
      }))
    ]);
  }, [setProducts]);

  const predictTags = useCallback(async () => {
    setLoading(true);

    const byBrand = products.reduce(
      (
        accum: Record<string, ProductInfoListResponse[]>,
        p: ProductInfoListResponse
      ) => {
        const newAccum = { ...accum };
        if (!newAccum[p.brand ?? 'FASLET_noBrand']) {
          newAccum[p.brand ?? 'FASLET_noBrand'] = [];
        }

        newAccum[p.brand ?? 'FASLET_noBrand'].push(p);

        return newAccum;
      },
      {}
    ) as Record<string, ProductInfoListResponse[]>;

    let results: any[] = [];

    const itemBatches = batchItems(Object.entries(byBrand), 1);

    setPredictionTotal(itemBatches.length);
    setPredictionProgress(0);

    while (itemBatches.length) {
      const nextBatch = itemBatches.shift();

      const promises =
        nextBatch?.map(async ([brand, productsForBrand]) => {
          interface Predictions {
            results: {
              product?: string;
              gender?: 'male' | 'female' | 'unisex';
              labels?: string;
              tag?: string;
              hasSizeChart?: boolean;
              ageGroups?: string;
            }[];
          }
          let predictions: Predictions | undefined;

          try {
            predictions = await raw<Predictions>(
              'product-predictor/batch-predict',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  retailer: retailer?.slug,
                  titles: productsForBrand.map(
                    p =>
                      `${p.customLabel0 ? `[${p.customLabel0}] ` : ''}${p.name}`
                  ),
                  genders: productsForBrand.map(p => p.gender),
                  labels: productsForBrand.map(p => p.sizeLabels?.join(',')),
                  brand: brand === 'FASLET_noBrand' ? undefined : brand,
                  ageGroups: productsForBrand.map(p => p.ageGroup)
                })
              }
            );
          } catch (e) {
            console.error(e);
          }

          const predictedForBrand = productsForBrand.map((ut, index) => ({
            ...ut,
            tagName:
              predictions?.results?.[index]?.tag ??
              (predictions?.results?.[index]?.product === 'excluded'
                ? 'Faslet_Excluded'
                : ut.tagName),
            tagGender: predictions?.results?.[index]?.gender ?? ut.tagGender,
            tagProductName:
              predictions?.results?.[index]?.product ?? ut.tagProductName,
            hasSizeChart:
              predictions?.results?.[index]?.hasSizeChart ?? ut.hasSizeChart
          }));

          return predictedForBrand;
        }) ?? [];

      // eslint-disable-next-line no-await-in-loop
      const newResults = await Promise.all(promises);

      results = results.concat(newResults);

      setPredictionProgress(old => old + 1);
    }

    const flattened = results
      .reduce((accum, curr) => (curr ? [...accum, ...curr] : accum), [])
      .sort(
        (a, b) =>
          a.productIdentifier?.localeCompare(b.productIdentifier ?? '') ?? 0
      );

    setProducts([...flattened]);
    setPredictionProgress(0);
    setLoading(false);
  }, [
    products,
    raw,
    retailer,
    setProducts,
    setPredictionProgress,
    setPredictionTotal
  ]);

  const deleteTags = useCallback(() => {
    setConfirmDelete({
      text: t('deleteSizeConfirmationText'),
      title: t('deleteSizeConfirmation'),
      value: 'all',
      onConfirm: async (value: string | undefined) => {
        setConfirmDelete(null);
        if (!value) {
          return;
        }

        setProducts(oldProducts => [
          ...oldProducts.map(p => ({
            ...p,
            tagName: undefined,
            tagGender: undefined,
            tagProductName: undefined
          }))
        ]);
      }
    });
  }, [setConfirmDelete, setProducts, t]);

  const changeTags = useCallback(() => {
    setEditingTag(true);
  }, [setEditingTag]);

  const cancelTagChange = useCallback(() => {
    setEditingTag(false);
  }, [setEditingTag]);

  const confirmTagChange = useCallback(
    async (tag?: Tag | null) => {
      setProducts(oldProducts => [
        ...oldProducts.map(p => ({
          ...p,
          tagName: tag?.name,
          tagGender: tag?.gender,
          tagProductName: tag?.product?.name
        }))
      ]);
    },
    [setProducts]
  );

  const addMissingBrands = useCallback(async () => {
    setLoading(true);

    const brandCsv = [['brand'].join(',')]
      .concat(
        products
          .map(p => p.brand)
          .filter(isNotNullOrUndefined)
          .filter((brand, index, arr) => arr.indexOf(brand) === index)
          .filter(brand => {
            const lowerValue = brand.toLocaleLowerCase();
            let brandObj = spot.data?.brands?.find(
              v =>
                v.name.toLocaleLowerCase() === lowerValue ||
                v.slug.toLocaleLowerCase() === lowerValue
            );

            if (!brandObj) {
              const alias = spot.data?.aliases?.find(
                a => a.name.toLocaleLowerCase() === lowerValue
              );
              if (alias) {
                brandObj = spot.data?.brands?.find(b => b.id === alias.brandId);
              }
            }
            return !brandObj;
          })
          .map(brand => [brand].join(','))
      )
      .join('\n');

    try {
      await raw(`brand/batch-create`, {
        body: brandCsv,
        method: 'POST',
        headers: {
          'content-type': 'text/csv'
        }
      });
      await query('brand/', {}, ['brands']);
      await query('brand/aliases', {}, ['aliases']);
      await refreshCurrent();
      displaySuccess();
    } catch (e) {
      displayErrors(e as Error[]);
    }

    setLoading(false);

    setLoading(false);
  }, [
    query,
    spot,
    products,
    displayErrors,
    displaySuccess,
    raw,
    refreshCurrent
  ]);

  const headers: GridColumns = [
    {
      field: 'imageLink',
      headerName: t('productImage'),
      width: 80,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <ProductImageWrapper>
            <LazyLoadImage
              src={params.value as string}
              alt={params.row.name as string}
              height="100%"
              effect="opacity"
              decoding="async"
            />
          </ProductImageWrapper>
        ) : null
    },
    {
      field: 'productIdentifier',
      headerName: t('productId'),
      flex: 0.6,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <ProductIdContainer>
          {isAdmin() && params.row.source === 'feed' && (
            <SourceIconContainer title={t('sourceFeed')}>
              <FeedIcon />
            </SourceIconContainer>
          )}
          {isAdmin() && params.row.source === 'widget' && (
            <SourceIconContainer title={t('sourceWidget')}>
              <WidgetIcon />
            </SourceIconContainer>
          )}
          {isAdmin() && params.row.source === 'size-chart-api' && (
            <SourceIconContainer title={t('sourceSizeChartApi')}>
              <SizeChartUploadIcon />
            </SourceIconContainer>
          )}
          <p title={params.value}>{params.value}</p>
        </ProductIdContainer>
      )
    },
    {
      field: 'name',
      headerName: t('title'),
      flex: 1.4,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <p title={params.row.name}>{`${
          params.row.customLabel0 ? `[${params.row.customLabel0}] ` : ''
        }${params.row.name}`}</p>
      )
    },
    {
      field: 'ageGroup',
      headerName: t('ageGroup'),
      width: 100,
      hide: !isAdmin(),
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <p title={params.row.ageGroup}>{t(params.row.ageGroup)}</p>
      )
    },
    {
      field: 'tagName',
      headerName: t('fasletStatus'),
      flex: 0.6,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <>
          <SizeChartLink
            isAdmin={isAdmin}
            brand={params.row.brand}
            brandSlug={params.row.brandSlug}
            tagName={params.row.tagName}
            tagGender={params.row.tagGender}
            tagProductName={params.row.tagProductName}
            hasSizeChart={params.row.hasSizeChart}
            hasChartLabels={params.row.hasChartLabels}
          />
          {hasTagChanged(params.row as ProductInfoListResponse) ? '*' : ''}
        </>
      )
    },
    {
      field: 'retailer',
      headerName: t('retailer'),
      flex: 0.6,
      hide: retailer !== null,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <RetailerLink retailer={params.row.retailer} />
      )
    },
    {
      field: 'brand',
      headerName: t('brand'),
      flex: 0.6,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <BrandLink brand={params.row.brand} retailer={retailer} />
      )
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 64,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <Row>
          <IconButton
            disabled={!params.row.productUrl}
            onClick={() => openInNewTab(`${params.row.productUrl}`)}
            title={
              params.row.productUrl ? `${params.row.productUrl}` : undefined
            }
            size="large"
          >
            <PopoutIcon />
          </IconButton>
        </Row>
      )
    }
  ];

  return (
    <>
      <CenteredRow>
        <IconButton
          color="primary"
          disabled={loading}
          onClick={refreshCurrent}
          title={t('refresh')}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
        {isAdmin() && (
          <IconButton
            color="primary"
            disabled={!products?.length || loading}
            onClick={handleSaveChanges}
            title={t('save')}
            size="large"
          >
            <SaveIcon />
          </IconButton>
        )}
        {isAdmin() && (
          <>
            <IconButton
              disabled={retailer === null || loading}
              color="primary"
              onClick={addMissingBrands}
              title={t('addMissingBrands')}
              size="large"
            >
              <SvgIcon component={AddBrandIcon} />
            </IconButton>
            <IconButton
              color="primary"
              disabled={!products?.length || loading}
              onClick={changeTags}
              title={t('changeTags')}
              size="large"
            >
              <EditIcon />
            </IconButton>
            <IconButton
              disabled={retailer === null || loading}
              color="primary"
              onClick={predictTags}
              title={t('predictTags')}
              size="large"
            >
              <SvgIcon component={PredictIcon} />
            </IconButton>
            <IconButton
              color="primary"
              disabled={!products?.length || loading}
              onClick={handleExcludeProducts}
              title={t('excludeAll')}
              size="large"
            >
              <ExcludeIcon />
            </IconButton>
            <IconButton
              color="primary"
              disabled={!products?.length || loading}
              onClick={deleteTags}
              title={t('deleteTags')}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}

        <Spacer />
        <SelectWrapper>
          <Select value={status} onChange={handleStatusChange} fullWidth>
            <MenuItem value="all">{t('allProducts')}</MenuItem>
            <MenuItem value="untagged">{t('untagged')}</MenuItem>
            <MenuItem value="tagged">{t('tagged')}</MenuItem>
            {isAdmin() && (
              <MenuItem value="untagged-size-upload">
                {t('untaggedWithSizeUpload')}
              </MenuItem>
            )}
            {isAdmin() && (
              <MenuItem value="missing-size-chart">
                {t('missingSizeChart')}
              </MenuItem>
            )}
            {isAdmin() && (
              <MenuItem value="different-labels">
                {t('differentLabels')}
              </MenuItem>
            )}
            {isAdmin() && (
              <MenuItem value="unknown-brand">{t('unknownBrand')}</MenuItem>
            )}
          </Select>
        </SelectWrapper>
        <Gap />
        <SelectWrapper>
          <Select value={gender} onChange={handleGenderChange} fullWidth>
            <MenuItem value="allGenders">{t('allGenders')}</MenuItem>
            <MenuItem value="male">{t('male')}</MenuItem>
            <MenuItem value="female">{t('female')}</MenuItem>
            <MenuItem value="unisex">{t('unisex')}</MenuItem>
            {isAdmin() && (
              <MenuItem value="null">{t('genderMissing')}</MenuItem>
            )}
          </Select>
        </SelectWrapper>
        <Gap />
        <SelectWrapper>
          <Select value={ageGroup} onChange={handleAgeGroupChange} fullWidth>
            <MenuItem value="all">{t('allAgeGroups')}</MenuItem>
            <MenuItem value="adult">{t('adult')}</MenuItem>
            <MenuItem value="kids">{t('kids')}</MenuItem>
          </Select>
        </SelectWrapper>
        <Gap />
        {isAdmin() && (
          <>
            <SelectWrapper>
              <Select value={source} onChange={handleSourceChange} fullWidth>
                <MenuItem value="all">{t('allSources')}</MenuItem>
                <MenuItem value="feed">{t('productFeedLabel')}</MenuItem>
                <MenuItem value="widget">{t('pdpIntegration')}</MenuItem>
                <MenuItem value="size-chart-api">{t('sizeChartApi')}</MenuItem>
              </Select>
            </SelectWrapper>
            <Gap />
            <FileInputButton
              buttonText={t('importProductIds')}
              onFileSelected={handleImportProductIds}
              loading={loading}
              icon={<ImportIcon />}
            />
            <Gap />
          </>
        )}
        <Button
          onClick={handleExportItems}
          variant="contained"
          color="primary"
          disabled={loading}
          startIcon={<ExportIcon />}
        >
          {t('exportResults')}
        </Button>
      </CenteredRow>
      <Gap />
      <Grid container>
        <Grid xs={selectedItem ? 8 : 12}>
          <Row>
            <SearchField onChange={setSearchTerm} loading={loading} />
          </Row>
          <Gap />
          <Box
            style={{
              display: 'flex',
              height: '58vh',
              width: '100%',
              position: 'relative'
            }}
          >
            <StyleDataGrid
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              loading={loading}
              rows={products}
              onRowClick={gridRow =>
                onSelectProduct(gridRow.row as ProductInfoListResponse)
              }
              columns={headers}
              autoPageSize
              getRowId={row => row.productIdentifier}
              components={{
                NoRowsOverlay: retailer ? undefined : CustomNoRowsOverlay
              }}
              getRowClassName={params =>
                `${
                  hasTagChanged(params.row as ProductInfoListResponse)
                    ? 'row-has-changes'
                    : ''
                }`
              }
            />
            {loading && predictionProgress > 0 && (
              <PredictionOverlay>
                <Typography variant="h4">{t('predictingByBrand')}</Typography>
                <Typography variant="h5">
                  {predictionProgress} / {predictionTotal}
                </Typography>
              </PredictionOverlay>
            )}
          </Box>
        </Grid>
        {selectedItem && (
          <Grid xs={4}>
            <InfoContainer>
              <ProductInfo
                key={`${selectedItem.productIdentifier}-${selectedItem.tagName}`}
                product={selectedItem}
                onRefresh={refreshCurrent}
                onUpdateProduct={updateProduct}
              />
              <CloseButton
                onClick={() => {
                  setSelectedItem(undefined);
                }}
              >
                <CloseIcon />
              </CloseButton>
            </InfoContainer>
          </Grid>
        )}
      </Grid>
      {confirmDelete?.onConfirm && (
        <ConfirmDeleteAlert
          id="confirmBrandDelete"
          title={t('deleteTagMetadataConfirmation')}
          text={t('deleteTagMetadatasConfirmationText')}
          open={!!confirmDelete}
          onConfirm={confirmDelete?.onConfirm}
          value={confirmDelete?.value}
          keepMounted
        />
      )}

      {editingTag && (
        <ChangeTagDialog
          dialogOpen={editingTag}
          text={t('editTagsInfo')}
          title={t('editTag')}
          onSubmit={confirmTagChange}
          onClose={cancelTagChange}
          brand={selectedBrand?.name ?? undefined}
        />
      )}
      {notification}
    </>
  );
}

function CustomNoRowsOverlay() {
  const { t } = useTranslation();
  return (
    <GridOverlay>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        <div>{t('chooseRetailerToGetProductList')}</div>
      </Box>
    </GridOverlay>
  );
}
