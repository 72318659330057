import React from 'react';

import {
  HomeRounded as HomeIcon,
  AssessmentRounded as SizeChartSetupIcon,
  UnarchiveRounded as SizeChartUploadIcon,
  StoreRounded as RetailerIcon,
  LocalOfferRounded as BrandIcon,
  AccountBoxRounded as UserIcon,
  HelpOutlineRounded as HelpIcon,
  EmailRounded as ContactIcon,
  LabelRounded as TagIcon,
  LabelOffRounded as LabelOffIcon,
  MoneyOffRounded as MissedSalesIcon,
  TrackChangesRounded as AccuracyIcon,
  PublicRounded as GlobalConfigurationIcon,
  Inventory2Rounded as ProductsIcon,
  CategoryRounded as ProductInsightsIcon,
  SettingsAccessibilityRounded as ProfileInsightsIcon,
  AssignmentReturnRounded as ReturnsIcon
} from '@mui/icons-material';
import { SvgIcon } from '@mui/material';

import { PageDescriptor } from 'framework';
import * as screens from 'screens';

import { ReactComponent as ExperimentsIcon } from '../images/beaker.svg';
import { ReactComponent as IntegrationsIcon } from '../images/app-marketplace.svg';
import { ReactComponent as CouponsIcon } from '../images/coupons.svg';

export const pages: Record<string, PageDescriptor> = {
  notFound: {
    title: 'notfound',
    path: '/not-found',
    icon: HomeIcon,
    screen: screens.NotFound,
    hidden: true,
    authorized: false,
    ignoreContainer: true
  },
  maintenance: {
    title: 'maintenance',
    path: '/maintenance',
    icon: HomeIcon,
    screen: screens.Maintenance,
    hidden: true,
    authorized: false,
    ignoreContainer: true
  },
  userNotFound: {
    title: 'userNotFound',
    path: '/user-not-found',
    icon: HomeIcon,
    screen: screens.UserNotFound,
    hidden: true,
    authorized: false,
    ignoreContainer: true
  },
  signedOut: {
    title: 'signedout',
    path: '/signed-out',
    icon: HomeIcon,
    screen: screens.SignedOut,
    hidden: true,
    authorized: false,
    ignoreContainer: true
  },
  landing: {
    title: 'landing',
    path: '/welcome',
    icon: HomeIcon,
    screen: screens.Landing,
    authorized: true,
    hidden: false,
    groups: ['admin', 'user', 'analytics-admin']
  },
  profileInsights: {
    title: 'profileInsights',
    path: '/profile-insights',
    icon: ProfileInsightsIcon,
    screen: screens.ProfileInsights,
    authorized: true,
    hidden: false,
    newDecoration: false,
    groups: ['admin', 'user']
  },
  missedSales: {
    title: 'missedSalesTitle',
    path: '/missed-sales',
    icon: MissedSalesIcon,
    screen: screens.MissedSales,
    authorized: true,
    hidden: false,
    newDecoration: false,
    groups: ['admin', 'user']
  },
  productInsights: {
    title: 'productInsights',
    path: '/product-insights',
    icon: ProductInsightsIcon,
    screen: screens.ProductInsights,
    authorized: true,
    hidden: false,
    newDecoration: false,
    groups: ['admin', 'user']
  },
  returns: {
    title: 'returns',
    path: '/returns',
    icon: ReturnsIcon,
    screen: screens.ReturnInsights,
    authorized: true,
    hidden: false,
    newDecoration: true,
    groups: ['admin', 'user']
  },
  retailer: {
    title: 'retailers',
    path: '/retailer',
    pathParams: [':retailer?'],
    icon: RetailerIcon,
    screen: screens.RetailerEditor,
    authorized: true,
    groups: ['admin', 'user']
  },
  userAccount: {
    title: 'userAccount',
    path: '/user-account',
    icon: HomeIcon,
    screen: screens.UserAccount,
    authorized: true,
    hidden: true,
    groups: ['admin', 'user']
  },
  experiments: {
    title: 'experiments',
    path: '/experiments',
    pathParams: [':id?'],
    icon: () => <SvgIcon component={ExperimentsIcon} />,
    screen: screens.Experiments,
    authorized: true,
    hidden: false,
    groups: ['admin', 'user']
  },
  brands: {
    title: 'brands',
    path: '/brand',
    pathParams: [':brand?'],
    icon: BrandIcon,
    screen: screens.BrandEditor,
    authorized: true,
    groups: ['admin']
  },
  sizeChartSetup: {
    title: 'sizeAndFit',
    path: '/size-and-fit',
    pathParams: [':tab?', ':brandOrProduct?', ':brandOrItem?'],
    icon: SizeChartSetupIcon,
    screen: screens.SizeAndFit,
    authorized: true,
    groups: ['admin']
  },
  sizeChartUpload: {
    title: 'sizeChartUpload',
    path: '/size-chart-upload',
    icon: SizeChartUploadIcon,
    screen: screens.SizeChartUpload,
    authorized: true,
    groups: ['admin', 'user']
  },
  tag: {
    title: 'tagsAndProducts',
    path: '/tags-and-products',
    pathParams: [':tab?', ':tagOrProduct?'],
    icon: TagIcon,
    screen: screens.TagsAndProducts,
    authorized: true,
    groups: ['admin']
  },
  products: {
    title: 'products',
    path: '/products',
    icon: ProductsIcon,
    screen: screens.ProductsAndDictionaries,
    authorized: true,
    newDecoration: true,
    features: ['productsScreen'],
    groups: ['admin', 'user']
  },
  accuracy: {
    title: 'accuracyTitle',
    path: '/product-accuracy',
    pathParams: [':tab?'],
    icon: AccuracyIcon,
    screen: screens.ProductAccuracy,
    authorized: true,
    hidden: false,
    newDecoration: true,
    groups: ['admin']
  },
  globalConfiguration: {
    title: 'globalConfiguration',
    path: '/global-config',
    icon: GlobalConfigurationIcon,
    screen: screens.GlobalConfiguration,
    authorized: true,
    groups: ['admin'],
    pathParams: [':tab?']
  },
  user: {
    title: 'users',
    path: '/user',
    pathParams: [':email?'],
    icon: UserIcon,
    screen: screens.UserEditor,
    authorized: true,
    groups: ['admin']
  },
  integrations: {
    title: 'integrations',
    path: '/integrations',
    pathParams: [':retailer?', ':integration?'],
    icon: () => <SvgIcon component={IntegrationsIcon} />,
    screen: screens.Integrations,
    authorized: true,
    hidden: false,
    groups: ['admin', 'user']
  },
  coupons: {
    title: 'freeGifts',
    path: '/coupons',
    icon: () => <SvgIcon component={CouponsIcon} />,
    screen: screens.Coupons,
    authorized: true,
    hidden: false,
    newDecoration: true,
    groups: ['admin', 'user']
  },
  docs: {
    title: 'documentation',
    path: 'https://docs.faslet.net/docs/widget/',
    icon: HelpIcon,
    authorized: true
  },
  contact: {
    title: 'contactUs',
    path: 'mailto:support@faslet.me?subject=Faslet Portal Support',
    icon: ContactIcon,
    authorized: true
  },
  signup: {
    title: 'signup',
    path: '/signup',
    icon: HomeIcon,
    screen: screens.Signup,
    hidden: true,
    authorized: false,
    ignoreContainer: true
  },
  signupComplete: {
    title: 'signup-complete',
    path: '/signup-complete',
    pathParams: [':slug'],
    icon: HomeIcon,
    screen: screens.SignupComplete,
    hidden: true,
    authorized: false,
    ignoreContainer: true
  }
};
