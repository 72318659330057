import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import { Trans, useTranslation } from 'react-i18next';
import { ExperimentList } from './experiment-list';
import { SingleExperiment } from './single-experiment';
import { RoleContext, openInNewTab } from '../../framework';

import experimentsBlocked from '../../images/experimentsBlocked.png';
import { Gap } from '../../components';

const StyledLink = styled.span`
  text-decoration: underline;
`;

const SectionDisabledContainer = styled(Box)`
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
`;

const SectionDisabledImage = styled.img`
  width: 100%;
  position: relative;
`;

const SectionDisabledTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export function Experiments() {
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();

  const { isAdmin } = useContext(RoleContext);

  const handleExperimentsClicked = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Faslet Experiments&body=I'd like to find out more about Experiments in my store`
    );
  }, []);

  if (!isAdmin()) {
    return (
      <SectionDisabledContainer onClick={handleExperimentsClicked}>
        <SectionDisabledImage src={experimentsBlocked} />
        <SectionDisabledTextContainer>
          <Typography variant="h4" color="primary">
            {t('experimentsDisabled')}
          </Typography>
          <Gap size={2} />
          <Typography variant="subtitle2" color="primary">
            <Trans
              i18nKey="experimentsDisabledInfo"
              components={[<StyledLink key="link" />]}
            />
          </Typography>
        </SectionDisabledTextContainer>
      </SectionDisabledContainer>
    );
  }

  return id ? (
    <SingleExperiment id={id === 'new' ? undefined : id} />
  ) : (
    <ExperimentList />
  );
}
