import React, { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import {
  DeleteForeverRounded as DeleteIcon,
  MoreHoriz as DotsIcon
} from '@mui/icons-material';
import { SizeChartsUploadItem } from '../../framework';
import { Gap, Row } from '../../components';

interface UploadSizeChartItemProps {
  item: SizeChartsUploadItem;
  index: number;
  onUpdate: (index: number, update: SizeChartsUploadItem) => void;
  onDelete: (index: number) => void;
  canDelete: boolean;
  validation: <T, K extends keyof T>(item: T, propertyName: K) => string | null;
}

export function UploadSizeChartItem({
  item,
  index,
  onUpdate,
  onDelete,
  canDelete,
  validation
}: UploadSizeChartItemProps) {
  const { t } = useTranslation();

  const hasError = <T, K extends keyof T>(obj: T, propertyName: K): boolean => {
    const errorText = validation(obj, propertyName);
    return !!errorText;
  };

  const uploadInputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Row style={{ alignItems: 'baseline' }}>
        <TextField
          id={`size-chart-product-name-${index}`}
          required
          style={{ flex: 2 }}
          label={t('productName')}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            onUpdate(index, { productName: event.target.value });
          }}
          value={item.productName}
          helperText={validation(item, 'productName')}
          error={hasError(item, 'productName')}
        />
        <Gap />
        <TextField
          id={`size-chart-product-url-${index}`}
          required
          style={{ flex: 2 }}
          label={t('productUrl')}
          onChange={(event: React.ChangeEvent<{ value: string }>) => {
            onUpdate(index, { productUrl: event.target.value });
          }}
          value={item.productUrl}
          helperText={validation(item, 'productUrl')}
          error={hasError(item, 'productUrl')}
        />
        <Gap />
        <FormControl
          error={hasError(item, 'gender')}
          style={{ flex: 1 }}
          required
        >
          <InputLabel id="gender-label">{t('gender')}</InputLabel>
          <Select
            label={t('gender')}
            labelId="gender-label"
            id={`size-chart-gender-${index}`}
            value={item.gender ?? 'male'}
            onChange={(event: SelectChangeEvent) => {
              onUpdate(index, { gender: event.target.value });
            }}
          >
            <MenuItem value="male">{t('male')}</MenuItem>
            <MenuItem value="female">{t('female')}</MenuItem>
            <MenuItem value="unisex">{t('unisex')}</MenuItem>
          </Select>
          <FormHelperText>{validation(item, 'gender')}</FormHelperText>
        </FormControl>
        <Gap />
        <TextField
          required
          style={{ flex: 2 }}
          label={t('sizeChartFile')}
          value={item.sizeChartFile}
          helperText={validation(item, 'sizeChartFile')}
          error={hasError(item, 'sizeChartFile')}
          onClick={() => uploadInputRef.current?.click()}
        />
        <label htmlFor={`size-chart-upload-input-${index}`}>
          <Input
            id={`size-chart-upload-input-${index}`}
            type="file"
            ref={uploadInputRef}
            style={{ display: 'none' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onUpdate(index, {
                sizeChartFile:
                  event.target.files && event.target.files.length > 0
                    ? event.target.files[0].name
                    : '',
                sizeChartFileObj:
                  event.target.files && event.target.files.length > 0
                    ? event.target.files[0]
                    : undefined
              });
            }}
          />
          <IconButton
            component="span"
            color="primary"
            className="grid-icon-button"
            size="large"
          >
            <DotsIcon />
          </IconButton>
        </label>
        {canDelete && (
          <>
            <Gap />
            <span>
              <IconButton
                className="grid-icon-button"
                style={{ flex: 0 }}
                onClick={() => onDelete(index)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </span>
          </>
        )}
      </Row>
      <Row>&nbsp;</Row>
    </>
  );
}
