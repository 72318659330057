import * as React from 'react';
import {
  ClearRounded as ClearIcon,
  SearchRounded as SearchIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, TextField, IconButton } from '@mui/material';

const SearchContainer = styled(Box)`
  padding: ${p => p.theme.spacing(1)};
`;

interface QuickSearchToolbarProps {
  clearSearch: () => void;
  onChange: () => void;
  value: string;
}

export function escapeRegExp(value: string): string {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function QuickSearchToolbar({
  clearSearch,
  onChange,
  value
}: QuickSearchToolbarProps) {
  const { t } = useTranslation();

  return (
    <SearchContainer>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        value={value}
        onChange={onChange}
        placeholder={t('searchPlaceholder')}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title={t('clear')}
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )
        }}
      />
    </SearchContainer>
  );
}
