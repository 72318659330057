// TODO: These should all be env vars.
export const getApi = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'http://localhost:8080';
    case 'portal.dev.faslet.net':
    case 'portal-dev.faslet.net':
      return 'https://api.dev.faslet.net';
    case 'portal.prod.faslet.net':
    case 'portal.faslet.net':
    default:
      return 'https://api.prod.faslet.net';
  }
};

export const getCdnPath = () => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'portal.dev.faslet.net':
    case 'portal-dev.faslet.net':
      return 'https://cdn.api.dev.faslet.net';
    case 'portal.prod.faslet.net':
    case 'portal.faslet.net':
    default:
      return 'https://cdn.api.prod.faslet.net';
  }
};

export const getS3Path = () => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'portal.dev.faslet.net':
    case 'portal-dev.faslet.net':
      return 'https://faslet-backend-dev-retailer-storage.s3.eu-west-1.amazonaws.com';
    case 'portal.prod.faslet.net':
    case 'portal.faslet.net':
    default:
      return 'https://faslet-backend-prod-retailer-storage.s3.eu-west-1.amazonaws.com';
  }
};

export const getWidgetCdnPath = () => {
  switch (window.location.hostname) {
    case 'localhost':
    case 'portal.dev.faslet.net':
    case 'portal-dev.faslet.net':
      return 'https://widget.dev.faslet.net';
    case 'portal.prod.faslet.net':
    case 'portal.faslet.net':
    default:
      return 'https://widget.faslet.net';
  }
};
