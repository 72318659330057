import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Gap, Row, Spacer, Table } from 'components';
import { formatStat, Retailer, RoleContext, useSpot } from 'framework';

export interface ExperimentStatsProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  retailer: Retailer | null;
}

export function ExperimentStats({
  startDate,
  endDate,
  retailer
}: ExperimentStatsProps) {
  const { spot, query } = useSpot();
  const { isAdmin } = useContext(RoleContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const executeExperimentStatsQuery = useCallback(async () => {
    const request: {
      startDate: string;
      endDate: string;
      retailers?: string[];
    } = {
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString()
    };

    if (retailer) {
      request.retailers = [encodeURIComponent(retailer.name), retailer.slug];
    } else if (spot.data?.profile?.role !== 'admin') {
      request.retailers = spot.data.retailers.reduce(
        (accum, r) => [...accum, encodeURIComponent(r.name), r.slug],
        [] as string[]
      );
    }

    if (isAdmin() && retailer) {
      setLoading(true);
      await query(`analytics/experiment-stats`, request, [
        'analytics',
        'experimentStats'
      ]);
      setLoading(false);
    }
  }, [retailer, startDate, endDate, spot, query, isAdmin]);

  useEffect(() => {
    executeExperimentStatsQuery();
  }, [executeExperimentStatsQuery]);

  if (!retailer) {
    return <Typography>{t('experimentStatsSelectRetailer')}</Typography>;
  }

  return (
    <>
      <Gap />
      {loading && <Typography>{t('loading')}</Typography>}
      {!loading && (
        <Table
          columns={[{}, {}, { align: 'right' }, { align: 'right' }]}
          rows={
            spot.data.analytics?.experimentStats?.map(e => [
              e.eventName,
              e.experimentVariants,
              formatStat(e.users, false, 0),
              formatStat(e.events, false, 0)
            ]) ?? []
          }
          loading={loading}
          headers={[t('event'), t('variant'), t('users'), t('events')]}
        />
      )}
    </>
  );
}
