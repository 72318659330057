import React from 'react';
import { Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { CustomDialog } from './custom-dialog';
import { BrandSelector } from '../brand-selector';
import { Brand, Product, useSpot } from '../../framework';
import { ProductSelector } from '../product-selector';
import { Line } from '../styled';
import { FormFieldWrapper, FormLabel, FormRow } from '../form-input';
import { Table } from '../table';
import { HorizontalLoadingBar } from '../horizontal-loading-bar';

export const DialogContainer = styled.div``;

export const TableCellWrapper = styled.div`
  padding: ${p => p.theme.spacing(1)};
  text-transform: capitalize;
`;

export interface CopySizeChartInfo {
  brand: Brand | null;
  product: Product | null;
  overwriteExisting: boolean;
}

export interface CopySizeChartsDialogProps {
  targetBrand: Brand;
  dialogOpen: boolean;
  onSubmit?: (copySizeChartInfo: CopySizeChartInfo) => Promise<void>;
  onClose?: () => unknown;
}

export const CopySizeChartsDialog = (props: CopySizeChartsDialogProps) => {
  const { t } = useTranslation();
  const { targetBrand } = props;
  const { query, data, loading } = useSpot();

  const renderControl = (
    setValue: (val?: CopySizeChartInfo) => void,
    value?: CopySizeChartInfo
  ) => {
    const handleBrandChange = async (selectedBrand: Brand | null) => {
      if (!selectedBrand) {
        return;
      }

      await query(`item/?brandId=${selectedBrand.id}`, {}, ['sourceItems']);

      setValue({
        product: value?.product ?? null,
        overwriteExisting: value?.overwriteExisting ?? false,
        brand: selectedBrand
      });
    };

    const handleProductChange = (selectedProduct: Product | null) => {
      setValue({
        brand: value?.brand ?? null,
        overwriteExisting: value?.overwriteExisting ?? false,
        product: selectedProduct
      });
    };

    const handleToggleOverrideConflicts = (e: unknown, checked: boolean) => {
      setValue({
        brand: value?.brand ?? null,
        product: value?.product ?? null,
        overwriteExisting: checked
      });
    };

    const tableHeaders = [
      t('productName'),
      t('gender'),
      t('measurement'),
      t('sizeLabels')
    ];

    const tableRows = value
      ? data.sourceItems
          .filter(
            item => !value.product || item.productName === value.product.name
          )
          .map(item => ({
            id: item.id,
            className: data.items.find(
              original =>
                original.productName === item.productName &&
                original.measurementName === item.measurementName &&
                original.gender === item.gender
            )
              ? 'copy-status-warning'
              : '',
            cells: [
              <TableCellWrapper key="productName">
                {item.productName}
              </TableCellWrapper>,
              <TableCellWrapper key="gender">{item.gender}</TableCellWrapper>,
              <TableCellWrapper key="measurementName">
                {item.measurementName}
              </TableCellWrapper>,
              item.sizes.map(size => size.label).join(', ')
            ]
          }))
      : [];

    return (
      <DialogContainer>
        <Typography>{t('copySizeChartsInfo')}</Typography>
        <Line />
        <FormRow>
          <FormLabel>{t('targetBrand')}</FormLabel>
          <FormFieldWrapper>
            <BrandSelector selectedBrand={targetBrand} />
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel>{t('sourceBrand')}</FormLabel>
          <FormFieldWrapper>
            <BrandSelector
              onBrandChanged={handleBrandChange}
              selectedBrand={value?.brand ?? null}
              initialBrandSlug="faslet-defaults"
            />
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel>{t('sourceProduct')}</FormLabel>
          <FormFieldWrapper>
            <ProductSelector
              includeAllProducts
              onProductChanged={handleProductChange}
              selectedProduct={value?.product ?? null}
            />
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel>{t('overrideConflicts')}</FormLabel>
          <FormFieldWrapper>
            <Switch
              color="primary"
              onChange={handleToggleOverrideConflicts}
              checked={value?.overwriteExisting}
            />
          </FormFieldWrapper>
        </FormRow>
        <HorizontalLoadingBar loading={loading} />
        <Table rows={tableRows} headers={tableHeaders} />
      </DialogContainer>
    );
  };

  return CustomDialog({
    ...props,
    title: t('copySizeChartsTitle'),
    maxWidth: 'md',
    acceptLabel: t('copySizeCharts'),
    renderControl
  });
};
