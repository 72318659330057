import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import {
  DeleteForeverRounded as DeleteIcon,
  SaveRounded as SaveIcon,
  AddRounded as AddIcon,
  ArrowBackRounded as BackIcon,
  DragIndicatorRounded as DragIcon
} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@mui/material';

import { Error } from 'spot-store';
import styled from '@emotion/styled';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  Brand,
  Product,
  ReferenceModel,
  useErrorNotification,
  useSpot
} from '../../framework';
import {
  BrandSelector,
  CenteredRow,
  ConfirmDeleteAlert,
  Container,
  FileInputButton,
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  FormTextInput,
  Gap,
  ProductSelector,
  Row,
  Spacer
} from '../../components';
import {
  DraggableGridRow,
  useDraggableGridRow
} from '../../components/draggable-grid-row';

const ProductImageWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
`;

const ReferenceModelsContainer = styled(Box)`
  position: relative;
  height: calc(100vh - 280px);
  flex: 1 1 40%;
`;

export interface ReferenceModelsProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function ReferenceModels({ onLoadingChanged }: ReferenceModelsProps) {
  const { t } = useTranslation();
  const { spot, query, command, raw, loading } = useSpot();
  const [referenceModel, setReferenceModel] =
    useState<Partial<ReferenceModel> | null>(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [modelToDeleteId, setModelToDeleteId] = useState<number | null>(null);
  const [brand, setBrand] = useState<Brand | null>(null);
  const localFileNameRef = useRef('');
  const [rows, setRows] = useState<ReferenceModel[]>([]);

  const { displayErrors, notification } = useErrorNotification();

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const refresh = useCallback(async () => {
    try {
      const params: Record<string, any> = {};
      if (brand?.slug) {
        params.brand = brand.slug;
      }

      await query('brand', {}, ['brands']);
      await query('product', {}, ['products']);
      await query('global-configuration/reference-models', params, [
        'globalConfig'
      ]);
      setRows(
        spot.data.globalConfig.referenceModels.map((r, i) => ({
          ...r,
          order: i
        }))
      );
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [query, displayErrors, spot, brand?.slug]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const saveReferenceModel = useCallback(
    async (rm: Partial<ReferenceModel> | null) => {
      if (!rm) {
        return;
      }

      try {
        await command(
          `global-configuration/reference-models/${rm.id ? rm.id : ''}`,
          {
            name: rm.name,
            brand: rm.brand,
            imageUrl: rm.imageUrl,
            product: rm.product
          }
        );
        await refresh();

        if (!rm.id) {
          setShowSidebar(false);
        }
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [command, displayErrors, setShowSidebar, refresh]
  );

  const deleteReferenceModel = useCallback(
    async (id: number | undefined) => {
      setModelToDeleteId(null);
      if (!id) {
        return;
      }
      try {
        await command(
          `global-configuration/reference-models/${id}`,
          {},
          { method: 'DELETE' }
        );
        await refresh();
        setReferenceModel({
          name: '',
          brand: undefined,
          product: 'shoe1',
          imageUrl: ''
        });
        setShowSidebar(false);
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [command, displayErrors, refresh]
  );

  const validate = useCallback((rm: Partial<ReferenceModel> | null) => {
    if (!rm) {
      return false;
    }

    return !!rm.name && !!rm.brand && !!rm.product;
  }, []);

  const createNewreferenceModel = useCallback(() => {
    setShowSidebar(true);
    setReferenceModel({
      name: '',
      product: 'shoe1',
      imageUrl: '',
      brand: brand?.slug
    });
  }, [setShowSidebar, brand?.slug]);

  const confirmDeleteReferenceModel = useCallback(
    async id => {
      if (id) {
        setModelToDeleteId(id);
      }
    },
    [setModelToDeleteId]
  );

  const rowSelected = useCallback(
    params => {
      setReferenceModel(params.row as ReferenceModel);
      setShowSidebar(true);
    },
    [setReferenceModel, setShowSidebar]
  );

  const onBack = useCallback(async () => {
    setShowSidebar(false);
  }, [setShowSidebar]);

  const handleImageUpload = useCallback(
    async (brandSlug: string, productName: string, file: File) => {
      if (brandSlug) {
        const formData = new FormData();
        formData.append('image', file);

        try {
          const urlResult = await raw<{ url: string; transformedFile: File }>(
            `global-configuration/reference-models/${brandSlug}/upload-image/${productName}`,
            {
              method: 'POST',
              body: formData
            }
          );
          const url = urlResult?.url;

          if (url) {
            const imageUrl = new URL(url);
            const imageUrlClean = `${imageUrl.origin}${imageUrl.pathname}`;

            setReferenceModel(existing => ({
              ...existing,
              imageUrl: `${imageUrlClean}`
            }));

            localFileNameRef.current = new Date().toISOString();
          }
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    },
    [raw, setReferenceModel, displayErrors]
  );

  const reorderRows = useCallback(
    async (dragIndex, dragTargetIndex) => {
      setRows(oldRows => {
        const newRows = [...oldRows];
        const row = newRows[dragIndex];
        const deleteIndex =
          dragIndex < dragTargetIndex ? dragIndex : dragIndex + 1;
        newRows.splice(dragTargetIndex, 0, row);
        newRows.splice(deleteIndex, 1);
        const modifiedRows = newRows.map((r, i) => ({
          ...r,
          order: i
        }));
        command(`global-configuration/reference-models/order`, {
          referenceModels: modifiedRows.map(r => ({ id: r.id, order: r.order }))
        });
        return modifiedRows;
      });
    },
    [setRows, command]
  );

  const { onDragEnd, onDragOver, onDragStart, isDragging } =
    useDraggableGridRow(reorderRows);

  const headers = [
    {
      field: 'imageUrl',
      headerName: t('image'),
      width: 80,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <ProductImageWrapper>
            <LazyLoadImage
              src={params.value as string}
              alt={params.row.name as string}
              height="100%"
              effect="opacity"
              decoding="async"
            />
          </ProductImageWrapper>
        ) : null
    },
    { field: 'name', headerName: t('name'), flex: 1 },
    { field: 'brand', headerName: t('brand'), flex: 1 },
    { field: 'product', headerName: t('product') },
    {
      field: 'actions',
      headerName: ' ',
      width: 128,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <CenteredRow>
          <IconButton
            disabled={loading}
            onClick={() => confirmDeleteReferenceModel(params.row.id)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
          <DragIcon color="action" />
        </CenteredRow>
      )
    }
  ];

  return (
    <>
      <CenteredRow>
        {showSidebar && (
          <IconButton onClick={onBack} size="large">
            <BackIcon />
          </IconButton>
        )}
        <Spacer />
        {showSidebar ? (
          <>
            <Button
              disabled={!referenceModel?.id}
              variant="contained"
              color="warning"
              onClick={() =>
                referenceModel?.id &&
                confirmDeleteReferenceModel(referenceModel.id)
              }
              startIcon={<DeleteIcon />}
            >
              {t('delete')}
            </Button>
            <Gap />
            <Button
              disabled={showSidebar && !validate(referenceModel)}
              variant="contained"
              color="primary"
              onClick={() => saveReferenceModel(referenceModel)}
              startIcon={<SaveIcon />}
            >
              {referenceModel?.id ? t('save') : t('create')}
            </Button>
          </>
        ) : (
          <>
            <BrandSelector
              includeAllBrands
              selectedBrand={brand}
              onBrandChanged={setBrand}
            />
            <Gap />
            <Button
              disabled={showSidebar && !validate(referenceModel)}
              variant="contained"
              color="primary"
              onClick={createNewreferenceModel}
              startIcon={<AddIcon />}
            >
              {t('addReferenceModel')}
            </Button>
          </>
        )}
      </CenteredRow>
      <Gap size={1} />
      <Row>
        {showSidebar ? (
          <Container>
            <FormSection>
              <FormSectionTitle>{t('editReferenceModel')}</FormSectionTitle>
              <FormRow>
                <FormLabel>{t('name')}</FormLabel>
                <FormFieldWrapper>
                  <FormTextInput
                    value={referenceModel?.name}
                    onChange={(newValue: string) =>
                      setReferenceModel(existing => ({
                        ...existing,
                        name: newValue
                      }))
                    }
                  />
                </FormFieldWrapper>
              </FormRow>
              <FormRow>
                <FormLabel>{t('product')}</FormLabel>
                <FormFieldWrapper>
                  <ProductSelector
                    onProductChanged={(product: Product | null) =>
                      setReferenceModel(existing => ({
                        ...existing,
                        product: product?.name
                      }))
                    }
                    selectedProduct={
                      spot.data.products?.find(
                        p => p.name === referenceModel?.product
                      ) ?? null
                    }
                    initialProductName={referenceModel?.product}
                    includeAllProducts={false}
                  />
                </FormFieldWrapper>
              </FormRow>
              <FormRow>
                <FormLabel>{t('brand')}</FormLabel>
                <FormFieldWrapper>
                  <BrandSelector
                    includeAllBrands={false}
                    initialBrandSlug={referenceModel?.brand}
                    selectedBrand={
                      spot.data.brands.find(
                        b => b.slug === referenceModel?.brand
                      ) ?? null
                    }
                    onBrandChanged={(b: Brand | null) =>
                      setReferenceModel(existing => ({
                        ...existing,
                        brand: b?.slug
                      }))
                    }
                  />
                </FormFieldWrapper>
              </FormRow>
              <Gap />
              <FormRow>
                <Box>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <Paper variant="outlined" style={{ fontSize: 0 }}>
                        {referenceModel?.imageUrl ? (
                          <img
                            width={200}
                            height={200}
                            key={localFileNameRef.current}
                            src={referenceModel?.imageUrl ?? ''}
                            alt={t('brandLogo')}
                            style={{ objectFit: 'scale-down' }}
                          />
                        ) : (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            style={{ width: 200, height: 200 }}
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="overline"
                                align="center"
                                display="block"
                              >
                                {t('noLogo')}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Box display="flex">
                        <Box p={1}>
                          <FileInputButton
                            disabled={
                              !referenceModel?.brand || !referenceModel?.product
                            }
                            buttonText={t('upload')}
                            onFileSelected={file =>
                              referenceModel?.brand &&
                              referenceModel?.product &&
                              handleImageUpload(
                                referenceModel.brand,
                                referenceModel.product,
                                file
                              )
                            }
                          />
                        </Box>
                        <Box
                          p={1}
                          display={referenceModel?.imageUrl ? 'block' : 'none'}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              setReferenceModel(existing => ({
                                ...existing,
                                imageUrl: undefined
                              }))
                            }
                          >
                            {t('delete')}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </FormRow>
            </FormSection>
          </Container>
        ) : (
          <ReferenceModelsContainer>
            <DataGrid
              onRowClick={rowSelected}
              columns={headers}
              rows={rows}
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              loading={loading}
              autoPageSize
              components={{
                Row: DraggableGridRow
              }}
              componentsProps={{
                row: {
                  dragStart: onDragStart,
                  dragOver: onDragOver,
                  dragEnd: onDragEnd,
                  clickable: !isDragging
                }
              }}
            />
          </ReferenceModelsContainer>
        )}
      </Row>
      {modelToDeleteId !== null && (
        <ConfirmDeleteAlert
          id="confirmBrandDelete"
          title={t('deleteBrandConfirmation')}
          text={t('deleteBrandConfirmationText')}
          open={modelToDeleteId !== null}
          onConfirm={deleteReferenceModel}
          value={modelToDeleteId}
          keepMounted
        />
      )}
      {notification}
    </>
  );
}
