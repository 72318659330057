import React from 'react';
import styled from '@emotion/styled';

interface Props {
  width: number;
  height: number;
  url: string;
  alt?: string;
}

const Image = styled.div`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
`;

export function CroppedImage({ width, height, url, alt }: Props) {
  return (
    <Image
      style={{
        backgroundImage: `url(${url})`,
        width,
        height
      }}
      title={alt}
    />
  );
}
