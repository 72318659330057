import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { useSpot } from 'framework';
import { StepConfiguration as StepConfigurationComponent } from 'components';

const ConfigContainer = styled(Box)`
  position: relative;
  height: calc(100vh - 280px);
`;

export interface StepConfigurationProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function StepConfiguration({
  onLoadingChanged
}: StepConfigurationProps) {
  const { loading } = useSpot();
  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  return (
    <ConfigContainer>
      <StepConfigurationComponent />
    </ConfigContainer>
  );
}
