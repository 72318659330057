import React, { ReactNode, useRef, MutableRefObject, useCallback } from 'react';
import {
  Table as MaterialTable,
  TableHead as MaterialTableHead,
  TableBody as MaterialTableBody,
  TableRow as MaterialTableRow,
  TableCell as MaterialTableCell,
  Typography,
  Box
} from '@mui/material';
import styled from '@emotion/styled';
import { HorizontalLoadingBar } from '../horizontal-loading-bar';

const StyledTable = styled(MaterialTable)`
  border-collapse: collapse;
`;

export const StyledHeader = styled(Typography)`
  margin: 8px 0;
  height: 24px;
  color: ${p => p.theme.palette.text.secondary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
`;

export const StyledTableHeader = styled(MaterialTableCell)<{
  mincolwidth?: number;
}>`
  min-width: ${p => (p.mincolwidth ? `${p.mincolwidth}px` : '')};
  padding: ${p => p.theme.spacing(0, 2)};
  border-bottom: 1px solid ${p => p.theme.palette.background.default};
`;

export const StyledTableRow = styled(MaterialTableRow)<{ clickable?: string }>`
  cursor: ${p => (p.clickable === 'true' ? 'pointer' : 'unset')};
`;

export const StyledTableCell = styled(MaterialTableCell)<{
  disabled?: boolean;
  selected?: boolean;
  cellPadding?: number;
}>`
  padding: ${p => p.cellPadding ?? 4}px;
  background-color: ${p => (p.selected ? '#50aa8d23' : 'unset')};

  color: ${p =>
    p.disabled ? p.theme.palette.text.disabled : p.theme.palette.text.primary};

  border-bottom: 1px solid ${p => p.theme.palette.background.default};
  :first-child {
    border-left: 1px solid ${p => p.theme.palette.background.default};
  }
  :last-child {
    border-right: 1px solid ${p => p.theme.palette.background.default};
  }

  position: relative;
`;

const Container = styled(Box)<{
  maxHeight?: number | string;
  ref?: MutableRefObject<HTMLElement | undefined>;
}>`
  overflow: auto;
  max-height: ${p => p.maxHeight};
`;

type TableCell = ReactNode;
type TableHeaderCell = string | HeaderCell;
export type TableRow = string[] | RowDescriptor;

interface HeaderCell {
  id: string;
  content: ReactNode;
}

export interface RowDescriptor {
  id: string;
  cells: TableCell[];
  disabled?: boolean;
  selected?: boolean;
  className?: string;
}

export interface TableColumnDescriptor {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

interface Props<T> {
  columns?: TableColumnDescriptor[];
  headers?: TableHeaderCell[];
  rows: (TableRow | T)[];
  renderCustomRow?: (row: T) => ReactNode;
  renderEmptyContent?: () => ReactNode;
  maxHeight?: number | string;
  minColWidth?: number;
  onScrollToEnd?: () => unknown;
  onRowClick?: (row: RowDescriptor) => unknown;
  loading?: boolean;
  cellPadding?: number;
}

export function Table<T>({
  columns,
  headers,
  rows,
  renderCustomRow,
  renderEmptyContent,
  maxHeight,
  minColWidth,
  onScrollToEnd,
  onRowClick,
  loading,
  cellPadding
}: Props<T>) {
  const scrollContainer = useRef<HTMLElement>();
  const scrollDelta = 5;

  const onScroll = async () => {
    if (
      onScrollToEnd &&
      !!scrollContainer.current &&
      scrollContainer.current.offsetHeight +
        scrollContainer.current.scrollTop +
        scrollDelta >=
        scrollContainer.current.scrollHeight
    ) {
      onScrollToEnd();
    }
  };

  const getAlignment = useCallback(
    (index: number) => {
      if (columns && columns[index]) {
        return columns[index].align;
      }
      return 'left';
    },
    [columns]
  );

  const renderHeader = (header: TableHeaderCell, index: number) => {
    if (typeof header === 'string') {
      return (
        <StyledTableHeader
          key={header}
          mincolwidth={minColWidth}
          align={getAlignment(index)}
        >
          <StyledHeader>{header}</StyledHeader>
        </StyledTableHeader>
      );
    }

    const headerObject = header as HeaderCell;
    return (
      <StyledTableHeader
        key={headerObject.id}
        mincolwidth={minColWidth}
        align={getAlignment(index)}
      >
        {headerObject.content}
      </StyledTableHeader>
    );
  };

  const renderRow = useCallback(
    (row: TableRow | T) => {
      const columnKeys = headers?.map((header: TableHeaderCell) => {
        if (typeof header === 'string') return header;

        const headerObject = header as HeaderCell;
        return headerObject.id;
      }) || ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

      const rowObject = row as RowDescriptor;

      if (!rowObject.id && !rowObject.cells) {
        const rowArray = row as string[];

        const isArray = Array.isArray(rowObject);
        const containsStrings =
          isArray && rowArray.every(entry => typeof entry === 'string');

        if (!containsStrings) {
          throw Error(
            'A row should either be an array of string or a Row descriptor object!!'
          );
        }

        if (onRowClick) {
          throw Error(
            'onRowClick was provided with a string[] row type, this only applies to Row type with an ID!!'
          );
        }

        return (
          <StyledTableRow key={btoa(JSON.stringify(rowArray))}>
            {rowArray.map((rowCell: string, index: number) => (
              <StyledTableCell
                key={`${columnKeys[index]}`}
                disabled={false}
                align={getAlignment(index)}
                cellPadding={cellPadding}
              >
                {rowCell}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        );
      }

      return (
        <StyledTableRow
          key={rowObject.id}
          hover={!!onRowClick}
          clickable={`${!!onRowClick}`}
          onClick={() => onRowClick && onRowClick(rowObject)}
          className={rowObject.className}
        >
          {rowObject.cells.map((rowCell: ReactNode, index: number) => (
            <StyledTableCell
              key={`${columnKeys[index]}`}
              disabled={rowObject.disabled}
              selected={rowObject.selected}
              align={getAlignment(index)}
              cellPadding={cellPadding}
            >
              {rowCell}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      );
    },
    [cellPadding, headers, getAlignment, onRowClick]
  );

  const onRenderEmpty = () => (
    <StyledTableRow key="empty">
      <StyledTableCell colSpan={headers?.length ?? 1} cellPadding={cellPadding}>
        {renderEmptyContent ? renderEmptyContent() : 'No items'}
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <Container maxHeight={maxHeight} ref={scrollContainer} onScroll={onScroll}>
      <StyledTable stickyHeader>
        {headers && (
          <MaterialTableHead>
            <MaterialTableRow>{headers.map(renderHeader)}</MaterialTableRow>
          </MaterialTableHead>
        )}
        <MaterialTableBody>
          {rows.length
            ? rows.map(row =>
                renderCustomRow
                  ? renderCustomRow(row as T)
                  : renderRow(row as TableRow)
              )
            : onRenderEmpty()}
        </MaterialTableBody>
      </StyledTable>
      <HorizontalLoadingBar loading={!!loading} />
    </Container>
  );
}
