import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Box, Button, SvgIconProps } from '@mui/material';
import styled from '@emotion/styled';
import { Gap } from './spacer';

const ContrastButton = styled(Button)`
  color: ${p => p.theme.palette.primary.main};
  height: 200px;
  width: 200px;
  padding: ${p => p.theme.spacing(0, 3)};
  display: flex;
  flex-direction: column;
`;

const LinkBox = styled(Box)<{ active: string }>`
  background-color: ${p =>
    p.active === 'true' ? 'rgba(255,255,255,0.1)' : 'unset'};
  position: relative;
`;

const ButtonLabel = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  color: ${p => p.theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 16px;
  user-select: none;
`;

export function HomeButton({
  title,
  path,
  icon: Icon
}: {
  title?: string;
  path: string;
  icon: (props: SvgIconProps) => JSX.Element;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const goTo = useCallback(() => {
    navigate(path);
  }, [path, navigate]);

  return (
    <LinkBox active={String(location.pathname.startsWith(path))}>
      <ButtonLabel>
        {!!Icon && <Icon fontSize="large" />}
        <Gap size={2} />
        {title}
      </ButtonLabel>
      <ContrastButton
        variant="outlined"
        onClick={goTo}
        color="primary"
        size="large"
      />
    </LinkBox>
  );
}
