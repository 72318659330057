import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Switch, Typography } from '@mui/material';

import {
  AddCircleRounded as AddIcon,
  SaveRounded as SaveIcon,
  ArrowBackRounded as BackIcon,
  GetAppRounded as ExportIcon
} from '@mui/icons-material';

import {
  Experiment,
  ExperimentVariant,
  useSpot,
  Retailer,
  RetailerContext,
  downloadCsv
} from 'framework';
import {
  CenteredRow,
  FormFieldWrapper,
  FormLabel,
  FormNumberInput,
  FormRow,
  FormTextInput,
  FormSection,
  FormSectionTitle,
  Spacer,
  RetailerSelector,
  Tabs,
  Gap
} from 'components';
import { objectDifference } from 'helpers';
import { VariantEditor } from './variant-editor';
import { ExperimentStats } from './experiment-stats';

interface SingleExperimentProps {
  id: string | undefined;
}

export function SingleExperiment({ id }: SingleExperimentProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { retailer: globalRetailer } = useContext(RetailerContext);

  const { query, loading, data, command, raw, rawQuery } = useSpot();
  const [experiment, setExperiment] = useState<Experiment | undefined>();
  const [retailer, setRetailer] = useState<Retailer | null>(
    experiment?.retailers?.length
      ? (experiment.retailers[0] as Retailer)
      : globalRetailer
  );
  const [tabIndex, setTabIndex] = useState('details');

  useEffect(() => {
    (async () => {
      await query('experiments', {}, ['experiments']);
    })();
  }, [query]);

  const experimentId = experiment?.id;
  const experimentFirstRetailer = experiment?.retailers?.[0];

  useEffect(() => {
    const defaultRetailer = (experimentFirstRetailer as Retailer) ?? null;
    setRetailer(defaultRetailer);
  }, [experimentFirstRetailer, setRetailer]);

  useEffect(() => {
    if (retailer) {
      query(`retailer/slug/${retailer?.slug}`, {}, ['extendedRetailer']);
    }
  }, [retailer, query]);

  useEffect(() => {
    if (data.experiments === undefined) {
      return;
    }

    if (
      experimentId &&
      (`${experimentId}` === id || (!id && experimentId === -1))
    ) {
      return;
    }
    setExperiment(
      data.experiments?.find(exp => `${exp.id}` === id) ?? {
        id: -1,
        active: false,
        variants: [],
        userPercentage: 10,
        retailers: [],
        name: t('newExperiment'),
        hasResults: false
      }
    );
  }, [data.experiments, setExperiment, id, t, experimentId]);

  const updateVariant = useCallback(
    (variant: ExperimentVariant) => {
      if (!experiment) {
        return;
      }

      const filteredVariants = experiment.variants.filter(
        innerVariant => innerVariant.id !== variant.id
      );

      const configDifference =
        retailer && data.extendedRetailer
          ? objectDifference(
              data.extendedRetailer.widgetConfiguration,
              variant.widgetConfiguration ?? {}
            )
          : {};

      setExperiment({
        ...experiment,
        variants: [
          ...filteredVariants,
          {
            ...variant,
            widgetConfiguration: retailer ? configDifference : undefined
          }
        ]
      });
    },
    [setExperiment, experiment, retailer, data.extendedRetailer]
  );

  const addVariant = useCallback(() => {
    if (!experiment) {
      return;
    }

    setExperiment({
      ...experiment,
      active: experiment.variants.length + 1 < 2 ? false : experiment.active,
      variants: [
        ...experiment.variants,
        {
          // Hack to make sure that it's always a negative number even if there's no variants
          id: -1 * (experiment.variants.length + 1),
          name: t('newVariant'),
          features: {
            effects: true,
            aovDashboard: {
              currencySymbol: '€',
              enabled: false
            },
            widgetTester: true
          }
        }
      ]
    });
  }, [setExperiment, experiment, t]);

  const deleteVariant = useCallback(
    (variantId: number) => {
      if (!experiment) {
        return;
      }
      const filteredVariants = experiment.variants.filter(
        innerVariant => innerVariant.id !== variantId
      );
      setExperiment({
        ...experiment,
        active: filteredVariants.length < 2 ? false : experiment.active,
        variants: [...filteredVariants]
      });
    },
    [setExperiment, experiment]
  );

  const updateRetailer = useCallback(
    async (r: Retailer | null) => {
      if (!experiment) {
        return;
      }
      setExperiment({
        ...experiment,
        retailers: r
          ? [
              {
                id: r.id,
                name: r.name,
                slug: r.slug
              }
            ]
          : []
      });
    },
    [setExperiment, experiment]
  );

  const onSave = useCallback(async () => {
    if (!experiment) {
      return;
    }

    const toBeSaved = {
      ...experiment,
      retailers: experiment?.retailers.map(r => r.id),
      variants: experiment?.variants.map(v => ({
        ...v,
        id: v.id
      }))
    };

    if (id) {
      await command(`experiments/${id}`, toBeSaved, {
        method: 'PATCH'
      });
    } else {
      const newExperiment = await raw<Experiment>('experiments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(toBeSaved)
      });

      if (!newExperiment) {
        return;
      }

      navigate(`/experiments/${newExperiment?.id}`);
    }

    await query('experiments', {}, ['experiments']);
  }, [command, experiment, id, navigate, query, raw]);

  const onExportStats = useCallback(async () => {
    if (!experiment || !experiment.id) {
      return;
    }

    const csv = await raw(`experiments/${experiment.id}/export-stats`, {
      method: 'GET'
    });
    downloadCsv(`experiemnt_${experiment.id}.csv`, csv);
  }, [experiment, rawQuery]);

  const onBack = useCallback(async () => {
    navigate(`/experiments`);
  }, [navigate]);

  const handleTabChange = useCallback((newValue: string) => {
    setTabIndex(newValue);
  }, []);

  const tabs = experiment
    ? {
        details: (
          <>
            <FormSection>
              <CenteredRow>
                <FormSectionTitle>{t('experimentDetails')}</FormSectionTitle>
                <Spacer />
                <Switch
                  name="experimentActive"
                  color="primary"
                  disabled={experiment.variants.length < 2}
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) => {
                    setExperiment({
                      ...experiment,
                      active: event.target.checked
                    });
                  }}
                  checked={experiment.active}
                />
              </CenteredRow>
              <FormRow>
                <FormLabel>{t('retailer')}</FormLabel>
                <FormFieldWrapper>
                  <RetailerSelector
                    includeAllRetailers
                    selectedRetailer={retailer}
                    onRetailerChanged={updateRetailer}
                  />
                </FormFieldWrapper>
              </FormRow>
              <FormRow>
                <FormLabel>{t('experimentName')}</FormLabel>
                <FormFieldWrapper>
                  <FormTextInput
                    disabled={loading}
                    value={experiment.name}
                    onChange={(value: string) => {
                      setExperiment({ ...experiment, name: value });
                    }}
                  />
                </FormFieldWrapper>
              </FormRow>
              <FormRow>
                <FormLabel>{t('userPercentage')}</FormLabel>
                <FormFieldWrapper>
                  <FormNumberInput
                    disabled={loading}
                    value={experiment?.userPercentage}
                    min={1}
                    max={99}
                    onChange={(value: number) => {
                      setExperiment({
                        ...experiment,
                        userPercentage: value
                      });
                    }}
                  />
                </FormFieldWrapper>
              </FormRow>
            </FormSection>
            <FormSection>
              <CenteredRow>
                <FormSectionTitle>{t('experimentVariants')}</FormSectionTitle>
                <Spacer />
                <IconButton onClick={addVariant} size="large">
                  <AddIcon />
                </IconButton>
              </CenteredRow>
              {experiment.variants
                .slice()
                .sort((v1, v2) => v1.id - v2.id)
                .map(variant => (
                  <VariantEditor
                    loading={loading}
                    variant={variant}
                    updateVariant={updateVariant}
                    deleteVariant={deleteVariant}
                    retailer={retailer}
                    key={variant.tracker}
                  />
                ))}
            </FormSection>
          </>
        ),
        ...(experiment.hasResults && {
          stats: <ExperimentStats experiment={experiment} />
        })
      }
    : {};

  return (
    <>
      <CenteredRow>
        <IconButton onClick={onBack} size="large">
          <BackIcon />
        </IconButton>
        <Typography variant="h4">{t('experiment')}</Typography>
        <Spacer />
        {experiment?.hasResults && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onExportStats}
            startIcon={<ExportIcon />}
          >
            {t('exportStats')}
          </Button>
        )}
        <Gap size={1} />
        <Button
          variant="contained"
          color="primary"
          onClick={onSave}
          startIcon={<SaveIcon />}
        >
          {t('save')}
        </Button>
      </CenteredRow>
      {experiment && (
        <>
          <Tabs
            selected={tabIndex}
            onTabChange={handleTabChange}
            tabs={tabs}
            hideIfSingle
            loading={loading}
          />
        </>
      )}
    </>
  );
}
