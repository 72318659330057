import { Alert, AlertTitle, Box, Typography } from '@mui/material';
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import {
  DateBox,
  FallbackStatsComponent,
  Gap,
  Row,
  Spacer
} from '../../components';
import {
  openInNewTab,
  RetailerContext,
  RoleContext,
  useSpot
} from '../../framework';

import productInsightsBlocked from '../../images/productInsightsBlocked.png';

const ProductStatsComponent = lazy(() => import('./product-stats'));

const StyledLink = styled.span`
  text-decoration: underline;
`;

const StyledAlert = styled(Alert)`
  margin: ${p => p.theme.spacing(2)};
`;

const SectionDisabledContainer = styled(Box)`
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
`;

const SectionDisabledImage = styled.img`
  width: 100%;
  position: relative;
`;

const SectionDisabledTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function ProductInsights() {
  const { t } = useTranslation();
  const { spot, loading } = useSpot();
  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  const { retailer } = useContext(RetailerContext);
  const { isAdmin } = useContext(RoleContext);

  const [showProductInsights, setShowProductInsights] = useState(false);

  const longTimePeriod =
    startDate && endDate && endDate.diff(startDate, 'days') > 32;

  useEffect(() => {
    const selectedRetailers = !retailer ? [...spot.data.retailers] : [retailer];
    const shouldShowProductInsights = selectedRetailers.reduce(
      (anyEnabled, curr) => anyEnabled || !!curr.features?.productInsights,
      false
    );

    setShowProductInsights(shouldShowProductInsights || isAdmin());
  }, [retailer, spot.data.retailers, isAdmin]);

  const handleProductInsightsUpsell = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Enable Product Insights&body=I'd like to enable Product Insights in my store`
    );
  }, []);

  return (
    <>
      <Row>
        <Box>
          <Typography variant="h4">{t('productInsights')}</Typography>
        </Box>
        <Spacer />
        <DateBox
          disabled={loading}
          onEndChange={setEndDate}
          onStartChange={setStartDate}
          allowedRanges={[
            'lastSevenDays',
            'lastThirtyDays',
            'lastMonth',
            'thisMonth',
            'custom'
          ]}
        />
      </Row>
      {longTimePeriod && (
        <StyledAlert severity="warning">
          <AlertTitle>{t('warning')}</AlertTitle>
          {t('longTimePeriodWarning')}
        </StyledAlert>
      )}
      {!longTimePeriod && showProductInsights && (
        <Suspense fallback={<FallbackStatsComponent />}>
          <ProductStatsComponent
            startDate={startDate}
            endDate={endDate}
            retailer={retailer}
          />
        </Suspense>
      )}
      {!longTimePeriod && !showProductInsights && (
        <SectionDisabledContainer onClick={handleProductInsightsUpsell}>
          <SectionDisabledImage src={productInsightsBlocked} />
          <SectionDisabledTextContainer>
            <Typography variant="h4" color="primary">
              {t('productInsightsDisabled')}
            </Typography>
            <Gap size={2} />
            <Typography variant="subtitle2" color="primary">
              <Trans
                i18nKey="productInsightsDisabledInfo"
                components={[<StyledLink key="link" />]}
              />
            </Typography>
          </SectionDisabledTextContainer>
        </SectionDisabledContainer>
      )}
      <Gap size={3} />
    </>
  );
}
