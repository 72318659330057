import { useTranslation } from 'react-i18next';
import React from 'react';
import { Box, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import {
  ExtendedBrand,
  SizeChartsUpload,
  SizeChartsUploadItem,
  TagMetadata
} from '../../framework';
import {
  CustomDialog,
  DialogContent,
  Gap,
  RightButtons
} from '../../components';
import { UploadSizeChartItem } from './upload-item';

interface Props {
  brand: ExtendedBrand;
  dialogOpen: boolean;
  onSubmit?: (items: SizeChartsUpload[]) => Promise<void>;
  onClose?: () => unknown;
  tagMetadata: TagMetadata[];
}

export function UploadSizeChartsDialog({
  brand,
  dialogOpen,
  onSubmit,
  onClose,
  tagMetadata
}: Props) {
  const { t } = useTranslation();

  const requiredFields = ['productName', 'gender', 'sizeChartFile'];

  const itemPropertyError = <T, K extends keyof T>(
    item: T,
    propertyName: K
  ): string | null => {
    const propertyValue = item[propertyName] as unknown as string;

    if (
      requiredFields.includes(propertyName as string) &&
      !propertyValue &&
      !(propertyValue?.length > 0)
    ) {
      return t('required');
    }

    if (propertyName === 'productName') {
      const existingTagMeta = tagMetadata?.find(
        tm => tm.name === propertyValue
      );
      if (existingTagMeta) {
        return t('tagMetaExists');
      }
    }

    return null;
  };

  const validateAll = (items: SizeChartsUploadItem[] | undefined): boolean => {
    if (!items) {
      return false;
    }

    for (let i = 0; i < items.length; i++) {
      if (
        itemPropertyError(items[i], 'productName') ||
        itemPropertyError(items[i], 'productUrl') ||
        itemPropertyError(items[i], 'gender') ||
        itemPropertyError(items[i], 'sizeChartFile')
      ) {
        return false;
      }
    }
    return true;
  };

  const renderControl = (
    setValue: (val?: SizeChartsUploadItem[]) => void,
    value?: SizeChartsUploadItem[]
  ) => {
    return (
      <DialogContent className="size-chart-upload-dialog">
        <Box>
          {value?.map((item, index) => (
            <UploadSizeChartItem
              // eslint-disable-next-line react/no-array-index-key
              key={`upload-item-${index}`}
              item={item}
              index={index}
              onUpdate={(idx: number, update: SizeChartsUploadItem) => {
                if (value) {
                  const updatedItem = { ...value[idx], ...update };
                  value.splice(idx, 1, updatedItem);
                  setValue([...value]);
                }
              }}
              onDelete={(idx: number) => {
                value.splice(idx, 1);
                setValue([...value]);
              }}
              canDelete={value?.length > 1}
              validation={itemPropertyError}
            />
          ))}
        </Box>
        <Gap />
        <RightButtons>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const uploadItems = [...(value || [])];
              uploadItems.push({
                productName: '',
                productUrl: '',
                gender: '',
                sizeChartFile: ''
              });
              setValue(uploadItems);
            }}
            startIcon={<Add />}
          >
            {t('addSizeChartUpload')}
          </Button>
        </RightButtons>
      </DialogContent>
    );
  };

  return CustomDialog({
    initialValue: [
      {
        productName: '',
        productUrl: '',
        gender: '',
        sizeChartFile: ''
      }
    ],
    title: `${t('uploadSizeChartsTitle')} '${brand.name}'`,
    dialogOpen,
    onSubmit,
    onClose,
    canSubmit: val => validateAll(val),
    renderControl,
    maxWidth: 'lg'
  });
}
