import { Box, MenuItem, Select, Typography } from '@mui/material';
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import styled from '@emotion/styled';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import {
  DateBox,
  FallbackStatsComponent,
  Gap,
  Row,
  Spacer
} from '../../components';
import {
  openInNewTab,
  RetailerContext,
  RoleContext,
  useSpot
} from '../../framework';

import profileInsightsBlocked from '../../images/profileInsightsBlocked.png';

const ProfileStatsComponent = lazy(() => import('./profile-stats'));

const StyledLink = styled.span`
  text-decoration: underline;
`;

const SectionDisabledContainer = styled(Box)`
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
`;

const SectionDisabledImage = styled.img`
  width: 100%;
  position: relative;
`;

const SectionDisabledTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SelectContainer = styled(Box)`
  flex: 0 0 300px;
`;

export function ProfileInsights() {
  const { t } = useTranslation();
  const { spot, loading } = useSpot();
  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const [productTypeRoot, setProductTypeRoot] = useState<
    'top' | 'bottom' | 'full' | 'shoe' | 'all'
  >('all');
  const [viewType, setViewType] = useState<
    'fasletUsers' | 'fasletOrders' | 'returns'
  >('fasletUsers');

  const { retailer } = useContext(RetailerContext);
  const { isAdmin } = useContext(RoleContext);

  const [showProfileInsights, setShowProfileInsights] = useState(false);

  useEffect(() => {
    const selectedRetailers = !retailer ? [...spot.data.retailers] : [retailer];
    const shouldShowProfileInsights = selectedRetailers.reduce(
      (anyEnabled, curr) => anyEnabled || !!curr.features?.profileInsights,
      false
    );

    setShowProfileInsights(shouldShowProfileInsights || isAdmin());
  }, [retailer, spot.data.retailers, isAdmin]);

  const handleProfileInsightsUpsell = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Enable Profile Insights&body=I'd like to enable Profile Insights in my store`
    );
  }, []);

  const productSelected = useCallback(
    event => {
      setProductTypeRoot(event.target.value);
    },
    [setProductTypeRoot]
  );

  const viewTypeSelected = useCallback(
    event => {
      setViewType(
        event.target.value as 'fasletUsers' | 'fasletOrders' | 'returns'
      );
    },
    [setViewType]
  );

  return (
    <>
      <Row>
        <Box>
          <Typography variant="h4">{t('profileInsights')}</Typography>
        </Box>
        <Spacer />
        <SelectContainer>
          <Select
            value={productTypeRoot === 'all' ? viewType : 'fasletUsers'}
            onChange={viewTypeSelected}
            fullWidth
            disabled={productTypeRoot !== 'all'}
          >
            <MenuItem value="fasletUsers">{t('usersWithSizeRec')}</MenuItem>
            <MenuItem value="fasletOrders">{t('ordersWithSizeRec')}</MenuItem>
            <MenuItem value="returns">{t('returnsWithSizeRec')}</MenuItem>
          </Select>
        </SelectContainer>
        <Gap size={2} />
        <SelectContainer>
          <Select
            value={viewType === 'fasletUsers' ? productTypeRoot : 'all'}
            onChange={productSelected}
            fullWidth
            disabled={viewType !== 'fasletUsers'}
          >
            <MenuItem value="all">{t('allProductTypes')}</MenuItem>
            <MenuItem value="full">{t('fullbody')}</MenuItem>
            <MenuItem value="top">{t('top')}</MenuItem>
            <MenuItem value="bottom">{t('bottom')}</MenuItem>
            <MenuItem value="shoe">{t('shoes')}</MenuItem>
          </Select>
        </SelectContainer>
        <Gap size={2} />
        <DateBox
          disabled={loading}
          onEndChange={setEndDate}
          onStartChange={setStartDate}
          allowedRanges={[
            'lastSevenDays',
            'lastThirtyDays',
            'lastMonth',
            'thisMonth',
            'custom'
          ]}
        />
      </Row>
      <Suspense fallback={<FallbackStatsComponent />}>
        {showProfileInsights && (
          <Suspense fallback={<FallbackStatsComponent />}>
            <ProfileStatsComponent
              startDate={startDate}
              endDate={endDate}
              retailer={retailer}
              productTypeRoot={productTypeRoot}
              viewType={viewType}
            />
          </Suspense>
        )}
        {!showProfileInsights && (
          <SectionDisabledContainer onClick={handleProfileInsightsUpsell}>
            <SectionDisabledImage src={profileInsightsBlocked} />
            <SectionDisabledTextContainer>
              <Typography variant="h4" color="primary">
                {t('profileInsightsDisabled')}
              </Typography>
              <Gap size={2} />
              <Typography variant="subtitle2" color="primary">
                <Trans
                  i18nKey="profileInsightsDisabledInfo"
                  components={[<StyledLink key="link" />]}
                />
              </Typography>
            </SectionDisabledTextContainer>
          </SectionDisabledContainer>
        )}
      </Suspense>
    </>
  );
}
