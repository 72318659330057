import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { Row, Tabs } from '../../components';
import { SizeChartSetup } from './size-chart-setup';
import { Multipliers } from './multipliers';
import { SlackValues } from './slack-values';
import { WidgetTester } from './widget-tester';

export function SizeAndFit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();
  const [loading, setLoading] = useState(false);

  const onChangeTab = useCallback(
    newTab => {
      navigate(`/size-and-fit/${newTab}`);
    },
    [navigate]
  );

  return (
    <>
      <Row>
        <Typography variant="h4">{t('sizeAndFit')}</Typography>
      </Row>
      <Tabs
        selected={tab ?? 'size-charts'}
        onTabChange={onChangeTab}
        hideIfSingle
        loading={loading}
        tabs={{
          'size-charts': <SizeChartSetup onLoadingChanged={setLoading} />,
          multipliers: <Multipliers onLoadingChanged={setLoading} />,
          'slack-values': <SlackValues onLoadingChanged={setLoading} />,
          'size-tester': <WidgetTester onLoadingChanged={setLoading} />
        }}
      />
    </>
  );
}
