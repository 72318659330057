import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Switch,
  Typography
} from '@mui/material';
import {
  SaveRounded as SaveIcon,
  InfoRounded as InfoIcon,
  RssFeedRounded as ProcessFeedIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Error, Retailer, RoleContext, useSpot } from 'framework';
import {
  FeedValidationDialog,
  FeedValidationResults
} from './feed-validation-dialog';
import {
  CenteredRow,
  FormFieldWrapperWide,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  Gap,
  Row,
  Spacer,
  StatusIcon
} from 'components';

const ButtonRow = styled(CenteredRow)`
  max-width: 700px;
  flex-wrap: nowrap;
`;

const ButtonContainer = styled(CenteredRow)`
  flex-wrap: nowrap;
`;

const FeedStatusIcon = styled(Box)`
  margin-left: ${p => p.theme.spacing(1)};
  padding-top: ${p => p.theme.spacing(1)};
`;

const StyledFormSection = styled(FormSection)`
  width: 100%;
`;

interface ProductFeedConfigurationProps {
  retailer: Retailer;
  updateRetailer: (retailer: Retailer) => void;
  displayErrors: (errors: Error[]) => void;
}

export function ProductFeedConfiguration({
  retailer,
  displayErrors,
  updateRetailer
}: ProductFeedConfigurationProps) {
  const { t } = useTranslation();

  const { raw, command } = useSpot();
  const { isAdmin } = useContext(RoleContext);
  const [feedValidationResults, setFeedValidationResults] =
    useState<FeedValidationResults | null>(null);
  const [feedStatus, setFeedStatus] = useState<
    'success' | 'failure' | 'in_progress' | 'unknown'
  >('unknown');
  const [feedValidationDialogOpen, setFeedValidationDialogOpen] =
    useState(false);
  const pollingHandle = useRef<number | null>(null);

  const feedStatusMap = {
    success: 'active',
    failure: 'not-active',
    in_progress: 'unknown',
    unknown: 'unknown'
  } as Record<
    'success' | 'failure' | 'in_progress' | 'unknown',
    'active' | 'not-active' | 'unknown'
  >;

  const validFeedUrl = useMemo(() => {
    return (
      (retailer.productFeed && retailer.productFeed.trim().length > 0) ||
      (retailer.sftpConfigHost && retailer.sftpConfigHost.trim().length > 0)
    );
  }, [retailer]);

  const getValidationStatus = useCallback(
    async (showPopup: boolean) => {
      if (!validFeedUrl) {
        return 'unknown';
      }

      try {
        const response = await raw<{
          [retailerSlug: string]: {
            status: 'success' | 'failure' | 'in_progress' | 'unknown';
            result: FeedValidationResults;
          };
        }>(`feeds/${retailer.slug}/validation-status`, {
          headers: {
            'Content-Type': 'application/json'
          },
          method: 'GET'
        });

        if (!response) {
          setFeedStatus('unknown');
          displayErrors([
            {
              message: 'Feed Validation Error',
              body: 'Something went wrong while validating the feed. Please try again.'
            }
          ]);
          return 'failure';
        }

        if (response[retailer.slug]?.status !== 'success') {
          setFeedValidationResults(response[retailer.slug].result);
          setFeedValidationDialogOpen(showPopup);
        } else {
          setFeedValidationResults(null);
        }
        setFeedStatus(response[retailer.slug]?.status);

        return response[retailer.slug]?.status;
      } catch (e) {
        setFeedStatus('unknown');
        displayErrors(e as Error[]);
        return 'failure';
      }
    },
    [
      raw,
      setFeedStatus,
      setFeedValidationResults,
      retailer,
      displayErrors,
      validFeedUrl
    ]
  );

  const pollForValidation = useCallback(
    async (showPopup: boolean) => {
      const status = await getValidationStatus(showPopup);
      pollingHandle.current = window.setTimeout(() => {
        if (status === 'in_progress') {
          pollForValidation(showPopup);
        }
      }, 5000);
    },
    [getValidationStatus]
  );

  useEffect(() => {
    pollForValidation(false);
  }, [pollForValidation]);

  useEffect(() => {
    return () => {
      if (pollingHandle.current) {
        clearTimeout(pollingHandle.current);
        pollingHandle.current = null;
      }
    };
  }, [pollingHandle, retailer]);

  const validateFeed = useCallback(async () => {
    if (!validFeedUrl) {
      return;
    }

    const message = {
      retailer: retailer.slug,
      productFeed: retailer.productFeed,
      encoding: retailer.feedEncoding,
      format: retailer.feedFormat,
      sftpEnabled: retailer.sftpEnabled,
      sftpConfigHost: retailer.sftpConfigHost,
      sftpConfigPort: retailer.sftpConfigPort,
      sftpConfigUsername: retailer.sftpConfigUsername,
      sftpConfigFilePath: retailer.sftpConfigFilePath,
      sftpConfigPasswordSecretPath: retailer.sftpConfigPasswordSecretPath,
      sftpConfigPrivateKeySecretPath: retailer.sftpConfigPrivateKeySecretPath
    } as {
      retailer: string;
      productFeed: string;
      encoding?: string;
      format?: 'xml' | 'csv' | 'json';
    };

    if (retailer.feedFormat) {
      message.format = retailer.feedFormat;
    }

    try {
      await raw(`feeds/${retailer.slug}/start-validation`, {
        body: JSON.stringify(message),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });
    } catch (e) {
      setFeedStatus('unknown');
      displayErrors(e as Error[]);
    }

    setFeedStatus('in_progress');
    await pollForValidation(true);
  }, [
    raw,
    retailer,
    displayErrors,
    pollForValidation,
    setFeedStatus,
    validFeedUrl
  ]);

  const handleFeedFormatChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newFormat = event.target.value as
        | 'xml'
        | 'csv'
        | 'json'
        | 'autoDetect';
      if (newFormat === 'autoDetect') {
        const newRetailer = { ...retailer };
        delete newRetailer.feedFormat;
        updateRetailer(newRetailer);
      } else {
        updateRetailer({
          ...retailer,
          feedFormat: newFormat ?? ''
        });
      }
    },
    [retailer, updateRetailer]
  );

  const processFeed = useCallback(async () => {
    if (!validFeedUrl) {
      return;
    }

    try {
      await command(`retailer/${retailer.slug}/process-feed`, {
        productFeed: retailer.productFeed,
        encoding: retailer.feedEncoding,
        format: retailer.feedFormat,
        sftpEnabled: retailer.sftpEnabled,
        sftpConfigHost: retailer.sftpConfigHost,
        sftpConfigPort: retailer.sftpConfigPort,
        sftpConfigUsername: retailer.sftpConfigUsername,
        sftpConfigFilePath: retailer.sftpConfigFilePath,
        sftpConfigPasswordSecretPath: retailer.sftpConfigPasswordSecretPath,
        sftpConfigPrivateKeySecretPath: retailer.sftpConfigPrivateKeySecretPath
      });
    } catch (e) {
      displayErrors(e as Error[]);
    }
  }, [command, displayErrors, retailer, validFeedUrl]);

  return (
    <>
      <StyledFormSection>
        <ButtonRow>
          <FormSectionTitle>{t('productFeedConfiguration')}</FormSectionTitle>
          <Spacer />
          <CenteredRow>
            <Typography>HTTP</Typography>
            <Switch
              checked={retailer?.sftpEnabled ?? false}
              onChange={event => {
                updateRetailer({
                  ...retailer,
                  sftpEnabled: event.target.checked
                });
              }}
            />
            <Typography>SFTP</Typography>
          </CenteredRow>
        </ButtonRow>

        {!retailer?.sftpEnabled && (
          <FormRow>
            <FormLabel info={t('productFeedHelp')}>
              {t('productFeed')}
            </FormLabel>
            <FormFieldWrapperWide>
              <TextField
                fullWidth
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  let enteredUrl = event.target.value;
                  if (enteredUrl.length && !enteredUrl.startsWith('http')) {
                    enteredUrl = `https://${enteredUrl}`;
                  }

                  setFeedValidationResults(null);

                  updateRetailer({
                    ...retailer,
                    productFeed: enteredUrl
                  });
                }}
                value={retailer.productFeed ?? ''}
                InputProps={{
                  endAdornment: (
                    <FeedStatusIcon>
                      <StatusIcon
                        loading={feedStatus === 'in_progress'}
                        status={feedStatusMap[feedStatus]}
                      />
                    </FeedStatusIcon>
                  )
                }}
              />
            </FormFieldWrapperWide>
          </FormRow>
        )}

        {!!retailer?.sftpEnabled && (
          <FormRow>
            <FormLabel>{t('productFeedSftpHost')}</FormLabel>
            <FormFieldWrapperWide>
              <TextField
                fullWidth
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  let enteredUrl = event.target.value;
                  setFeedValidationResults(null);

                  updateRetailer({
                    ...retailer,
                    sftpConfigHost: enteredUrl
                  });
                }}
                value={retailer.sftpConfigHost ?? ''}
                InputProps={{
                  endAdornment: (
                    <FeedStatusIcon>
                      <StatusIcon
                        loading={feedStatus === 'in_progress'}
                        status={feedStatusMap[feedStatus]}
                      />
                    </FeedStatusIcon>
                  )
                }}
              />
            </FormFieldWrapperWide>
          </FormRow>
        )}
        <FormRow>
          <FormLabel>{t('feedFormat')}</FormLabel>
          <FormFieldWrapperWide>
            <Select
              fullWidth
              value={retailer?.feedFormat ?? 'autoDetect'}
              onChange={handleFeedFormatChange}
            >
              <MenuItem value="autoDetect">{t('autoDetect')}</MenuItem>
              <MenuItem value="xml">{t('xml')}</MenuItem>
              <MenuItem value="csv">{t('csv')}</MenuItem>
              <MenuItem value="json">{t('json')}</MenuItem>
            </Select>
          </FormFieldWrapperWide>
        </FormRow>

        {!!retailer?.sftpEnabled && (
          <>
            <FormRow>
              <FormLabel>{t('productFeedSftpFilePath')}</FormLabel>
              <FormFieldWrapperWide>
                <TextField
                  fullWidth
                  onChange={(event: React.ChangeEvent<{ value: string }>) => {
                    setFeedValidationResults(null);
                    updateRetailer({
                      ...retailer,
                      sftpConfigFilePath: event.target.value
                    });
                  }}
                  value={retailer.sftpConfigFilePath ?? ''}
                />
              </FormFieldWrapperWide>
            </FormRow>
            <FormRow>
              <FormLabel>{t('productFeedSftpPort')}</FormLabel>
              <FormFieldWrapperWide>
                <TextField
                  fullWidth
                  onChange={(event: React.ChangeEvent<{ value: string }>) => {
                    setFeedValidationResults(null);
                    updateRetailer({
                      ...retailer,
                      sftpConfigPort: Number.parseInt(event.target.value, 10)
                    });
                  }}
                  value={retailer.sftpConfigPort ?? 22}
                />
              </FormFieldWrapperWide>
            </FormRow>
            <FormRow>
              <FormLabel>{t('productFeedSftpUsername')}</FormLabel>
              <FormFieldWrapperWide>
                <TextField
                  fullWidth
                  onChange={(event: React.ChangeEvent<{ value: string }>) => {
                    setFeedValidationResults(null);
                    updateRetailer({
                      ...retailer,
                      sftpConfigUsername: event.target.value
                    });
                  }}
                  value={retailer.sftpConfigUsername ?? ''}
                />
              </FormFieldWrapperWide>
            </FormRow>
            {isAdmin() && (
              <>
                <FormRow>
                  <FormLabel>
                    {t('productFeedSftpPasswordSecretPath')}
                  </FormLabel>
                  <FormFieldWrapperWide>
                    <TextField
                      fullWidth
                      onChange={(
                        event: React.ChangeEvent<{ value: string }>
                      ) => {
                        setFeedValidationResults(null);
                        updateRetailer({
                          ...retailer,
                          sftpConfigPasswordSecretPath: event.target.value
                        });
                      }}
                      value={retailer.sftpConfigPasswordSecretPath ?? ''}
                    />
                  </FormFieldWrapperWide>
                </FormRow>
                <FormRow>
                  <FormLabel>
                    {t('productFeedSftpPrivateKeySecretPath')}
                  </FormLabel>
                  <FormFieldWrapperWide>
                    <TextField
                      fullWidth
                      onChange={(
                        event: React.ChangeEvent<{ value: string }>
                      ) => {
                        setFeedValidationResults(null);
                        updateRetailer({
                          ...retailer,
                          sftpConfigPrivateKeySecretPath: event.target.value
                        });
                      }}
                      value={retailer.sftpConfigPrivateKeySecretPath ?? ''}
                    />
                  </FormFieldWrapperWide>
                </FormRow>
              </>
            )}
          </>
        )}
        {isAdmin() && (
          <>
            <FormRow>
              <FormLabel>{t('feedProductIdKey')}</FormLabel>
              <FormFieldWrapperWide>
                <TextField
                  fullWidth
                  onChange={(event: React.ChangeEvent<{ value: string }>) => {
                    updateRetailer({
                      ...retailer,
                      feedProductIdKey: event.target.value ?? ''
                    });
                  }}
                  value={retailer.feedProductIdKey ?? ''}
                />
              </FormFieldWrapperWide>
            </FormRow>
            <Gap />
          </>
        )}
        {isAdmin() && (
          <>
            <FormRow>
              <FormLabel>{t('feedEncoding')}</FormLabel>
              <FormFieldWrapperWide>
                <TextField
                  fullWidth
                  onChange={(event: React.ChangeEvent<{ value: string }>) => {
                    updateRetailer({
                      ...retailer,
                      feedEncoding: event.target.value ?? ''
                    });
                  }}
                  value={retailer.feedEncoding ?? ''}
                />
              </FormFieldWrapperWide>
            </FormRow>
            <Gap />
          </>
        )}

        <ButtonRow>
          <Spacer />
          <ButtonContainer>
            <Button
              disabled={(feedValidationResults?.errorCount ?? 0) === 0}
              onClick={() => {
                setFeedValidationDialogOpen(true);
              }}
              color="info"
              variant="contained"
              startIcon={<InfoIcon />}
            >
              {t('viewErrors')}
            </Button>
            <Gap />
            <Button
              disabled={!validFeedUrl}
              onClick={validateFeed}
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              {t('saveAndValidate')}
            </Button>
            {isAdmin() && (
              <>
                <Gap />
                <Button
                  disabled={!validFeedUrl}
                  onClick={processFeed}
                  color="primary"
                  variant="contained"
                  startIcon={<ProcessFeedIcon />}
                >
                  {t('processFeed')}
                </Button>
              </>
            )}
          </ButtonContainer>
        </ButtonRow>
        <Gap />
      </StyledFormSection>

      <FeedValidationDialog
        feedValidationResults={
          feedValidationDialogOpen ? feedValidationResults : null
        }
        onClose={() => setFeedValidationDialogOpen(false)}
      />
    </>
  );
}
