import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Gap, Spacer } from 'components';
import imageNotFound from '../images/reading-corner.jpg';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

const PageContainer = styled(Container)`
  max-width: 600px;
`;

export function Maintenance() {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Gap />
      <Typography variant="h4">{t('maintenanceTitle')}</Typography>
      <Gap />
      <Typography variant="subtitle1">{t('maintenanceInfo')}</Typography>
      <Gap />
      <img src={imageNotFound} alt={t('notFound')} width={600} />
      <Spacer />
    </PageContainer>
  );
}
