import React, { HtmlHTMLAttributes, useCallback, useEffect } from 'react';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import { StoreRounded as GoToRetailerIcon } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { Retailer, useSpot } from '../framework';
import { CenteredRow } from './layout';
import { Gap } from './spacer';
import unknownLogo from '../images/spiral-logo.png';

const Wrapper = styled.div`
  flex: 0 0 360px;

  ${p => p.theme.breakpoints.down('md')} {
    flex: 0 0 300px;
  }

  display: flex;
  align-items: center;
`;

const RetailerNameRow = styled(CenteredRow)`
  flex-wrap: nowrap;
  flex: 1 1 auto;
`;

const Logo = styled.div<{ image?: string }>`
  height: 32px;
  background: url('${p => p.image}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 50px;
  margin-right: 12px;
  margin-left: 4px;
`;

const RetailerName = styled(Typography)`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const StyledInput = styled(TextField)`
  color: black;
  background-color: white;
  border-radius: 5px;
`;

const GoToStoreButton = styled(IconButton)`
  color: ${p => p.theme.palette.primary.contrastText};
`;

export interface RetailerSelectorProps {
  includeAllRetailers?: boolean;
  onRetailerChanged: (retailer: Retailer | null) => unknown;
  selectedRetailer: Retailer | null;
  initialRetailerSlug?: string;
}

export const allRetailersRetailerId = -1;

const portalLogo = unknownLogo;

export const allRetailersRetailer: Retailer = {
  name: 'allRetailers',
  slug: 'all-retailers',
  id: allRetailersRetailerId,
  logo: portalLogo,
  logoS3: null,
  brands: []
};

const defaultFilterOptions = createFilterOptions<Retailer>({
  limit: 30,
  ignoreCase: true,
  ignoreAccents: true
});

export function RetailerSelector({
  includeAllRetailers,
  onRetailerChanged,
  selectedRetailer,
  initialRetailerSlug
}: RetailerSelectorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { spot, query, data, loading } = useSpot();

  const disabled = loading;

  useEffect(() => {
    (async () => {
      if (!spot.data?.retailers) {
        await query('retailer/', {}, ['retailers']);
      }
      if (
        spot.data.retailers?.length === 1 &&
        selectedRetailer?.id !== spot.data.retailers?.[0]?.id
      ) {
        onRetailerChanged(spot.data.retailers?.[0] ?? null);
      } else if (initialRetailerSlug) {
        const foundRetailer = spot.data.retailers?.find(
          r => r.slug === initialRetailerSlug
        );
        onRetailerChanged(foundRetailer ?? null);
      } else if (!includeAllRetailers) {
        onRetailerChanged(spot.data.retailers?.[0] ?? null);
      }
    })();
  }, []);

  const baseRetailers = includeAllRetailers
    ? [allRetailersRetailer]
    : ([] as Retailer[]);

  const defaultRetailer = includeAllRetailers ? allRetailersRetailer : null;

  const extendedRetailers = baseRetailers.concat(data?.retailers ?? []);

  const retailerChanged = useCallback(
    (event: unknown, newRetailer: Retailer | null) => {
      onRetailerChanged &&
        onRetailerChanged(
          newRetailer?.id === allRetailersRetailerId ? null : newRetailer
        );
    },
    [onRetailerChanged]
  );

  const onGoToRetailer = useCallback(() => {
    navigate(`/retailer/${selectedRetailer?.slug}`);
  }, [navigate, selectedRetailer]);

  const getOptionLabel = useCallback(
    (r: Retailer) =>
      r.id === allRetailersRetailerId ? t(r.name) : r.name ?? '-',
    [t]
  );

  const renderInput = useCallback(
    (params: any) => (
      <StyledInput
        {...params}
        hiddenLabel
        fullWidth
        size="small"
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: <Logo image={selectedRetailer?.logo ?? portalLogo} />
        }}
      />
    ),
    [selectedRetailer]
  );

  const renderOption = useCallback(
    (props: HtmlHTMLAttributes<HTMLLIElement>, r: Retailer) => (
      <li {...props} key={r.slug}>
        <RetailerNameRow>
          <Logo image={r.logo ?? portalLogo} />
          <RetailerName>
            {r.id === allRetailersRetailerId ? t(r.name) : r.name ?? '-'}
          </RetailerName>
        </RetailerNameRow>
      </li>
    ),
    [t]
  );

  const getOptionSelected = useCallback(
    (r1: Retailer, r2: Retailer) => r1.slug === r2.slug,
    []
  );

  return (
    <Wrapper>
      <Autocomplete
        disabled={disabled}
        fullWidth
        onChange={retailerChanged}
        value={selectedRetailer ?? defaultRetailer}
        disableClearable={!defaultRetailer}
        selectOnFocus
        options={extendedRetailers}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        renderInput={renderInput}
        filterOptions={defaultFilterOptions}
        renderOption={renderOption}
      />
      <Gap size={1} />
      <GoToStoreButton
        onClick={onGoToRetailer}
        disabled={!selectedRetailer}
        title={t('goToRetailer')}
      >
        <GoToRetailerIcon color="inherit" />
      </GoToStoreButton>
    </Wrapper>
  );
}
