export const reasonStrings = [
  'Too big',
  'Too small',
  'Product is damaged',
  'Product isn’t as expected',
  'Wrong article/size',
  'Color isn’t as expected',
  'Quality isn’t as expected',
  'Other reason',
  'Ordered Multiple Sizes'
];
