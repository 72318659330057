import React, { useEffect } from 'react';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Row } from './layout';
import { useSpot } from 'framework';

export const SizeChartRow = styled(Row)`
  position: relative;
  width: 100%;
  align-items: center;
`;

export const SizeChartName = styled(Typography)`
  text-transform: capitalize;
  font-size: 1em;
  position: relative;
  width: 100%;
`;

export function useSizeChartLink() {
  const { spot, query } = useSpot();

  function findTag(productType: string, gender: string) {
    let tagObj = spot.data?.tags?.find(
      v =>
        v.product?.name.toLocaleLowerCase() ===
          productType.toLocaleLowerCase() &&
        v.gender.toLocaleLowerCase() === gender.toLocaleLowerCase()
    );

    return tagObj ?? null;
  }

  useEffect(() => {
    (async () => {
      if (!spot.data?.tags) {
        await query('tag/', {}, ['tags']);
      }
    })();
  }, [spot, query]);

  return { findTag };
}

export function SizeChartLink({
  isAdmin,
  brand,
  brandSlug,
  tagGender,
  tagProductName,
  tagName,
  hasSizeChart,
  hasChartLabels
}: {
  isAdmin: () => boolean;
  brand: string | undefined;
  brandSlug: string | undefined;
  tagGender: string | undefined;
  tagProductName: string | undefined;
  tagName: string | undefined;
  hasSizeChart?: boolean;
  hasChartLabels?: boolean;
}) {
  const { t } = useTranslation();

  // Not connected
  if (
    !tagName ||
    !tagProductName ||
    (!tagGender && tagProductName !== 'excluded')
  ) {
    return <SizeChartName color="error">{t('noSizeChart')}</SizeChartName>;
  }

  // Connected and Admin
  if (isAdmin()) {
    let color = 'primary';
    if (hasSizeChart !== undefined && !hasSizeChart) {
      color = 'error';
    } else if (hasChartLabels !== undefined && !hasChartLabels) {
      color = '#FFA500';
    }

    return (
      <SizeChartRow>
        <Link
          href={
            brand && brandSlug
              ? `/size-and-fit/size-charts/${brandSlug}/${encodeURIComponent(
                  brand
                )}-${tagProductName}-${tagGender}`
              : undefined
          }
          target="_blank"
          color={color}
          title={t('sizeChartColorInfo')}
        >
          <SizeChartName>
            {tagProductName}
            {tagProductName === 'excluded' ? '' : ` (${tagGender})`}
          </SizeChartName>
        </Link>
      </SizeChartRow>
    );
  }

  // Connected and not admin
  return (
    <SizeChartRow>
      <Row>
        <SizeChartName color="primary">
          {tagProductName === 'excluded' ? t('excluded') : t('connected')}
        </SizeChartName>
      </Row>
    </SizeChartRow>
  );
}
