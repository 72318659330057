import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Button, Grid, IconButton, Slider, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import styled from '@emotion/styled';

import {
  DeleteForeverRounded as DeleteIcon,
  GetAppRounded as ExportIcon,
  PublishRounded as ImportIcon,
  FileCopyRounded as CopyIcon,
  SaveRounded as SaveIcon
} from '@mui/icons-material';

import {
  ConfirmDeleteAlert,
  FileInputButton,
  Gap,
  DataGridFrame,
  BrandSelector,
  Spacer,
  CenteredRow,
  CopySizeChartsDialogProps,
  CopySizeChartsDialog,
  CopySizeChartInfo,
  Container,
  FormRow,
  FormSection,
  FormSectionTitle,
  converItemsToItemRows,
  SizeChartItemEditor,
  Table
} from 'components';

import { ImportItemsDialog } from 'components/dialogs';

import {
  Brand,
  downloadObjectsAsCsv,
  isNotNullOrUndefined,
  Item,
  useSpot,
  CsvItem,
  ItemAdjustment,
  formatDate,
  ConfirmDeleteDialogState,
  ItemRow,
  ItemsDialogState
} from 'framework';
import { Box } from '@mui/material';
import moment from 'moment';

const TtsSkewInfo = styled(Typography)`
  padding: ${p => p.theme.spacing(1)};
  margin-bottom: 30px;
`;

const MaxHeightGrid = styled(Grid)`
  position: relative;
  height: 100%;
`;

const MaxHeightContainer = styled(Container)`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const AdjustmentTableWrapper = styled(Box)`
  flex: 1 0 200px;
`;

export interface SizeChartSetupProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function SizeChartSetup({ onLoadingChanged }: SizeChartSetupProps) {
  const { brandOrProduct: brand, brandOrItem: item } = useParams<{
    brandOrProduct?: string;
    brandOrItem?: string;
  }>();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();
  const { spot, query, command, data, loading } = useSpot();
  const [selectedItem, setSelectedItem] = useState<ItemRow | null>();
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [itemsDialog, setItemsDialog] = useState<ItemsDialogState | null>(null);
  const [itemRows, setItemRows] = useState<ItemRow[]>([]);
  const [confirmDelete, setConfirmDelete] = useState<ConfirmDeleteDialogState<
    number[]
  > | null>(null);

  const [copySizeChartsDialog, setCopySizeChartsDialog] =
    useState<CopySizeChartsDialogProps | null>(null);

  useEffect(() => {
    if (spot.data.loading) {
      return;
    }
    const newPath = `/size-and-fit/size-charts/${
      selectedBrand?.slug ?? brand ?? ''
    }${
      (selectedItem?.id || item) && brand
        ? `/${encodeURIComponent(`${selectedItem?.id ?? item}`)}`
        : ''
    }`;

    if (decodeURIComponent(newPath) !== decodeURIComponent(location.pathname)) {
      navigate(newPath);
    }
  }, [selectedBrand, navigate, location, spot, selectedItem, item, brand]);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  useEffect(() => {
    (async () => {
      const promises: Promise<unknown>[] = [];

      if (!spot.data.measurements) {
        promises.push(query('measurement/', {}, ['measurements']));
      }
      if (!spot.data.products) {
        promises.push(query('product/', {}, ['products']));
      }
      if (!spot.data.types) {
        promises.push(query('type/', {}, ['types']));
      }
    })();
  }, [query, spot]);

  const onChangeTtsSkew = useCallback(
    (_, value) => {
      if (!selectedItem) {
        return;
      }

      setSelectedItem({
        ...selectedItem,
        ttsSkew: Number(value)
      });
    },
    [setSelectedItem, selectedItem]
  );

  const refreshSelected = useCallback(
    async (id: string | undefined) => {
      if (selectedBrand) {
        await query(`item/?brandId=${selectedBrand.id}`, {}, ['items']);
      }
      const rows = converItemsToItemRows(spot.data.items ?? []);
      setItemRows(rows);

      if (id) {
        setSelectedItem(rows.find(i => i.id === id));
      } else {
        setSelectedItem(null);
      }
    },
    [query, selectedBrand, setItemRows, spot]
  );

  useEffect(() => {
    (async () => {
      await refreshSelected(item);
    })();
  }, [refreshSelected, item]);

  const deleteItems = useCallback(
    async (ids: number[] | undefined) => {
      setConfirmDelete(null);

      if (ids) {
        const promises = ids.map(id =>
          command(`item/${id}`, undefined, {
            method: 'DELETE'
          })
        );
        await Promise.all(promises);

        await refreshSelected(undefined);
      }
    },
    [command, setConfirmDelete, refreshSelected]
  );

  const confirmDeleteItems = useCallback(
    async (ids: number[]) => {
      if (ids) {
        setConfirmDelete({
          text: t('deleteItemConfirmationText'),
          title: t('deleteItemConfirmation'),
          value: ids,
          onConfirm: (itemIds: number[] | undefined) => deleteItems(itemIds)
        });
      }
    },
    [setConfirmDelete, deleteItems, t]
  );

  const itemHeaders: GridColumns = [
    {
      field: 'brandName',
      headerName: t('brandName'),
      flex: 1,
      hide: !!selectedItem
    },
    { field: 'gender', headerName: t('gender'), flex: 1 },
    { field: 'productName', headerName: t('productName'), flex: 1 },
    {
      field: 'id',
      headerName: ' ',
      width: 40,
      resizable: false,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => (
        <IconButton
          className="grid-icon-button"
          onClick={() => confirmDeleteItems(params.row.itemIds)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      )
    }
  ];

  const handleImportItems = async (file: File) => {
    setItemsDialog({
      file,
      onClose: () => setItemsDialog(null),
      onSubmit: async (items: CsvItem[]) => {
        if (!selectedBrand) {
          setItemsDialog(null);
          return;
        }

        const fails = items.some(
          i =>
            !data.brands.find(b => b.name === i.Brand) ||
            !data.products.find(p => p.name === i.Product) ||
            !data.measurements.find(m => m.name === i.Measurement)
        );

        if (fails) {
          console.error(`Error: Failed items ${JSON.stringify(items)}`);
          throw new Error(
            "Failed to parse items. Check the Products, brands and measurements to see if there's one that isn't in the database"
          );
        }

        const mapSizes = (itemToMap: CsvItem) => {
          const valueKeys = Object.keys(itemToMap)
            .filter(innerItem => innerItem.match(/value[0-9]+/gi))
            .map(innerItem => innerItem.substring('value'.length));

          // ts-ignore
          return valueKeys
            .map(i => {
              const maxValue = (itemToMap as any)[`MaxValue${i}`];
              return {
                label: (itemToMap as any)[`Label${i}`],
                value: parseInt((itemToMap as any)[`Value${i}`], 10),
                maxValue: maxValue ? parseInt(maxValue, 10) : null
              };
            })
            .filter(
              i =>
                isNotNullOrUndefined(i.label) && isNotNullOrUndefined(i.value)
            )
            .sort((a, b) => a.value - b.value);
        };

        const dbItems = items.map(itemToMap => {
          const sizes = mapSizes(itemToMap);
          return {
            id: data.items.find(
              i =>
                i.brandName === itemToMap.Brand &&
                i.gender === itemToMap.Gender &&
                i.productName === itemToMap.Product &&
                i.measurementName === itemToMap.Measurement
            )?.id,
            brandId: data.brands.find(b => b.name === itemToMap.Brand)?.id,
            productId: data.products.find(p => p.name === itemToMap.Product)
              ?.id,
            measurementId: data.measurements.find(
              m => m.name === itemToMap.Measurement
            )?.id,
            models: [],
            sizes,
            limit: itemToMap.Limit
              ? itemToMap.Limit
              : sizes[sizes.length - 1].value +
                (sizes[sizes.length - 1].value - sizes[sizes.length - 2].value),
            gender: itemToMap.Gender,
            ttsSkew: itemToMap.TTS
          };
        });

        await command('item/import', { items: dbItems });

        await refreshSelected(undefined);
      }
    });
  };

  const handleExportItems = useCallback(
    async (currentBrand: Brand | null) => {
      if (currentBrand && spot.data.items) {
        const rows = spot.data.items
          .map((itemToMap: Item) => {
            // Properties assignment order here defines the column order in the target CSV
            const csvItem = {
              Brand: itemToMap.brandName,
              Gender: itemToMap.gender,
              Measurement: itemToMap.measurementName,
              Product: itemToMap.productName,
              Limit: itemToMap.limit,
              TTS: itemToMap.ttsSkew
            };

            Object.keys(itemToMap.sizes)
              .map(index => Number.parseInt(index, 10))
              .forEach((index: number) => {
                (csvItem as any)[`Label${index + 1}`] =
                  itemToMap.sizes[index]?.label;
                (csvItem as any)[`Value${index + 1}`] =
                  itemToMap.sizes[index]?.value;
                (csvItem as any)[`MaxValue${index + 1}`] =
                  itemToMap.sizes[index]?.maxValue;
              });

            return csvItem as unknown as CsvItem;
          })
          .sort((a, b) => Object.keys(b).length - Object.keys(a).length);

        const filename = `${currentBrand.slug}_items.csv`;
        downloadObjectsAsCsv(rows, filename);
      }
    },
    [spot]
  );

  const handleCopySizeCharts = useCallback(
    async (info: CopySizeChartInfo) => {
      await command(
        `item/copy-items-from/${selectedBrand?.slug}`,
        {
          sourceBrand: info.brand?.slug,
          sourceProduct: info.product?.name,
          overwriteExisting: !!info.overwriteExisting
        },
        {
          method: 'POST'
        }
      );
      setCopySizeChartsDialog(null);
      await refreshSelected(undefined);
    },
    [selectedBrand, setCopySizeChartsDialog, command, refreshSelected]
  );

  const handleSaveSkew = useCallback(async () => {
    if (!selectedItem) {
      return;
    }

    const promises = selectedItem.itemIds.map(id => {
      const originalItem = spot.data.items.find(i => id === i.id);
      return command(
        `item/${id}`,
        {
          ...originalItem,
          ttsSkew: selectedItem.ttsSkew
        },
        {
          method: 'PATCH'
        }
      );
    });

    await Promise.all(promises);

    await refreshSelected(selectedItem.id);
  }, [command, selectedItem, spot, refreshSelected]);

  const adjustments = useMemo(() => {
    return (
      selectedItem?.adjustments
        ?.sort((a, b) => {
          return moment(b.createdAt).diff(moment(a.createdAt), 'seconds');
        })
        ?.filter(
          (i, index, arr) =>
            arr.findIndex(
              a =>
                a.adjustmentType === i.adjustmentType &&
                a.reason === i.reason &&
                moment(i.createdAt).diff(moment(a.createdAt), 'seconds') === 0
            ) === index
        )
        ?.slice(0, 5)
        ?.map((adjustment: ItemAdjustment, index) => ({
          id: index,
          cells: [
            formatDate(adjustment.createdAt),
            t(adjustment.adjustmentType),
            adjustment.reason
          ]
        })) ?? []
    );
  }, [selectedItem]);

  return (
    <>
      {confirmDelete?.onConfirm && (
        <ConfirmDeleteAlert
          id="confirmItemDelete"
          title={confirmDelete?.title ?? ''}
          text={confirmDelete?.text ?? ''}
          open={!!confirmDelete}
          onConfirm={confirmDelete?.onConfirm}
          value={confirmDelete?.value}
          keepMounted
        />
      )}
      <CenteredRow>
        <BrandSelector
          includeAllBrands={false}
          onBrandChanged={setSelectedBrand}
          selectedBrand={selectedBrand}
          initialBrandSlug={brand}
        />
        <Spacer />
        <>
          <Button
            disabled={!selectedBrand || loading}
            variant="contained"
            color="primary"
            onClick={() =>
              selectedBrand &&
              setCopySizeChartsDialog({
                targetBrand: selectedBrand,
                dialogOpen: true,
                onClose: () => setCopySizeChartsDialog(null),
                onSubmit: handleCopySizeCharts
              })
            }
            startIcon={<CopyIcon />}
          >
            {t('copySizesFrom')}
          </Button>
          <Gap />
          <FileInputButton
            disabled={!selectedBrand || loading}
            buttonText={t('importItems')}
            onFileSelected={handleImportItems}
            icon={<ImportIcon />}
          />
          <Gap />
          <Button
            disabled={
              !selectedBrand ||
              !spot.data.items ||
              spot.data.items.length <= 0 ||
              loading
            }
            variant="contained"
            color="primary"
            onClick={() => handleExportItems(selectedBrand)}
            startIcon={<ExportIcon />}
          >
            {t('export')}
          </Button>
        </>
      </CenteredRow>
      <Gap />
      <DataGridFrame>
        <MaxHeightGrid container spacing={1}>
          <Grid item xs={selectedItem ? 5 : 12}>
            <DataGrid
              onRowClick={selectParams =>
                setSelectedItem(selectParams.row as ItemRow)
              }
              selectionModel={selectedItem ? selectedItem.id : undefined}
              disableColumnMenu
              disableColumnSelector
              loading={loading}
              rows={itemRows}
              columns={itemHeaders}
              filterModel={{
                items: [
                  {
                    columnField: 'brandName',
                    operatorValue: 'equals',
                    value: selectedBrand?.name
                  }
                ]
              }}
            />
          </Grid>

          {selectedItem && selectedBrand && (
            <MaxHeightGrid item xs={7}>
              <MaxHeightContainer>
                <FormSection>
                  <CenteredRow>
                    <FormSectionTitle>{t('ttsSkew')}</FormSectionTitle>
                    <Spacer />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSaveSkew}
                      startIcon={<SaveIcon />}
                      disabled={loading}
                    >
                      {t('save')}
                    </Button>
                  </CenteredRow>
                  <TtsSkewInfo variant="subtitle2">
                    {t('ttsSkewInfo')}
                  </TtsSkewInfo>
                  <FormRow>
                    <Slider
                      id="ttsSkew"
                      value={selectedItem.ttsSkew}
                      onChange={onChangeTtsSkew}
                      marks
                      track={false}
                      valueLabelDisplay="on"
                      step={0.1}
                      min={-2}
                      max={2}
                    />
                  </FormRow>
                </FormSection>
                <Gap />
                <SizeChartItemEditor
                  item={selectedItem}
                  refresh={refreshSelected}
                  onLoadingChanged={onLoadingChanged}
                  onDeleteItems={confirmDeleteItems}
                />
                <Gap />
                {!!adjustments.length && (
                  <AdjustmentTableWrapper>
                    <Table
                      rows={adjustments}
                      headers={[t('date'), t('type'), t('reason')]}
                      maxHeight="100%"
                    />
                  </AdjustmentTableWrapper>
                )}
              </MaxHeightContainer>
            </MaxHeightGrid>
          )}
        </MaxHeightGrid>
      </DataGridFrame>
      {!!itemsDialog && (
        <ImportItemsDialog
          dialogOpen={!!itemsDialog}
          onSubmit={itemsDialog?.onSubmit}
          onClose={itemsDialog?.onClose}
          file={itemsDialog?.file}
          brandName={selectedBrand?.name}
          existingItems={spot.data.items}
        />
      )}
      {!!copySizeChartsDialog && (
        <CopySizeChartsDialog {...copySizeChartsDialog} />
      )}
    </>
  );
}
