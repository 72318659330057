import { Size } from 'framework';

export const SizeLabelLookup = {
  XXXXXS: -6,
  '5XS': -6,
  XXXXS: -5,
  '4XS': -5,
  XXXS: -4,
  '3XS': -4,
  XXS: -3,
  '2XS': -3,
  XS: -2,
  S: -1,
  M: 0,
  L: 1,
  XL: 2,
  XXL: 3,
  '2XL': 3,
  XXXL: 4,
  '3XL': 4,
  XXXXL: 5,
  '4XL': 5,
  XXXXXL: 6,
  '5XL': 6,
  XXXXXXL: 7,
  '6XL': 7
};

export const sizesAreDifferent = (sizesA: Size[], sizesB: Size[]) => {
  if (sizesA?.length !== sizesB?.length) {
    return true;
  }
  const sizesASorted = sizesA.sort((a, b) =>
    `${a.label}`.localeCompare(b.label)
  );
  const sizesBSorted = sizesB.sort((a, b) =>
    `${a.label}`.localeCompare(b.label)
  );

  for (let i = 0; i < sizesASorted.length; i++) {
    if (
      sizesASorted[i].value !== sizesBSorted[i].value ||
      sizesASorted[i].maxValue !== sizesBSorted[i].maxValue
    ) {
      return true;
    }
  }

  return false;
};

export function getBiggestNumberInString(string: string) {
  const numberStrings = string.match(/\b[0-9]+\b/gi);
  if (!numberStrings) {
    return null;
  }
  const numbers = numberStrings.map(s => Number(s));
  return Math.max(...numbers);
}

export function compareSizes(sizeA: Size, sizeB: Size) {
  if (sizeA.value !== sizeB.value) {
    return sizeB.value - sizeA.value;
  }

  return compareLabels(sizeA.label, sizeB.label);
}

export function compareLabels(labelA: string, labelB: string) {
  const aLabelSize =
    SizeLabelLookup[labelA] ??
    getBiggestNumberInString(labelA) ??
    Number.parseInt(labelA, 10) ??
    0;
  const bLabelSize =
    SizeLabelLookup[labelB] ??
    getBiggestNumberInString(labelB) ??
    Number.parseInt(labelB, 10) ??
    0;
  return bLabelSize - aLabelSize;
}
