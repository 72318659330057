import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, SelectChangeEvent, Slider } from '@mui/material';
import {
  isNotNullOrUndefined,
  SizingAlgorithm,
  WidgetConfiguration
} from '../framework';

import {
  FormFieldWrapper,
  FormRow,
  FormLabel,
  FormSection
} from './form-input';

import { Gap, Spacer } from './spacer';

export interface WidgetSizesProps {
  updateWidgetConfiguration: (
    widgetConfiguration: WidgetConfiguration | null
  ) => unknown;
  widgetConfiguration: WidgetConfiguration | null | undefined;
  externallyChanged?: Partial<WidgetConfiguration>;
}

const maleWeightMarks = [
  {
    value: 40,
    label: '40'
  },
  {
    value: 80,
    label: '80'
  },
  {
    value: 120,
    label: '120'
  },
  {
    value: 160,
    label: '160'
  },
  {
    value: 200,
    label: '200 kg'
  }
];

const femaleWeightMarks = [
  {
    value: 40,
    label: '40'
  },
  {
    value: 70,
    label: '70'
  },
  {
    value: 100,
    label: '100'
  },
  {
    value: 130,
    label: '130'
  },
  {
    value: 160,
    label: '160 kg'
  }
];

const maleHeightMarks = [
  {
    value: 130,
    label: '130'
  },
  {
    value: 155,
    label: '155'
  },
  {
    value: 180,
    label: '180'
  },
  {
    value: 205,
    label: '205'
  },
  {
    value: 230,
    label: '230 cm'
  }
];

const femaleHeightMarks = [
  {
    value: 130,
    label: '130'
  },
  {
    value: 155,
    label: '155'
  },
  {
    value: 180,
    label: '180'
  },
  {
    value: 205,
    label: '205'
  },
  {
    value: 230,
    label: '230 cm'
  }
];

const kidsAgeMarks = [
  {
    value: 2,
    label: '2'
  },
  {
    value: 4,
    label: '4'
  },
  {
    value: 6,
    label: '6'
  },
  {
    value: 8,
    label: '8'
  },
  {
    value: 10,
    label: '10'
  },
  {
    value: 12,
    label: '12'
  },
  {
    value: 14,
    label: '14'
  },
  {
    value: 16,
    label: '16'
  }
];

export function WidgetConfigurationSizes({
  updateWidgetConfiguration,
  widgetConfiguration,
  externallyChanged
}: WidgetSizesProps) {
  const { t } = useTranslation();

  const updateMaleWeight = useCallback(
    (event: unknown, newValue: number | number[]) =>
      updateWidgetConfiguration({
        ...widgetConfiguration,
        maleMinWeight: newValue[0],
        maleMaxWeight: newValue[1]
      }),
    [updateWidgetConfiguration, widgetConfiguration]
  );

  const maleWeightValue = useMemo(
    () => [
      widgetConfiguration?.maleMinWeight ?? 60,
      widgetConfiguration?.maleMaxWeight ?? 130
    ],
    [widgetConfiguration?.maleMinWeight, widgetConfiguration?.maleMaxWeight]
  );

  const updateFemaleWeight = useCallback(
    (event: unknown, newValue: number | number[]) =>
      updateWidgetConfiguration({
        ...widgetConfiguration,
        femaleMinWeight: newValue[0],
        femaleMaxWeight: newValue[1]
      }),
    [updateWidgetConfiguration, widgetConfiguration]
  );

  const femaleWeightValue = useMemo(
    () => [
      widgetConfiguration?.femaleMinWeight ?? 45,
      widgetConfiguration?.femaleMaxWeight ?? 110
    ],
    [widgetConfiguration?.femaleMinWeight, widgetConfiguration?.femaleMaxWeight]
  );

  const updateMaleHeight = useCallback(
    (event: unknown, newValue: number | number[]) =>
      updateWidgetConfiguration({
        ...widgetConfiguration,
        maleMinHeight: newValue[0],
        maleMaxHeight: newValue[1]
      }),
    [updateWidgetConfiguration, widgetConfiguration]
  );

  const maleHeightValue = useMemo(
    () => [
      widgetConfiguration?.maleMinHeight ?? 160,
      widgetConfiguration?.maleMaxHeight ?? 210
    ],
    [widgetConfiguration?.maleMinHeight, widgetConfiguration?.maleMaxHeight]
  );

  const updateFemaleHeight = useCallback(
    (event: unknown, newValue: number | number[]) =>
      updateWidgetConfiguration({
        ...widgetConfiguration,
        femaleMinHeight: newValue[0],
        femaleMaxHeight: newValue[1]
      }),
    [updateWidgetConfiguration, widgetConfiguration]
  );

  const femaleHeightValue = useMemo(
    () => [
      widgetConfiguration?.femaleMinHeight ?? 150,
      widgetConfiguration?.femaleMaxHeight ?? 190
    ],
    [widgetConfiguration?.femaleMinHeight, widgetConfiguration?.femaleMaxHeight]
  );

  const updateKidsAge = useCallback(
    (event: unknown, newValue: number | number[]) =>
      updateWidgetConfiguration({
        ...widgetConfiguration,
        kidsMinAge: newValue[0],
        kidsMaxAge: newValue[1]
      }),
    [updateWidgetConfiguration, widgetConfiguration]
  );

  const kidsAgeValue = useMemo(
    () => [
      widgetConfiguration?.kidsMinAge ?? 2,
      widgetConfiguration?.kidsMaxAge ?? 16
    ],
    [widgetConfiguration?.kidsMinAge, widgetConfiguration?.kidsMaxAge]
  );

  return (
    <FormSection>
      <Gap />
      <FormRow
        highlighted={isNotNullOrUndefined(externallyChanged?.sizingAlgorithm)}
      >
        <Spacer />
        <FormLabel id="sizing-algorithm-label">
          {t('sizingAlgorithm')}
        </FormLabel>
        <FormFieldWrapper>
          <Select
            label={t('sizingAlgorithm')}
            labelId="sizing-algorithm-label"
            onChange={(event: SelectChangeEvent) =>
              updateWidgetConfiguration({
                ...widgetConfiguration,
                sizingAlgorithm:
                  (event.target.value as SizingAlgorithm) ?? undefined
              })
            }
            value={
              (widgetConfiguration?.sizingAlgorithm ?? 'gorgonzola') as string
            }
          >
            <MenuItem value="gorgonzola">Legacy (gorgonzola)</MenuItem>
            <MenuItem value="cheddar">November 2021 (cheddar)</MenuItem>
          </Select>
        </FormFieldWrapper>
        <Spacer />
      </FormRow>
      <Gap />
      <FormRow
        highlighted={
          isNotNullOrUndefined(externallyChanged?.maleMinWeight) ||
          isNotNullOrUndefined(externallyChanged?.maleMaxWeight)
        }
      >
        <Spacer />
        <FormLabel>{t('maleWeightRange')}</FormLabel>
        <FormFieldWrapper>
          <Slider
            value={maleWeightValue}
            onChange={updateMaleWeight}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={5}
            min={40}
            max={200}
            marks={maleWeightMarks}
          />
        </FormFieldWrapper>
        <Spacer />
      </FormRow>
      <Gap />
      <FormRow
        highlighted={
          isNotNullOrUndefined(externallyChanged?.maleMinHeight) ||
          isNotNullOrUndefined(externallyChanged?.maleMaxHeight)
        }
      >
        <Spacer />
        <FormLabel>{t('maleHeightRange')}</FormLabel>
        <FormFieldWrapper>
          <Slider
            value={maleHeightValue}
            onChange={updateMaleHeight}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={5}
            min={130}
            max={230}
            marks={maleHeightMarks}
          />
        </FormFieldWrapper>
        <Spacer />
      </FormRow>
      <Gap />
      <FormRow
        highlighted={
          isNotNullOrUndefined(externallyChanged?.femaleMinWeight) ||
          isNotNullOrUndefined(externallyChanged?.femaleMaxWeight)
        }
      >
        <Spacer />
        <FormLabel>{t('femaleWeightRange')}</FormLabel>
        <FormFieldWrapper>
          <Slider
            value={femaleWeightValue}
            onChange={updateFemaleWeight}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={5}
            min={40}
            max={160}
            marks={femaleWeightMarks}
          />
        </FormFieldWrapper>
        <Spacer />
      </FormRow>
      <Gap />
      <FormRow
        highlighted={
          isNotNullOrUndefined(externallyChanged?.femaleMinHeight) ||
          isNotNullOrUndefined(externallyChanged?.femaleMaxHeight)
        }
      >
        <Spacer />
        <FormLabel>{t('femaleHeightRange')}</FormLabel>
        <FormFieldWrapper>
          <Slider
            value={femaleHeightValue}
            onChange={updateFemaleHeight}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={5}
            min={130}
            max={230}
            marks={femaleHeightMarks}
          />
        </FormFieldWrapper>
        <Spacer />
      </FormRow>
      <FormRow
        highlighted={
          isNotNullOrUndefined(externallyChanged?.kidsMinAge) ||
          isNotNullOrUndefined(externallyChanged?.kidsMaxAge)
        }
      >
        <Spacer />
        <FormLabel>{t('kidsAgeRange')}</FormLabel>
        <FormFieldWrapper>
          <Slider
            value={kidsAgeValue}
            onChange={updateKidsAge}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            step={1}
            min={2}
            max={16}
            marks={kidsAgeMarks}
          />
        </FormFieldWrapper>
        <Spacer />
      </FormRow>
      <Gap />
    </FormSection>
  );
}
