import React, { useEffect, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

import {
  ExtendedRetailer,
  getCdnPath,
  getS3Path,
  getWidgetCdnPath,
  WidgetConfiguration
} from 'framework';
import { Gap } from 'components';
import { get } from 'http';

const WidgetPreviewContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  overflow: visible;

  ${p => p.theme.breakpoints.down('sm')} {
    width: calc(100vw - 80px);
  }
`;

const WidgetPreviewElement = styled(Box)``;

const WidgetButtonContainer = styled(Box)`
  width: 100%;
  z-index: 10;
  min-height: 240px;
  padding-top: 50px;
`;

interface WidgetPreviewProps {
  extendedRetailer: ExtendedRetailer;
  widgetConfiguration: WidgetConfiguration;
}

export function WidgetPreview({
  extendedRetailer,
  widgetConfiguration
}: WidgetPreviewProps) {
  const timeoutHandle = useRef(-1);

  const remountWidget = useCallback(() => {
    if (!extendedRetailer) {
      return;
    }

    const buttonParent = document.querySelector('#faslet-button-container');
    const widgetParent = document.querySelector('#faslet-widget-container');

    window._faslet = window._faslet ?? {
      variants: [],
      shopUrl: 'local'
    };
    window._faslet.retailer = {
      name: extendedRetailer.name ?? '',
      slug: extendedRetailer.slug ?? '',
      // Purposefully replace CDN URL with S3 URL for preview
      logo: extendedRetailer.logo?.replace(getCdnPath(), getS3Path()) ?? '',
      productType: 't-shirt',
      gender: 'male',
      requires: ['height', 'weight', 'belly', 'bust', 'hips', 'fit'],
      features: extendedRetailer.features ?? {},
      widgetConfiguration: {
        ...widgetConfiguration,
        // Purposefully replace CDN URL with S3 URL for preview
        headerImage:
          widgetConfiguration.headerImage?.replace(getCdnPath(), getS3Path()) ??
          '',
        // Purposefully replace locale auto detect
        locale:
          widgetConfiguration.locale ??
          window.navigator.language.substring(0, 2)
      }
    };

    const setupWidget = (id: string) => {
      const newElt = document.createElement('faslet-app', { is: 'faslet-app' });
      newElt.id = id;
      newElt.setAttribute('platform', 'unknown');
      newElt.setAttribute('product-name', 'T-Shirt');
      newElt.setAttribute('shop-id', extendedRetailer.name);
      newElt.setAttribute(
        'brand',
        extendedRetailer.brands[0]?.name ?? extendedRetailer.name
      );
      newElt.setAttribute('categories', 'Faslet_TShirt_Male');
      newElt.setAttribute(
        'product-img',
        `${getWidgetCdnPath()}/generic-t-shirt.png`
      );
      newElt.setAttribute('kiosk', 'true');
      newElt.setAttribute('mode', 'kiosk');
      return newElt;
    };

    if (buttonParent) {
      const existingButton = document.querySelector('#faslet-widget-button');
      if (existingButton) {
        buttonParent.removeChild(existingButton);
      }
      const button = setupWidget('faslet-widget-button');
      buttonParent.appendChild(button);
    }

    if (widgetParent) {
      const existingScreen = document.querySelector('#faslet-widget-screen');

      if (existingScreen) {
        widgetParent.removeChild(existingScreen);
      }
      const widgetEl = setupWidget('faslet-widget-screen');
      widgetEl.setAttribute('kiosk-screen', 'basic');
      widgetParent.appendChild(widgetEl);
    }
  }, [extendedRetailer, widgetConfiguration]);

  useEffect(() => {
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(remountWidget, 500);
  }, [remountWidget, timeoutHandle]);

  return (
    <WidgetPreviewContainer>
      <Gap size={1} />
      <WidgetPreviewElement id="faslet-widget-container" />
      <Gap size={4} />
      <WidgetButtonContainer id="faslet-button-container" />
      <Gap size={1} />
    </WidgetPreviewContainer>
  );
}
