import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

export interface ConfirmDeleteAlertProps<T> {
  id: string;
  value: T | undefined;
  open: boolean;
  onConfirm: (value: T | undefined) => unknown;
  title: string;
  text: string;
  keepMounted: boolean;
}

export function ConfirmDeleteAlert<T>(props: ConfirmDeleteAlertProps<T>) {
  const { t } = useTranslation();
  const { value, open, onConfirm, title, text, ...other } = props;

  const handleCancel = () => {
    onConfirm(undefined);
  };

  const handleOk = () => {
    onConfirm(value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => onConfirm(undefined)}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleOk} color="primary" autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
