import React from 'react';

import { useTheme } from '@nivo/core';
import { animated } from '@react-spring/web';
import { useBrandLink } from './brand-link';
import { useRetailerLink } from './retailer-link';

export function BrandLogoPieChartLinkLabel({ datum, label, style }) {
  const theme = useTheme();
  const { findBrand } = useBrandLink();
  const brandLogo = findBrand(datum.id as string)?.logo;
  return (
    <animated.g style={{ pointerEvents: 'none' }} opacity={style.opacity}>
      <animated.path
        fill="none"
        stroke={style.linkColor}
        strokeWidth={style.thickness}
        d={style.path}
      />
      {brandLogo ? (
        <>
          <animated.circle fill="#fff" r={26} transform={style.textPosition} />
          <animated.image
            transform={style.textPosition}
            x={-24}
            y={-24}
            href={brandLogo ?? ''}
            width={48}
            height={48}
          />
        </>
      ) : (
        <animated.text
          transform={style.textPosition}
          textAnchor={style.textAnchor}
          dominantBaseline="central"
          style={{
            ...theme.text,
            fill: style.textColor
          }}
        >
          {label}
        </animated.text>
      )}
    </animated.g>
  );
}

export function PieTooltip({ datum }) {
  const theme = useTheme();

  return (
    <div style={theme.tooltip.container}>
      <div style={theme.tooltip.basic}>
        <span>
          {datum.label}: <strong>{`${datum.value}`}</strong>
        </span>
      </div>
    </div>
  );
}

export function RetailerLogoPieChartLinkLabel({ datum, label, style }) {
  const theme = useTheme();
  const { findRetailer } = useRetailerLink();
  const retailerLogo = findRetailer(datum.id as string)?.logo;
  return (
    <animated.g style={{ pointerEvents: 'none' }} opacity={style.opacity}>
      <animated.path
        fill="none"
        stroke={style.linkColor}
        strokeWidth={style.thickness}
        d={style.path}
      />
      {retailerLogo ? (
        <>
          <animated.circle fill="#fff" r={26} transform={style.textPosition} />
          <animated.image
            transform={style.textPosition}
            x={-24}
            y={-24}
            href={retailerLogo ?? ''}
            width={48}
            height={48}
          />
        </>
      ) : (
        <animated.text
          transform={style.textPosition}
          textAnchor={style.textAnchor}
          dominantBaseline="central"
          style={{
            ...theme.text,
            fill: style.textColor
          }}
        >
          {label}
        </animated.text>
      )}
    </animated.g>
  );
}
