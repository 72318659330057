import React, { useEffect, useRef, useCallback } from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

const WidgetContainer = styled(Box)`
  min-height: 40px;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

const WidgetButtonContainer = styled(Box)`
  z-index: 10;
`;

interface WidgetProps {
  shopId: string;
  brand: string;
  productId: string;
}

export function Widget({ shopId, brand, productId }: WidgetProps) {
  const timeoutHandle = useRef(-1);

  const remountWidget = useCallback(() => {
    const buttonParent = document.querySelector('#faslet-button-container');

    window._faslet = window._faslet ?? {
      variants: [],
      shopUrl: 'local'
    };

    const setupWidget = (id: string) => {
      const newElt = document.createElement('faslet-app', { is: 'faslet-app' });
      newElt.id = id;
      newElt.setAttribute('platform', 'unknown');
      newElt.setAttribute('shop-id', shopId);
      newElt.setAttribute('brand', brand);
      newElt.setAttribute('product-identifier', productId);
      return newElt;
    };

    if (buttonParent) {
      const existingButton = document.querySelector('#faslet-widget-button');
      if (existingButton) {
        buttonParent.removeChild(existingButton);
      }
      const button = setupWidget('faslet-widget-button');
      buttonParent.appendChild(button);
    }
  }, [shopId, brand, productId]);

  useEffect(() => {
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(remountWidget, 500);
  }, [remountWidget, timeoutHandle]);

  return (
    <WidgetContainer>
      <WidgetButtonContainer id="faslet-button-container" />
    </WidgetContainer>
  );
}
