import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { useOrdinalColorScale } from '@nivo/colors';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import {
  ExitToAppRounded as PopoutIcon,
  PlayArrowRounded as TestIcon
} from '@mui/icons-material';

import {
  ProfileMeasurements,
  WidgetResponse,
  WidgetResponseInfo
} from 'components/dialogs';

import { Error } from 'spot-store';
import {
  Features,
  Item,
  ItemRow,
  ProductInfoListResponse,
  WidgetConfiguration,
  formatStat,
  openInNewTab,
  useErrorNotification,
  useSpot,
  Profile,
  WidgetErrorGroup
} from 'framework';
import { Gap, Row, converItemsToItemRows } from 'components';
import { Panel, TallChartContainer } from './common';

const ProductImageWrapper = styled.div`
  position: relative;
  height: 100%;
  margin: auto;
`;

export const chartMargins = { top: 20, right: 10, bottom: 60, left: 50 };

interface KeyedAggregate {
  gender: string;
  count: number;
  key: string;
}

type AggregatedProfile = KeyedAggregate & Profile;

interface WidgetErrorsInfoProps {
  widgetErrorGroup: WidgetErrorGroup;
}

export function WidgetErrorsInfo({ widgetErrorGroup }: WidgetErrorsInfoProps) {
  const { t } = useTranslation();

  const errorColorScale = useOrdinalColorScale({ scheme: 'category10' }, 'id');
  const profileColorScale = useOrdinalColorScale(
    { scheme: 'category10' },
    'id'
  );

  const [errorColors] = useState<
    Record<
      | 'size-label-mismatch'
      | 'no-sizes-for-brand'
      | 'no-nearest-sizes-found'
      | 'retailer-not-found'
      | 'brand-not-found',
      string
    >
  >({
    ['size-label-mismatch']: errorColorScale({ id: 'size-label-mismatch' }),
    ['no-sizes-for-brand']: errorColorScale({ id: 'no-sizes-for-brand' }),
    ['no-nearest-sizes-found']: errorColorScale({
      id: 'no-nearest-sizes-found'
    }),
    ['retailer-not-found']: errorColorScale({ id: 'retailer-not-found' }),
    ['brand-not-found']: errorColorScale({ id: 'brand-not-found' })
  });

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<ProductInfoListResponse[]>([]);
  const [itemRow, setItemRow] = useState<ItemRow | null>(null);
  const [profileMeasurements, setProfileMeasurements] =
    useState<ProfileMeasurements | null>(null);
  const [brandSlug, setBrandSlug] = useState<string | null>(null);

  const { displayErrors, notification } = useErrorNotification();
  const [widgetInfo, setWidgetInfo] = useState<{
    widgetConfig: WidgetConfiguration;
    features: Features;
  } | null>(null);
  const [widgetResponse, setWidgetResponse] = useState<WidgetResponse | null>(
    null
  );

  const { query, spot, raw } = useSpot();

  const loadProductInfo = useCallback(async () => {
    setLoading(true);
    const params = {
      brand: widgetErrorGroup.brand,
      retailer: widgetErrorGroup.retailer,
      productGender: widgetErrorGroup.gender,
      productType: widgetErrorGroup.productType
    } as Record<string, unknown>;

    try {
      await query(`product-info`, params, ['productInfos']);
    } catch (e) {
      displayErrors(e as Error[]);
    }

    setProducts([...(spot.data.productInfos?.products ?? [])]);

    setLoading(false);
  }, [query, spot, setProducts, setLoading, displayErrors, widgetErrorGroup]);

  useEffect(() => {
    loadProductInfo();
  }, [loadProductInfo]);

  const getItemRow = useCallback(
    async (brandId: number, product: string, gender: string) => {
      const items = await raw(`item/?brandId=${brandId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const allMeasurements = items.filter(
        (i: Item) => i.gender === gender && i.productName === product
      );

      if (!allMeasurements.length) {
        return null;
      }

      return converItemsToItemRows(allMeasurements)[0];
    },
    [raw]
  );

  const testProfile = useCallback(
    async (
      productId: string,
      testRetailer: string,
      testBrand: string,
      product: string,
      productGender: string,
      userGender: string,
      profile: {
        h: number;
        w: number;
        chest: number;
        hips: number;
        belly: number;
        fit: number;
      }
    ) => {
      setLoading(true);

      let algorithm;

      const lowerValue = testBrand.toLocaleLowerCase();

      let brandObj = spot.data?.brands?.find(
        v =>
          v.name.toLocaleLowerCase() === lowerValue ||
          v.slug.toLocaleLowerCase() === lowerValue
      );

      if (!brandObj) {
        const alias = spot.data?.aliases?.find(
          a => a.name.toLocaleLowerCase() === lowerValue
        );
        if (alias) {
          brandObj = spot.data?.brands?.find(b => b.id === alias.brandId);
        }
      }

      if (brandObj) {
        setBrandSlug(brandObj?.slug);
        try {
          const item = await getItemRow(brandObj.id, product, productGender);
          setItemRow(item);
        } catch (e) {
          setItemRow(null);
        }
      }

      try {
        const widgetInfoResponse = await raw(
          `widget/info/${testRetailer}/${testBrand}?productIdentifier=${productId}&gender=${productGender}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          },
          'omit'
        );

        algorithm =
          widgetInfoResponse?.widgetConfiguration?.sizingAlgorithm ?? 'cheddar';

        widgetInfoResponse &&
          setWidgetInfo({
            widgetConfig: widgetInfoResponse?.widgetConfiguration,
            features: widgetInfoResponse?.features
          });
        // eslint-disable-next-line no-empty
      } catch (e) {}

      try {
        const result = await raw(
          `widget/size/${testRetailer}/${testBrand}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product,
              gender: userGender,
              height: profile.h,
              weight: profile.w,
              measurements: {
                chest: profile.chest,
                waist: profile.belly,
                fit: profile.fit,
                hips: profile.hips
              },
              algorithm
            })
          },
          'omit'
        );
        setWidgetResponse(result);
      } catch (e) {
        const errorBody = JSON.parse((e as Error)[0].body);
        setWidgetResponse({
          errorMessage: errorBody.message,
          info: errorBody.info
        });
      }

      try {
        const measurements = await raw(
          `widget/size/measurements/${testRetailer}/${testBrand}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              product,
              productGender,
              userGender,
              height: profile.h,
              weight: profile.w,
              chest: profile.chest,
              waist: profile.belly,
              fit: profile.fit,
              hips: profile.hips
            })
          }
        );

        setProfileMeasurements(measurements);
      } catch (e) {
        const errorBody = JSON.parse((e as Error)[0].body);
        setWidgetResponse({
          errorMessage: errorBody.message,
          info: errorBody.info
        });
      }

      setLoading(false);
    },
    [raw, setWidgetResponse, getItemRow, spot, setWidgetInfo]
  );

  const pieData = useMemo(
    () =>
      widgetErrorGroup.errorMessages.reduce((accum, e) => {
        let found = accum.find(a => a.label === e);
        if (!found) {
          found = { label: e, id: t(e), value: 0 };
          accum.push(found);
        }
        found.value++;
        return accum;
      }, [] as any[]),
    [widgetErrorGroup.errorMessages, t]
  );

  const scatterplotData = useMemo(
    () =>
      widgetErrorGroup.profiles.reduce(
        (accum, p) => ({
          ...accum,
          data: [...accum.data, { x: p.w, y: p.h }]
        }),
        { id: t('profile'), data: [] as { x: number; y: number }[] }
      ),
    [widgetErrorGroup.profiles, t]
  );

  const profiles = useMemo(() => {
    return widgetErrorGroup.profiles
      .reduce((accum, p, index) => {
        const userGender = widgetErrorGroup.userGenders[index];
        const key = `${p.h}-${p.w}-${p.fit}-${p.chest}-${p.hips}-${p.belly}-${userGender}`;
        let found = accum.find(p => p.key === key);
        if (!found) {
          found = { ...p, gender: userGender, key, count: 0 };
          accum.push(found);
        }
        found.count++;
        return accum;
      }, [] as AggregatedProfile[])
      .sort((a, b) => b.count - a.count);
  }, [widgetErrorGroup.profiles]);

  const productHeaders: GridColumns = [
    {
      field: 'imageLink',
      headerName: t('productImage'),
      width: 80,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <ProductImageWrapper>
            <LazyLoadImage
              src={params.value as string}
              alt={params.row.name as string}
              height="100%"
              effect="opacity"
              decoding="async"
            />
          </ProductImageWrapper>
        ) : null
    },
    {
      field: 'productIdentifier',
      headerName: t('productId'),
      flex: 0.6,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <p title={params.value}>{params.value}</p>
      )
    },
    {
      field: 'name',
      headerName: t('title'),
      flex: 1.8,
      resizable: false,
      renderCell: (params: GridCellParams) => (
        <p title={params.row.name}>{`${
          params.row.customLabel0 ? `[${params.row.customLabel0}] ` : ''
        }${params.row.name}`}</p>
      )
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 64,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <Row>
          <IconButton
            disabled={!params.row.productUrl}
            onClick={() => openInNewTab(`${params.row.productUrl}`)}
            title={
              params.row.productUrl ? `${params.row.productUrl}` : undefined
            }
            size="large"
          >
            <PopoutIcon />
          </IconButton>
        </Row>
      )
    }
  ];

  const profileHeaders: GridColumns = [
    {
      field: 'h',
      headerName: t('height'),
      flex: 1,
      renderCell: (params: GridCellParams) => `${formatStat(params.value)} cm`
    },
    {
      field: 'w',
      headerName: t('weight'),
      flex: 1,
      renderCell: (params: GridCellParams) => `${formatStat(params.value)} kg`
    },
    {
      field: 'gender',
      headerName: t('gender'),
      renderCell: (params: GridCellParams) => t(params.value as string),
      flex: 1
    },
    {
      field: 'chest',
      headerName: t('chest'),
      width: 60
    },
    {
      field: 'hips',
      headerName: t('hips'),
      width: 60
    },
    {
      field: 'belly',
      headerName: t('belly'),
      width: 60
    },
    {
      field: 'fit',
      headerName: t('fit'),
      width: 60
    },
    {
      field: 'count',
      headerName: t('count'),
      flex: 1,
      renderCell: (params: GridCellParams) => formatStat(params.value)
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 64,
      renderCell: (params: GridCellParams) => (
        <Row>
          <IconButton
            onClick={() =>
              testProfile(
                widgetErrorGroup.productIds[0],
                widgetErrorGroup.retailer,
                widgetErrorGroup.brand,
                widgetErrorGroup.productType,
                widgetErrorGroup.gender,
                params.row.gender,
                params.row as Profile
              )
            }
          >
            <TestIcon />
          </IconButton>
        </Row>
      )
    }
  ];

  return (
    <Panel>
      <TallChartContainer>
        <Typography variant="h5" color="primary">
          {t('errorMessages')}
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {t('errorMessagesDescription')}
        </Typography>
        <ResponsivePie
          data={pieData}
          innerRadius={0.4}
          padAngle={3}
          animate={false}
          cornerRadius={8}
          activeOuterRadiusOffset={8}
          valueFormat={val => formatStat(val, false, 0)}
          colors={val => errorColors[val.label]}
          arcLabelsTextColor="#fff"
          arcLabelsSkipAngle={10}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={3}
          arcLinkLabelsDiagonalLength={8}
          arcLinkLabelsStraightLength={32}
          arcLinkLabelsColor={{ from: 'color' }}
          margin={chartMargins}
          borderWidth={5}
          borderColor="rgba(255,255,255,0)"
        />
      </TallChartContainer>
      <TallChartContainer>
        <Typography variant="h5" color="primary">
          {t('errorProfiles')}
        </Typography>
        <Typography variant="subtitle2" color="primary">
          {t('errorProfilesDescription')}
        </Typography>
        <ResponsiveScatterPlot
          data={[scatterplotData]}
          colors={profileColorScale}
          margin={chartMargins}
          xFormat={val => `${val} kg`}
          yFormat={val => `${val} cm`}
          animate={false}
          axisBottom={{
            legend: t('weight'),
            legendPosition: 'middle',
            legendOffset: 30
          }}
          axisLeft={{
            legend: t('height'),
            legendPosition: 'middle',
            legendOffset: -40
          }}
          xScale={{
            type: 'linear',
            min: 40,
            max: 150
          }}
          yScale={{
            type: 'linear',
            min: 140,
            max: 220
          }}
        />
      </TallChartContainer>
      <Gap />
      <Typography variant="h5" color="primary">
        {t('products')}
      </Typography>
      <Gap />
      <DataGrid
        rows={products}
        loading={loading}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        autoHeight
        columns={productHeaders}
        pageSize={10}
        getRowId={row => row.productIdentifier}
      />
      <Gap />
      <Gap />
      <Typography variant="h5" color="primary">
        {t('profiles')}
      </Typography>
      <Gap />
      <DataGrid
        rows={profiles}
        loading={loading}
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        columns={profileHeaders}
        pageSize={10}
        autoHeight
        getRowId={row => row.key}
        sortModel={[{ field: 'count', sort: 'desc' }]}
      />
      {notification}

      {!!widgetResponse && (
        <WidgetResponseInfo
          title={t('widgetResponseTitle')}
          dialogOpen={!!widgetResponse}
          widgetResponse={widgetResponse}
          profileMeasurements={profileMeasurements}
          item={itemRow}
          brandSlug={brandSlug}
          widgetInfo={widgetInfo}
          onClose={() => setWidgetResponse(null)}
        />
      )}
    </Panel>
  );
}
