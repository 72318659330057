import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowModel
} from '@mui/x-data-grid';
import {
  AddRounded as AddIcon,
  SaveRounded as SaveIcon,
  CancelRounded as CancelIcon,
  DeleteForeverRounded as DeleteIcon,
  ArrowBackRounded as BackIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  ExtendedProduct,
  Product,
  Type,
  useSpot,
  useErrorNotification,
  RoleContext
} from 'framework';

import {
  Container,
  Gap,
  Row,
  Spacer,
  ConfirmDeleteAlert,
  QuickSearchToolbar,
  escapeRegExp,
  DataGridImage,
  DataGridFrame,
  FormFieldWrapper,
  FormRow,
  FormLabel,
  FormTextInput
} from 'components';

export interface ProductEditorProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function ProductEditor({ onLoadingChanged }: ProductEditorProps) {
  const navigate = useNavigate();
  const { tagOrProduct: product } = useParams<{ tagOrProduct?: string }>();
  const { t } = useTranslation();
  const { spot, query, command, raw, data, loading } = useSpot();
  const [extendedProduct, setExtendedProduct] =
    useState<ExtendedProduct | null>();

  const [confirmProductDeleteOpen, setConfirmProductDeleteOpen] =
    useState<boolean>(false);

  const [deletedProductId, setDeletedProductId] = useState<
    string | undefined
  >();

  const [searchText, setSearchText] = useState<string>('');
  const [productRows, setProductRows] = useState<GridRowModel[]>([]);
  const [createTags, setCreateTags] = useState<boolean>(true);

  const { displayErrors, notification } = useErrorNotification();

  const { isAdmin } = useContext(RoleContext);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const refresh = useCallback(async () => {
    const promises: Promise<unknown>[] = [];

    promises.push(query('product/', {}, ['products']));
    promises.push(query('type/', {}, ['types']));

    await Promise.all(promises);
  }, [query]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    setProductRows(data.products ?? []);
  }, [data.products]);

  useEffect(() => {
    (async () => {
      if (product) {
        await query(`product/name/${product}`, {}, ['extendedProduct']);
        setExtendedProduct(spot.data.extendedProduct);
      } else {
        setExtendedProduct(null);
      }
    })();
  }, [query, product, spot, setExtendedProduct]);

  const errorText = useCallback(
    fieldName => {
      const requiredFields = ['name'];
      const existingFields = ['name'];

      const fieldValue = (extendedProduct as any)[fieldName];

      if (requiredFields.includes(fieldName) && !fieldValue) {
        return t('required');
      }

      if (
        existingFields.includes(fieldName) &&
        spot.data.products?.find(
          b =>
            (b as any)[fieldName].toLocaleLowerCase() ===
              fieldValue.toLocaleLowerCase() &&
            b.ageGroup === extendedProduct?.ageGroup &&
            b.id !== extendedProduct?.id
        )
      ) {
        return t('productExists');
      }
      return undefined;
    },
    [t, extendedProduct, spot]
  );

  const hasError = useCallback(
    fieldName => {
      return !!errorText(fieldName);
    },
    [errorText]
  );

  const validate = useCallback(
    (productObj: ExtendedProduct) => {
      return !Object.keys(productObj)
        .map(fieldName => hasError(fieldName))
        .includes(true);
    },
    [hasError]
  );

  const productHeaders: GridColumns = [
    {
      field: 'logoS3',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 70,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <DataGridImage alt={t('logo')} src={params.value?.toString()} />
        ) : (
          <span>&nbsp;</span>
        )
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select'
    },
    {
      field: 'ageGroup',
      headerName: t('ageGroup'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) => t(params.value as string)
    },
    {
      field: 'type',
      headerName: t('productType'),
      width: 150,
      resizable: false,
      sortable: false,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return isAdmin() ? (
          <Box display="flex">
            <Box>{(params.value as Type).name}</Box>
          </Box>
        ) : null;
      }
    },
    {
      field: 'itemsCount',
      headerName: t('productSizeChartsCount'),
      width: 130,
      resizable: false,
      sortable: false,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return isAdmin() ? (
          <Box display="flex">
            <Box>{(params.value as number) > 0 ? params.value : ''}</Box>
          </Box>
        ) : null;
      }
    },
    {
      field: 'id',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 40,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        isAdmin() ? (
          <IconButton
            className="grid-icon-button"
            onClick={() => confirmDeleteProduct(params.value)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
    }
  ];

  const addProduct = useCallback(async () => {
    setExtendedProduct({
      id: -1,
      name: '',
      type: data.types[0],
      ageGroup: 'adult'
    });
  }, [data]);

  const requestSearch = useCallback(
    (searchValue: string) => {
      setSearchText(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

      if (spot.data.products) {
        const filteredRows = spot.data.products.filter((row: GridRowModel) => {
          return searchRegex.test(row.name);
        });
        setProductRows(filteredRows);
      }
    },
    [setProductRows, spot, setSearchText]
  );

  const saveProduct = useCallback(
    async productObj => {
      if (productObj && validate(productObj)) {
        const productData = {
          ...productObj
        };

        try {
          await command(
            `product/${productObj.id > 0 ? productObj.id : ''}`,
            productData,
            {
              method: productObj.id > 0 ? 'PATCH' : 'POST'
            }
          );

          setExtendedProduct(null);
          navigate(`/tags-and-products/products`);

          const postfix = productObj.ageGroup === 'kids' ? '_kids' : '';

          const createdProduct = await raw(
            `product/name/${productData.name}${postfix}`,
            {}
          );

          await refresh();
          if (productObj.id <= 0 && createTags) {
            // Create 3 tags
            const promises = ['Male', 'Female', 'Unisex'].map(gender =>
              command(
                'tag/',
                {
                  name: `Faslet_${createdProduct.name.replace(
                    /(?:^|\s|_)([a-z])/gi,
                    match => match.toLocaleUpperCase()
                  )}_${gender}`,
                  gender: gender.toLocaleLowerCase(),
                  product: {
                    ...createdProduct
                  }
                },
                {
                  method: 'POST'
                }
              )
            );
            await Promise.all(promises);
          }

          requestSearch(searchText ?? '');
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    },
    [
      command,
      setExtendedProduct,
      navigate,
      searchText,
      requestSearch,
      validate,
      displayErrors,
      createTags,
      raw,
      refresh
    ]
  );

  const goBack = useCallback(async () => {
    await refresh();
    setExtendedProduct(null);
    navigate(`/tags-and-products/products`);
    requestSearch(searchText ?? '');
  }, [setExtendedProduct, navigate, searchText, requestSearch, refresh]);

  const cancelProductEdit = useCallback(
    async productObj => {
      if (productObj) {
        const originalProduct = spot.data.products.find(
          p => p.id === productObj.id
        );
        if (originalProduct) {
          setExtendedProduct({
            id: originalProduct.id,
            name: originalProduct.name,
            type: originalProduct.type
          });
        }
      }
    },
    [setExtendedProduct, spot]
  );

  const deleteProduct = useCallback(
    async (productId: string | undefined) => {
      setConfirmProductDeleteOpen(false);

      try {
        if (productId) {
          await command(`product/${productId}`, undefined, {
            method: 'DELETE'
          });

          await refresh();

          requestSearch(searchText ?? '');
        }
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [command, requestSearch, searchText, displayErrors, refresh]
  );

  const confirmDeleteProduct = useCallback(
    async productId => {
      if (productId) {
        setDeletedProductId(productId);
        setConfirmProductDeleteOpen(true);
      }
    },
    [setDeletedProductId, setConfirmProductDeleteOpen]
  );

  return !extendedProduct ? (
    <>
      <ConfirmDeleteAlert
        id="confirmProductDelete"
        title={t('deleteProductConfirmation')}
        text={t('deleteProductConfirmationText')}
        open={confirmProductDeleteOpen}
        onConfirm={(productId: string | undefined) => deleteProduct(productId)}
        value={deletedProductId}
        keepMounted
      />
      <Row>
        <Spacer />
        {isAdmin() && (
          <Button
            variant="contained"
            color="primary"
            onClick={addProduct}
            startIcon={<AddIcon />}
          >
            {t('addProduct')}
          </Button>
        )}
      </Row>
      <Gap />
      <DataGridFrame>
        <DataGrid
          onCellClick={(params: GridCellParams) => {
            const clickedField = params.colDef.field;
            if (!(clickedField === 'name')) {
              return;
            }
            if (params.row) {
              const selected = params.row as unknown as Product;
              navigate(`/tags-and-products/products/${selected.name}`);
            }
          }}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          loading={loading}
          rows={productRows}
          columns={productHeaders}
          autoPageSize
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (e: React.ChangeEvent<{ value: string }>) =>
                requestSearch(e.target.value),
              clearSearch: () => requestSearch('')
            }
          }}
        />
      </DataGridFrame>
      {notification}
    </>
  ) : (
    <>
      <Row>
        <Typography variant="h4">
          {data.products?.length > 1 && (
            <IconButton onClick={goBack} size="large">
              <BackIcon />
            </IconButton>
          )}
        </Typography>
        <Spacer />
        <Button
          disabled={!validate(extendedProduct)}
          variant="contained"
          color="primary"
          onClick={() => saveProduct(extendedProduct)}
          startIcon={<SaveIcon />}
        >
          {t('save')}
        </Button>
        {extendedProduct.id <= 0 ? null : (
          <>
            <Gap />
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => cancelProductEdit(extendedProduct)}
              startIcon={<CancelIcon />}
            >
              {t('reset')}
            </Button>
          </>
        )}
      </Row>
      <Gap />
      <Row>
        <Container>
          {extendedProduct.id <= 0 && (
            <FormRow>
              <FormLabel>{t('name')}</FormLabel>
              <FormFieldWrapper>
                <FormTextInput
                  validate={() => !hasError('name')}
                  onChange={(value: string) =>
                    setExtendedProduct({
                      ...extendedProduct,
                      name: value
                    })
                  }
                  value={extendedProduct.name}
                  endAdornment={
                    extendedProduct.ageGroup === 'kids' ? '_kids' : ''
                  }
                />
              </FormFieldWrapper>
            </FormRow>
          )}
          {extendedProduct.name && (
            <>
              <FormRow>
                <FormLabel id="productType-label">{t('productType')}</FormLabel>
                <FormFieldWrapper>
                  <Select
                    fullWidth
                    labelId="productType-label"
                    id="product-type"
                    value={extendedProduct.type?.id ?? data.types[0].id}
                    onChange={(event: SelectChangeEvent<number>) =>
                      setExtendedProduct({
                        ...extendedProduct,
                        type: data.types.find(
                          x => x.id === (event.target.value as number)
                        )
                      })
                    }
                  >
                    {data.types.map(type => (
                      <MenuItem key={`type_${type.id}`} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errorText('type')}</FormHelperText>
                </FormFieldWrapper>
              </FormRow>
              <FormRow>
                <FormLabel id="productAgeGroup-label">
                  {t('ageGroup')}
                </FormLabel>
                <FormFieldWrapper>
                  <Select
                    fullWidth
                    labelId="productAgeGroup-label"
                    id="product-ageroup"
                    value={extendedProduct.ageGroup ?? 'adult'}
                    onChange={(event: SelectChangeEvent<string>) =>
                      setExtendedProduct({
                        ...extendedProduct,
                        ageGroup: event.target.value as string
                      })
                    }
                  >
                    <MenuItem value="adult">{t('adult')}</MenuItem>
                    <MenuItem value="kids">{t('kids')}</MenuItem>
                  </Select>
                  <FormHelperText>{errorText('ageGroup')}</FormHelperText>
                </FormFieldWrapper>
              </FormRow>
            </>
          )}
          {extendedProduct.id <= 0 && extendedProduct.name && (
            <FormRow>
              <FormLabel>{t('createTagsWithProdct')}</FormLabel>
              <FormFieldWrapper>
                <Checkbox
                  checked={createTags}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setCreateTags(e.target.checked)
                  }
                />
              </FormFieldWrapper>
            </FormRow>
          )}
        </Container>
      </Row>
      {notification}
    </>
  );
}
