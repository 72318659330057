import React, { ReactNode, useCallback, useState } from 'react';
import { Box, Typography, CircularProgress, Popover } from '@mui/material';
import styled from '@emotion/styled';
import { HelpOutlineRounded as HelpIcon } from '@mui/icons-material';

import { Trans, useTranslation } from 'react-i18next';
import { Gap } from './spacer';
import { openInNewTab } from '../framework';
import upliftUpsell from '../images/statUpsell.png';

const StyledLink = styled.span`
  text-decoration: underline;
`;

const UpsellContainer = styled(Box)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const UpsellTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const UpsellImage = styled.img`
  border-radius: 18px;
  width: 100%;
  position: relative;
`;

const LinkBox = styled.div<{ active: boolean; hasEvent: boolean }>`
  height: 124px;
  width: 240px;
  position: relative;
  margin: 16px 16px 8px;
  border-radius: 18px;
  box-shadow: ${p => (p.active ? '4px 4px #dbdbdb' : '2px 2px #f2f2f2')};
  background-color: ${p => (p.active ? p.theme.palette.primary.main : 'unset')};
  pointer-events: ${p => (p.hasEvent ? 'auto' : 'none')};
  cursor: ${p => (p.hasEvent ? 'pointer' : 'auto')};
  border: 1px solid #50aa8d;
  color: ${p => p.theme.palette.primary.main};
`;

const IconBox = styled.div<{ active: boolean }>`
  position: absolute;
  top: ${p => p.theme.spacing(2)};
  right: ${p => p.theme.spacing(2)};
  color: ${p => (p.active ? 'white' : p.theme.palette.primary.main)};
  pointer-events: auto;
`;

const ButtonLabelContainer = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  color: ${p => (p.active ? 'white' : p.theme.palette.primary.main)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  user-select: none;
  text-align: center;
  padding: ${p => p.theme.spacing(2)};
`;

const ButtonTitle = styled(Typography)`
  font-size: 15px;
  align-self: flex-start;
  text-align: left;
`;

const ButtonValue = styled(Typography)<{ contentLength?: number }>`
  align-self: flex-start;
  font-size: ${p => ((p.contentLength ?? 0) > 9 ? '2.5rem' : '3rem')};
`;

const LoaderBox = styled(Box)`
  height: 4.5rem;
  padding: ${p => p.theme.spacing(2)};
`;

const InfoPopup = styled(Popover)`
  pointer-events: none;
`;

const InfoTextBox = styled(Box)`
  padding: ${p => p.theme.spacing(2)};
  border: 1px solid ${p => p.theme.palette.primary.main};
  border-radius: 5px;
  max-width: 400px;
`;

export function StatButton<T>({
  id,
  title,
  value,
  loading,
  info,
  onClick,
  active,
  upsell
}: {
  id: T;
  title: string;
  value: ReactNode;
  loading?: boolean;
  info?: string;
  onClick?: (id: T) => void;
  active?: boolean;
  upsell?: boolean;
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleShowGraph = useCallback(() => {
    if (upsell) {
      openInNewTab(
        `mailto:sales@faslet.me?subject=Enable ${title} Uplift&body=I'd like to enable ${title} uplift in my store`
      );
      return;
    }

    onClick && onClick(id);
  }, [id, onClick, upsell, title]);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return (
    <LinkBox active={!!active} hasEvent={!!onClick} onClick={handleShowGraph}>
      {upsell && (
        <UpsellContainer>
          <UpsellImage src={upliftUpsell} />
          <UpsellTextContainer>
            <Typography variant="h5" color="primary">
              {t('statDisabled')}
            </Typography>
            <Gap size={2} />
            <Typography variant="subtitle2" color="primary">
              <Trans
                i18nKey="upliftDisabledInfo"
                components={[<StyledLink key="sales" />]}
              />
            </Typography>
          </UpsellTextContainer>
        </UpsellContainer>
      )}

      {!upsell && (
        <ButtonLabelContainer active={!!active}>
          <ButtonTitle>{title}</ButtonTitle>
          <Gap size={1} />
          {loading ? (
            <LoaderBox>
              <CircularProgress color="inherit" />
            </LoaderBox>
          ) : (
            <ButtonValue variant="h3" contentLength={`${value}`.length}>
              {value}
            </ButtonValue>
          )}
        </ButtonLabelContainer>
      )}
      {!upsell && info && (
        <>
          <IconBox
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            active={!!active}
          >
            <HelpIcon fontSize="small" />
          </IconBox>
          <InfoPopup
            id="mouse-over-popover"
            open={!!anchorEl}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <InfoTextBox>{info}</InfoTextBox>
          </InfoPopup>
        </>
      )}
    </LinkBox>
  );
}
