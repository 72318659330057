import React, { useCallback, useState } from 'react';
import { GridRow, GridRowProps } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

import { isNotNullOrUndefined } from 'framework';
import { Row } from './layout';

const TableRow = styled(Row)<{
  dropTop: boolean;
  dropBottom: boolean;
  dragging: boolean;
}>`
  position: relative;
  cursor: pointer;
  :hover {
    background-color: #50aa8d20;
  }
  border-top: ${p => (p.dropTop ? '5px' : '0px')} solid rgba(0, 0, 0, 0.15);
  border-bottom: ${p =>
    p.dropBottom ? '5px solid rgba(0, 0, 0, 0.15)' : '1px solid #50aa8d30'};
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropZoneTop = styled(Box)<{
  hovered: boolean;
  acceptEvents: boolean;
}>`
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: calc(45% + 5px);
  pointer-events: ${p => (p.acceptEvents ? 'auto' : 'none')};
`;

const DropZoneBottom = styled(Box)<{
  hovered: boolean;
  acceptEvents: boolean;
}>`
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: calc(45% + 5px);
  pointer-events: ${p => (p.acceptEvents ? 'auto' : 'none')};
`;

export function DraggableGridRow({
  row,
  dragStart,
  dragOver,
  dragEnd,
  clickable,
  ...props
}: GridRowProps & {
  dragStart: (currentIndex: number) => unknown;
  dragOver: (targetIndex: number) => unknown;
  dragEnd: () => unknown;
  clickable: boolean;
}) {
  const index = row?.order;

  const [dropTarget, setDropTarget] = useState<'top' | 'bottom' | 'none'>(
    'none'
  );
  const [dragging, setDragging] = useState(false);

  const onDragStart = useCallback(() => {
    dragStart(index);
    setDragging(true);
  }, [dragStart, index]);

  const onDragEnd = useCallback(() => {
    dragEnd();
    setDragging(false);
  }, [dragEnd]);

  const onDragEnterTop = useCallback(() => {
    setDropTarget('top');
    dragOver(index);
  }, [index, setDropTarget, dragOver]);

  const onDragEnterBottom = useCallback(() => {
    setDropTarget('bottom');
    dragOver(index + 1);
  }, [index, setDropTarget, dragOver]);

  const onDragLeave = useCallback(() => {
    setDropTarget('none');
  }, [setDropTarget]);

  return (
    <TableRow
      draggable="true"
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      dropBottom={dropTarget === 'bottom'}
      dropTop={dropTarget === 'top'}
      dragging={dragging}
    >
      <DropZoneTop
        onDragEnter={onDragEnterTop}
        onDragLeave={onDragLeave}
        hovered={dropTarget === 'top'}
        acceptEvents={!clickable}
      />
      <DropZoneBottom
        onDragEnter={onDragEnterBottom}
        onDragLeave={onDragLeave}
        hovered={dropTarget === 'bottom'}
        acceptEvents={!clickable}
      />
      <GridRow {...props} row={row} />
    </TableRow>
  );
}

export function useDraggableGridRow(
  dragEnd: (startIndex: number, endIndex: number) => Promise<unknown>
) {
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [dragTargetIndex, setDragTargetIndex] = useState<number | null>(null);

  const onDragStart = useCallback(
    index => {
      setDragIndex(index);
      setDragTargetIndex(null);
    },
    [setDragIndex, setDragTargetIndex]
  );

  const onDragOver = useCallback(
    index => {
      setDragTargetIndex(index);
    },
    [setDragTargetIndex]
  );

  const onDragEnd = useCallback(async () => {
    const validDrag =
      isNotNullOrUndefined(dragIndex) && isNotNullOrUndefined(dragTargetIndex);
    if (!validDrag) {
      return;
    }

    await dragEnd(dragIndex, dragTargetIndex);

    setDragIndex(null);
    setDragTargetIndex(null);
  }, [dragIndex, dragTargetIndex, setDragIndex, setDragTargetIndex, dragEnd]);

  return {
    onDragStart,
    onDragOver,
    onDragEnd,
    isDragging: dragIndex !== null
  };
}
