import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { CenteredRow, Container, Row, Spacer } from 'components';
import { OpenInNewRounded as GotoLinkIcon } from '@mui/icons-material';
import { openInNewTab } from '../../framework';

const IntegrationBox = styled(Container)`
  position: relative;
  flex: 0 0 400px;
  height: 220px;
  padding: ${p => p.theme.spacing(2, 2)};
  margin: ${p => p.theme.spacing(2, 2)};
  border-radius: 18px;
  box-shadow: 2px 2px #f2f2f2;
  border: 2px solid ${p => p.theme.palette.primary.main};
`;

const IntegrationCompany = styled(Typography)`
  font-weight: 600;
  font-size: 1.2em;
`;

const IntegrationDescription = styled(Typography)`
  white-space: normal;
  font-size: 0.8em;
  margin-top: ${p => p.theme.spacing(1)};
`;
const InfoContainer = styled(Container)`
  position: relative;
  flex: 1 1 100%;
  padding-right: ${p => p.theme.spacing(2)};
`;

const ButtonContainer = styled(CenteredRow)`
  justify-content: flex-start;
`;

const NoWrapRow = styled(Row)`
  flex-wrap: nowrap;
  position: relative;
  flex: 1 1 100%;
`;

const IntegrationImage = styled.img`
  width: 64px;
  height: 64px;
  object-fit: scale-down;
  border: 0;
  margin-right: ${p => p.theme.spacing(2)};
  flex: 0 0 80px;
`;

const IntegrationButton = styled(Button)`
  flex: 1 1 110px;
`;

interface PlatformTileProps {
  name: string;
  logo: string;
  id: string;
  documentationUrl: string;
}

export function PlatformTile({
  name,
  logo,
  id,
  documentationUrl
}: PlatformTileProps) {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    openInNewTab(documentationUrl);
  }, [documentationUrl]);

  return (
    <IntegrationBox>
      <NoWrapRow>
        <IntegrationImage alt="companyImage" src={logo} />
        <InfoContainer>
          <IntegrationCompany>{name}</IntegrationCompany>
          <IntegrationDescription>
            {t(`${id}Description`)}
          </IntegrationDescription>
          <Spacer />
          <ButtonContainer>
            <IntegrationButton
              variant="contained"
              onClick={handleClick}
              startIcon={<GotoLinkIcon />}
            >
              {t(`readMore`)}
            </IntegrationButton>
          </ButtonContainer>
        </InfoContainer>
      </NoWrapRow>
    </IntegrationBox>
  );
}
