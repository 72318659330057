import React, { useCallback, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { SearchRounded as SearchIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { Spacer } from './spacer';

interface SearchFieldProps {
  debounceDelay?: number;
  loading?: boolean;
  onChange: (search: string) => unknown;
}

export function SearchField({
  loading,
  debounceDelay,
  onChange
}: SearchFieldProps) {
  const [search, setSearch] = useState('');
  const timeoutHandle = useRef(-1);
  const searchDelay = useRef(debounceDelay);
  const { t } = useTranslation();

  const onSearchChange = useCallback(
    e => {
      const newSearch = e.target.value;
      searchDelay.current = debounceDelay ?? 1000;
      setSearch(newSearch);

      if (timeoutHandle.current >= 0) {
        clearTimeout(timeoutHandle.current);
      }

      timeoutHandle.current = window.setTimeout(() => {
        onChange(newSearch);
      }, searchDelay.current);
    },
    [setSearch, debounceDelay, onChange]
  );

  return (
    <Spacer>
      <TextField
        onChange={onSearchChange}
        value={search}
        fullWidth
        disabled={loading}
        placeholder={t('searchTerm')}
        InputProps={{ startAdornment: <SearchIcon fontSize="small" /> }}
      />
    </Spacer>
  );
}
