import React, { useMemo, useRef, useState } from 'react';
import { Alert, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { useTranslation } from 'react-i18next';
import { useOrdinalColorScale } from '@nivo/colors';

import { ResponsivePie } from '@nivo/pie';
import { FormSection, FormSectionTitle, Gap, Row } from '../../components';
import { AccuracyStat, formatStat } from '../../framework';
import { compareLabels } from '../../helpers';

import {
  getBucketName,
  ageBuckets,
  addToBucket,
  heightBuckets,
  weightBuckets,
  fitBuckets,
  chestBuckets,
  waistBuckets,
  hipsBuckets,
  heightBaseData,
  weightBaseData,
  ageBaseData,
  fitBaseData,
  chestBaseData,
  waistBaseData,
  hipsBaseData,
  barMargins,
  heightRangeLabels,
  weightRangeLabels,
  fitLabels,
  chestLabels,
  waistLabels,
  hipsLabels,
  ageRangeLabels,
  isOrderedSize
} from './accuracy-helpers';

import {
  ChartContainer,
  RecommendationBar,
  RecommendationTab,
  TallChartContainer
} from './common';

export function VariantStatistics({ variant }: { variant: AccuracyStat }) {
  const colorScale = useOrdinalColorScale({ scheme: 'category10' }, 'id');
  const resultColorScale = useOrdinalColorScale({ scheme: 'category10' }, 'id');
  const returnColorScale = useOrdinalColorScale({ scheme: 'category10' }, 'id');

  const [colors] = useState<Record<'ordered' | 'recommended', string>>({
    recommended: resultColorScale({ id: 'recommended' }),
    ordered: resultColorScale({ id: 'ordered' })
  });

  const [returnColors] = useState<
    Record<'tooSmall' | 'tooBig' | 'wrongSize' | 'otherReturns', string>
  >({
    otherReturns: returnColorScale({ id: 'otherReturns' }),
    wrongSize: returnColorScale({ id: 'wrongSize' }),
    tooBig: returnColorScale({ id: 'tooBig' }),
    tooSmall: returnColorScale({ id: 'tooSmall' })
  });

  const { t } = useTranslation();

  const totalAgePoints = useRef(0);
  const totalChestPoints = useRef(0);
  const totalHipsPoints = useRef(0);

  const profileData = useMemo(() => {
    totalAgePoints.current = 0;
    totalChestPoints.current = 0;
    totalHipsPoints.current = 0;

    return (
      variant.profiles?.reduce(
        (profiles, profile) => {
          // Age Data
          const ageBucketName = getBucketName(ageBuckets, profile.a);

          if (ageBucketName && !Number.isNaN(ageBucketName)) {
            addToBucket(ageBucketName, profiles.ages);
            totalAgePoints.current += 1;
          }

          // Height Data
          const heightBucketName = getBucketName(heightBuckets, profile.h);

          if (heightBucketName && !Number.isNaN(heightBucketName)) {
            addToBucket(heightBucketName, profiles.heights);
          }

          // Weight Data
          const weightBucketName = getBucketName(weightBuckets, profile.w);

          if (weightBucketName && !Number.isNaN(weightBucketName)) {
            addToBucket(weightBucketName, profiles.weights);
          }

          // Fit Data
          const fitBucketName = getBucketName(fitBuckets, profile.fit);

          if (fitBucketName && !Number.isNaN(fitBucketName)) {
            addToBucket(fitBucketName, profiles.fit);
          }

          // Chest Data
          const chestBucketName = getBucketName(chestBuckets, profile.chest);

          if (chestBucketName && !Number.isNaN(chestBucketName)) {
            addToBucket(chestBucketName, profiles.chest);
            totalChestPoints.current += 1;
          }

          // Waist Data
          const waistBucketName = getBucketName(waistBuckets, profile.belly);

          if (waistBucketName && !Number.isNaN(waistBucketName)) {
            addToBucket(waistBucketName, profiles.waist);
          }

          // Hips Data
          const hipsBucketName = getBucketName(hipsBuckets, profile.hips);

          if (hipsBucketName && !Number.isNaN(hipsBucketName)) {
            addToBucket(hipsBucketName, profiles.hips);
            totalHipsPoints.current += 1;
          }

          return profiles;
        },
        {
          heights: [...heightBaseData],
          weights: [...weightBaseData],
          ages: [...ageBaseData],
          fit: [...fitBaseData],
          chest: [...chestBaseData],
          waist: [...waistBaseData],
          hips: [...hipsBaseData]
        }
      ) ?? {}
    );
  }, [variant, totalHipsPoints, totalChestPoints, totalAgePoints]);

  const resultsData = useMemo(
    () =>
      variant.results
        .reduce(
          (accum, r) => {
            const resultName = r.toLocaleUpperCase();
            let found = accum.find(res => res.name === resultName);

            if (!found) {
              found = {
                name: resultName,
                count: 0,
                countColor: isOrderedSize(resultName, variant.variant)
                  ? '#ff0000'
                  : '#000000'
              };
              accum.push(found);
            }
            found.count += 1;

            return accum;
          },
          [] as { name: string; count: number; countColor: string }[]
        )
        .sort((a, b) => compareLabels(b.name, a.name)),
    [variant]
  );

  const targetIndex = useMemo(
    () => resultsData.findIndex(r => isOrderedSize(r.name, variant.variant)),
    [resultsData, variant]
  );

  let countSum = 0;
  const delta = resultsData?.reduce((accum, r, index) => {
    countSum += r.count;
    if (index < targetIndex) {
      return accum - r.count;
    }
    if (index > targetIndex) {
      return accum + r.count;
    }
    return accum;
  }, 0);

  const safeDelta = 0.15;

  return (
    <>
      <FormSection>
        <FormSectionTitle>{t('variantRecommendations')}</FormSectionTitle>
        <Gap />
        <Alert severity="info">
          {Math.abs(delta / countSum) <= safeDelta && t('noAdjustment')}
          {delta / countSum < -safeDelta && t('adjustDownVariant')}
          {delta / countSum > safeDelta && t('adjustUpVariant')}
        </Alert>
        <Gap />
        <RecommendationBar>
          <RecommendationTab delta={delta / countSum} title={`${delta}`} />
        </RecommendationBar>
        <Gap />
      </FormSection>
      <Gap />
      <Row>
        <ChartContainer>
          <Typography variant="h5" color="primary">
            {t('resultsChartTitle')}
          </Typography>
          <ResponsiveBar
            data={resultsData}
            labelTextColor="#fff"
            colors={datum =>
              isOrderedSize(datum.data.name as string, variant.variant)
                ? colors.ordered
                : colors.recommended
            }
            tooltipLabel={val => `${t(val.id as string)}`}
            enableLabel={false}
            keys={['count']}
            groupMode="grouped"
            indexBy="name"
            padding={0.3}
            margin={barMargins}
            animate={false}
          />
        </ChartContainer>
        <ChartContainer>
          <Typography variant="h5" color="primary">
            {t('heightChartTitle')}
          </Typography>
          <ResponsiveBar
            data={profileData.heights}
            colors={colorScale}
            axisBottom={{
              format: (val: number) => `${heightRangeLabels[val]}`
            }}
            labelTextColor="#fff"
            tooltipLabel={val =>
              `${t(val.id as string)} (${
                heightRangeLabels[val.indexValue as number]
              })`
            }
            enableLabel={false}
            keys={['count']}
            groupMode="grouped"
            indexBy="name"
            padding={0.3}
            margin={barMargins}
            animate={false}
          />
        </ChartContainer>
        <ChartContainer>
          <Typography variant="h5" color="primary">
            {t('weightChartTitle')}
          </Typography>
          <ResponsiveBar
            data={profileData.weights}
            colors={colorScale}
            axisBottom={{
              format: (val: number) => `${weightRangeLabels[val]}`
            }}
            labelTextColor="#fff"
            tooltipLabel={val =>
              `${t(val.id as string)} (${
                weightRangeLabels[val.indexValue as number]
              })`
            }
            enableLabel={false}
            keys={['count']}
            groupMode="grouped"
            indexBy="name"
            padding={0.3}
            margin={barMargins}
            animate={false}
          />
        </ChartContainer>
        <ChartContainer>
          <Typography variant="h5" color="primary">
            {t('fitChartTitle')}
          </Typography>
          <ResponsiveBar
            data={profileData.fit}
            colors={colorScale}
            axisBottom={{
              format: (val: number) => `${fitLabels[val]}`
            }}
            labelTextColor="#fff"
            tooltipLabel={val =>
              `${t(val.id as string)} (${fitLabels[val.indexValue as number]})`
            }
            enableLabel={false}
            keys={['count']}
            groupMode="grouped"
            indexBy="name"
            padding={0.3}
            margin={barMargins}
            animate={false}
          />
        </ChartContainer>
        {totalChestPoints.current > 1 && (
          <ChartContainer>
            <Typography variant="h5" color="primary">
              {t('chestChartTitle')}
            </Typography>
            <ResponsiveBar
              data={profileData.chest}
              colors={colorScale}
              axisBottom={{
                format: (val: number) => `${chestLabels[val]}`
              }}
              labelTextColor="#fff"
              tooltipLabel={val =>
                `${t(val.id as string)} (${
                  chestLabels[val.indexValue as number]
                })`
              }
              enableLabel={false}
              keys={['count']}
              groupMode="grouped"
              indexBy="name"
              padding={0.3}
              margin={barMargins}
              animate={false}
            />
          </ChartContainer>
        )}
        <ChartContainer>
          <Typography variant="h5" color="primary">
            {t('waistChartTitle')}
          </Typography>
          <ResponsiveBar
            data={profileData.waist}
            colors={colorScale}
            axisBottom={{
              format: (val: number) => `${waistLabels[val]}`
            }}
            labelTextColor="#fff"
            tooltipLabel={val =>
              `${t(val.id as string)} (${
                waistLabels[val.indexValue as number]
              })`
            }
            enableLabel={false}
            keys={['count']}
            groupMode="grouped"
            indexBy="name"
            padding={0.3}
            margin={barMargins}
            animate={false}
          />
        </ChartContainer>
        {totalHipsPoints.current > 1 && (
          <ChartContainer>
            <Typography variant="h5" color="primary">
              {t('hipsChartTitle')}
            </Typography>
            <ResponsiveBar
              data={profileData.hips}
              colors={colorScale}
              axisBottom={{
                format: (val: number) => `${hipsLabels[val]}`
              }}
              labelTextColor="#fff"
              tooltipLabel={val =>
                `${t(val.id as string)} (${
                  hipsLabels[val.indexValue as number]
                })`
              }
              enableLabel={false}
              keys={['count']}
              groupMode="grouped"
              indexBy="name"
              padding={0.3}
              margin={barMargins}
              animate={false}
            />
          </ChartContainer>
        )}
        {totalAgePoints.current > 5 && (
          <ChartContainer>
            <Typography variant="h5" color="primary">
              {t('ageCount')}
            </Typography>
            <ResponsiveBar
              data={profileData.ages}
              colors={colorScale}
              axisBottom={{
                format: (val: number) => `${ageRangeLabels[val]}`
              }}
              labelTextColor="#fff"
              tooltipLabel={val =>
                `${t(val.id as string)} (${
                  ageRangeLabels[val.indexValue as number]
                })`
              }
              enableLabel={false}
              keys={['count']}
              groupMode="grouped"
              indexBy="name"
              padding={0.3}
              margin={barMargins}
              animate={false}
            />
          </ChartContainer>
        )}
        {variant.returned > 0 && (
          <TallChartContainer>
            <Typography variant="h5" color="primary">
              {t('returns')}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              {t('returnsGraphDescription')}
            </Typography>
            <ResponsivePie
              data={[
                {
                  label: 'otherReturns',
                  id: t('otherReturns'),
                  value:
                    variant.returned -
                    variant.wrongSize -
                    variant.tooBig -
                    variant.tooSmall
                },
                {
                  label: 'tooSmall',
                  id: t('tooSmall'),
                  value: variant.tooSmall
                },
                {
                  label: 'tooBig',
                  id: t('tooBig'),
                  value: variant.tooBig
                },
                {
                  label: 'wrongSize',
                  id: t('wrongSize'),
                  value: variant.wrongSize,
                  color: '#ff00ff'
                }
              ].filter(d => d.value > 0)}
              innerRadius={0.4}
              padAngle={3}
              cornerRadius={8}
              activeOuterRadiusOffset={8}
              valueFormat={val => formatStat(val, false, 0)}
              colors={val => returnColors[val.label]}
              arcLabelsTextColor="#fff"
              arcLabelsSkipAngle={10}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={3}
              arcLinkLabelsDiagonalLength={8}
              arcLinkLabelsStraightLength={32}
              arcLinkLabelsColor={{ from: 'color' }}
              margin={barMargins}
              borderWidth={5}
              borderColor="rgba(255,255,255,0)"
            />
          </TallChartContainer>
        )}
      </Row>
    </>
  );
}
