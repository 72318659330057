import React from 'react';
import styled from '@emotion/styled';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link } from '@mui/material';
import { FormSectionTitle } from 'components';
import { MailOutlineRounded as MailIcon } from '@mui/icons-material';

const BannerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 10px;
  margin: auto;
`;

const LinkBox = styled(Button)`
  display: flex;
  justify-content: center;
  color: ${p => p.theme.palette.primary.main};
`;

const MailIconStyled = styled(MailIcon)`
  padding-right: ${p => p.theme.spacing(1)};
`;

const RequestTitle = styled(FormSectionTitle)`
  color: ${p => p.theme.palette.primary.main};
  font-size: 18px;
`;

export function IntegrationRequest() {
  const { t } = useTranslation();

  const eventTrack = (category: string, action: string, label: string) => {
    ReactGA.event({
      category,
      action,
      label
    });
  };

  return (
    <BannerContainer>
      <RequestTitle>{t('requestIntegrationTitle')}</RequestTitle>
      <Link
        key="mailto"
        href="mailto:support@faslet.me?subject=Integration%20Request&body=Hello!%20I%20would%20like%20to%20add%20this%20integration:"
        onClick={() =>
          eventTrack(
            'integration_request',
            'integration_request_email',
            'integration_request'
          )
        }
      >
        <LinkBox>
          <MailIconStyled />
          {t('requestIntegrationButton')}
        </LinkBox>
      </Link>
    </BannerContainer>
  );
}
