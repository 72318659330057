import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  RetailerContext,
  useSpot,
  IntegrationDetails,
  openInNewTab,
  KnownIntegrations,
  RoleContext
} from 'framework';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import {
  Row,
  Line,
  Gap,
  ConfirmDeleteAlert,
  Container,
  SearchField
} from 'components';
import styled from '@emotion/styled';

import { IntegrationTile } from './integration-tile';
import {
  Sendcloud,
  Returnless,
  Returnista,
  Faslet,
  Buckles,
  Datafuse,
  IntegrationEditProps
} from './partners';
import { IntegrationRequest } from './integration-request';

import sendCloudImage from './sendcloud-logo.png';
import returnistaImage from './returnista-logo.png';
import fasletImage from './faslet-logo.png';
import returnlessImage from './returnless-logo.png';
import datafuseImage from './datafuse-logo.png';
import bucklesImage from './buckles-logo.svg';

import platformInstant from './platform-instant.png';
import platformLightspeed from './platform-lightspeed.png';
import platformShopify from './platform-shopify.png';
import platformWooCommerce from './platform-woocommerce.png';
import platformMagento from './platform-magento.png';
import platformShopware from './platform-shopware.png';
import platformNextChapter from './platform-nextchapter.png';
import platformOther from './platform-other.png';

import integrationsUpsell from '../../images/integrationsUpsell.png';
import { PlatformTile } from './platform-tile';

const StyledLink = styled.span`
  text-decoration: underline;
`;

const DisabledContainer = styled(Box)`
  flex: 1 1 auto;
  position: relative;
  cursor: pointer;
`;

const DisabledImage = styled.img`
  width: 100%;
  position: relative;
`;

const DisabledTextContainer = styled(Box)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 32px;
`;

const SelectRetailerText = styled(Box)`
  width: 35%;
  margin: auto;
`;

const IntegrationsRow = styled(Row)``;

interface ConfirmDeleteDialogState<T> {
  title: string;
  text: string;
  onConfirm: (value: T | undefined) => unknown;
  value: T;
}

interface ConnectionIntegration {
  id: KnownIntegrations;
  name: string;
  logo: string;
  linked: (integrationDetails?: IntegrationDetails) => boolean;
  EditComponent: (props: IntegrationEditProps) => JSX.Element;
}

const connectionIntegrations: ConnectionIntegration[] = [
  {
    id: 'faslet',
    name: 'Faslet API',
    logo: fasletImage,
    linked: (integrationDetails?: IntegrationDetails) =>
      !!integrationDetails?.fasletApi?.apiToken,
    EditComponent: (props: IntegrationEditProps) => <Faslet {...props} />
  },
  {
    id: 'sendcloud',
    name: 'Sendcloud',
    logo: sendCloudImage,
    linked: (integrationDetails?: IntegrationDetails) =>
      !!integrationDetails?.sendcloud?.apiKey ||
      !!integrationDetails?.sendcloud?.apiSecret,
    EditComponent: (props: IntegrationEditProps) => <Sendcloud {...props} />
  },
  {
    id: 'returnista',
    name: 'Returnista',
    logo: returnistaImage,
    linked: (integrationDetails?: IntegrationDetails) =>
      !!integrationDetails?.returnista?.apiKey,
    EditComponent: (props: IntegrationEditProps) => <Returnista {...props} />
  },
  {
    id: 'returnless',
    name: 'Returnless',
    logo: returnlessImage,
    linked: (integrationDetails?: IntegrationDetails) =>
      !!integrationDetails?.returnless?.apiUser ||
      !!integrationDetails?.returnless?.apiPassword,
    EditComponent: (props: IntegrationEditProps) => <Returnless {...props} />
  },
  {
    id: 'datafuse',
    name: 'Datafuse',
    logo: datafuseImage,
    linked: (integrationDetails?: IntegrationDetails) =>
      !!integrationDetails?.datafuse?.apiKey,
    EditComponent: (props: IntegrationEditProps) => <Datafuse {...props} />
  },
  {
    id: 'buckles',
    name: 'Buckles',
    logo: bucklesImage,
    linked: (integrationDetails?: IntegrationDetails) =>
      !!integrationDetails?.buckles?.fasletApiToken,
    EditComponent: (props: IntegrationEditProps) => <Buckles {...props} />
  }
];

interface PlatformIntegration {
  id: string;
  name: string;
  documentationUrl: string;
  logo: string;
}

const platformIntegrations: PlatformIntegration[] = [
  {
    id: 'shopify',
    name: 'Shopify',
    logo: platformShopify,
    documentationUrl: 'https://docs.faslet.net/docs/widget/shopify'
  },
  {
    id: 'magento',
    name: 'Magento',
    logo: platformMagento,
    documentationUrl: 'https://docs.faslet.net/docs/widget/magento'
  },
  {
    id: 'shopware',
    name: 'Shopware',
    logo: platformShopware,
    documentationUrl: 'https://docs.faslet.net/docs/widget/shopware'
  },
  {
    id: 'lightspeed',
    name: 'Lightspeed',
    logo: platformLightspeed,
    documentationUrl: 'https://docs.faslet.net/docs/widget/lightspeed'
  },
  {
    id: 'nextchapter',
    name: 'NextChapter',
    logo: platformNextChapter,
    documentationUrl: 'https://docs.faslet.net/docs/widget/nextchapter'
  },
  {
    id: 'instant',
    name: 'Instant',
    logo: platformInstant,
    documentationUrl: 'https://docs.faslet.net/docs/widget/instant-commerce'
  },
  {
    id: 'woocommerce',
    name: 'WooCommerce',
    logo: platformWooCommerce,
    documentationUrl: 'https://docs.faslet.net/docs/widget/woocommerce'
  },
  {
    id: 'other',
    name: 'Other Platforms',
    logo: platformOther,
    documentationUrl: 'https://docs.faslet.net/docs/widget/other'
  }
];

export function Integrations() {
  const { t } = useTranslation();
  const { query, spot, loading, command } = useSpot();
  const [edit, setEdit] = useState<KnownIntegrations | null>(null);
  const [integrationDetails, setIntegrationDetails] =
    useState<IntegrationDetails>();

  const [confirmDelete, setConfirmDelete] =
    useState<ConfirmDeleteDialogState<string> | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const { retailer } = useContext(RetailerContext);

  useEffect(() => {
    (async () => {
      await query('retailer/', {}, ['retailers']);
    })();
  }, [query]);

  const queryIntegrationDetails = useCallback(async () => {
    await query(`retailer/${retailer?.slug}/integrations`, {}, [
      'integrationDetails'
    ]);
    setIntegrationDetails(spot.data?.integrationDetails);
  }, [query, spot, retailer]);

  useEffect(() => {
    if (retailer?.slug && !edit) {
      queryIntegrationDetails();
    }
  }, [queryIntegrationDetails, retailer, edit]);

  useEffect(() => {
    setEdit(null);
  }, [retailer]);

  const deleteIntegration = useCallback(
    async (id: KnownIntegrations) => {
      setConfirmDelete({
        text: t('deleteSizeConfirmationText'),
        title: t('deleteSizeConfirmation'),
        value: id,
        onConfirm: async (value: string | undefined) => {
          setConfirmDelete(null);
          if (value) {
            const newIntegration = {
              ...integrationDetails
            } as IntegrationDetails;

            if (value === 'faslet') {
              newIntegration.fasletApi = {};
            } else {
              newIntegration[value] = {};
            }

            await command(
              `retailer/${retailer?.slug}/integrations`,
              { ...newIntegration },
              {
                method: 'POST'
              }
            );
            await queryIntegrationDetails();
            setEdit(null);
          }
        }
      });
    },
    [
      command,
      retailer,
      integrationDetails,
      queryIntegrationDetails,
      setConfirmDelete,
      t
    ]
  );

  const onClose = useCallback(() => {
    setEdit(null);
  }, [setEdit]);

  const handleIntegrationsUpsell = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Enable Integrations&body=I'd like to enable Integrations in my store`
    );
  }, []);

  const onEditIntegration = useCallback(
    async (id: KnownIntegrations) => {
      setSearchTerm('');
      await queryIntegrationDetails();
      setEdit(id);
    },
    [setEdit, queryIntegrationDetails]
  );

  const { isAdmin } = useContext(RoleContext);

  const showIntegrations = isAdmin() || retailer?.features?.integrations;
  const editIntegration =
    edit && connectionIntegrations.find(i => i.id === edit);

  const connections = connectionIntegrations.filter(s =>
    s.name.toLocaleLowerCase().startsWith(searchTerm?.toLocaleLowerCase())
  );

  const platforms = platformIntegrations.filter(s =>
    s.name.toLocaleLowerCase().startsWith(searchTerm?.toLocaleLowerCase())
  );

  return showIntegrations ? (
    <>
      <Row>
        <Typography variant="h4">{t('integrations')}</Typography>
      </Row>
      <Line />
      {editIntegration && (
        <editIntegration.EditComponent
          active={!!integrationDetails}
          slug={retailer?.slug}
          close={onClose}
          integration={integrationDetails}
          loading={loading}
        />
      )}
      {!edit &&
        (retailer ? (
          <>
            <Container>
              <SearchField onChange={setSearchTerm} />
              <Gap />
              <Typography variant="h5" color="primary" mx={2}>
                {t('connectionIntegrations')}
              </Typography>
              <IntegrationsRow>
                {connections.length ? (
                  connections.map((integration: ConnectionIntegration) => (
                    <IntegrationTile
                      key={integration.id}
                      linked={integration.linked(integrationDetails)}
                      onClick={onEditIntegration}
                      unlink={deleteIntegration}
                      name={integration.name}
                      id={integration.id}
                      logo={integration.logo}
                    />
                  ))
                ) : (
                  <Typography variant="body1" color="primary" mx={2}>
                    {t('noConnectionsFound')}
                  </Typography>
                )}
              </IntegrationsRow>
              <Gap size={4} />
              <Typography variant="h5" color="primary" mx={2}>
                {t('platformIntegrations')}
              </Typography>
              <IntegrationsRow>
                {platforms.length ? (
                  platforms.map((integration: PlatformIntegration) => (
                    <PlatformTile
                      key={integration.id}
                      name={integration.name}
                      id={integration.id}
                      logo={integration.logo}
                      documentationUrl={integration.documentationUrl}
                    />
                  ))
                ) : (
                  <Typography variant="body1" color="primary" mx={2}>
                    {t('noPlatformsFound')}
                  </Typography>
                )}
              </IntegrationsRow>
            </Container>
            <Gap />
            <Line style={{ width: '80%', margin: 'auto' }} />
            <Gap size={4} />
            <Typography
              variant="h4"
              align="center"
              color="primary"
              fontStyle="italic"
            >
              {t('comingSoon')}
            </Typography>
            <Gap size={3} />
            <IntegrationRequest />
          </>
        ) : (
          <>
            <Gap size={3} />
            <SelectRetailerText>
              <Typography>{t('integrationsDirections')}</Typography>
            </SelectRetailerText>
          </>
        ))}
      {confirmDelete?.onConfirm && (
        <ConfirmDeleteAlert
          id="confirmAppDelete"
          title={t('deleteAppConfirmation')}
          text={t('deleteAppConfirmationText')}
          open={!!confirmDelete}
          onConfirm={confirmDelete?.onConfirm}
          value={confirmDelete?.value}
          keepMounted
        />
      )}
    </>
  ) : (
    <DisabledContainer onClick={handleIntegrationsUpsell}>
      <DisabledImage src={integrationsUpsell} />
      <DisabledTextContainer>
        <Typography variant="h4" color="primary">
          {t('insightsDisabled')}
        </Typography>
        <Gap size={2} />
        <Typography variant="subtitle2" color="primary">
          <Trans
            i18nKey="insightsDisabledInfo"
            components={[<StyledLink key="link" />]}
          />
        </Typography>
      </DisabledTextContainer>
    </DisabledContainer>
  );
}
