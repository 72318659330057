import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { SaveRounded as SaveIcon } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router';

import { Brand, Product, SlackValue, SlackValueMode, useSpot } from 'framework';
import {
  Row,
  Container,
  Gap,
  Spacer,
  BrandSelector,
  CenteredRow,
  ProductSelector
} from 'components';
import styled from '@emotion/styled';
import { SlackValueSlider } from './slack-value-slider';

const SliderWrapper = styled(Row)`
  padding: ${p => p.theme.spacing(2)};
  flex-direction: column;
`;

interface SlackValuesProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function SlackValues({ onLoadingChanged }: SlackValuesProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { brandOrProduct: product, brandOrItem: brand } = useParams<{
    brandOrProduct?: string;
    brandOrItem?: string;
  }>();
  const { spot, query, command, loading } = useSpot();
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [currentSlackValues, setCurrentSlackValues] = useState<{
    [k: string]: SlackValue | undefined;
  }>({});

  const getMeasurementValue = useCallback(
    (measurement: string) =>
      spot.data.slackValues?.find(
        i =>
          i.measurement === measurement &&
          i.brand?.id === selectedBrand?.id &&
          i.product?.id === selectedProduct?.id
      ) ??
      spot.data.slackValues?.find(
        i =>
          i.measurement === measurement &&
          !i.product &&
          i.brand?.id === selectedBrand?.id
      ) ??
      spot.data.slackValues?.find(
        i =>
          i.measurement === measurement &&
          i.product?.id === selectedProduct?.id &&
          !i.brand
      ) ??
      spot.data.slackValues?.find(
        i => i.measurement === measurement && !i.product && !i.brand
      ),
    [selectedProduct, selectedBrand, spot]
  );

  const refresh = useCallback(async () => {
    await query('slack-value/', {}, ['slackValues']);

    const innerLegValue = getMeasurementValue('inner-leg');
    const heightValue = getMeasurementValue('height');
    const hipsValue = getMeasurementValue('hips');
    const chestValue = getMeasurementValue('chest');
    const waistValue = getMeasurementValue('waist');
    const footLengthValue = getMeasurementValue('foot-length');

    setCurrentSlackValues({
      'inner-leg': innerLegValue ? { ...innerLegValue } : undefined,
      height: heightValue ? { ...heightValue } : undefined,
      hips: hipsValue ? { ...hipsValue } : undefined,
      chest: chestValue ? { ...chestValue } : undefined,
      waist: waistValue ? { ...waistValue } : undefined,
      'foot-length': footLengthValue ? { ...footLengthValue } : undefined
    });
  }, [query, setCurrentSlackValues, getMeasurementValue]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (!spot.data.slackValues) {
      return;
    }
    let locationString = '/size-and-fit/slack-values/';

    if (selectedProduct) {
      locationString += selectedProduct.name;

      if (selectedBrand) {
        locationString += `/${selectedBrand.slug}`;
      }
    }

    navigate(locationString);
  }, [selectedBrand, selectedProduct, navigate, spot]);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const handleSave = useCallback(async () => {
    const isNew = (measurement: string) => {
      const original = spot.data.slackValues?.find(
        i =>
          i.measurement === measurement &&
          i.brand?.id === selectedBrand?.id &&
          i.product?.id === selectedProduct?.id
      );

      return !original;
    };

    const isDifferent = (measurement: string) => {
      const original = getMeasurementValue(measurement);
      return (
        currentSlackValues[measurement]?.negativeValue !==
          original?.negativeValue ||
        currentSlackValues[measurement]?.positiveValue !==
          original?.positiveValue ||
        currentSlackValues[measurement]?.mode !== original?.mode
      );
    };

    const promises = [
      'hips',
      'inner-leg',
      'chest',
      'waist',
      'height',
      'foot-length'
    ].map(measurement => {
      const slackValue = { ...currentSlackValues[measurement] } as SlackValue;
      if (!slackValue) {
        return Promise.resolve();
      }

      if (isDifferent(measurement)) {
        if (isNew(measurement)) {
          return command(
            `slack-value/`,
            {
              ...slackValue,
              id: null,
              brand: selectedBrand?.slug,
              product: selectedProduct?.name
            },
            { method: 'POST' }
          );
        }
        return command(
          `slack-value/${slackValue.id}`,
          {
            ...slackValue,
            brand: selectedBrand?.slug,
            product: selectedProduct?.name
          },
          { method: 'PATCH' }
        );
      }

      return Promise.resolve();
    });

    await Promise.all(promises);

    await refresh();
  }, [
    selectedBrand,
    selectedProduct,
    spot,
    currentSlackValues,
    getMeasurementValue,
    refresh,
    command
  ]);

  const handleProductChange = useCallback(
    (prod: Product | null) => {
      setSelectedProduct(prod);
    },
    [setSelectedProduct]
  );

  const handleValueChange = useCallback(
    (measurement: string, values: number[]) => {
      const slackValue = currentSlackValues[measurement];

      if (!slackValue) {
        return;
      }

      slackValue.negativeValue = values[0];
      slackValue.positiveValue = values[1];

      setCurrentSlackValues({
        ...currentSlackValues,
        [measurement]: slackValue
      });
    },
    [currentSlackValues, setCurrentSlackValues]
  );

  const handleModeChange = useCallback(
    (measurement: string, mode: SlackValueMode) => {
      const slackValue = currentSlackValues[measurement];

      if (!slackValue) {
        return;
      }

      slackValue.mode = mode;

      setCurrentSlackValues({
        ...currentSlackValues,
        [measurement]: slackValue
      });
    },
    [currentSlackValues, setCurrentSlackValues]
  );

  const canDelete = useCallback(
    (measurement: string) => {
      const slackValue = currentSlackValues[measurement];
      return !!(slackValue && (!!slackValue.product || !!slackValue.brand));
    },
    [currentSlackValues]
  );

  const handleReset = useCallback(
    async measurement => {
      const slackValue = currentSlackValues[measurement];

      if (!slackValue || !canDelete(measurement)) {
        return;
      }

      await command(`slack-value/${slackValue.id}`, undefined, {
        method: 'DELETE'
      });

      await refresh();
    },
    [command, refresh, currentSlackValues, canDelete]
  );

  return (
    <Container padding={0}>
      <CenteredRow>
        <ProductSelector
          includeAllProducts
          onProductChanged={handleProductChange}
          selectedProduct={selectedProduct}
          initialProductName={product}
        />
        <Gap />
        <BrandSelector
          includeAllBrands
          onBrandChanged={setSelectedBrand}
          selectedBrand={selectedBrand}
          initialBrandSlug={brand}
        />
        <Spacer />
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          {t('save')}
        </Button>
      </CenteredRow>
      <Gap />
      <Container padding={2}>
        <Row>
          <Typography variant="h5">{t('inner-leg')}</Typography>
        </Row>
        <SliderWrapper>
          <SlackValueSlider
            values={[
              currentSlackValues['inner-leg']?.negativeValue ?? 0,
              currentSlackValues['inner-leg']?.positiveValue ?? 0
            ]}
            onValuesChanged={handleValueChange}
            mode={currentSlackValues['inner-leg']?.mode ?? 'absolute'}
            id="inner-leg"
            onReset={handleReset}
            deleteDisabled={!canDelete('inner-leg')}
            onModeChanged={handleModeChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('chest')}</Typography>
        </Row>
        <SliderWrapper>
          <SlackValueSlider
            values={[
              currentSlackValues.chest?.negativeValue ?? 0,
              currentSlackValues.chest?.positiveValue ?? 0
            ]}
            onValuesChanged={handleValueChange}
            mode={currentSlackValues.chest?.mode ?? 'absolute'}
            id="chest"
            onReset={handleReset}
            deleteDisabled={!canDelete('chest')}
            onModeChanged={handleModeChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('waist')}</Typography>
        </Row>
        <SliderWrapper>
          <SlackValueSlider
            values={[
              currentSlackValues.waist?.negativeValue ?? 0,
              currentSlackValues.waist?.positiveValue ?? 0
            ]}
            onValuesChanged={handleValueChange}
            mode={currentSlackValues.waist?.mode ?? 'absolute'}
            id="waist"
            onReset={handleReset}
            deleteDisabled={!canDelete('waist')}
            onModeChanged={handleModeChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('hips')}</Typography>
        </Row>
        <SliderWrapper>
          <SlackValueSlider
            values={[
              currentSlackValues.hips?.negativeValue ?? 0,
              currentSlackValues.hips?.positiveValue ?? 0
            ]}
            onValuesChanged={handleValueChange}
            mode={currentSlackValues.hips?.mode ?? 'absolute'}
            id="hips"
            onReset={handleReset}
            deleteDisabled={!canDelete('hips')}
            onModeChanged={handleModeChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('height')}</Typography>
        </Row>
        <SliderWrapper>
          <SlackValueSlider
            values={[
              currentSlackValues.height?.negativeValue ?? 0,
              currentSlackValues.height?.positiveValue ?? 0
            ]}
            onValuesChanged={handleValueChange}
            mode={currentSlackValues.height?.mode ?? 'absolute'}
            id="height"
            onReset={handleReset}
            deleteDisabled={!canDelete('height')}
            onModeChanged={handleModeChange}
          />
        </SliderWrapper>
        <Gap />
        <Row>
          <Typography variant="h5">{t('footLength')}</Typography>
        </Row>
        <SliderWrapper>
          <SlackValueSlider
            values={[
              currentSlackValues['foot-length']?.negativeValue ?? 0,
              currentSlackValues['foot-length']?.positiveValue ?? 0
            ]}
            onValuesChanged={handleValueChange}
            mode={currentSlackValues['foot-length']?.mode ?? 'absolute'}
            id="foot-length"
            onReset={handleReset}
            deleteDisabled={!canDelete('foot-length')}
            onModeChanged={handleModeChange}
          />
        </SliderWrapper>
      </Container>
    </Container>
  );
}
