import React, { Fragment, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { IconButton, Link, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { GridOnRounded as SizeChartIcon } from '@mui/icons-material';

import {
  CenteredRow,
  SizeSpecDialog,
  FormFieldWrapper,
  FormLabel,
  FormRow,
  FormSection,
  FormSectionTitle,
  Gap,
  useBrandLink,
  ProductInfoSummary
} from 'components';

import {
  ProductInfoListResponse,
  SizeChartsUploadInfo,
  formatDateTime,
  useSpot
} from 'framework';

const Panel = styled(Paper)`
  padding: ${p => p.theme.spacing(2)};
`;

export function ProductInfo({
  product,
  onRefresh,
  onUpdateProduct
}: {
  product: ProductInfoListResponse;
  onRefresh: () => unknown;
  onUpdateProduct: (product: ProductInfoListResponse) => unknown;
}) {
  const { t } = useTranslation();
  const { spot } = useSpot();
  const [sizeSpecDialog, setSizeSpecDialog] =
    useState<SizeChartsUploadInfo | null>(null);
  useBrandLink();

  const variants = useMemo(() => {
    if (!product.retailerSlug) {
      return [];
    }
    return (
      spot.data.productInfo?.[product.retailerSlug]?.[product.productIdentifier]
        ?.variants ?? []
    );
  }, [spot.data.productInfo, product]);

  return (
    <Panel>
      <ProductInfoSummary
        product={product}
        onRefresh={onRefresh}
        onUpdateProduct={onUpdateProduct}
      />
      {product.sizeUploads?.length ? (
        <>
          <Gap />
          <FormSectionTitle>{t('uploadedSizeCharts')}</FormSectionTitle>
          <FormSection>
            {product.sizeUploads.map(sizeUpload => (
              <Fragment key={sizeUpload.id}>
                <FormRow key={sizeUpload.id}>
                  <FormLabel size="narrow">{t('sizeChart')}</FormLabel>
                  <FormFieldWrapper>
                    {sizeUpload.sizeChartFileUrl ? (
                      <Link
                        href={`${sizeUpload.sizeChartFileUrl}`}
                        target="_blank"
                        title={t('openSizeChartFile')}
                      >
                        {sizeUpload.sizeChartFile}
                      </Link>
                    ) : (
                      <CenteredRow>
                        <IconButton
                          color="primary"
                          onClick={() => setSizeSpecDialog(sizeUpload)}
                          size="large"
                        >
                          <SizeChartIcon />
                        </IconButton>
                        <span>{t('view')}</span>
                      </CenteredRow>
                    )}
                  </FormFieldWrapper>
                </FormRow>
                <FormRow>
                  <FormLabel size="narrow">{t('uploadedBy')}</FormLabel>
                  <FormFieldWrapper>
                    {sizeUpload.uploadedBy ? (
                      <Link
                        href={`mailto:${sizeUpload.uploadedBy.email}`}
                        target="_blank"
                        title={`${sizeUpload.uploadedBy.firstName} ${sizeUpload.uploadedBy.lastName}`}
                      >
                        {`${sizeUpload.uploadedBy.firstName} ${sizeUpload.uploadedBy.lastName}`}
                      </Link>
                    ) : (
                      'API'
                    )}
                  </FormFieldWrapper>
                </FormRow>
                <FormRow>
                  <FormLabel size="narrow">{t('uploadedAt')}</FormLabel>
                  <FormFieldWrapper>
                    {formatDateTime(sizeUpload.uploadedAt)}
                  </FormFieldWrapper>
                </FormRow>
              </Fragment>
            ))}
          </FormSection>
        </>
      ) : null}
      <Gap />
      <FormSectionTitle>{t('productVariants')}</FormSectionTitle>
      {variants.map(v => (
        <FormSection key={`${v.variantIdentifier}-${v.globalIdentifier}`}>
          <FormRow>
            <FormLabel size="narrow">{t('variantIdentifier')}</FormLabel>
            <FormFieldWrapper>
              <Typography>{v.variantIdentifier}</Typography>
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel size="narrow">{t('globalIdentifier')}</FormLabel>
            <FormFieldWrapper>
              <Typography>{v.globalIdentifier}</Typography>
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel size="narrow">{t('sizeLabel')}</FormLabel>
            <FormFieldWrapper>
              <Typography>{v.sizeLabel}</Typography>
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel size="narrow">{t('color')}</FormLabel>
            <FormFieldWrapper>
              <Typography>{v.color}</Typography>
            </FormFieldWrapper>
          </FormRow>
          <FormRow>
            <FormLabel size="narrow">{t('stockStatus')}</FormLabel>
            <FormFieldWrapper>
              <Typography>
                {v.available ? t('inStock') : t('outOfStock')}
              </Typography>
            </FormFieldWrapper>
          </FormRow>
        </FormSection>
      ))}
      {!!sizeSpecDialog && (
        <SizeSpecDialog
          title={t('sizeSpec')}
          dialogOpen={!!sizeSpecDialog}
          sizeSpec={sizeSpecDialog}
          onClose={() => setSizeSpecDialog(null)}
        />
      )}
    </Panel>
  );
}
