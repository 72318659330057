import React from 'react';
import { Retailer } from './spot-context';

interface RetailerContextObject {
  retailer: Retailer | null;
  onRetailerChanged: (retailer: Retailer | null) => unknown;
}

export const RetailerContext = React.createContext<RetailerContextObject>({
  retailer: null,
  onRetailerChanged: () => undefined
});
