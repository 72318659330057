import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Link,
  Alert,
  AlertTitle,
  Box,
  IconButton,
  Select,
  MenuItem,
  Typography,
  Button,
  Slider
} from '@mui/material';
import Papa from 'papaparse';

import {
  PublishRounded as ImportIcon,
  PlayArrowRounded as TestIcon,
  GetAppRounded as ExportIcon
} from '@mui/icons-material';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { downloadObjectsAsCsv, useSpot } from 'framework';
import {
  Spacer,
  FileInputButton,
  Gap,
  CenteredRow,
  FormRow,
  FormLabel
} from '../../components';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { batchItems } from 'helpers';

interface ResultPair {
  id: string;
  expected: string;
  fasletRecommended?: string;
  shopId: string;
  brand: string;
  product: string;
  match?: boolean;

  profile: {
    gender: string;
    height: number;
    weight: number;
    chest?: number;
    waist?: number;
    hips?: number;
    fit?: number;
  };
}

const NotAMatch = styled.span`
  color: red;
`;

const Match = styled.span`
  color: green;
`;

const StyledSelect = styled(Select)`
  width: 200px;
`;

const StyledSlider = styled(Slider)`
  width: 200px;
`;

const StyledAlert = styled(Alert)`
  margin: ${p => p.theme.spacing(2)};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

export interface WidgetResponse {
  label?: string;
  measurement?: string;
  min?: number;
  max?: number;
  info?: {
    label: string;
    delta: number;
    measurement: string;
    min: number;
    max: number;
    target: number;
    displayName: string | null;
    aliases: string[] | null;
  }[];
  errorMessage?: string;
}

export function stripSpecialRegexCharacters(input: string | undefined | null) {
  return input?.replace(/[.*+?^${}()|[\]\\]/g, '');
}

export function isOrderedSize(expected: string, result: string) {
  if (
    result?.trim().toLocaleLowerCase() === expected?.trim().toLocaleLowerCase()
  ) {
    return true;
  }

  const componentRegex =
    /^W?\s?([a-z]+|[0-9]+)[^a-z0-9]*[Lx]?[^a-z0-9]*([a-z]+|[0-9]*)[^a-z0-9]*$/i;

  const chartLabelComponents = componentRegex
    .exec(expected)
    ?.slice(1)
    ?.filter(s => !!s);

  const labelComponents = componentRegex
    .exec(result)
    ?.slice(1)
    ?.filter(s => !!s);

  // Site sends 30-32, and chart has 30/32, where all components match
  if (
    chartLabelComponents?.length === labelComponents?.length &&
    chartLabelComponents?.every(
      (component, index) =>
        component?.toLocaleLowerCase() ===
        labelComponents?.[index]?.toLocaleLowerCase()
    )
  ) {
    return true;
  }

  const sizeRegex = new RegExp(
    `\\b${stripSpecialRegexCharacters(expected?.toLocaleLowerCase())}\\b`
  );
  const strippedLabel = stripSpecialRegexCharacters(
    result.toLocaleLowerCase()
  )!;
  const matched = !!strippedLabel!.match(sizeRegex);

  if (!!matched) {
    // If we have a text search match, return a higher weight if the first component matches
    if (
      chartLabelComponents?.[0]?.toLocaleLowerCase() ===
      labelComponents?.[0]?.toLocaleLowerCase()
    ) {
      return true;
    }

    return true;
  }

  return false;
}

interface WidgetTesterProps {
  onLoadingChanged: (loading: boolean) => void;
}

export function WidgetTester({ onLoadingChanged }: WidgetTesterProps) {
  const { t } = useTranslation();
  const [resultPairs, setResultPairs] = useState<ResultPair[]>([]);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(100);
  const timeoutHandle = useRef(-1);
  const [limitInternal, setLimitInternal] = useState<number>(limit);
  const [filter, setFilter] = useState<string>('all');
  const [importedFile, setImportedFile] = useState<File | null>(null);
  const { raw } = useSpot();

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const onSetFilter = useCallback(
    event => {
      setFilter(event.target.value);
    },
    [setFilter]
  );

  async function getResultFromRow(row: ResultPair) {
    try {
      const result = (await raw(
        `widget/size/${row.shopId}/${row.brand}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            product: row.product,
            gender: row.profile.gender,
            height: row.profile.height,
            weight: row.profile.weight,
            measurements: {
              chest: row.profile.chest,
              waist: row.profile.waist,
              fit: row.profile.fit,
              hips: row.profile.hips
            }
          })
        },
        'omit'
      )) as WidgetResponse;
      return result;
    } catch (e) {
      return {} as WidgetResponse;
    }
  }

  const testRow = useCallback(
    async (row: ResultPair) => {
      setLoading(true);
      const result = await getResultFromRow(row);

      setResultPairs(prev => {
        const next = [...prev];
        const index = next.findIndex(r => r.id === row.id);
        next[index].fasletRecommended = result.label;
        next[index].match = result.label
          ? isOrderedSize(next[index].expected, result.label)
          : false;
        return next;
      });
      setLoading(false);
    },
    [raw, setResultPairs]
  );

  const handleTestSizes = useCallback(
    async (file: File) => {
      setResultPairs([]);
      setLoading(true);
      setImportedFile(file);

      const parsed = (await new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          complete: resolve,
          error: reject,
          dynamicTyping: true
        });
      })) as Papa.ParseResult<{
        shopId: string;
        brand: string;
        gender: string;
        product: string;
        result: string;
        height: number;
        weight: number;
        chest?: number;
        waist?: number;
        hips?: number;
        fit?: number;
      }>;

      const rows = [...parsed.data].slice(0, limit);
      const results: ResultPair[] = [];

      const batches = batchItems(rows, 20);
      while (batches.length) {
        const batch = batches.shift();
        if (!batch) continue;

        const promises = batch.map(async (row, index) => {
          if (!row) return;
          const nextRow: ResultPair = {
            id: `${row.shopId}-${row.brand}-${batches.length}-${index}`,
            expected: row.result,
            shopId: row.shopId,
            brand: row.brand,
            product: row.product,
            profile: {
              gender: row.gender,
              height: row.height,
              weight: row.weight,
              chest: row.chest,
              waist: row.waist,
              hips: row.hips,
              fit: row.fit
            }
          };
          results.push(nextRow);

          const result = await getResultFromRow(nextRow);
          nextRow.fasletRecommended = result.label;
          nextRow.match = result.label
            ? isOrderedSize(nextRow.expected, result.label)
            : false;
        });
        await Promise.all(promises);
        // setResultPairs(results);
      }
      setLoading(false);
      setResultPairs(results);
    },
    [limit, setResultPairs, setLoading, getResultFromRow, setImportedFile]
  );

  const rerunTests = useCallback(
    async () => (importedFile ? handleTestSizes(importedFile) : null),
    [resultPairs, handleTestSizes, importedFile]
  );

  const exportResults = useCallback(() => {
    downloadObjectsAsCsv(
      resultPairs.map(pair => ({
        'Shop ID': pair.shopId,
        Brand: pair.brand,
        Product: pair.product,
        'Source Result': pair.expected,
        'Faslet Result': pair.fasletRecommended,
        Match: pair.match,
        Profile: JSON.stringify(pair.profile)
      })),
      'widget-test-results.csv'
    );
  }, [resultPairs]);

  const headers: GridColumns = [
    {
      field: 'expected',
      headerName: t('expected'),
      width: 120
    },
    {
      field: 'fasletRecommended',
      headerName: t('fasletRecommended'),
      width: 120,
      renderCell: (params: GridCellParams) =>
        params.row.match ? (
          <Match>{params.value}</Match>
        ) : (
          <NotAMatch>{params.value}</NotAMatch>
        )
    },
    {
      field: 'profile',
      headerName: t('profile'),
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <>{JSON.stringify(params.value)}</>
      )
    },
    {
      field: 'actions',
      headerName: ' ',
      width: 128,
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => (
        <CenteredRow>
          <IconButton
            disabled={loading}
            onClick={() => testRow(params.row)}
            size="large"
          >
            <TestIcon />
          </IconButton>
        </CenteredRow>
      )
    }
  ];

  const filteredRows = useMemo(() => {
    if (filter === 'all') return resultPairs;
    return resultPairs.filter(row => {
      if (filter === 'matching') return row.match;
      if (filter === 'not-matching') return !row.match;
      return true;
    });
  }, [resultPairs, filter]);

  const matchingRows = useMemo(
    () => resultPairs.filter(row => row.match),
    [resultPairs]
  );

  const handleLimitChange = (event, newValue) => {
    setLimitInternal(newValue);
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(() => {
      setLimit(newValue);
    }, 500);
  };

  return (
    <>
      <CenteredRow>
        <FormRow>
          <FormLabel size="narrow">{t('maxRows')}</FormLabel>
          <StyledSlider
            value={limitInternal}
            onChange={handleLimitChange}
            marks
            valueLabelDisplay="on"
            step={10}
            min={50}
            max={250}
          />
        </FormRow>
        <Spacer />

        <Gap />
        <StyledSelect onChange={onSetFilter} value={filter}>
          <MenuItem value="all">{t('all')}</MenuItem>
          <MenuItem value="matching">{t('matching')}</MenuItem>
          <MenuItem value="not-matching">{t('notMatching')}</MenuItem>
        </StyledSelect>
        <Gap />
        <FileInputButton
          buttonText={t('importProductIds')}
          onFileSelected={handleTestSizes}
          loading={loading}
          icon={<ImportIcon />}
        />
        <Gap />
        <Button
          onClick={exportResults}
          startIcon={<ExportIcon />}
          disabled={!resultPairs.length}
          variant="contained"
        >
          {t('export')}
        </Button>
        <Gap />
        <Button
          onClick={rerunTests}
          endIcon={<TestIcon />}
          disabled={!importedFile || loading}
          variant="contained"
        >
          {t('test')}
        </Button>
      </CenteredRow>
      <CenteredRow>
        <Typography variant="body1">
          {!!resultPairs.length
            ? t('matchAmount', {
                matching: matchingRows.length,
                total: resultPairs.length
              })
            : t('uploadFileToSeeResults')}
        </Typography>
      </CenteredRow>
      <Gap />
      <Box
        style={{
          display: 'flex',
          height: '58vh',
          width: '100%',
          position: 'relative'
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={headers}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          loading={loading}
          autoPageSize
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
        />
      </Box>
    </>
  );
}

export default WidgetTester;
