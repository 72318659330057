import React, { useCallback, useMemo, useRef } from 'react';
import { useOrdinalColorScale } from '@nivo/colors';
import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import { GetAppRounded as ExportIcon } from '@mui/icons-material';

import { Button, Typography } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { DataGrid, GridColumns } from '@mui/x-data-grid';

import { downloadObjectsAsCsv, ErrorProfile, formatStat } from 'framework';
import {
  BrandLink,
  PieTooltip,
  FormLabel,
  FormRow,
  FormSection,
  FormTextValue,
  Gap,
  RetailerLink,
  Line,
  Spacer,
  Row,
  useRetailerLink
} from 'components';

import {
  ChartContainer,
  DataContainer,
  Panel,
  TallChartContainer
} from './common';
import {
  addToBucket,
  ageBaseData,
  ageBuckets,
  barMargins,
  chestBaseData,
  chestBuckets,
  chestLabels,
  fitBaseData,
  fitBuckets,
  fitLabels,
  getBucketName,
  heightBaseData,
  heightBuckets,
  hipsBaseData,
  hipsBuckets,
  hipsLabels,
  waistBaseData,
  waistBuckets,
  waistLabels,
  weightBaseData,
  weightBuckets
} from './accuracy-helpers';

interface ErrorProfileInfoProps {
  errorProfile: ErrorProfile;
}

export function ErrorProfileInfo({ errorProfile }: ErrorProfileInfoProps) {
  const { t } = useTranslation();
  const { findRetailer } = useRetailerLink();
  const colorScale = useOrdinalColorScale({ scheme: 'category10' }, 'id');
  const referenceColorScale = useOrdinalColorScale(
    { scheme: 'category10' },
    'id'
  );

  const errorMessages = useMemo(
    () =>
      errorProfile.errorMessages
        .reduce((accum: Record<string, any>[], name) => {
          let found = accum.find(b => b.id === name);
          if (!found) {
            found = { id: name, label: t(name), value: 0 };
            accum.push(found);
          }

          found.value++;
          return accum;
        }, [])
        .sort((a, b) => b.value - a.value),
    [errorProfile]
  );

  const totalAgePoints = useRef(0);
  const totalChestPoints = useRef(0);
  const totalHipsPoints = useRef(0);

  const profileData = useMemo(() => {
    totalAgePoints.current = 0;
    totalChestPoints.current = 0;
    totalHipsPoints.current = 0;

    return (
      errorProfile.profiles.reduce(
        (profiles, profile) => {
          // Age Data
          const ageBucketName = getBucketName(ageBuckets, profile.a);

          if (ageBucketName && !Number.isNaN(ageBucketName)) {
            addToBucket(ageBucketName, profiles.ages);
            totalAgePoints.current += 1;
          }

          // Height Data
          const heightBucketName = getBucketName(heightBuckets, profile.h);

          if (heightBucketName && !Number.isNaN(heightBucketName)) {
            addToBucket(heightBucketName, profiles.heights);
          }

          // Weight Data
          const weightBucketName = getBucketName(weightBuckets, profile.w);

          if (weightBucketName && !Number.isNaN(weightBucketName)) {
            addToBucket(weightBucketName, profiles.weights);
          }

          // Fit Data
          const fitBucketName = getBucketName(fitBuckets, profile.fit);

          if (fitBucketName && !Number.isNaN(fitBucketName)) {
            addToBucket(fitBucketName, profiles.fit);
          }

          // Chest Data
          const chestBucketName = getBucketName(chestBuckets, profile.chest);

          if (chestBucketName && !Number.isNaN(chestBucketName)) {
            addToBucket(chestBucketName, profiles.chest);
            totalChestPoints.current += 1;
          }

          // Waist Data
          const waistBucketName = getBucketName(waistBuckets, profile.belly);

          if (waistBucketName && !Number.isNaN(waistBucketName)) {
            addToBucket(waistBucketName, profiles.waist);
          }

          // Hips Data
          const hipsBucketName = getBucketName(hipsBuckets, profile.hips);

          if (hipsBucketName && !Number.isNaN(hipsBucketName)) {
            addToBucket(hipsBucketName, profiles.hips);
            totalHipsPoints.current += 1;
          }

          return profiles;
        },
        {
          heights: [...heightBaseData],
          weights: [...weightBaseData],
          ages: [...ageBaseData],
          fit: [...fitBaseData],
          chest: [...chestBaseData],
          waist: [...waistBaseData],
          hips: [...hipsBaseData]
        }
      ) ?? {}
    );
  }, [errorProfile, totalHipsPoints, totalChestPoints, totalAgePoints]);

  const exportProducts = useCallback(() => {
    downloadObjectsAsCsv(errorProfile.products, 'error-profile-products.csv');
  }, [errorProfile]);

  const productHeaders: GridColumns = [
    {
      field: 'productId',
      headerName: t('productId'),
      flex: 1
    },
    {
      field: 'retailer',
      headerName: t('retailer'),
      flex: 1,
      renderCell: ({ value }) => <RetailerLink retailer={value} />
    },
    {
      field: 'brand',
      headerName: t('brand'),
      flex: 1,
      renderCell: ({ value, row }) => (
        <BrandLink brand={value} retailer={findRetailer(row.retailer)} />
      )
    },
    {
      field: 'productType',
      headerName: t('productType'),
      flex: 0.5,
      minWidth: 64
    },
    {
      field: 'productGender',
      headerName: t('productGender'),
      flex: 0.5,
      minWidth: 64
    },
    {
      field: 'count',
      headerName: t('count'),
      type: 'number',
      minWidth: 64,
      maxWidth: 64
    }
  ];

  return (
    <Panel>
      <FormSection>
        <FormRow>
          <FormLabel size="narrow">{t('height')}</FormLabel>
          <FormTextValue>{errorProfile.height}</FormTextValue>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('weight')}</FormLabel>
          <FormTextValue>{errorProfile.weight}</FormTextValue>
        </FormRow>
        <FormRow>
          <FormLabel size="narrow">{t('users')}</FormLabel>
          <FormTextValue>{errorProfile.users}</FormTextValue>
        </FormRow>
        {errorMessages.length === 1 && (
          <FormRow>
            <FormLabel size="narrow">{t('errorMessage')}</FormLabel>
            <FormTextValue>{t(errorProfile.errorMessages[0])}</FormTextValue>
          </FormRow>
        )}
      </FormSection>
      <Gap />
      <ChartContainer>
        <Typography variant="h5" color="primary">
          {t('fitChartTitle')}
        </Typography>
        <ResponsiveBar
          data={profileData.fit}
          colors={colorScale}
          axisBottom={{
            format: (val: number) => `${fitLabels[val]}`
          }}
          labelTextColor="#fff"
          tooltipLabel={val =>
            `${t(val.id as string)} (${fitLabels[val.indexValue as number]})`
          }
          enableLabel={false}
          keys={['count']}
          groupMode="grouped"
          indexBy="name"
          padding={0.3}
          margin={barMargins}
          animate={false}
        />
      </ChartContainer>
      {totalChestPoints.current > 1 && (
        <ChartContainer>
          <Typography variant="h5" color="primary">
            {t('chestChartTitle')}
          </Typography>
          <ResponsiveBar
            data={profileData.chest}
            colors={colorScale}
            axisBottom={{
              format: (val: number) => `${chestLabels[val]}`
            }}
            labelTextColor="#fff"
            tooltipLabel={val =>
              `${t(val.id as string)} (${
                chestLabels[val.indexValue as number]
              })`
            }
            enableLabel={false}
            keys={['count']}
            groupMode="grouped"
            indexBy="name"
            padding={0.3}
            margin={barMargins}
            animate={false}
          />
        </ChartContainer>
      )}
      <ChartContainer>
        <Typography variant="h5" color="primary">
          {t('waistChartTitle')}
        </Typography>
        <ResponsiveBar
          data={profileData.waist}
          colors={colorScale}
          axisBottom={{
            format: (val: number) => `${waistLabels[val]}`
          }}
          labelTextColor="#fff"
          tooltipLabel={val =>
            `${t(val.id as string)} (${waistLabels[val.indexValue as number]})`
          }
          enableLabel={false}
          keys={['count']}
          groupMode="grouped"
          indexBy="name"
          padding={0.3}
          margin={barMargins}
          animate={false}
        />
      </ChartContainer>
      {totalHipsPoints.current > 1 && (
        <ChartContainer>
          <Typography variant="h5" color="primary">
            {t('hipsChartTitle')}
          </Typography>
          <ResponsiveBar
            data={profileData.hips}
            colors={colorScale}
            axisBottom={{
              format: (val: number) => `${hipsLabels[val]}`
            }}
            labelTextColor="#fff"
            tooltipLabel={val =>
              `${t(val.id as string)} (${hipsLabels[val.indexValue as number]})`
            }
            enableLabel={false}
            keys={['count']}
            groupMode="grouped"
            indexBy="name"
            padding={0.3}
            margin={barMargins}
            animate={false}
          />
        </ChartContainer>
      )}
      {errorMessages.length > 1 && (
        <>
          <TallChartContainer>
            <ResponsivePie
              data={errorMessages}
              innerRadius={0.4}
              padAngle={1}
              cornerRadius={8}
              activeOuterRadiusOffset={8}
              valueFormat={val => formatStat(val, false, 0)}
              colors={referenceColorScale}
              arcLinkLabel={d => `${d.label}`}
              arcLabelsTextColor="#fff"
              arcLabelsSkipAngle={15}
              arcLinkLabelsSkipAngle={15}
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsThickness={3}
              arcLinkLabelsDiagonalLength={48}
              arcLinkLabelsStraightLength={32}
              arcLinkLabelsColor={{ from: 'color' }}
              tooltip={PieTooltip}
              margin={{ bottom: 60, right: 60, left: 60, top: 60 }}
            />
            <Gap />
            <Typography variant="subtitle2" color="primary">
              {t('errorMessages')}
            </Typography>
          </TallChartContainer>
          <Gap />
        </>
      )}
      <Gap />
      <Line />
      <Row>
        <Typography variant="h5" color="primary">
          {t('products')}
        </Typography>
        <Spacer />
        <Button
          variant="contained"
          color="primary"
          onClick={exportProducts}
          startIcon={<ExportIcon />}
        >
          {t('export')}
        </Button>
      </Row>
      <Gap />
      <DataContainer>
        <DataGrid
          rows={errorProfile.products}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          columns={productHeaders}
          pageSize={10}
          autoHeight
          getRowId={row => `${row.productId}-${row.retailer}-${row.brand}`}
          sortModel={[{ field: 'count', sort: 'desc' }]}
        />
      </DataContainer>
    </Panel>
  );
}
