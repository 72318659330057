export const actions = {
  '/brand': 'brand',
  '/item': 'item',
  '/measurement': 'measurement',
  '/profile': 'profile',
  '/multiplier': 'multiplier',
  '/product': 'product',
  '/tag': 'tag',
  '/retailer': 'retailer',
  '/size': 'size',
  '/size-upload': 'size-upload',
  '/type': 'type',
  '/user': 'user',
  '/experiments': 'experiments',
  '/shopify': 'shopify',
  '/tag-metadata': 'tag-metadata',
  '/slack-value': 'slack-value',
  '/product-predictor': 'product-predictor',
  '/product-info': 'product-info',
  '/global-configuration': 'global-configuration',
  '/audit-trail': 'audit-trail',
  '/feeds': 'feeds',
  '/step-config': 'step-config'
};

export function describeAction(baseUrl: string, path: string, method: string) {
  const action = actions[baseUrl] ?? baseUrl;
  if (!action) {
    return null;
  }

  switch (action) {
    case 'brand':
      return describeBrandAction(path, method);
    case 'item':
      return describeItemAction(path, method);
    case 'profile':
      return describeProfileAction(path, method);
    case 'retailer':
      return describeRetailerAction(path, method);
    case 'size-upload':
      return describeSizeUploadAction(path, method);
    case 'user':
      return describeActionUserAction(path, method);
    case 'shopify':
      return 'shopify';
    case 'tag-metadata':
      return describeTagMetadataAction(path, method);
    case 'product-predictor':
      return describeProductPredictorAction(path, method);
    case 'global-configuration':
      return describeGlobalConfigAction(path, method);
    case 'feeds':
      return describeFeedsAction(path, method);
    case 'step-config':
      return describeStepConfigAction(path, method);
    case 'measurement':
    case 'multiplier':
    case 'product':
    case 'tag':
    case 'size':
    case 'type':
    case 'experiments':
    case 'slack-value':
      return describeDefaultActions(path, method);
    default:
      return `${method}_${path}`;
  }
}

function describeDefaultActions(path: string, method: string) {
  if (path === '/' && method === 'POST') {
    return 'new';
  }

  if (path.match('/[0-9]*')) {
    if (method === 'PATCH') {
      return 'edit';
    }
    if (method === 'DELETE') {
      return 'delete';
    }
  }

  return `${method}_${path}`;
}

function describeBrandAction(path: string, method: string) {
  if (path === '/batch-create' && method === 'POST') {
    return 'batchCreateBrands';
  }

  if (path.match('/.*/logo-upload/.*') && method === 'POST') {
    return 'uploadBrandLogo';
  }

  return describeDefaultActions(path, method);
}

function describeItemAction(path: string, method: string) {
  if (path === '/import' && method === 'POST') {
    return 'importItems';
  }

  if (path.match('/copy-items-from/.*') && method === 'POST') {
    return 'copyItemsFromBrand';
  }

  return describeDefaultActions(path, method);
}

function describeProfileAction(path: string, method: string) {
  if (path === '/logged-in' && method === 'POST') {
    return 'loggedIn';
  }

  if (path.match(`/notifications/[0-9]*/read`) && method === 'POST') {
    return 'readNotification';
  }

  return describeDefaultActions(path, method);
}

function describeRetailerAction(path: string, method: string) {
  if (path.match('/.*/tag-product') && method === 'POST') {
    return 'tagProduct';
  }

  if (path.match('/.*/price-plan') && method === 'POST') {
    return 'editPricePlan';
  }

  if (path.match('/.*/integrations') && method === 'POST') {
    return 'editIntegrations';
  }

  if (path.match('/.*/integrations/generate-token') && method === 'POST') {
    return 'generateToken';
  }

  if (
    path.match('/.*/integrations/generate-buckles-token') &&
    method === 'POST'
  ) {
    return 'generateBucklesToken';
  }

  if (path.match('/.*/process-feed') && method === 'POST') {
    return 'processFeed';
  }

  if (path.match('/.*/send-developer-integration-email') && method === 'POST') {
    return 'sendDeveloperEmail';
  }

  return describeDefaultActions(path, method);
}

function describeSizeUploadAction(path: string, method: string) {
  if (path.match('/retailer/.*/brand/.*') && method === 'POST') {
    return 'uploadSizeChart';
  }

  if (path.match('/[0-9]*/tag/.*') && method === 'PATCH') {
    return 'tagSizeChart';
  }

  return describeDefaultActions(path, method);
}

function describeActionUserAction(path: string, method: string) {
  if (path === '/signup' && method === 'POST') {
    return 'signup';
  }

  if (path === '/billing/info' && method === 'PATCH') {
    return 'updateBillingInfo';
  }

  if (path === '/new' && method === 'POST') {
    return 'new';
  }

  if (path === '/send-welcome' && method === 'POST') {
    return 'sendWelcome';
  }

  if (path === '/send-order-tracking-warning' && method === 'POST') {
    return 'sendOrderTrackingWarning';
  }

  if (path === '/send-traffic-drop' && method === 'POST') {
    return 'sendTrafficDrop';
  }

  return describeDefaultActions(path, method);
}

function describeTagMetadataAction(path: string, method: string) {
  if (path.match('/csv') && method === 'POST') {
    return 'importCsv';
  }

  if (path.match('/.*/migrate-product-ids') && method === 'POST') {
    return 'migrateProductIds';
  }

  if (path.match('/.*') && method === 'DELETE') {
    return 'delete';
  }

  return describeDefaultActions(path, method);
}

function describeProductPredictorAction(path: string, method: string) {
  if (path.match('/batch-predict') && method === 'POST') {
    return 'batchPredict';
  }

  if (path.match('/predict') && method === 'POST') {
    return 'predict';
  }

  if (path.match('/dictionary') && method === 'POST') {
    return 'editDictionary';
  }

  if (path.match('/ignored-words') && method === 'POST') {
    return 'editIgnoreList';
  }

  return describeDefaultActions(path, method);
}

function describeGlobalConfigAction(path: string, method: string) {
  if (path.startsWith('/reference-brands')) {
    const action = path.split('/reference-brands')[1];

    if (action.match('/order') && method === 'POST') {
      return 'editReferenceBrandsOrder';
    }

    return `${describeDefaultActions(action, method)}ReferenceBrand`;
  }

  if (path.startsWith('/reference-models')) {
    const action = path.split('/reference-models')[1];

    if (action.match('/order') && method === 'POST') {
      return 'editReferenceModelsOrder';
    }

    if (action.match('/.*/upload-image/.*')) {
      return 'uploadReferenceModelImage';
    }

    return `${describeDefaultActions(action, method)}ReferenceModel`;
  }

  return describeDefaultActions(path, method);
}

function describeFeedsAction(path: string, method: string) {
  if (path.match('/validate') && method === 'POST') {
    return 'validate';
  }

  if (path.match('/.*/start-validation')) {
    return 'startValidation';
  }

  return describeDefaultActions(path, method);
}

function describeStepConfigAction(path: string, method: string) {
  if (path.match('/[0-9]+') && method === 'POST') {
    return 'editStepConfig';
  }

  return describeDefaultActions(path, method);
}
