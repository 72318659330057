import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import {
  ImportExport,
  UnarchiveRounded as SizeChartUploadIcon,
  GetAppRounded as DownloadIcon,
  CloseRounded as CloseIcon
} from '@mui/icons-material';
import { GridRowModel } from '@mui/x-data-grid';
import styled from '@emotion/styled';

import { Error as SpotError } from 'spot-store';

import {
  Brand,
  ExtendedBrand,
  downloadObjectsAsCsv,
  SizeChartsUpload,
  SizeChartsUploadInfo,
  SizeChartsUploadItem,
  useSpot,
  RetailerItem,
  RetailerContext,
  downloadBlob,
  useErrorNotification,
  TagMetadata,
  RoleContext,
  ProductInfoResponse,
  ProductInfoListResponse,
  getCdnPath
} from '../../framework';
import {
  escapeRegExp,
  Gap,
  HorizontalLoadingBar,
  Line,
  Row,
  CenteredRow,
  BrandSelector,
  Spacer,
  ProductInfoSummary
} from '../../components';
import { UploadedSizeChartList } from './upload-list';
import { UploadSizeChartsDialog } from './upload-dialog';

const Panel = styled(Paper)`
  padding: ${p => p.theme.spacing(2)};
  position: relative;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${p => p.theme.spacing(1)};
  right: ${p => p.theme.spacing(1)};
`;

interface SizeChartsUploadState {
  retailer: RetailerItem;
  brand: ExtendedBrand;
  tagMetadata: TagMetadata[];
  onSubmit?: (items: SizeChartsUploadItem[]) => Promise<void>;
  onClose?: () => unknown;
}

export function SizeChartUpload() {
  const { t } = useTranslation();
  const { spot, query, raw, command, data, loading } = useSpot();
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [selectedItem, setSelectedItem] = useState<ProductInfoListResponse>();
  const [downloading, setDownloading] = useState(false);
  const [uploadDialog, setUploadDialog] =
    useState<SizeChartsUploadState | null>(null);

  const { retailer } = useContext(RetailerContext);

  const [searchText, setSearchText] = useState<string>('');
  const [sizeUploadRows, setSizeUploadRows] = useState<
    GridRowModel[] | undefined
  >(undefined);

  const { displayErrors, notification } = useErrorNotification();

  const { isAdmin } = useContext(RoleContext);

  const selectItem = useCallback(
    async (sizeUpload: SizeChartsUploadInfo) => {
      try {
        await query(
          `product-info/${encodeURIComponent(sizeUpload.productId)}`,
          { retailer: sizeUpload.retailer },
          ['productInfo', sizeUpload.retailer, sizeUpload.productId]
        );
        setSelectedItem({
          ...spot.data?.productInfo?.[sizeUpload.retailer]?.[
            sizeUpload.productId
          ]
        });
      } catch (e) {
        setSelectedItem(undefined);
      }
    },
    [query, spot]
  );

  useEffect(() => {
    (async () => {
      const promises: Promise<unknown>[] = [];
      promises.push(query('brand/', {}, ['brands']));
      promises.push(query('tag/', {}, ['tags']));

      await Promise.all(promises);
    })();
  }, [query]);

  const filterResults = useCallback(
    (searchValue = '') => {
      setSearchText(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

      const filteredRows =
        spot.data.sizeUploads?.filter((row: GridRowModel) => {
          return (
            searchRegex.test(row.productName) || searchRegex.test(row.productId)
          );
        }) ?? [];
      setSizeUploadRows(
        filteredRows.map(b => ({
          ...b,
          brandName:
            spot.data.brands?.find(spotBrand => b.brand === spotBrand.slug)
              ?.name ?? b.brand
        }))
      );
    },
    [spot]
  );

  const refresh = useCallback(
    async (selectedRetailer, brand) => {
      let queryUrl = 'size-upload';

      if (selectedRetailer) {
        queryUrl += `/retailer/${selectedRetailer.slug}`;

        if (brand) {
          queryUrl += `/brand/${brand.slug}`;
        }
      }

      await query(queryUrl, {}, ['sizeUploads']);
      setSelectedItem(undefined);

      filterResults(searchText ?? '');
    },
    [query, searchText, filterResults]
  );

  const deleteSizeUpload = useCallback(
    async (id: number) => {
      await command(`size-upload/${id}`, {}, { method: 'DELETE' });
      await refresh(retailer, selectedBrand);
    },
    [command, refresh, retailer, selectedBrand]
  );

  useEffect(() => {
    if (!spot.data.retailers?.length || !spot.data.brands?.length) {
      return;
    }

    const allowedBrands = [
      ...spot.data.brands.filter(b =>
        b.retailers.find(r1 =>
          retailer
            ? r1.slug === retailer.slug
            : spot.data.retailers.find(r2 => r2.slug === r1.slug)
        )
      )
    ];

    if (allowedBrands.length > 1) {
      setSelectedBrand(null);
    } else {
      setSelectedBrand(allowedBrands[0]);
    }
  }, [spot, retailer, isAdmin]);

  useEffect(() => {
    setSizeUploadRows([]);
    refresh(retailer, selectedBrand);
  }, [selectedBrand, retailer]);

  const submitSizeCharts = useCallback(
    async items => {
      if (retailer && selectedBrand && items && items.length > 0) {
        await command(
          `size-upload/retailer/${retailer.slug}/brand/${selectedBrand.slug}`,
          items,
          {
            method: 'POST'
          }
        );

        await refresh(retailer, selectedBrand);
      }
    },
    [retailer, selectedBrand, refresh, command]
  );

  const uploadSizeCharts = useCallback(
    async (
      currentBrand: Brand,
      item: SizeChartsUploadItem
    ): Promise<SizeChartsUpload> => {
      const result = await raw(
        `size-upload/brand/${currentBrand.slug}/file-upload-url/${item.sizeChartFileObj?.name}`
      );

      const url = result?.url;

      if (!url) {
        throw new Error(
          `Failed to obtain upload URL for file '${item.sizeChartFileObj?.name}'`
        );
      }

      const uploadResponse: Response = await fetch(url, {
        method: 'PUT',
        body: item.sizeChartFileObj
      });

      if (!uploadResponse.ok) {
        throw new Error(
          `Failed to upload file '${item.sizeChartFileObj?.name}'`
        );
      }

      const fileUrl = new URL(url);
      const uploadedFileUrl = `${fileUrl.origin}${fileUrl.pathname}`;

      return {
        productName: item.productName,
        productUrl: item.productUrl,
        gender: item.gender,
        sizeChartFile: item.sizeChartFile,
        sizeChartFileUrl: uploadedFileUrl
      };
    },
    [raw]
  );

  const openSizeUploadDialog = useCallback(
    async (currentBrand: Brand | null) => {
      if (retailer && currentBrand) {
        await query(`brand/${currentBrand.slug}`, {}, ['extendedBrand']);
        await query(
          `tag-metadata`,
          { brand: currentBrand.slug, retailer: retailer.slug },
          ['tagMetadata']
        );

        setUploadDialog({
          retailer,
          brand: spot.data.extendedBrand,
          tagMetadata: spot.data.tagMetadata,
          onClose: () => setUploadDialog(null),
          onSubmit: async (items: SizeChartsUploadItem[]) => {
            // TODO: validate input
            try {
              const uploadPromises: Promise<SizeChartsUpload>[] = [];

              items.forEach(item => {
                const promise = uploadSizeCharts(currentBrand, item);
                uploadPromises.push(promise);
              });

              const uploadedItems = await Promise.all(uploadPromises);
              await submitSizeCharts(uploadedItems);
            } catch (e) {
              displayErrors(e as SpotError[]);
            }
          }
        });
      }
    },
    [
      retailer,
      setUploadDialog,
      displayErrors,
      query,
      spot.data.extendedBrand,
      spot.data.tagMetadata,
      submitSizeCharts,
      uploadSizeCharts
    ]
  );

  const handleTagSave = useCallback(
    async (sizeChartUploadId: number, tagName: string) => {
      try {
        await command(
          `size-upload/${sizeChartUploadId}/tag`,
          { tagName },
          {
            method: 'PATCH'
          }
        );

        await refresh(retailer, selectedBrand);
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [selectedBrand, refresh, command, displayErrors, retailer]
  );

  const handleExportItems = useCallback(
    async (currentBrand: Brand | null) => {
      if (spot.data.sizeUploads) {
        const rows = spot.data.sizeUploads.map(
          (item: SizeChartsUploadInfo) => ({
            brand: item.brand,
            productName: item.productName,
            productUrl: item.productUrl,
            productId: item.productId,
            gender: item.gender,
            sizeChartFile: item.sizeChartFile,
            sizeChartFileUrl: item.sizeChartFileUrl,
            tag: item.tag,
            uploadedAt: item.uploadedAt,
            uploadedByEmail: item.uploadedBy?.email,
            uploadedByFirstName: item.uploadedBy?.firstName,
            uploadedByLastName: item.uploadedBy?.lastName
          })
        );

        const filename = `${
          currentBrand?.slug ?? 'AllBrands'
        }_size_chart_uploads.csv`;
        downloadObjectsAsCsv(rows, filename);
      }
    },
    [spot]
  );

  const downloadSizeChartTemplate = useCallback(async () => {
    setDownloading(true);
    const response = await fetch(
      `${getCdnPath()}/downloads/Faslet-Size-Chart%20Template.xlsx`
    );
    const file = await response.blob();

    setDownloading(false);
    downloadBlob(file, 'Faslet-SizeChart-Template.xlsx');
  }, []);

  return (
    <>
      <Typography variant="h4">{t('sizeChartUpload')}</Typography>
      <Line />
      <HorizontalLoadingBar loading={downloading || loading} />
      <CenteredRow>
        <BrandSelector
          includeAllBrands
          onBrandChanged={setSelectedBrand}
          selectedBrand={selectedBrand}
        />
        <Spacer />
        <Button
          title={t('downloadSizeChartTemplate')}
          variant="text"
          color="primary"
          onClick={downloadSizeChartTemplate}
          startIcon={<DownloadIcon />}
        >
          {t('downloadSizeChartTemplate')}
        </Button>
        <Gap />
        <Button
          title={t('uploadSizeCharts')}
          disabled={!selectedBrand || !retailer}
          variant="contained"
          color="primary"
          onClick={() => openSizeUploadDialog(selectedBrand)}
          startIcon={<SizeChartUploadIcon />}
        >
          {t('upload')}
        </Button>
        <Gap />
        <Button
          title={t('exportSizeUploads')}
          disabled={!spot.data.sizeUploads}
          variant="contained"
          color="primary"
          onClick={() => handleExportItems(selectedBrand)}
          startIcon={<ImportExport />}
        >
          {t('export')}
        </Button>
      </CenteredRow>
      <Row>
        {(!selectedBrand || !retailer) && (
          <Typography variant="caption" color="error">
            {t('selectBrandAndRetailerToUpload')}
          </Typography>
        )}
      </Row>
      <Gap />
      <Grid container>
        <Grid xs={selectedItem ? 8 : 12}>
          <Row>
            {sizeUploadRows && (
              <UploadedSizeChartList
                sizeUploadRows={sizeUploadRows}
                deleteSizeUpload={deleteSizeUpload}
                onSearchTermChanged={filterResults}
                tagNames={data.tags?.map(tag => tag.name)}
                onTagSave={handleTagSave}
                onSizeUploadSelected={selectItem}
              />
            )}
          </Row>
        </Grid>
        {selectedItem && (
          <Grid xs={4}>
            <Panel>
              <ProductInfoSummary
                product={selectedItem}
                onRefresh={() => {}}
                onUpdateProduct={setSelectedItem}
              />
              <CloseButton
                onClick={() => {
                  setSelectedItem(undefined);
                }}
              >
                <CloseIcon />
              </CloseButton>
            </Panel>
          </Grid>
        )}
      </Grid>
      {!!uploadDialog && (
        <UploadSizeChartsDialog
          tagMetadata={uploadDialog.tagMetadata}
          brand={uploadDialog.brand}
          dialogOpen={!!uploadDialog}
          onClose={uploadDialog?.onClose}
          onSubmit={uploadDialog?.onSubmit}
        />
      )}
      {notification}
    </>
  );
}
