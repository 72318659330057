import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';

import {
  Brand,
  ErrorProfile,
  formatStat,
  RetailerContext,
  useSpot
} from 'framework';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Box,
  Slider
} from '@mui/material';

import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';

import {
  Spacer,
  Gap,
  CenteredRow,
  FormRow,
  FormLabel,
  FormFieldWrapper
} from 'components';
import { ErrorProfileInfo } from './error-profile-info';
import { DataContainer, SelectWrapper } from './common';

export interface ErrorProfilesProps {
  onLoadingChanged: (loading: boolean) => void;
  startDate: moment.Moment | undefined;
  endDate: moment.Moment | undefined;
  selectedBrand: Brand | null;
}

export function ErrorProfiles({
  onLoadingChanged,
  startDate,
  endDate,
  selectedBrand
}: ErrorProfilesProps) {
  const { t } = useTranslation();
  const { spot, query } = useSpot();
  const { retailer } = useContext(RetailerContext);

  const [loading, setLoading] = useState(false);

  const [selectedErrorProfile, setSelectedErrorProfile] =
    useState<ErrorProfile>();

  const [allProfiles, setAllProfiles] = useState<ErrorProfile[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>(
    'no-nearest-sizes-found'
  );
  const [threshold, setThreshold] = useState<number>(30);
  const [thresholdInternal, setThresholdInternal] = useState<number>(threshold);
  const timeoutHandle = useRef(-1);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  const handleThresholdChange = (event, newValue) => {
    setThresholdInternal(newValue);
    if (timeoutHandle.current >= 0) {
      clearTimeout(timeoutHandle.current);
    }
    timeoutHandle.current = window.setTimeout(() => {
      setThreshold(newValue);
    }, 500);
  };

  const getErrors = useCallback(async () => {
    setLoading(true);

    const errorsClause =
      errorMessage !== 'all-errors'
        ? `&errorMessage=${encodeURIComponent(errorMessage)}`
        : '';
    let retailers: string[] = [];
    if (retailer) {
      retailers = [encodeURIComponent(retailer.name), retailer.slug];
    } else if (spot.data?.profile?.role !== 'admin') {
      retailers = spot.data.retailers.reduce(
        (accum, r) => [...accum, encodeURIComponent(r.name), r.slug],
        [] as string[]
      );
    }

    const params = {
      startDate: moment(startDate).startOf('day').toISOString(),
      endDate: moment(endDate).endOf('day').toISOString(),
      retailers,
      brands: selectedBrand ? [selectedBrand.name] : [],
      threshold
    } as Record<string, any>;

    if (errorMessage !== 'all-errors') {
      params.errorMessage = errorMessage;
    }

    await query(`analytics/error-profiles`, params, ['errorProfiles']);

    setAllProfiles(spot.data.errorProfiles.errors);

    setLoading(false);
  }, [
    spot,
    query,
    retailer,
    startDate,
    endDate,
    t,
    errorMessage,
    selectedBrand,
    threshold
  ]);

  useEffect(() => {
    startDate && endDate && getErrors();
  }, [getErrors, setAllProfiles]);

  const headers: GridColumns = [
    {
      field: 'height',
      headerName: t('height'),
      renderCell: (params: GridCellParams) =>
        `${formatStat(params.value, false, 0)} cm`,
      flex: 1
    },
    {
      field: 'weight',
      headerName: t('weight'),
      renderCell: (params: GridCellParams) =>
        `${formatStat(params.value, false, 0)} kg`,
      flex: 1
    },
    {
      field: 'gender',
      headerName: t('gender'),
      renderCell: (params: GridCellParams) => t(params.value as string),
      flex: 1
    },
    {
      field: 'users',
      headerName: t('users'),
      align: 'right',
      width: 150,
      renderCell: (params: GridCellParams) => formatStat(params.value)
    }
  ];

  return (
    <>
      <CenteredRow>
        <FormRow>
          <FormLabel>{t('users')}</FormLabel>
          <FormFieldWrapper>
            <Slider
              value={thresholdInternal}
              onChange={handleThresholdChange}
              marks
              valueLabelDisplay="on"
              step={1}
              min={0}
              max={50}
            />
          </FormFieldWrapper>
        </FormRow>
        <Spacer />
        <SelectWrapper>
          <Select
            fullWidth
            onChange={(e: SelectChangeEvent<string>) =>
              setErrorMessage(e.target.value)
            }
            value={errorMessage}
          >
            <MenuItem value="all-errors">{t('all-errors')}</MenuItem>
            <MenuItem value="size-label-mismatch">
              {t('size-label-mismatch')}
            </MenuItem>
            <MenuItem value="no-sizes-for-brand">
              {t('no-sizes-for-brand')}
            </MenuItem>
            <MenuItem value="no-nearest-sizes-found">
              {t('no-nearest-sizes-found')}
            </MenuItem>
            <MenuItem value="retailer-not-found">
              {t('retailer-not-found')}
            </MenuItem>
            <MenuItem value="brand-not-found">{t('brand-not-found')}</MenuItem>
          </Select>
        </SelectWrapper>
      </CenteredRow>
      <Gap />
      <Grid container>
        <Grid xs={selectedErrorProfile ? 6 : 12}>
          <Box
            style={{
              display: 'flex',
              height: '58vh',
              width: '100%',
              position: 'relative'
            }}
          >
            <DataGrid
              disableColumnMenu
              disableColumnSelector
              disableSelectionOnClick
              loading={loading}
              rows={allProfiles}
              onRowClick={gridRow =>
                setSelectedErrorProfile(gridRow.row as ErrorProfile)
              }
              columns={headers}
              autoPageSize
              getRowId={row => `${row.height}-${row.weight}-${row.gender}`}
              selectionModel={
                selectedErrorProfile
                  ? `${selectedErrorProfile.height}-${selectedErrorProfile.weight}-${selectedErrorProfile.gender}`
                  : undefined
              }
              columnBuffer={2}
              headerHeight={40}
              rowHeight={52}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'errorPercentage', sort: 'desc' }]
                }
              }}
            />
          </Box>
        </Grid>
        {!!selectedErrorProfile && (
          <Grid xs={6}>
            <DataContainer>
              <ErrorProfileInfo errorProfile={selectedErrorProfile} />
            </DataContainer>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ErrorProfiles;
