export const ageRangeLabels = {
  0: `<15`,
  15: `15`,
  25: `25`,
  35: `35`,
  45: `45`,
  55: `55`,
  65: `65`,
  75: `75+`
} as Record<number, string>;

export const heightRangeLabels = {
  110: `110`,
  120: `120`,
  130: `130`,
  140: `140`,
  150: `150`,
  160: `160`,
  170: `170`,
  180: `180`,
  190: `190`,
  200: `200`,
  210: `210+`
} as Record<number, string>;

export const weightRangeLabels = {
  40: `40`,
  50: `50`,
  60: `60`,
  70: `70`,
  80: `80`,
  90: `90`,
  100: `100`,
  110: `110`,
  120: `120`,
  130: `130+`
} as Record<number, string>;

export const fitLabels = {
  1: `Tight`,
  2: `Regular`,
  3: `Loose`
} as Record<number, string>;

export const chestLabels = {
  1: `Narrow`,
  2: `Regular`,
  3: `Wide`
} as Record<number, string>;

export const waistLabels = {
  1: `Flat`,
  2: `Regular`,
  3: `Round`
} as Record<number, string>;

export const hipsLabels = {
  1: `Narrow`,
  2: `Regular`,
  3: `Wide`
} as Record<number, string>;

export const barMargins = { top: 20, right: 20, bottom: 60, left: 40 };

export const weightBuckets = Object.keys(weightRangeLabels).map(k =>
  Number.parseInt(k, 10)
);

export const heightBuckets = Object.keys(heightRangeLabels).map(k =>
  Number.parseInt(k, 10)
);

export const ageBuckets = Object.keys(ageRangeLabels).map(k =>
  Number.parseInt(k, 10)
);

export const fitBuckets = Object.keys(fitLabels).map(k =>
  Number.parseInt(k, 10)
);

export const chestBuckets = Object.keys(chestLabels).map(k =>
  Number.parseInt(k, 10)
);

export const waistBuckets = Object.keys(waistLabels).map(k =>
  Number.parseInt(k, 10)
);

export const hipsBuckets = Object.keys(hipsLabels).map(k =>
  Number.parseInt(k, 10)
);

export function getBucketName(
  buckets: number[],
  profileValue: number | undefined
) {
  if (!profileValue) {
    return undefined;
  }

  return buckets.find(
    (bucket, ind, arr) =>
      profileValue >= bucket && profileValue < (arr?.[ind + 1] ?? Infinity)
  );
}
export function addToBucket(
  bucketName: number,
  bucketData: { name: number; count: number }[]
) {
  let bucket = bucketData.find(b => b.name === bucketName);
  if (!bucket) {
    bucket = {
      name: bucketName,
      count: 0
    };
    bucketData.push(bucket);
  }
  bucket.count += 1;
}
export const heightBaseData = heightBuckets.map(name => ({
  name,
  count: 0
}));
export const weightBaseData = weightBuckets.map(name => ({
  name,
  count: 0
}));
export const ageBaseData = ageBuckets.map(name => ({
  name,
  count: 0
}));
export const fitBaseData = fitBuckets.map(name => ({
  name,
  count: 0
}));
export const chestBaseData = chestBuckets.map(name => ({
  name,
  count: 0
}));
export const waistBaseData = waistBuckets.map(name => ({
  name,
  count: 0
}));
export const hipsBaseData = hipsBuckets.map(name => ({
  name,
  count: 0
}));

export function stripSpecialRegexCharacters(input: string | undefined | null) {
  return input?.replace(/[.*+?^${}()|[\]\\]/g, '');
}

export function isOrderedSize(variantName: string, orderedVariant: string) {
  const sizeRegex = new RegExp(
    `\\b${stripSpecialRegexCharacters(variantName.toLowerCase())}\\b`
  );
  const match = stripSpecialRegexCharacters(
    orderedVariant?.toLocaleLowerCase()
  )?.match(sizeRegex);

  return !!match;
}
