import React from 'react';
import { CircularProgress } from '@mui/material';
import {
  Cancel as NotIntegratedIcon,
  CheckCircle as IntegratedIcon,
  HelpRounded as UnknownIcon
} from '@mui/icons-material';

export interface StatusIconProps {
  loading: boolean;
  status: 'active' | 'not-active' | 'unknown';
}

export function StatusIcon({ loading, status }: StatusIconProps) {
  if (loading) {
    return <CircularProgress size={24} />;
  }

  if (status === 'active') {
    return <IntegratedIcon color="primary" />;
  }
  if (status === 'not-active') {
    return <NotIntegratedIcon color="error" />;
  }
  if (status === 'unknown') {
    return <UnknownIcon color="secondary" />;
  }

  return null;
}
