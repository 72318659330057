import React from 'react';

interface RoleContextObject {
  role: string | null;
  onRoleChanged: (role: string | null) => unknown;
  isAdmin: () => boolean;
  hasRole: (allowedRoles: string[]) => boolean;
}

export const RoleContext = React.createContext<RoleContextObject>({
  role: null,
  onRoleChanged: () => undefined,
  isAdmin: () => false,
  hasRole: (allowedRoles: string[]) => false
});
