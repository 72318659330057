import React, { useState, ReactNode } from 'react';
import {
  Dialog as MaterialDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box
} from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { Capitalized } from 'components/styled';
import { Gap, Spacer } from 'components/spacer';
import { Error } from 'spot-store';
import { Notification } from '../notification';

const Line = styled(Box)`
  margin-bottom: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const StyledDialog = styled(MaterialDialog)`
  .MuiDialog-paperScrollPaper {
    ${p => p.theme.breakpoints.down('md')} {
      margin: 0;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    position: relative;
  }
`;

const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

interface Props<T> {
  title: ReactNode;
  initialValue?: T;
  acceptLabel?: string;
  rejectLabel?: string;
  dialogOpen: boolean;
  renderControl: (setValue: (value?: T) => void, value?: T) => JSX.Element;
  onSubmit?: (value: T) => Promise<void>;
  onClose?: () => unknown;
  canSubmit?: (value?: T) => boolean;
  onValidate?: (value?: T) => boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export function CustomDialog<T>({
  title,
  initialValue,
  acceptLabel,
  rejectLabel,
  dialogOpen,
  renderControl,
  onSubmit,
  onClose,
  canSubmit,
  onValidate,
  maxWidth
}: Props<T>) {
  const [value, setValue] = useState<T | undefined>(initialValue || undefined);

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [notification, setNotification] = useState<ReactNode>();
  const handleSnackClose = () => setNotification(undefined);

  const handleClose = () => {
    if (loading) return;
    onClose && onClose();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (value !== null && value !== undefined && !loading) {
      setLoading(true);

      try {
        if (onValidate && !onValidate(value)) {
          return;
        }

        onSubmit && (await onSubmit(value));
        setNotification(
          <Notification onClose={handleSnackClose} severity="success" />
        );
        setValue(undefined);
        onClose && onClose();
      } catch (error) {
        const singleError = error as Error;
        let errorMessage = t('error');
        if (typeof singleError?.message === 'string') {
          errorMessage = singleError.message;
        } else if (Array.isArray(error)) {
          errorMessage = error
            .map(err => {
              try {
                const parsed = JSON.parse(err.body);
                return parsed.error ?? parsed.message;
              } catch (e2) {
                return err.statusText ?? t('error');
              }
            })
            .join('. ');
        }

        setNotification(
          <Notification
            onClose={handleSnackClose}
            severity="error"
            message={`${errorMessage}`}
          />
        );

        // Reset the value
        setValue(value);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <StyledDialog
        open={dialogOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={maxWidth ?? 'sm'}
      >
        <DialogForm noValidate onSubmit={handleSubmit}>
          <DialogTitle>{title}</DialogTitle>
          <Line />
          <DialogContent>
            <Gap />
            {!!renderControl && renderControl(setValue, value)}
            <Gap />
          </DialogContent>
          <Spacer />
          <DialogActions>
            <Button onClick={handleClose} color="secondary" disabled={loading}>
              <Capitalized>{rejectLabel ?? t('cancel')}</Capitalized>
            </Button>
            {onSubmit && (
              <Button
                color="primary"
                type="submit"
                disabled={loading || (canSubmit && !canSubmit(value))}
              >
                <Capitalized>
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    acceptLabel ?? t('submit')
                  )}
                </Capitalized>
              </Button>
            )}
          </DialogActions>
        </DialogForm>
      </StyledDialog>
      {notification}
    </>
  );
}
