import React, {
  HtmlHTMLAttributes,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';

import { LabelRounded as GoToProductIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';
import { Product, RoleContext, useSpot } from '../framework';
import { CenteredRow } from './layout';
import { Gap } from './spacer';

const Wrapper = styled.div`
  flex: 0 0 360px;

  ${p => p.theme.breakpoints.down('md')} {
    flex: 0 0 300px;
  }

  display: flex;
  align-items: center;
`;

const ProductNameRow = styled(CenteredRow)`
  flex-wrap: nowrap;
  flex: 1 1 auto;
`;

const ProductName = styled(Typography)`
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const StyledInput = styled(TextField)`
  color: black;
  background-color: white;
  border-radius: 5px;
`;

const GoToProductButton = styled(IconButton)`
  color: ${p => p.theme.palette.primary.main};
`;

export interface ProductSelectorProps {
  includeAllProducts?: boolean;
  onProductChanged: (product: Product | null) => unknown;
  selectedProduct: Product | null;
  initialProductName?: string; // to allow passing url values in
  disabled?: boolean;
  ageGroup?: string;
}

export const allProductsProductId = -1;

export const allProductsProduct: Product = {
  name: 'allProducts',
  id: allProductsProductId
};

const defaultFilterOptions = createFilterOptions<Product>({
  limit: 30,
  ignoreCase: true,
  ignoreAccents: true
});

export function ProductSelector({
  includeAllProducts,
  onProductChanged,
  selectedProduct,
  initialProductName,
  disabled: propDisabled,
  ageGroup
}: ProductSelectorProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { spot, query, data, loading } = useSpot();

  const { isAdmin } = useContext(RoleContext);

  const disabled = propDisabled || loading;

  useEffect(() => {
    (async () => {
      if (!spot.data?.products) {
        await query('product/', {}, ['products']);
      }

      if (
        spot.data.products?.length === 1 &&
        selectedProduct?.id !== spot.data.products?.[0]?.id
      ) {
        onProductChanged(spot.data.products?.[0] ?? null);
      } else if (initialProductName) {
        const foundProduct = spot.data.products?.find(
          b => b.name === initialProductName
        );
        onProductChanged(foundProduct ?? null);
      } else if (!includeAllProducts) {
        onProductChanged(spot.data.products?.[0] ?? null);
      }
    })();
  }, []);

  const baseProducts = includeAllProducts
    ? [allProductsProduct]
    : ([] as Product[]);

  const defaultProduct = includeAllProducts ? allProductsProduct : null;

  const extendedProducts = baseProducts
    .concat(data?.products ?? [])
    .sort((a, b) => {
      if (a.name === 'allProducts') {
        return -1;
      }
      if (b.name === 'allProducts') {
        return 1;
      }
      if (ageGroup) {
        if (a.ageGroup === ageGroup && b.ageGroup !== ageGroup) {
          return -1;
        }
        if (a.ageGroup !== ageGroup && b.ageGroup === ageGroup) {
          return 1;
        }
      }
      return a.name?.localeCompare(b.name ?? '') ?? 0;
    });

  const productChanged = useCallback(
    (event: unknown, newProduct: Product | null) => {
      onProductChanged &&
        onProductChanged(
          newProduct?.id === allProductsProductId ? null : newProduct
        );
    },
    [onProductChanged]
  );

  const onGoToProduct = useCallback(() => {
    navigate(`/tags-and-products/products/${selectedProduct?.name}`);
  }, [navigate, selectedProduct]);

  const getOptionLabel = useCallback(
    (r: Product) => (r.id === allProductsProductId ? t(r.name) : r.name ?? '-'),
    [t]
  );

  const renderInput = useCallback(
    (params: any) => (
      <StyledInput
        {...params}
        hiddenLabel
        fullWidth
        size="small"
        InputProps={{
          ...params.InputProps,
          disableUnderline: true
        }}
      />
    ),
    []
  );

  const renderOption = useCallback(
    (props: HtmlHTMLAttributes<HTMLLIElement>, p: Product) => (
      <li {...props}>
        <ProductNameRow>
          <ProductName>
            {p.id === allProductsProductId ? t(p.name) : p.name ?? '-'}
          </ProductName>
        </ProductNameRow>
      </li>
    ),
    [t]
  );

  const getOptionSelected = useCallback(
    (r1: Product, r2: Product) => r1.name === r2.name,
    []
  );

  return (
    <Wrapper>
      <Autocomplete
        disabled={disabled}
        fullWidth
        onChange={productChanged}
        value={selectedProduct ?? defaultProduct}
        disableClearable={!defaultProduct}
        selectOnFocus
        options={extendedProducts}
        filterOptions={defaultFilterOptions}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        renderInput={renderInput}
        renderOption={renderOption}
      />
      <Gap size={1} />
      {isAdmin() && (
        <GoToProductButton
          onClick={onGoToProduct}
          disabled={!selectedProduct}
          title={t('gotoProduct')}
        >
          <GoToProductIcon color="inherit" />
        </GoToProductButton>
      )}
    </Wrapper>
  );
}
