import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Link,
  TextField,
  Select,
  MenuItem,
  SelectChangeEvent,
  Alert,
  AlertTitle,
  Button,
  Typography
} from '@mui/material';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';

import { ExtendedRetailer, BillingDetails, Features } from 'framework';
import { objectDifference, objectPatch } from 'helpers';
import {
  Gap,
  FormSection,
  FormRow,
  FormFieldWrapper,
  FormLabel,
  FormSectionTitle,
  FeatureEditor
} from '../../components';

const OptionsContainer = styled(Box)`
  display: flex;
  padding-bottom: ${p => p.theme.spacing(2)};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 0 320px;
`;

const StyledAlert = styled(Alert)`
  margin: ${p => p.theme.spacing(2)};
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

interface PricePlanProps {
  extendedRetailer: ExtendedRetailer;
  pricePlan?: BillingDetails;
  updatePricePlan: (pricePlan?: BillingDetails) => unknown;
  updateFeatures: (features: Features) => unknown;
}

export interface PricePlanDefinition {
  name: string;
  id: string;
  features: Features;
  price?: number;
  discount?: number;
}

export const featureDefaults: Features = {
  buttonHidden: false,
  skipLabelDetection: false,
  automaticTagPrediction: false,
  automaticTagOnlySizeUploads: false,
  filledWidgetResult: false,
  dualWidgetResults: false,
  expandedWidgetResults: false,
  widgetUserName: false,
  mobileHeightAndWeightInputField: false,
  ageAsDropdown: true,
  customerFeedback: false,
  widgetTester: false,
  effects: true,
  integrations: false,
  returns: false,
  profileInsights: false,
  productInsights: false,
  missedSalesInsights: false,
  productsScreen: false,
  ttsEditorInProductsScreen: false,
  braSizeInsteadOfChest: false,
  orderBasedSizing: true,
  productRecommendations: undefined,
  productRecommendationsOnlyOutOfStock: false,
  feedOnlyProductsInProductScreen: true,
  roiTile: false,
  excludeGlobalReferenceBrands: false,
  aovDashboard: undefined,
  repeatReturners: false
};

export const pricePlans: PricePlanDefinition[] = [
  {
    name: 'Single Brand - S',
    id: 'single-brand-s',
    features: {
      ...featureDefaults,
      aovDashboard: undefined,
      effects: true
    },
    price: 130
  },
  {
    name: 'Single Brand - M',
    id: 'single-brand-m',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    },
    price: 299
  },
  {
    name: 'Single Brand - L',
    id: 'single-brand-l',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    },
    price: 499
  },
  {
    name: 'Single Brand - XL',
    id: 'single-brand-xl',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    },
    price: 999
  },
  {
    name: 'Single Brand - XXL',
    id: 'single-brand-xxl',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    },
    price: 2999
  },
  {
    name: 'Single Brand - Tailored',
    id: 'single-brand-tailored',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    }
  },
  {
    name: 'Multi Brand - S',
    id: 'multi-brand-s',
    features: {
      ...featureDefaults,
      aovDashboard: undefined,
      effects: true
    },
    price: 299
  },
  {
    name: 'Multi Brand - M',
    id: 'multi-brand-m',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    },
    price: 499
  },
  {
    name: 'Multi Brand - L',
    id: 'multi-brand-l',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    },
    price: 999
  },
  {
    name: 'Multi Brand - Tailored',
    id: 'multi-brand-tailored',
    features: {
      ...featureDefaults,
      aovDashboard: { enabled: true, currencySymbol: '€' },
      effects: true,
      integrations: true,
      returns: true,
      profileInsights: true,
      productInsights: true,
      missedSalesInsights: true,
      widgetUserName: true
    }
  }
];

export function PricePlan({
  extendedRetailer,
  pricePlan,
  updatePricePlan,
  updateFeatures
}: PricePlanProps) {
  const { t } = useTranslation();

  const [selectedPricePlan, setSelectedPricePlan] = useState<
    PricePlanDefinition | undefined
  >();

  useEffect(() => {
    if (pricePlan) {
      const existingPlan = pricePlans.find(p => p.id === pricePlan.planId);
      setSelectedPricePlan(existingPlan);
    }
  }, [pricePlan]);

  const resolvedFeatures = objectDifference(
    objectPatch(featureDefaults, extendedRetailer.features ?? {}),
    selectedPricePlan?.features ?? {}
  );

  const applyPlanFeatures = useCallback(() => {
    if (selectedPricePlan) {
      updateFeatures(selectedPricePlan.features);
    }
  }, [selectedPricePlan, updateFeatures]);

  return !extendedRetailer?.brands.length ? (
    <StyledAlert severity="warning">
      <AlertTitle>{t('warning')}</AlertTitle>
      <Trans
        i18nKey="noBrands"
        components={[
          <StyledLink key="mailto" href="mailto:support@faslet.me" />
        ]}
      />
    </StyledAlert>
  ) : (
    <OptionsContainer>
      <FormSection>
        <FormRow>
          <FormLabel>{t('price')}</FormLabel>
          <FormFieldWrapper>
            <TextField
              fullWidth
              hiddenLabel
              onChange={(event: React.ChangeEvent<{ value: string }>) =>
                updatePricePlan({
                  ...pricePlan,
                  price: parseInt(event.target.value, 10)
                })
              }
              value={pricePlan?.price ?? selectedPricePlan?.price ?? ''}
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap />
        <FormRow>
          <FormLabel>{t('name')}</FormLabel>
          <FormFieldWrapper>
            <Select
              value={pricePlan?.planId ?? ''}
              displayEmpty
              fullWidth
              onChange={(event: SelectChangeEvent<unknown>) => {
                const plan = pricePlans.find(
                  p => p.id === (event.target.value as string)
                );
                if (plan) {
                  updatePricePlan({
                    ...pricePlan,
                    name: plan.name,
                    planId: plan.id,
                    price: pricePlan?.price ?? plan.price
                  });
                }
              }}
            >
              {pricePlans.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormFieldWrapper>
        </FormRow>
        <FormRow>
          <FormLabel />
          <FormFieldWrapper>
            <Button onClick={applyPlanFeatures} disabled={!selectedPricePlan}>
              {t('applyPlanFeatures')}
            </Button>
          </FormFieldWrapper>
        </FormRow>
      </FormSection>
      <FormSection>
        <FormSectionTitle>{t('features')}</FormSectionTitle>
        <FormSection>
          <Typography variant="subtitle1">
            {t('planFeaturesSubtitle')}
          </Typography>
          <FeatureEditor
            hideTitle
            features={extendedRetailer?.features ?? {}}
            onChange={updateFeatures}
            featureSet="portal"
            externallyChanged={resolvedFeatures}
          />
        </FormSection>
      </FormSection>
    </OptionsContainer>
  );
}

export default PricePlan;
