import React from 'react';
import Papa from 'papaparse';
import { DataGrid, GridCellParams, GridColumns } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { sizesAreDifferent } from 'helpers';
import { CustomDialog } from './custom-dialog';
import { isNotNullOrUndefined, Item, CsvItem } from '../../framework';
import { ImportedValue } from './imported-value';
import { SizesDiff } from './import-size-diff';
import { DataGridFrame } from '../layout';

interface Props {
  file: File;
  dialogOpen: boolean;
  onSubmit?: (items: CsvItem[]) => Promise<void>;
  onClose?: () => unknown;
  brandName?: string;
  existingItems: Item[];
}

export function ImportItemsDialog({
  file,
  dialogOpen,
  onSubmit,
  onClose,
  brandName,
  existingItems
}: Props) {
  const { t } = useTranslation();

  const itemHeaders: GridColumns = [
    { field: 'Brand', headerName: t('brandName'), flex: 1 },
    { field: 'Gender', headerName: t('gender'), flex: 1 },
    { field: 'Product', headerName: t('productName'), flex: 1 },
    { field: 'Measurement', headerName: t('measurementName'), flex: 1 },
    { field: 'TTS', headerName: t('ttsSkew'), flex: 1 },
    {
      field: 'Limit',
      headerName: t('limit'),
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const originalValue = getOriginalValue(params, existingItems, 'limit');
        return (
          <ImportedValue
            oldValue={originalValue}
            newValue={params.value}
            required
          />
        );
      }
    },
    {
      field: 'id',
      headerName: t('sizes'),
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const oldSizes = getOriginalValue(params, existingItems, 'sizes');
        const newSizes = csvItemToItem(params.row as CsvItem)?.sizes;
        return (
          <SizesDiff id={params.id} oldSizes={oldSizes} newSizes={newSizes} />
        );
      }
    }
  ];

  const csvItemToItem = (csvItem: CsvItem): Item => {
    const valueKeys = Object.keys(csvItem)
      .filter(item => item.match(/\bvalue[0-9]+/gi))
      .map(item => item.substring('value'.length));

    const sizes = valueKeys
      .map(i => {
        const maxValue = (csvItem as any)[`MaxValue${i}`];
        return {
          id: 0,
          label: (csvItem as any)[`Label${i}`],
          value: parseInt((csvItem as any)[`Value${i}`], 10),
          maxValue: maxValue ? parseInt(maxValue, 10) : null,
          aliases: [],
          displayName: csvItem.DisplayName
        };
      })
      .filter(s => isNotNullOrUndefined(s.label));

    return {
      brandName: csvItem.Brand,
      gender: csvItem.Gender,
      id: 0,
      limit: csvItem.Limit,
      measurementName: csvItem.Measurement,
      productName: csvItem.Product,
      sizes,
      ttsSkew: 0
    };
  };

  const getOriginalValue = (
    cellParams: GridCellParams,
    items: Item[],
    field: string
  ) => {
    const newItem = csvItemToItem(cellParams.row as CsvItem);

    const existingItem = items.find(
      i =>
        i.brandName === newItem.brandName &&
        i.gender === newItem.gender &&
        i.productName === newItem.productName &&
        i.measurementName === newItem.measurementName
    );

    return existingItem ? (existingItem as any)[field] : undefined;
  };

  const getImportStatus = (csvItem: CsvItem, items: Item[]) => {
    const newItem = csvItemToItem(csvItem);

    const existingItem = items.find(
      i =>
        i.brandName === newItem.brandName &&
        i.gender === newItem.gender &&
        i.productName === newItem.productName &&
        i.measurementName === newItem.measurementName
    );

    if (!existingItem) {
      return 'create';
    }
    if (
      existingItem.limit !== newItem.limit ||
      sizesAreDifferent(existingItem.sizes, newItem.sizes)
    ) {
      return 'update';
    }

    return 'not-updated';
  };

  const renderControl = (
    setValue: (val?: CsvItem[]) => void,
    value?: CsvItem[]
  ) => {
    if (!value) {
      (async () => {
        const parsed = (await new Promise((resolve, reject) => {
          Papa.parse(file, {
            header: true,
            complete: resolve,
            error: reject,
            dynamicTyping: true
          });
        })) as Papa.ParseResult<CsvItem>;

        setValue(
          parsed.data
            .filter((item: CsvItem) => item.Brand === brandName)
            .map((datum, index) => ({ id: index, ...datum }))
        );
      })();
    }
    return (
      <DataGridFrame>
        <DataGrid
          disableColumnMenu
          disableColumnSelector
          rows={value ?? []}
          columns={itemHeaders}
          isRowSelectable={() => false}
          getRowClassName={params =>
            `import-status-${getImportStatus(
              params.row as CsvItem,
              existingItems
            )}`
          }
          components={{
            NoRowsOverlay: () => (
              <div className="MuiDataGrid-overlay">
                {`${t('noBrandImportItems')} '${brandName}'`}
              </div>
            )
          }}
        />
      </DataGridFrame>
    );
  };

  return CustomDialog({
    initialValue: undefined,
    title: `${t('importItemsTitle')} '${brandName}'`,
    dialogOpen,
    onSubmit,
    onClose,
    renderControl,
    maxWidth: 'lg'
  });
}
