import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
  SelectChangeEvent,
  SvgIcon
} from '@mui/material';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowModel
} from '@mui/x-data-grid';
import {
  AddRounded as AddIcon,
  SaveRounded as SaveIcon,
  CancelRounded as CancelIcon,
  DeleteForeverRounded as DeleteIcon,
  ArrowBackRounded as BackIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  Tag,
  ExtendedTag,
  Product,
  useSpot,
  useErrorNotification,
  RoleContext
} from 'framework';

import {
  FormFieldWrapper,
  Gap,
  Row,
  Spacer,
  ConfirmDeleteAlert,
  QuickSearchToolbar,
  escapeRegExp,
  DataGridImage,
  DataGridFrame,
  FormRow,
  FormLabel,
  FormTextInput,
  FormFieldWrapperWide
} from 'components';

import { ReactComponent as SuggestIcon } from 'images/wand.svg';

export interface TagEditorProps {
  onLoadingChanged: (loading: boolean) => unknown;
}

export function TagEditor({ onLoadingChanged }: TagEditorProps) {
  const navigate = useNavigate();
  const { tagOrProduct: tag } = useParams<{ tagOrProduct?: string }>();
  const { t } = useTranslation();
  const { spot, query, command, data, loading } = useSpot();
  const [extendedTag, setExtendedTag] = useState<ExtendedTag | null>();

  const { displayErrors, notification } = useErrorNotification();

  const [confirmTagDeleteOpen, setConfirmTagDeleteOpen] =
    useState<boolean>(false);

  const [deletedTagId, setDeletedTagId] = useState<string | undefined>();

  const [searchText, setSearchText] = useState<string>('');
  const [tagRows, setTagRows] = useState<GridRowModel[]>([]);

  const { isAdmin } = useContext(RoleContext);

  useEffect(() => {
    onLoadingChanged(loading);
  }, [loading, onLoadingChanged]);

  useEffect(() => {
    (async () => {
      const promises: Promise<unknown>[] = [];

      promises.push(query('tag/', {}, ['tags']));

      if (!spot.data.products) {
        promises.push(query('product/', {}, ['products']));
      }

      if (promises.length > 0) {
        await Promise.all(promises);
      }

      setTagRows(spot.data.tags ?? []);
    })();
  }, [query, setTagRows, spot]);

  useEffect(() => {
    (async () => {
      if (tag) {
        await query(`tag/name/${tag}`, {}, ['extendedTag']);
        setExtendedTag(spot.data.extendedTag);
      } else {
        setExtendedTag(null);
      }
    })();
  }, [query, tag, spot, setExtendedTag]);

  const errorText = useCallback(
    fieldName => {
      const requiredFields = ['name', 'product', 'gender'];
      const existingFields = ['name'];

      const fieldValue = (extendedTag as any)[fieldName];

      if (requiredFields.includes(fieldName) && !fieldValue) {
        return t('required');
      }

      if (
        existingFields.includes(fieldName) &&
        spot.data.tags.find(
          b => (b as any)[fieldName] === fieldValue && b.id !== extendedTag?.id
        )
      ) {
        return t('tagExists');
      }
      return undefined;
    },
    [t, extendedTag, spot]
  );

  const hasError = useCallback(
    fieldName => {
      return !!errorText(fieldName);
    },
    [errorText]
  );

  const validate = useCallback(
    (tagObj: ExtendedTag) => {
      return !Object.keys(tagObj)
        .map(fieldName => hasError(fieldName))
        .includes(true);
    },
    [hasError]
  );

  const tagHeaders: GridColumns = [
    {
      field: 'logoS3',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 70,
      resizable: false,
      cellClassName: 'grid-select',
      renderCell: (params: GridCellParams) =>
        params.value ? (
          <DataGridImage alt={t('logo')} src={params.value?.toString()} />
        ) : (
          <span>&nbsp;</span>
        )
    },
    {
      field: 'name',
      headerName: t('name'),
      flex: 1,
      resizable: false,
      cellClassName: 'grid-select'
    },
    {
      field: 'gender',
      headerName: t('gender'),
      width: 150,
      resizable: false,
      align: 'left'
    },
    {
      field: 'product',
      headerName: t('product'),
      width: 150,
      resizable: false,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return (
          <Box display="flex">
            <Box>{(params.value as Product).name}</Box>
          </Box>
        );
      }
    },
    {
      field: 'id',
      headerName: ' ',
      filterable: false,
      sortable: false,
      width: 40,
      resizable: false,
      renderCell: (params: GridCellParams) =>
        isAdmin() ? (
          <IconButton
            className="grid-icon-button"
            onClick={() => confirmDeleteTag(params.value)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        ) : null
    }
  ];

  const addTag = useCallback(async () => {
    setExtendedTag({
      id: -1,
      name: '',
      gender: 'male',
      product: data.products[0]
    });
  }, [data]);

  const requestSearch = useCallback(
    (searchValue: string) => {
      setSearchText(searchValue);

      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');

      if (spot.data.tags) {
        const filteredRows = spot.data.tags.filter((row: GridRowModel) => {
          return searchRegex.test(row.name);
        });
        setTagRows(filteredRows);
      }
    },
    [setTagRows, spot, setSearchText]
  );

  const saveTag = useCallback(
    async tagObj => {
      if (tagObj && validate(tagObj)) {
        const tagData = {
          ...tagObj
        };

        try {
          await command(`tag/${tagObj.id > 0 ? tagObj.id : ''}`, tagData, {
            method: tagObj.id > 0 ? 'PATCH' : 'POST'
          });

          setExtendedTag(null);
          navigate(`/tags-and-products/tags`);

          await query('tag/', {}, ['tags']);

          requestSearch(searchText ?? '');
        } catch (e) {
          displayErrors(e as Error[]);
        }
      }
    },
    [
      query,
      command,
      setExtendedTag,
      navigate,
      searchText,
      requestSearch,
      validate,
      displayErrors
    ]
  );

  const goBack = useCallback(async () => {
    await query('tag/', {}, ['tags']);
    setExtendedTag(null);
    navigate(`/tags-and-products/tags`);
    requestSearch(searchText ?? '');
  }, [query, setExtendedTag, navigate, searchText, requestSearch]);

  const cancelTagEdit = useCallback(
    async tagObj => {
      if (tagObj) {
        const originalTag = spot.data.tags.find(x => x.id === tagObj.id);
        if (originalTag) {
          setExtendedTag({
            id: originalTag.id,
            name: originalTag.name,
            gender: originalTag.gender,
            product: originalTag.product
          });
        }
      }
    },
    [setExtendedTag, spot]
  );

  const deleteTag = useCallback(
    async (tagId: string | undefined) => {
      setConfirmTagDeleteOpen(false);

      try {
        if (tagId) {
          await command(`tag/${tagId}`, undefined, {
            method: 'DELETE'
          });

          await query('tag/', {}, ['tags']);

          requestSearch(searchText ?? '');
        }
      } catch (e) {
        displayErrors(e as Error[]);
      }
    },
    [query, command, requestSearch, searchText, displayErrors]
  );

  const confirmDeleteTag = useCallback(
    async tagId => {
      if (tagId) {
        setDeletedTagId(tagId);
        setConfirmTagDeleteOpen(true);
      }
    },
    [setDeletedTagId, setConfirmTagDeleteOpen]
  );

  const suggestTagName = (
    product: Product | null | undefined,
    gender: string | null | undefined
  ) => {
    if (product?.name && gender && extendedTag) {
      const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

      return `Faslet_${capitalize(product.name)}_${capitalize(gender)}`;
    }

    return '';
  };

  return !extendedTag ? (
    <>
      <ConfirmDeleteAlert
        id="confirmTagDelete"
        title={t('deleteTagConfirmation')}
        text={t('deleteTagConfirmationText')}
        open={confirmTagDeleteOpen}
        onConfirm={(tagId: string | undefined) => deleteTag(tagId)}
        value={deletedTagId}
        keepMounted
      />
      <Row>
        <Spacer />
        {isAdmin() && (
          <Button
            variant="contained"
            color="primary"
            onClick={addTag}
            startIcon={<AddIcon />}
          >
            {t('addTag')}
          </Button>
        )}
      </Row>
      <Gap />
      <DataGridFrame>
        <DataGrid
          onCellClick={(params: GridCellParams) => {
            const clickedField = params.colDef.field;
            if (!(clickedField === 'name')) {
              return;
            }
            if (params.row) {
              const selected = params.row as unknown as Tag;
              navigate(`/tags-and-products/tags/${selected.name}`);
            }
          }}
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          loading={loading}
          rows={tagRows}
          columns={tagHeaders}
          autoPageSize
          columnBuffer={2}
          headerHeight={40}
          rowHeight={52}
          components={{ Toolbar: QuickSearchToolbar }}
          componentsProps={{
            toolbar: {
              value: searchText,
              onChange: (e: React.ChangeEvent<{ value: string }>) =>
                requestSearch(e.target.value),
              clearSearch: () => requestSearch('')
            }
          }}
        />
      </DataGridFrame>
      {notification}
    </>
  ) : (
    <>
      <Row>
        <Typography variant="h4">
          {spot.data?.tags.length > 1 && (
            <IconButton onClick={goBack} size="large">
              <BackIcon />
            </IconButton>
          )}
        </Typography>
        <Spacer />
        <Button
          disabled={!validate(extendedTag)}
          variant="contained"
          color="primary"
          onClick={() => saveTag(extendedTag)}
          startIcon={<SaveIcon />}
        >
          {t('save')}
        </Button>
        {extendedTag.id <= 0 ? null : (
          <>
            <Gap />
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => cancelTagEdit(extendedTag)}
              startIcon={<CancelIcon />}
            >
              {t('reset')}
            </Button>
          </>
        )}
      </Row>
      <Gap />
      <FormRow>
        <FormLabel id="tag-name">{t('name')}</FormLabel>
        <FormFieldWrapperWide>
          <FormTextInput
            validate={() => !hasError('name')}
            onChange={(value: string) =>
              setExtendedTag({
                ...extendedTag,
                name: value
              })
            }
            value={extendedTag.name}
          />
          <Gap />
          <IconButton
            onClick={() =>
              setExtendedTag({
                ...extendedTag,
                name: suggestTagName(extendedTag?.product, extendedTag?.gender)
              })
            }
            size="large"
          >
            <SvgIcon component={SuggestIcon} />
          </IconButton>
        </FormFieldWrapperWide>
      </FormRow>
      <FormRow>
        <FormLabel id="product-name">{t('product')}</FormLabel>
        <FormFieldWrapper>
          <Autocomplete
            fullWidth
            onChange={(event, value) => {
              setExtendedTag({
                ...extendedTag,
                product: value
                  ? {
                      id: value.id,
                      name: value.name,
                      type: value.type
                    }
                  : undefined,
                name: extendedTag?.name
                  ? extendedTag.name
                  : suggestTagName(value, extendedTag?.gender)
              });
            }}
            value={extendedTag.product ?? data?.products[0]}
            options={data?.products ?? []}
            getOptionLabel={(p: Product) => p.name}
            isOptionEqualToValue={(p1: Product, p2: Product) =>
              p1?.id === p2?.id
            }
            renderInput={params => (
              <TextField {...params} required error={hasError('product')} />
            )}
          />
        </FormFieldWrapper>
      </FormRow>
      <FormRow>
        <FormLabel>{t('gender')}</FormLabel>
        <FormFieldWrapper>
          <Select
            fullWidth
            error={hasError('gender')}
            value={extendedTag.gender ?? 'male'}
            onChange={(event: SelectChangeEvent) => {
              setExtendedTag({
                ...extendedTag,
                gender: event.target.value,
                name: extendedTag?.name
                  ? extendedTag.name
                  : suggestTagName(extendedTag?.product, event.target.value)
              });
            }}
          >
            <MenuItem value="male">{t('male')}</MenuItem>
            <MenuItem value="female">{t('female')}</MenuItem>
            <MenuItem value="unisex">{t('unisex')}</MenuItem>
          </Select>
        </FormFieldWrapper>
      </FormRow>
      {notification}
    </>
  );
}
