export const batchItems = <T>(items: T[], batchSize: number) => {
  return items.reduce((accum: T[][], current: T) => {
    const result = [...accum];
    const currentBatch = result[result.length - 1];
    if (currentBatch && currentBatch.length < batchSize) {
      currentBatch.push(current);
      result[result.length - 1] = currentBatch;
    } else {
      result.push([current]);
    }

    return result;
  }, []);
};
