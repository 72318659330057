import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField
} from '@mui/material';
import {
  AddRounded as NewIcon,
  SaveRounded as SaveIcon
} from '@mui/icons-material';
import { CenteredRow, FormSection, Gap } from '../../../components';
import { ScrollableContainer, SearchContainer } from './common';
import {
  DictionaryEntryType,
  isNotNullOrUndefined,
  ProductGender
} from '../../../framework';
import { GenderRow } from './gender-row';

export function GenderDictionary({
  loading,
  genderWords,
  setGenderWords,
  saveDictionary
}: {
  loading: boolean;
  genderWords: {
    word: string;
    gender: ProductGender;
    type?: DictionaryEntryType;
    key: string;
  }[];
  setGenderWords: React.Dispatch<
    React.SetStateAction<
      {
        word: string;
        gender: ProductGender;
        type?: DictionaryEntryType;
        key: string;
      }[]
    >
  >;
  saveDictionary: () => unknown;
}) {
  const { t } = useTranslation();

  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [dragTargetIndex, setDragTargetIndex] = useState<number | null>(null);
  const [genderFilter, setGenderFilter] = useState<string | null>(null);

  const genderSearchOnChange = useCallback(
    (_, value) => {
      setGenderFilter(value);
    },
    [setGenderFilter]
  );

  const genderSearchInput = useCallback(
    p => (
      <TextField
        {...p}
        margin="none"
        placeholder={t('searchGenderWords')}
        variant="outlined"
      />
    ),
    [t]
  );

  const addGenderWord = useCallback(() => {
    setGenderFilter(null);
    setGenderWords(oldWords => [
      {
        word: 'word',
        gender: 'male',
        key: `${oldWords.length}-new-word`
      },
      ...oldWords
    ]);
  }, [setGenderWords]);

  const updateGenderWord = useCallback(
    (index, word, gender, type) => {
      setGenderWords(oldWords => {
        const newGenderWords = [...oldWords];
        newGenderWords[index] = {
          word,
          gender,
          type,
          key: newGenderWords[index].key
        };

        return [...newGenderWords];
      });
    },
    [setGenderWords]
  );

  const removeGenderWord = useCallback(
    index => {
      setGenderWords(oldWords => {
        const newWords = [...oldWords];
        newWords.splice(index, 1);
        return [...newWords];
      });
    },
    [setGenderWords]
  );

  const onDragStart = useCallback(index => {
    setDragIndex(index);
    setDragTargetIndex(null);
  }, []);

  const onDragOver = useCallback(index => {
    setDragTargetIndex(index);
  }, []);

  const onDragEnd = useCallback(() => {
    const validDrag =
      isNotNullOrUndefined(dragIndex) && isNotNullOrUndefined(dragTargetIndex);
    if (!validDrag) {
      return;
    }

    setGenderWords(oldWorlds => {
      const newWords = [...oldWorlds];
      const word = newWords[dragIndex];
      const deleteIndex =
        dragIndex < dragTargetIndex ? dragIndex : dragIndex + 1;
      newWords.splice(dragTargetIndex, 0, word);
      newWords.splice(deleteIndex, 1);
      return newWords;
    });
    setDragIndex(null);
    setDragTargetIndex(null);
  }, [setDragIndex, dragIndex, dragTargetIndex, setGenderWords]);

  const genderSearchOptions = useMemo(
    () =>
      genderWords.reduce(
        (accum, curr) =>
          [...accum, curr.word, curr.gender].filter(
            (v, index, arr) => arr.indexOf(v) === index
          ),
        [] as string[]
      ),
    [genderWords]
  );

  const genderRows = genderWords
    .filter(({ word, gender }) =>
      genderFilter
        ? word.indexOf(genderFilter) !== -1 ||
          gender.indexOf(genderFilter) !== -1
        : true
    )
    .map(({ word, gender, type, key }) => (
      <GenderRow
        key={key}
        word={word}
        gender={gender}
        type={type}
        index={genderWords.findIndex(w => w.key === key)}
        updateGenderWord={updateGenderWord}
        removeGenderWord={removeGenderWord}
        loading={loading}
        dragStart={onDragStart}
        dragOver={onDragOver}
        dragEnd={onDragEnd}
      />
    ));

  return (
    <FormSection>
      <CenteredRow>
        <SearchContainer>
          <Autocomplete
            fullWidth
            onChange={genderSearchOnChange}
            value={genderFilter}
            renderInput={genderSearchInput}
            options={genderSearchOptions}
          />
        </SearchContainer>
        <Gap />
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={addGenderWord}
          startIcon={loading ? <CircularProgress size={20} /> : <NewIcon />}
        >
          {t(`newWord`)}
        </Button>
        <Gap />
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={saveDictionary}
          startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
        >
          {t(`save`)}
        </Button>
      </CenteredRow>
      <ScrollableContainer>{genderRows}</ScrollableContainer>
    </FormSection>
  );
}
