import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleContext, useErrorNotification, useSpot } from 'framework';
import {
  TextField,
  Typography,
  Box,
  Button,
  Grid,
  Paper,
  Switch
} from '@mui/material';
import {
  CenteredRow,
  FileInputButton,
  Row,
  FormSection,
  FormRow,
  FormLabel,
  FormFieldWrapper,
  Spacer,
  Gap,
  Line
} from 'components';
import { SaveRounded as SaveIcon } from '@mui/icons-material';
import { User, Billing } from '../framework/spot-context';
import emptyAvatar from '../images/emptyAvatar.png';

export function UserAccount() {
  const { t } = useTranslation();
  const { spot, data, query, raw, command } = useSpot();
  const userId = data.profile.id;
  const currentProfile = data.profile;
  const [user, setUser] = useState<User | null>(null);
  const [billing, setBilling] = useState<Billing | null>(null);
  const { displayErrors, notification } = useErrorNotification();
  const { isAdmin } = useContext(RoleContext);

  useEffect(() => {
    (async () => {
      await query(`user/${userId}`, {}, ['currentUser']);
      setUser(spot.data?.currentUser);
      if (isAdmin()) {
        await query(`audit-trail/user/${userId}`, {}, ['auditTrail', 'user']);
      }
    })();
  }, [query, userId, spot, isAdmin]);

  useEffect(() => {
    (async () => {
      await query(`user/billing/info`, {}, ['currentBilling']);
      setBilling(spot.data?.currentBilling);
    })();
  }, [query, spot]);

  const saveUser = useCallback(async () => {
    if (user) {
      const userData = {
        ...user
      };

      const userBilling = { ...billing };

      try {
        await command(`user/${user.id}`, userData, {
          method: 'PATCH'
        });
        await command(`user/billing/info`, userBilling, {
          method: 'PATCH'
        });
        await query(`user/${user.id}`, {}, ['currentUser']);
        setUser(spot.data?.currentUser);
        await query(`user/billing/info`, {}, ['currentBilling']);
        setBilling(spot.data?.currentBilling);
      } catch (e) {
        displayErrors(e as Error[]);
      }
    }
  }, [query, command, setUser, displayErrors, user, spot.data, billing]);

  const handleAvatarUpload = useCallback(
    async (selectedUser: User, file: File) => {
      if (selectedUser) {
        const urlResult = await raw<{ url: string }>(
          `user/${selectedUser.email}/avatar-upload-url/${file.name}`
        );

        const url = urlResult?.url;

        if (url) {
          try {
            const uploadResponse: Response = await fetch(url, {
              method: 'PUT',
              body: file
            });

            if (uploadResponse.ok) {
              // TODO: use CDN url
              const avatarUrl = new URL(url);
              const avatarUrlClean = `${avatarUrl.origin}${avatarUrl.pathname}`;

              setUser({
                ...selectedUser,
                avatarS3: null
              });

              setUser({
                ...selectedUser,
                avatarS3: `${avatarUrlClean}`
              });
            }
          } catch (e) {
            displayErrors(e as Error[]);
          }
        }
      }
    },
    [raw, displayErrors]
  );

  return (
    <>
      <CenteredRow>
        <Typography variant="h4">{t('userAccount')}</Typography>
        <Spacer />
        <Button
          variant="contained"
          color="primary"
          onClick={saveUser}
          startIcon={<SaveIcon />}
        >
          {t('save')}
        </Button>
      </CenteredRow>
      <Line />
      {!!user && (
        <Row>
          <Spacer />
          <Box>
            <Grid
              container
              spacing={1}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <Paper variant="outlined" style={{ fontSize: 0 }}>
                  <img
                    width={200}
                    height={200}
                    src={user?.avatarS3 ?? user?.avatar ?? emptyAvatar}
                    alt={t('avatar')}
                    style={{ objectFit: 'scale-down' }}
                  />
                </Paper>
              </Grid>
              <Grid item>
                <Box display="flex">
                  <Box p={1}>
                    <FileInputButton
                      buttonText={t('upload')}
                      onFileSelected={file => handleAvatarUpload(user, file)}
                    />
                  </Box>
                  <Box p={1} display="block">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        setUser({
                          ...user,
                          avatarS3: null,
                          avatar: null
                        })
                      }
                    >
                      {t('delete')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <FormSection>
            <FormRow>
              <FormLabel>{t('updateFirstName')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  fullWidth
                  hiddenLabel
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    setUser({ ...user, firstName: event.target.value })
                  }
                  required
                  value={user.firstName ?? ''}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap />
            <FormRow>
              <FormLabel>{t('updateLastName')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  fullWidth
                  hiddenLabel
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    setUser({ ...user, lastName: event.target.value })
                  }
                  required
                  value={user.lastName ?? ''}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap />
            <FormRow>
              <FormLabel>{t('email')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  fullWidth
                  hiddenLabel
                  required
                  disabled
                  value={currentProfile.email}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap />
            <FormRow>
              <FormLabel>{t('phoneNumber')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  fullWidth
                  hiddenLabel
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    setUser({ ...user, phoneNumber: event.target.value })
                  }
                  required
                  value={user.phoneNumber ?? ''}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap />
            <FormRow>
              <FormLabel>{t('updateCompanyName')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  fullWidth
                  hiddenLabel
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    !!billing &&
                    setBilling({ ...billing, companyName: event.target.value })
                  }
                  required
                  value={billing?.companyName}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap />
            <FormRow>
              <FormLabel>{t('updateTaxNumber')}</FormLabel>
              <FormFieldWrapper>
                <TextField
                  fullWidth
                  hiddenLabel
                  onChange={(event: React.ChangeEvent<{ value: string }>) =>
                    !!billing &&
                    setBilling({ ...billing, taxNumber: event.target.value })
                  }
                  required
                  value={billing?.taxNumber}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap />
            <FormRow>
              <FormLabel>{t('optOutOfEmails')}</FormLabel>
              <FormFieldWrapper>
                <Switch
                  name="optOut"
                  color="primary"
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) =>
                    setUser({
                      ...user,
                      optOutOfEmails: event.target.checked
                    })
                  }
                  checked={!!user?.optOutOfEmails}
                />
              </FormFieldWrapper>
            </FormRow>
          </FormSection>
          <Spacer />
        </Row>
      )}
      {notification}
    </>
  );
}
